import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { OgrKullanici } from 'src/app/models/ogrKullanici.model';
import { GirisService } from 'src/app/services/giris.service';
import { OgrKullaniciService } from 'src/app/services/ogrKullanici.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';

@Component({
  selector: 'ogr-sifre-islem',
  templateUrl: './ogr-sifre-islem.component.html',
  styleUrls: ['./ogr-sifre-islem.component.css']
})

export class OgrSifreIslemComponent implements OnInit {
  kaydediliyor = false;

  constructor(private formBuilder: FormBuilder, private yetkiService: YetkiService, private ogrKullaniciService: OgrKullaniciService, private route: ActivatedRoute, private router: Router, private validationService: ValidationService, private datePipe: DatePipe, private girisService: GirisService) { }
  yukleniyor: boolean = false;
  hatalar: any = {};
  detayForm = this.formBuilder.group(new OgrKullanici());
  yeni = true;


  ngOnInit(): void {

  }


  kaydetGuncelle() {
    this.kaydediliyor = true;
    this.hatalar = {};
    var form = this.detayForm.value;
    this.ogrKullaniciService.ogrSifreDegistir(form).pipe(finalize(() => {
    })).subscribe({
      next: res => {
        if (res?.message && res?.message != "") { this.yetkiService.sweetAlertSuccess(res?.message) };
        this.kaydediliyor = false;
        this.router.navigateByUrl('/')
      },
      error: err => {
        this.hatalar = this.validationService.hatalar(err.error.Errors);
  
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
        }
        this.kaydediliyor = false;
      }
    });

  }
}




