import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { finalize } from 'rxjs';
import { OgrKullanici } from 'src/app/models/ogrKullanici.model';
import { SinavTanim } from 'src/app/models/sinavTanim.model';
import { TercihBasvuruDetay, TercihBasvurularColumns, TercihYerlesenlerColumns } from 'src/app/models/tercihBasvuru.model';
import { TercihDonemTanim } from 'src/app/models/tercihDonemTanim.model';
import { Ulke } from 'src/app/models/ulke.model';
import { GirisService } from 'src/app/services/giris.service';
import { OgrKullaniciService } from 'src/app/services/ogrKullanici.service';
import { TercihBasvuruService } from 'src/app/services/tercih-basvuru.service';
import { TercihDonemTanimService } from 'src/app/services/tercih-donem-tanim.service';
import { UlkeService } from 'src/app/services/ulke.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
declare var bootstrap: any;
import { YerlestirmeTanim } from 'src/app/models/yerlestirmeTanim.model';
import { YerlestirmeTanimService } from 'src/app/services/yerlestirme-tanim.service';

@Component({
  selector: 'tercih-yerlesen-bilgi',
  templateUrl: './tercih-yerlesen-bilgi.component.html',
  styleUrls: ['./tercih-yerlesen-bilgi.component.css']
})
export class TercihYerlesenBilgiComponent implements OnInit {

  constructor(private tercihBasvuruService: TercihBasvuruService, public yerlestirmeTanimService: YerlestirmeTanimService, public yetkiService: YetkiService, private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder) { this.service = tercihBasvuruService }
  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  yerlestirmeTanimId: number = 0;
  dtOptions: any = {};
  yerlestirmeTanim: YerlestirmeTanim = new YerlestirmeTanim();

  tumBasvurular: TercihBasvuruDetay[] = [];
  bavuruTamamlanmayanGoster = true;
  columnclass: TercihYerlesenlerColumns = new TercihYerlesenlerColumns();
  aramaClass:any={ basvuruTamamlamaDurum: true };
  tercihTanimId:number=0;

  ngOnInit(): void {
    this.route.params.subscribe(param => {
      if (param['id']) {
        this.yerlestirmeTanimId = param['id'];
        this.getYerlestirmeTanimById(Number(param['id']));
       
        this.route.queryParams.subscribe(params => {
          if (params['tercihTanimId']) {
            this.tercihTanimId = Number(params['tercihTanimId']);
            this.basvuruList(Number(params['tercihTanimId']), Number(param['id']));
            this.tercihBasvuruListAllExcel();
          }
        });
       
      }

  
    });
  }


  getYerlestirmeTanimById(id:number) {
    this.yukleniyor = true;
    this.yerlestirmeTanimService.getById(id).subscribe(res => {
      this.yerlestirmeTanim = res.data;  
    }, err => {
      this.router.navigateByUrl('/')
    },);
  }

  tamamlanmayanlar() {
    this.aramaClass.basvuruTamamlamaDurum=this.bavuruTamamlanmayanGoster;
    this.gridYenile();
  }

  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      const that = this;
      $('.search-input').on('keyup change', function (evt) {
        if (evt.keyCode == 13 ) {
          dtInstance
            .column(this['id'])
            .search($(this).val() + "")
            .draw();
        }
      });
    });
  }



  basvuruDetay: TercihBasvuruDetay[] = [];
  service: TercihBasvuruService;
  yukleniyor = false;

  basvuruList(tercihTanimId:number,yerlestirmeTanimId:number) {
    this.yukleniyor = true;

    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      orderMulti: true,
      dom: 'lrtip',
      
      ajax: (dataTablesParameters: any, callback: (arg0: { recordsTotal: any; recordsFiltered: any; data: never[]; }) => void) => {
        this.tercihBasvuruService.getGridList(dataTablesParameters, JSON.stringify(this.aramaClass),  tercihTanimId,0,yerlestirmeTanimId).pipe(finalize(() => {
          this.yukleniyor = false;
        })).subscribe({
          next: res => {
            that.basvuruDetay = res.data;
            callback({
              recordsTotal: res.recordCount,
              recordsFiltered: res.recordCount,
              data: res.data
            });
          },
          error: err => {
          }
        });
      },
      columns: this.columnclass.columnsdegerler,
      language: {
        url: '/assets/image/Turkish.json',
      },
      responsive: true,
    };

  };

  gridYenile(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  tercihBasvuruListAllExcel() {
    this.tercihBasvuruService.getListAll(this.tercihTanimId).subscribe(res => {
      this.tumBasvurular = res.data.filter(p=>p.basvuruBilgi.yerlestirmeTanimId==this.yerlestirmeTanimId && p.yerlesmeDurum==true &&p.basvuruBilgi.tercihTanimId==this.tercihTanimId);
    });
  }

  exportexcel(): void {
    const currentYear = new Date();
    let fileName = currentYear.getFullYear() + " Yös Yerleşen Listesi.xlsx"
    var element = document.getElementById('kl_subscriptions_table_excel');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '' + currentYear.getFullYear() + " Yös Başvuruları");
    XLSX.writeFile(wb, fileName);
  }

}




