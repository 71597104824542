import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { TercihKontenjan } from 'src/app/models/tercihKontenjan.model';
import { SinavTanim } from 'src/app/models/sinavTanim.model';
import { SinavTanimService } from 'src/app/services/sinav-tanim.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { TercihDonemTanimService } from 'src/app/services/tercih-donem-tanim.service';
import { TercihDonemTanim } from 'src/app/models/tercihDonemTanim.model';
import { TercihKontenjanService } from 'src/app/services/tercih-kontenjan.service';

@Component({
  selector: 'tercih-kontenjan-bilgi',
  templateUrl: './tercih-kontenjan-bilgi.component.html',
  styleUrls: ['./tercih-kontenjan-bilgi.component.css']
})
export class TercihKontenjanBilgiComponent implements OnInit {

  constructor(private tercihKontenjanService: TercihKontenjanService, private tercihTanimService: TercihDonemTanimService,public yetkiService: YetkiService, private route: ActivatedRoute, private router: Router, private validationService: ValidationService) { 
  this.tercihKontenjanDetayService=tercihKontenjanService;
  
  }

  tercihKontenjanDetay: TercihKontenjan[] = [];
  tercihKontenjanDetayService: TercihKontenjanService;
  siliniyor=false;
  
  tercihTanim:TercihDonemTanim=new TercihDonemTanim();
  tercihTanimid:number=0;
  @ViewChild('grid') grid?: GridComponent;
  
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.tercihTanimid = params['id'];
        this.getTercihTanimById(Number(params['id']))
      }
    });

  }


  getTercihTanimById(id: number) {
    this.tercihTanimService.getById(id).subscribe(res => {
      this.tercihTanim = res.data;
    }, err => {
      this.router.navigateByUrl('/')
    },);
  }



  silModal(tercihKontenjanTanim: TercihKontenjan) {
    Swal.fire({
      title: 'Kontenjan bilgilerini pasife almak istediğinize eminmisiniz ? <span class=text-danger>( İşlem geri alınamaz. )</span>',
      text: 'TercihKontenjan SİL',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sil',
      cancelButtonText: 'İptal',
    }).then((result) => {
      if (result.value) {
        this.siliniyor = true;
        this.tercihKontenjanService.delete(tercihKontenjanTanim).subscribe(response => {
          this.grid?.load();
          this.yetkiService.success("TercihKontenjan bilgileri başarıyla pasife alındı.", 1)
          this.siliniyor = false;
        }, error => {
          this.yetkiService.error("Hata oluştu.", 1)
          this.grid?.load();
          this.siliniyor = false;
        });
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("İptal edildi.", "Silme işlemi iptal edildi.", 'error')
      }
    })

  }

  
  exportexcel(): void {
    let fileName = "TercihKontenjanBilgi.xlsx"
    var element = document.getElementById('kl_subscriptions_table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
  }

}





