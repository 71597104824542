type Nullable<T> = T | null;
export class TercihDonemTanim {
    id:number=0;
    adi:string='';
    baslangicTarihi?:  Nullable<Date> = null;
    bitisTarihi:Nullable<Date> = null;
    donem:number=0;
    basvuruSayi:number=0;
    puanSiniriTipDis:number=0;
    puanSiniri:number=0;
    yapilacakTercihSayisi:number=0;
    aciklama:string='';
    anasayfaGorunurluk=false;
    yetkiliAdSoyad:string='';
    yetkiliUnvan:string='';
    yetkiliUnvanEng:string='';

    ayarlarAktarilsinMi:boolean=true;
   
    imzaResimUrl:string='';
    arsiv:boolean=false;
}

