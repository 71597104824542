import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Tanimlar } from '../models/tanimlar.model';
import { Response } from '../models/response.model';




@Injectable({
  providedIn: 'root'
})
export class TanimlarService {
  apiUrl = '/api/tanimlar';

  constructor(private http: HttpClient) { 

  }

  tanimListesi : Tanimlar[] = [];

  tanimYukle() {
    if (this.tanimListesi.length == 0)
    {
      this.getAll('', 1, 10000).subscribe(res => {
        this.tanimListesi = res.data;
      });
 
    }
  }

  tanimlarVer(tip: string = ''): Tanimlar[] {
    return this.tanimListesi.filter(p=>p.tip==tip);
  }

  tanimVer(id: number = 0): string {
    return this.tanimListesi.find(p=>p.id==id)?.aciklama ?? '';
  }

  getAll(query: string = '', page: number = 1, rowCount: number = 10): Observable<Response<Tanimlar[]>> {
    return this.http.get<Response<Tanimlar[]>>(this.apiUrl + '/getList', {
      params: {
        'aramastr': query,
        'sayfasayisi': page,
        'sayfadakisatirsayisi': rowCount
      }
    });
  }


  getById(id: number): Observable<Response<Tanimlar>> {
    return this.http.get<Response<Tanimlar>>(this.apiUrl + '/getById?id=' + id);
  }

  delete(dil: Tanimlar): Observable<Response<Tanimlar>> {
    return this.http.post<Response<Tanimlar>>(this.apiUrl + '/delete', dil)
  }


  addUpdate(dil: Tanimlar): Observable<Response<Tanimlar>> {
    if (dil.id > 0) {
      return this.http.post<Response<Tanimlar>>(this.apiUrl + "/update", dil)
    }
    else {
      return this.http.post<Response<Tanimlar>>(this.apiUrl + "/add", dil)
    }
  }
}