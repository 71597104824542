<div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h3 class="d-flex text-dark fw-bolder fs-2 flex-column mb-0">
                    <span><span class="me-4">Sınav Ek Alan Bilgi</span><i class="fas fa-file-signature me-2" style="font-size: large; color: #d4af37;"></i>
                        {{this.sinavTanim.tanim }}
                        <span>
                            &nbsp;&nbsp;
                            <i class="fas fa-calendar-alt text-success fs-3"></i>
                            {{this.sinavTanim.sinavTarih | date:'dd.MM.yyyy HH:mm' }}</span>

                    </span>
                    <span class="text-muted fs-7 fw-bold mt-2">Sınav ek alanlarını belirtiniz.</span> 
                </h3>

            </div>
           
            <div class="d-flex align-items-center gap-2 gap-lg-3">
                <button type="button" routerLink="/sinav-bilgi" id="kt_modal_new_target_cancel"
                class="btn btn-danger btn-hover-rise me-3"><i class="fas fa-reply"></i> Geri
            </button>
                <a *ngIf="yetkiService.yetkiVar(['FormTanimEkAlan.Ekle'])"
                class="btn btn-sm  btn-shadow-hover fs-6" [routerLink]="['/sinav-ek-alan-islem','yeni']"
                [queryParams]="{sinavid: sinavid}"
                    style="background-color: #77c2e9; color: white;">
                    <i class="fas fa-plus" style="color: white;"></i>
                    <!--end::Svg Icon-->Yeni Ekle
                </a>
    
            </div>
        </div>
    </div>

    <div class="post d-flex flex-column-fluid mt-10" id="kt_post">
        <div id="kt_content_container" class="container-fluid mb-10">
            <grid #grid [service]="sinavEkAlanDetayService" [id]="sinavid"  [(data)]="sinavEkAlanDetay" [rowCount]="10">
                <div style="overflow:auto;width: 100%;">
                    <table class="table   table-responsive gn-middle table-row-bordered fs-7 gy-2"
                        id="kl_subscriptions_table">
                        <thead>
                            <tr class="text-start fw-bolder fs-7 gs-0 " style="color:#cd7f32">
                                <th class="text-center" style="width: 5%;">#</th>
                                <th class="text-center">Label</th>
                                <th class="text-center">Tip</th>
                                <th class="text-center">Zorunlu Mu ?</th>
                                <th class="text-center" style="width: 15%;">Kabul Dosya Uzantilari</th>
                                <th class="text-center">Min uzunluk / Max uzunluk</th>
                                <th class="text-center mx-4" style="float: right;">işlemler</th>
                            </tr>
                        </thead>
                        <tbody class="text-gray-600 ">
                            <!-- dblclick -->
                            <tr *ngFor="let item of sinavEkAlanDetay;let i=index;" style="color: black;">
                                <td class="text-info text-left text-center fs-5" style="vertical-align: middle;">
                                    <b>{{(i+1) + (grid.page-1)*10}}</b>
                                </td>
                                <td class="text-center fs-5" style="vertical-align: middle;">
                                    {{ item.label }}
                                </td>
                                <td class="text-center fs-5" style="vertical-align: middle;">
                                    {{ item.tip }}
                                </td>
                                <td class="text-center fs-5" style="vertical-align: middle;">
                                    <label
                                                class="col-md-6 fs-4 fw-bolder text-success" title="Zorunlu alan" *ngIf="item.zorunluluk">
                                                <i class="fas fa-check-double text-success fs-4"></i></label>

                                                <label
                                                class="col-md-6 fs-4 fw-bolder text-danger" title="Zorunlu değil" *ngIf="!item.zorunluluk">
                                                <i class="fas fa-times-circle text-danger fs-3"></i></label>                              
                                </td>
                                <td class="text-center fs-5" style="vertical-align: middle;">
                                    {{ item.kabulDosyaUzantilari }}
                                </td>
                                <td class="text-center fs-5" style="vertical-align: middle;">
                                    {{ item.minUzunluk }} /  {{ item.maxUzunluk }}
                                </td>
                                <td  style="vertical-align: middle; float: right;">
                                    <div class="d-flex justify-content-center flex-shrink-0">

                                        <a *ngIf="yetkiService.yetkiVar(['FormTanimEkAlan.Düzenle'])" [routerLink]="['/sinav-ek-alan-islem',item.id]"   [queryParams]="{sinavid: sinavid}" style="background-color:#009ef8"
                                            class="btn btn-icon btn-hover-rise  btn-active-color-primary btn-sm me-1"
                                            title="Düzenle">
                                            <i class="far fa-edit text-white"></i>
                                        </a>

                                        <button *ngIf="yetkiService.yetkiVar(['FormTanimEkAlan.Sil'])"  type="button" style="background-color:#f1416c;" 
                                            class="btn btn-icon btn-hover-rise btn-active-color-danger btn-sm"
                                            title="Sil" (click)="silModal(item)">
                                            <i class="fas fa-trash-alt text-white"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </grid>
        </div>
    </div>
     

    



