import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '../models/response.model';
import { SinavEkAlan } from '../models/sinavEkAlan.model';


@Injectable({
  providedIn: 'root'
})
export class SinavEkAlanService {
  apiUrl = '/api/sinavekalan';

  constructor(private http: HttpClient) {

  }

  getAll(query: string = '', page: number = 1, rowCount: number = 10, sinavid: number = 0): Observable<Response<SinavEkAlan[]>> {
    return this.http.get<Response<SinavEkAlan[]>>(this.apiUrl + '/getList', {
      params: {
        'aramastr': query,
        'sayfasayisi': page,
        'sayfadakisatirsayisi': rowCount,
        'sinavid': sinavid
      }
    });
  }

  getListAll(): Observable<Response<SinavEkAlan[]>> {
    return this.http.get<Response<SinavEkAlan[]>>(this.apiUrl + '/getListAll');
  }

  getById(id: number): Observable<Response<SinavEkAlan>> {
    return this.http.get<Response<SinavEkAlan>>(this.apiUrl + '/getById?id=' + id);
  }

  getListInputType(): Observable<Response<any[]>> {
    return this.http.get<Response<any[]>>(this.apiUrl + '/inputType');
  }

  dosyaTipler(): Observable<Response<any[]>> {
    return this.http.get<Response<any[]>>(this.apiUrl + '/dosyaTipler');
  }


  delete(sinavEkAlan: SinavEkAlan): Observable<Response<SinavEkAlan>> {
    return this.http.post<Response<SinavEkAlan>>(this.apiUrl + '/delete', sinavEkAlan)
  }

  addUpdate(sinavEkAlan: SinavEkAlan): Observable<Response<SinavEkAlan>> {
    if (sinavEkAlan.id > 0) {
      return this.http.post<Response<SinavEkAlan>>(this.apiUrl + "/update", sinavEkAlan)
    }
    else {
      return this.http.post<Response<SinavEkAlan>>(this.apiUrl + "/add", sinavEkAlan)
    }
  }
}