import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { finalize } from 'rxjs';
import { OgrKullanici, OgrKullaniciColumns } from 'src/app/models/ogrKullanici.model';
import { OgrKullaniciService } from 'src/app/services/ogrKullanici.service';
import { SinavTanimService } from 'src/app/services/sinav-tanim.service';
import { SinavYerService } from 'src/app/services/sinav-yer.service';
import { UlkeService } from 'src/app/services/ulke.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
declare var bootstrap: any;
@Component({
  selector: 'ogr-kullanicilar',
  templateUrl: './ogr-kullanicilar.component.html',
  styleUrls: ['./ogr-kullanicilar.component.css']
})
export class OgrKullanicilarComponent implements OnInit {

  constructor(public sinavTanimService: SinavTanimService, public yetkiService: YetkiService, private route: ActivatedRoute, private router: Router, private ulkeService: UlkeService, private sinavYerService: SinavYerService, private ogrKullaniciService: OgrKullaniciService, private formBuilder: FormBuilder, private validationService: ValidationService) {  }
  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  dtOptions: any = {};
  basvuruSecilen: any;

  manuelSifre: boolean = false;
  tumBasvurular: OgrKullanici[] = [];
  bavuruTamamlanmayanGoster = true;
  base64textString: string = '';
  yeni = false;
  columnclass: OgrKullaniciColumns = new OgrKullaniciColumns();
  kaydediliyor = false;
  ogrKullaniciForm = this.formBuilder.group(new OgrKullanici());
  aramaClass:any={ basvuruTamamlamaDurum: true };
  hatalar: any = {};
  ngOnInit(): void {
    this.ogrKullaniciList();
  }

  getByIdOgr(id: number) {
    this.hatalar = {};
    this.yukleniyor = true;
    this.ogrKullaniciService.getById(id).subscribe(res => {
      this.yeni = false;
      this.ogrKullaniciForm = this.formBuilder.group(new OgrKullanici());
      this.ogrKullaniciForm.patchValue(res.data);
      this.yukleniyor = false;
    }, err => {
      this.yukleniyor = false;
      this.router.navigateByUrl('/')
    });
  }

  ngAfterViewInit(): void {

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      const that = this;
      $('.search-input').on('keyup change', function (evt) {
        if (evt.keyCode == 13) {
          dtInstance
            .column(this['id'])
            .search($(this).val() + "")
            .draw();
        }
      });
    });
  }

  ogrKullanicilar: OgrKullanici[] = [];
  yukleniyor = false;
  pageNo:number=0;
  ogrKullaniciList() {
    this.yukleniyor = true;
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      orderMulti: true,
      dom: 'lrtip',

      ajax: (dataTablesParameters: any, callback: (arg0: { recordsTotal: any; recordsFiltered: any; data: never[]; }) => void) => {
        this.ogrKullaniciService.getGridList(dataTablesParameters, JSON.stringify(this.aramaClass)).pipe(finalize(() => {
          this.yukleniyor = false;
        })).subscribe({
          next: res => {
            that.ogrKullanicilar = res.data;
            this.pageNo= (Number(dataTablesParameters.start) / Number(dataTablesParameters.length))+1;
            callback({
              recordsTotal: res.recordCount,
              recordsFiltered: res.recordCount,
              data: res.data
            });
          },
          error: err => {
          }
        });
      },
      columns: this.columnclass.columnsdegerler,
      language: {
        url: '/assets/image/Turkish.json',
      },
      responsive: true,
    };
  };

  gridYenile(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }


  sifreKaydetGuncelle() {
    this.kaydediliyor = true;
    var form = this.ogrKullaniciForm.value;
    this.ogrKullaniciService.ogrSifreDegistirAdmin(form).pipe(finalize(() => {
    })).subscribe({
      next: res => {
        if (res?.message && res?.message != "") { this.yetkiService.sweetAlertSuccess(res?.message) };
        this.kaydediliyor = false;
        bootstrap.Modal.getInstance(document.getElementById('kullanici_islem_modal')).hide();
        this.gridYenile();
      },
      error: err => {
        this.hatalar = this.validationService.hatalar(err.error.Errors);
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
        }
        this.kaydediliyor = false;
      }
    });

  }


}
