import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { RaporTanim, SinavTanim } from 'src/app/models/sinavTanim.model';
import { Bina, Salon, SalonTasimaIslemDto, SinavYer } from 'src/app/models/sinavYer.model';
import { Ulke } from 'src/app/models/ulke.model';
import { BinaService } from 'src/app/services/bina.service';
import { SalonService } from 'src/app/services/salon.service';
import { SinavTanimService } from 'src/app/services/sinav-tanim.service';
import { SinavYerService } from 'src/app/services/sinav-yer.service';
import { UlkeService } from 'src/app/services/ulke.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';
import s from 'src/assets/plugins/formvalidation/dist/es6/core/emitter';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
declare var bootstrap: any;

@Component({
  selector: 'sinav-yerler-bilgi',
  templateUrl: './sinav-yerler-bilgi.component.html',
  styleUrls: ['./sinav-yerler-bilgi.component.css']
})


export class SinavYerlerBilgiComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private sinavTanimService: SinavTanimService, private ulkeService: UlkeService, private sinavYerService: SinavYerService, public yetkiService: YetkiService, private route: ActivatedRoute, private router: Router, private validationService: ValidationService, private binaService: BinaService, private salonService: SalonService,private sanitizer: DomSanitizer) {
    this.sinavYerDetayService = sinavYerService;
    this.binaDetayDetayService = binaService;
    this.salonDetayDetayService = salonService;
  }
  secilenBina: Bina = new Bina();
  secilenSalon: Salon = new Salon();
  secilenSinavYer: SinavYer = new SinavYer();
  sinavTanim: SinavTanim = new SinavTanim();
  ulkeler: Ulke[] = [];

  sinavYerDetay: SinavYer[] = [];
  sinavYerDetayService: SinavYerService;
  detaySinavYerForm = this.formBuilder.group(new SinavYer());

  binaDetay: Bina[] = [];
  binaDetayDetayService: BinaService;
  detayBinaForm = this.formBuilder.group(new Bina());

  salonDetay: Salon[] = [];
  salonDetayDetayService: SalonService;
  detaySalonForm = this.formBuilder.group(new Salon());

  siliniyor = false;
  aliniyor = false;
  kaydediliyor = false;

  hatalar: any = {};
  sinavid: number = 0;
  yeniSinavYer = false;
  yeniBina = false;
  yeniSalon = false;
  @ViewChild('grid') grid?: GridComponent;
  salonOgrDetay: any[] = [];
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.sinavid = params['id'];
        this.sinavYeriList(this.sinavid)
        this.getSinavTanimById(this.sinavid)
        this.ulkeList();
      }
    });
  }

  getSinavTanimById(id: number) {
    this.sinavTanimService.getById(id).subscribe(res => {
      this.sinavTanim = res.data;
    }, err => {
      this.router.navigateByUrl('/')
    },);
  }

  getBinaById(id: number) {
    this.binaService.getById(id).subscribe(res => {
      this.secilenBina = res.data;
    }, err => {
      this.router.navigateByUrl('/')
    },);
  }

  getSalonById(id: number) {
    this.salonService.getById(id).subscribe(res => {
      this.secilenSalon = res.data;
    }, err => {
      this.router.navigateByUrl('/')
    },);
  }


  sinavYeriList(sinavid: number) {
    this.sinavYerService.getAll('', 1, 300, this.sinavid).subscribe(res => {
      this.sinavYerDetay = res.data;
    });
  }

  binaList(sinavyerid: number) {
    this.binaService.getAll('', 1, 300, sinavyerid).subscribe(res => {
      this.binaDetay = res.data;
    });
  }

  salonList(binaid: number) {
    this.salonService.getAll('', 1, 300, binaid).subscribe(res => {
      this.salonDetay = res.data;
    });
  }


  ulkeList() {
    this.ulkeService.getAll('', 1, 300).subscribe(res => {
      this.ulkeler = res.data;
    });
  }
  
  sinavYerGetById(id: number) {
    this.sinavYerService.getById(id).subscribe(res => {
      this.yeniSinavYer = false;
      this.detaySinavYerForm.patchValue(res.data);
      this.hatalar = {};
      document.getElementById('openSinavYeriModalButton')?.click();
    }, err => {
      this.router.navigateByUrl('/')
    },);
  }

  binaById(id: number) {
    this.binaService.getById(id).subscribe(res => {
      this.yeniBina = false;
      this.detayBinaForm.patchValue(res.data);
      this.hatalar = {};
      document.getElementById('openBinaModalButton')?.click();
    }, err => {
      this.router.navigateByUrl('/')
    },);
  }

  salonById(id: number) {
    this.salonService.getById(id).subscribe(res => {
      this.yeniSalon = false;
      this.detaySalonForm.patchValue(res.data);
      this.hatalar = {};
      document.getElementById('openSalonModalButton')?.click();
    }, err => {
      this.router.navigateByUrl('/')
    },);
  }


  silSinavYerModal(sinavYer: SinavYer) {
    Swal.fire({
      title: 'Sınav yeri bilgilerini pasife almak istediğinize eminmisiniz ? <span class=text-danger>( İşlem geri alınamaz. )</span>',
      text: 'SINAV YERİ SİL',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sil',
      cancelButtonText: 'İptal',
    }).then((result) => {
      if (result.value) {
        this.siliniyor = true;
        this.sinavYerService.delete(sinavYer).subscribe(response => {
          this.reloadComponent();
          this.yetkiService.success("Sınav yeri bilgileri başarıyla pasife alındı.", 1)
          this.siliniyor = false;
        }, error => {
          this.yetkiService.error("Hata oluştu.", 1)
          this.reloadComponent();
          this.siliniyor = false;
        });
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("İptal edildi.", "Silme işlemi iptal edildi.", 'error')
      }
    })

  }

  silBinaModal(bina: Bina) {
    Swal.fire({
      title: 'Bina bilgilerini pasife almak istediğinize eminmisiniz ? <span class=text-danger>( İşlem geri alınamaz. )</span>',
      text: 'BİNA SİL',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sil',
      cancelButtonText: 'İptal',
    }).then((result) => {
      if (result.value) {
        this.siliniyor = true;
        this.binaService.delete(bina).subscribe(response => {
          this.yetkiService.success("Bina bilgileri başarıyla pasife alındı.", 1)
          this.binaList(this.secilenSinavYer.id);
          this.salonDetay = [];
          this.siliniyor = false;
        }, error => {
          this.yetkiService.error("Hata oluştu.", 1)
          this.siliniyor = false;
        });
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("İptal edildi.", "Silme işlemi iptal edildi.", 'error')
      }
    })

  }

  silSalonModal(salon: Salon) {
    Swal.fire({
      title: 'Salon bilgilerini pasife almak istediğinize eminmisiniz ? <span class=text-danger>( İşlem geri alınamaz. )</span>',
      text: 'SALON SİL',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sil',
      cancelButtonText: 'İptal',
    }).then((result) => {
      if (result.value) {
        this.siliniyor = true;
        this.salonService.delete(salon).subscribe(response => {
          this.yetkiService.success("Salon bilgileri başarıyla pasife alındı.", 1)
          this.salonList(this.secilenBina.id);

          this.siliniyor = false;
        }, error => {
          this.yetkiService.error("Hata oluştu.", 1)
          this.siliniyor = false;
        });
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("İptal edildi.", "Silme işlemi iptal edildi.", 'error')
      }
    })

  }

  reloadComponent() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/sinav-yerler-bilgi/', this.sinavid]);
  }

  sinavYeriKaydetGuncelle() {
    this.hatalar = {};
    this.kaydediliyor = true;
    var form = this.detaySinavYerForm.value;
    form.adi = form.adi?.toLocaleUpperCase().trim();
    form.sinavTanimId = Number(this.sinavid);
    this.sinavYerService.addUpdate(form).pipe(finalize(() => {
    })).subscribe({
      next: res => {
        if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
        this.kaydediliyor = false;
        bootstrap.Modal.getInstance(document.getElementById('sinav_yeri_modal')).hide();
        this.reloadComponent();
      },
      error: err => {
        this.hatalar = this.validationService.hatalar(err.error.Errors);
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
        }
        this.kaydediliyor = false;
      }
    });
  }

  binaKaydetGuncelle() {
    this.kaydediliyor = true;
    var form = this.detayBinaForm.value;
    form.sinavYerid = Number(this.secilenSinavYer.id);
    form.adi = form.adi?.toLocaleUpperCase().trim();
    this.binaService.addUpdate(form).pipe(finalize(() => {
    })).subscribe({
      next: res => {
        if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
        this.kaydediliyor = false;
        bootstrap.Modal.getInstance(document.getElementById('bina_modal')).hide();
        this.binaList(this.secilenSinavYer.id);
        this.sinavYeriList(this.sinavid)
      },
      error: err => {
        this.hatalar = this.validationService.hatalar(err.error.Errors);
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
        }
        this.kaydediliyor = false;
      }
    });
  }

  salonKaydetGuncelle() {
    this.kaydediliyor = true;
    var form = this.detaySalonForm.value;
    form.binaId = Number(this.secilenBina.id);
    form.adi = form.adi?.toLocaleUpperCase().trim();
    this.salonService.addUpdate(form).pipe(finalize(() => {
    })).subscribe({
      next: res => {
        if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
        this.kaydediliyor = false;
        bootstrap.Modal.getInstance(document.getElementById('salon_modal')).hide();
        this.salonList(this.secilenBina.id);
        this.binaList(this.secilenSinavYer.id);
      },
      error: err => {
        this.hatalar = this.validationService.hatalar(err.error.Errors);
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
        }
        this.kaydediliyor = false;
      }
    });
  }

  sinavYerEkle() {
    this.hatalar = {};
    this.yeniSinavYer = true;
    this.detaySinavYerForm = this.formBuilder.group(new SinavYer());
    document.getElementById('openSinavYeriModalButton')?.click();
  }

  binaEkle() {
    this.hatalar = {};
    this.yeniBina = true;
    this.detayBinaForm = this.formBuilder.group(new Bina());
    document.getElementById('openBinaModalButton')?.click();
  }

  salonEkle() {
    this.hatalar = {};
    this.yeniSalon = true;
    this.detaySalonForm = this.formBuilder.group(new Salon());
    document.getElementById('openSalonModalButton')?.click();
  }

  sinavYeriSec(sinavYer: SinavYer) {
    this.secilenSinavYer = sinavYer;
    this.secilenBina = new Bina();
    this.salonDetay = [];
    this.binaList(sinavYer.id);
  }

  binaSec(bina: Bina) {
    this.secilenBina = bina;
    this.secilenSalon = new Salon();
    this.salonDetay = [];
    this.salonList(bina.id);
  }

  salonSec(salon: Salon) {
    this.secilenSalon = salon;
  }

  salonListYeni:Salon[] = [];
  salonOgrList(salonid: number) {

    this.sinavTanimService.salonOgrList(this.sinavTanim.id, salonid).pipe(finalize(() => {
    })).subscribe({
      next: res => {
        this.kaydediliyor = false;
        this.salonOgrDetay = res.data;
        this.salonListYeni=this.salonDetay.filter(p=>p.id!=this.secilenSalon.id);;
        document.getElementById('openSalonOgrModalButton')?.click();
      },
      error: err => {
        this.hatalar = this.validationService.hatalar(err.error.Errors);

        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
        }
        this.kaydediliyor = false;
      }
    });

  }

  previewUrl: any = this.sanitizer.bypassSecurityTrustResourceUrl("");
  rapor: RaporTanim = new RaporTanim();
  salonBelgeAl() {
  this.aliniyor=true;
    this.sinavTanimService.salonBelgeAl(this.salonOgrDetay).pipe(finalize(() => {
    })).subscribe({
      next: res => {
        this.rapor.format = 0;
        this.rapor.data = res.data.jsonData;
        this.rapor.id = "5"; //rapor id
        this.rapor.kod="85400YP4";
        const datepipe: DatePipe = new DatePipe('en-US');
        let sinavYili = datepipe.transform(this.sinavTanim.sinavTarih, 'YYYY');
        this.rapor.name = "NEÜYÖS "+sinavYili+" Salon Belgesi";
        this.sinavTanimService.download("https://neurapor.erbakan.edu.tr/app/raporlama", this.rapor).pipe(finalize(() => {
        this.aliniyor=false})).subscribe({
          next: res1 => {     
            this.previewUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(res1.data.url);
          //  window.open(res1.url);
          },
          error: err => {
            if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
              if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
              if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
            }
           
          }
        });


      },
      error: err => {
        this.hatalar = this.validationService.hatalar(err.error.Errors);
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
        }
        this.kaydediliyor = false;
      }
    });

  }

  secilenSalonId: number = 0;
  salonTasimaIslemDto: SalonTasimaIslemDto = new SalonTasimaIslemDto();

  salonOgrTasi() {
    var salonAd = this.salonDetay.find(p => p.id == this.secilenSalonId)?.adi;

    if (this.secilenSalonId != 0 && this.ogrSecilenId.length != 0) {
      Swal.fire({
        title: 'Seçilen öğrenciler ' + salonAd + ' adlı salona taşınacaktır. Taşıma işleminden sonra <span class=text-danger>her iki salonda da sıra numaraları otomatik olarak güncellenecektir.</span> Onaylıyor musunuz?',
        text: 'TAŞI',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet taşı',
        cancelButtonText: 'İptal',
      }).then((result) => {
        if (result.value) {
          this.salonTasimaIslemDto.eskiSalonId = this.secilenSalon.id;
          this.salonTasimaIslemDto.salonId = this.secilenSalonId;
          this.salonTasimaIslemDto.basvurularId = this.ogrSecilenId;
          this.salonService.salonTasimaIslem(this.salonTasimaIslemDto).pipe(finalize(() => {
          })).subscribe({
            next: res => {
              if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
              this.ogrSecilenId = [];
              this.ogrSecArray = [];
              this.selectedAll = false;
              this.secilenSalonId = 0;
              bootstrap.Modal.getInstance(document.getElementById('salon_ogr_modal')).hide();
              this.salonList(this.secilenBina.id);
            },
            error: err => {
              if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
                if (err.error?.message && err.error?.message != "") { this.yetkiService.error(err.error?.message, 1) };
                if (err.error?.Message && err.error?.Message != "") { this.yetkiService.error(err.error?.Message, 1) };
              }
            }
          });
        }
      })
    }
    else {
      this.yetkiService.sweetAlertError("Lütfen öğrenci ve atanacak salonu seçiniz.")
    }

  }

  ogrSecArray: any[] = [];
  ogrSecilenId: any[] = [];
  selectedAll: any;

  ogrSec(event: any, item: any) {
    if (event.currentTarget.checked) {
      var id = item.hasOwnProperty('basvuruId') ? item.basvuruId : 0;
      if (!this.ogrSecilenId.includes(id)) {
        this.ogrSecilenId.push(item.basvuruId)
        this.ogrSecArray.push(item)
      }
    } else {
      this.ogrSecilenId.splice(this.ogrSecilenId.indexOf(item.basvuruId), 1);
      this.ogrSecArray.forEach((secilenItem, index) => {
        if (secilenItem.id == item.basvuruId) {
          this.ogrSecArray.splice(index, 1);
          return;
        }
      })
    }
  }

  selectAll() {
    if (this.selectedAll == true) {
      for (var a = 0; a < this.salonOgrDetay.length; a++) {
        if (!this.ogrSecilenId.includes(this.salonOgrDetay[a].basvuruId)) {
          this.ogrSecilenId.push(this.salonOgrDetay[a].basvuruId);
          this.ogrSecArray.push(this.salonOgrDetay[a])
        }
      }
    }
    else {
      this.ogrSecilenId = [];
      this.ogrSecArray = [];
    }
    for (var i = 0; i < this.salonOgrDetay.length; i++) {
      this.salonOgrDetay[i].selected = this.selectedAll;
    }
    // this.selectedIds = this.kadroFilterArray.map(({ yokKadroNo }) => yokKadroNo);
  }

  checkIfAllSelected() {
    this.selectedAll = this.salonOgrDetay.every(function (item: any) {
      return item.selected == true;
    })
  }

}
