import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { SinavTanim } from 'src/app/models/sinavTanim.model';
import { SinavTanimService } from 'src/app/services/sinav-tanim.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';
import * as Editor from 'ckeditor5/build/ckeditor';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
@Component({
  selector: 'sinav-islem',
  templateUrl: './sinav-islem.component.html',
  styleUrls: ['./sinav-islem.component.css']
})
export class SinavIslemComponent implements OnInit {
  kaydediliyor = false;

  constructor(private formBuilder: FormBuilder, public yetkiService: YetkiService, private sinavTanimService: SinavTanimService, private route: ActivatedRoute, private router: Router, private validationService: ValidationService, private datePipe: DatePipe) { }
  yukleniyor: boolean = false;
  hatalar: any = {};
  detayForm = this.formBuilder.group(new SinavTanim());
  yeni = true;
  public Editor = Editor;
  public EditorEng = Editor;
  ngOnInit(): void {

    this.route.params.subscribe(params => {
      if (params['id'] != 'yeni') {
        this.getById(params['id']);
      }
      else {
        this.detayForm = this.formBuilder.group(new SinavTanim());
        this.sonSinavSay();
        this.detayForm.controls['sinavTarih'].setValue(this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm'));
      }
    });
  }

  getById(id: number) {
    this.yukleniyor = true;
    this.sinavTanimService.getById(id).subscribe(res => {
      this.yeni = false;
      this.model.editorData=res.data.aciklama;
      this.model.editorDataEng=res.data.aciklamaEng;
      this.detayForm.patchValue(res.data);
      this.yukleniyor = false;
    }, err => {
      this.yukleniyor = false;
      this.router.navigateByUrl('/')
    });
  }

  sonSinavSay() {
    this.sinavTanimService.sonSinavSayisi().subscribe(res => {
      this.detayForm.controls['sayi']?.setValue(res.data.yeniSayi);
    });
  }


  editorConfig = {
    
    toolbar: {
      
      items: [
        'bold',
        'italic',
        'underline',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'alignment',
        'fontSize',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'undo',
        'redo'
      ],
      
    },
    language: 'tr',
    licenseKey: '',
  };

  public onChange({ editor }: ChangeEvent) {
    const data = editor.getData();
  }

  public onChangeEng({ editor }: ChangeEvent) {
    const data = editor.getData();
  }


  public model = {
    editorData: '',
    editorDataEng:''
  };


  kaydetGuncelle() {
    this.kaydediliyor = true;
    this.hatalar = {};
    var form = this.detayForm.value;
    form.tanim = form.tanim?.trim();

    const datepipe: DatePipe = new DatePipe('en-US');
    let sinavTarih = datepipe.transform(form.sinavTarih, 'yyyy-MM-ddTHH:mm');
    form.sinavTarih = sinavTarih;

    let basvuruBaslangicTarihi = datepipe.transform(form.basvuruBaslangicTarihi, 'yyyy-MM-ddTHH:mm');
    form.basvuruBaslangicTarihi = basvuruBaslangicTarihi;

    let basvuruBitisTarihi = datepipe.transform(form.basvuruBitisTarihi, 'yyyy-MM-ddTHH:mm');
    form.basvuruBitisTarihi = basvuruBitisTarihi;

    if (basvuruBaslangicTarihi != null && basvuruBitisTarihi != null) {
      var dateBas = formatDate(form.basvuruBaslangicTarihi, 'yyyy-MM-ddTHH:mm', 'en_US');
      var dateBit = formatDate(form.basvuruBitisTarihi, 'yyyy-MM-ddTHH:mm', 'en_US');

      if (dateBas < dateBit) {
        form.aciklama = this.model.editorData;
        form.aciklamaEng = this.model.editorDataEng;
        this.sinavTanimService.addUpdate(form).pipe(finalize(() => {
          this.kaydediliyor = false;
        })).subscribe({
          next: res => {
            if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
            this.router.navigateByUrl('/sinav-bilgi')
          },
          error: err => {
            this.hatalar = this.validationService.hatalar(err.error.Errors);
            if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
              if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
              if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
            }
          }
        });
      }
      else {
        this.kaydediliyor = false;
        this.yetkiService.sweetAlertError('Başvuru başlangıç tarihi başvuru bitiş tarihinden küçük olmalıdır.')
      }
    }
    else {
      this.kaydediliyor = false;
      this.yetkiService.sweetAlertError('Başvuru başlangıç tarihi ve başvuru bitiş tarihi boş olmamalıdır.')
    }
  }
}




