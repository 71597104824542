type Nullable<T> = T | null;
export class YerlestirmeTanim {
    id:number=0;
    tercihTanimId:number=0;
    yerlestirmeTarihi:Date=new Date();
    yayindaMi:boolean=false;
    aciklama:string='';
    yerlestirmeNo:number=0;
    yerlesenSayisi:number=0;
    kayitBaslangicTarih?:  Nullable<Date> = null;
    kayitBitisTarih?:Nullable<Date> = null;
    arsiv:boolean=false;
}
