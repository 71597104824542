import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GirisComponent } from './modules/giris/giris.component';
import { GirisFormComponent } from './modules/giris/giris-form/giris-form.component';
import { YonetimComponent } from './modules/yonetim/yonetim.component';
import { YonetimAnasayfaComponent } from './modules/yonetim/yonetim-anasayfa/yonetim-anasayfa.component';
import { SiteComponent } from './modules/site/site.component';
import { AnasayfaComponent } from './modules/site/anasayfa/anasayfa.component';
import { SolMenuComponent } from './modules/yonetim/components/sol-menu/sol-menu.component';
import { UstMenuComponent } from './modules/yonetim/components/ust-menu/ust-menu.component';
import { AltMenuComponent } from './modules/yonetim/components/alt-menu/alt-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { BulunamadiComponent } from './modules/hata/bulunamadi/bulunamadi.component';
import { LoadingComponent } from './components/loading/loading.component';
import { GridComponent } from './components/grid/grid.component';
import { SubmitButtonComponent } from './components/submit-button/submit-button.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelect2Module } from 'ng-select2';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {DragDropModule} from '@angular/cdk/drag-drop';

import { DataTablesModule } from 'angular-datatables';
import { DatePipe } from '@angular/common';
import { SinavBilgiComponent } from './modules/yonetim/sinav-bilgi/sinav-bilgi.component';
import { SinavIslemComponent } from './modules/yonetim/sinav-bilgi/sinav-islem/sinav-islem.component';
import { SinavYerlerBilgiComponent } from './modules/yonetim/sinav-yerler-bilgi/sinav-yerler-bilgi.component';
import { TanimlarComponent } from './modules/yonetim/tanimlar/tanimlar.component';
import { TanimlarIslemComponent } from './modules/yonetim/tanimlar/tanimlar-islem/tanimlar-islem.component';
import { OgrSinavBasvuruComponent } from './modules/yonetim/ogr-sinav-basvuru/ogr-sinav-basvuru.component';
import { SinavEkAlanBilgiComponent } from './modules/yonetim/sinav-ek-alan-bilgi/sinav-ek-alan-bilgi.component';
import { SinavEkAlanIslemComponent } from './modules/yonetim/sinav-ek-alan-bilgi/sinav-ek-alan-islem/sinav-ek-alan-islem.component';
import { OgrKullaniciBilgiComponent } from './modules/yonetim/ogr-kullanici-bilgi/ogr-kullanici-bilgi.component';
import { SinavBasvurularComponent } from './modules/yonetim/sinav-basvurular/sinav-basvurular.component';
import { OgrBasvuruAyrintiComponent } from './modules/yonetim/ogr-sinav-basvuru/ogr-basvuru-ayrinti/ogr-basvuru-ayrinti.component';
import { GirisBelgeIslemComponent } from './modules/yonetim/giris-belge-islem/giris-belge-islem.component';
import { SonucBelgeIslemComponent } from './modules/yonetim/sonuc-belge-islem/sonuc-belge-islem.component';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { OgrBasvurularimComponent } from './modules/yonetim/ogr-basvurularim/ogr-basvurularim.component';
import { OgrSifreIslemComponent } from './modules/yonetim/ogr-kullanici-bilgi/ogr-sifre-islem/ogr-sifre-islem.component';
import { SafePipe, SafePipeModule } from 'safe-pipe';
import { TercihDonemBilgiComponent } from './modules/yonetim/tercih-donem-bilgi/tercih-donem-bilgi.component';
import { TercihDonemIslemComponent } from './modules/yonetim/tercih-donem-bilgi/tercih-donem-islem/tercih-donem-islem.component';
import { SinavTurComponent } from './modules/yonetim/sinav-tur/sinav-tur.component';
import { SinavTurIslemComponent } from './modules/yonetim/sinav-tur/sinav-tur-islem/sinav-tur-islem.component';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import { OgrTercihBasvuruComponent } from './modules/yonetim/ogr-tercih-basvuru/ogr-tercih-basvuru.component';
import { TercihBasvuruTipBilgiComponent } from './modules/yonetim/tercih-basvuru-tip-bilgi/tercih-basvuru-tip-bilgi.component';
import { TercihBasvuruTipIslemComponent } from './modules/yonetim/tercih-basvuru-tip-bilgi/tercih-basvuru-tip-islem/tercih-basvuru-tip-islem.component';
import { TercihKontenjanBilgiComponent } from './modules/yonetim/tercih-kontenjan-bilgi/tercih-kontenjan-bilgi.component';
import { TercihKontenjanIslemComponent } from './modules/yonetim/tercih-kontenjan-bilgi/tercih-kontenjan-islem/tercih-kontenjan-islem.component';
import { TercihBasvurularComponent } from './modules/yonetim/tercih-basvurular/tercih-basvurular.component';
import { OgrTercihBasvuruAyrintiComponent } from './modules/yonetim/ogr-tercih-basvuru/ogr-tercih-basvuru-ayrinti/ogr-tercih-basvuru-ayrinti.component';
import { OgrKullanicilarComponent } from './modules/yonetim/ogr-kullanicilar/ogr-kullanicilar.component';
import { OgrTercihBasvurularimComponent } from './modules/yonetim/ogr-tercih-basvurularim/ogr-tercih-basvurularim.component';
import { TercihYerlestirmeIslemComponent } from './modules/yonetim/tercih-yerlestirme-islem/tercih-yerlestirme-islem.component';
import { TercihYerlesenBilgiComponent } from './modules/yonetim/tercih-yerlesen-bilgi/tercih-yerlesen-bilgi.component';
import { HazircevapBilgiComponent } from './modules/yonetim/hazircevap-bilgi/hazircevap-bilgi.component';
import { HazircevapIslemComponent } from './modules/yonetim/hazircevap-bilgi/hazircevap-islem/hazircevap-islem.component';
import { IletisimBilgiComponent } from './modules/yonetim/iletisim-bilgi/iletisim-bilgi.component';
import { IletisimIslemComponent } from './modules/yonetim/iletisim-bilgi/iletisim-islem/iletisim-islem.component';
import { TercihKayitIslemComponent } from './modules/yonetim/tercih-kayit-islem/tercih-kayit-islem.component';
import { KartOdemeKontrolComponent } from './modules/yonetim/kart-odeme-kontrol/kart-odeme-kontrol.component';
import { UyelerComponent } from './modules/yonetim/uyeler/uyeler.component';
import { UyelerIslemComponent } from './modules/yonetim/uyeler/uyeler-islem/uyeler-islem.component';


@NgModule({
  declarations: [
    AppComponent,
    GirisComponent,
    GirisFormComponent,
    YonetimComponent,
    YonetimAnasayfaComponent,
    SiteComponent,
    AnasayfaComponent,
    SolMenuComponent,
    UstMenuComponent,
    AltMenuComponent,
    BulunamadiComponent,
    LoadingComponent,
    GridComponent,
    SubmitButtonComponent,
    SinavBilgiComponent,
    SinavIslemComponent,
    SinavYerlerBilgiComponent,
    TanimlarComponent,
    TanimlarIslemComponent,
    OgrSinavBasvuruComponent,
    SinavEkAlanBilgiComponent,
    SinavEkAlanIslemComponent,
    OgrKullaniciBilgiComponent,
    SinavBasvurularComponent,
    OgrBasvuruAyrintiComponent,
    GirisBelgeIslemComponent,
    SonucBelgeIslemComponent,
    OgrBasvurularimComponent,
    OgrSifreIslemComponent,
    TercihDonemBilgiComponent,
    TercihDonemIslemComponent,
    SinavTurComponent,
    SinavTurIslemComponent,
    OgrTercihBasvuruComponent,
    TercihBasvuruTipBilgiComponent,
    TercihBasvuruTipIslemComponent,
    TercihKontenjanBilgiComponent,
    TercihKontenjanIslemComponent,
    TercihBasvurularComponent,
    OgrTercihBasvuruAyrintiComponent,
    OgrKullanicilarComponent,
    OgrTercihBasvurularimComponent,
    TercihYerlestirmeIslemComponent,
    TercihYerlesenBilgiComponent,
    HazircevapBilgiComponent,
    HazircevapIslemComponent,
    IletisimBilgiComponent,
    IletisimIslemComponent,
    TercihKayitIslemComponent,
    KartOdemeKontrolComponent,
    UyelerComponent,
    UyelerIslemComponent,
  ],
 
  imports: [
    BrowserModule,
    NgMultiSelectDropDownModule.forRoot(),
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    CKEditorModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(
      {
        closeButton:true,
        autoDismiss:true,
        progressBar:true,      
      }
    ),
    FormsModule,
    NgSelect2Module,
    DataTablesModule,
    SafePipeModule,
    DragDropModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: "tr"
    })
  ],

  providers: [DatePipe, { provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,      
  }, DatePipe
],
bootstrap: [AppComponent],
  
})
export class AppModule { }
