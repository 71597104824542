import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { SinavTur } from 'src/app/models/sinavTur.model';
import { SinavTanim } from 'src/app/models/sinavTanim.model';
import { SinavTanimService } from 'src/app/services/sinav-tanim.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { SinavTurService } from 'src/app/services/sinav-tur.service';
import { TercihDonemTanimService } from 'src/app/services/tercih-donem-tanim.service';
import { TercihDonemTanim } from 'src/app/models/tercihDonemTanim.model';

@Component({
  selector: 'sinav-tur',
  templateUrl: './sinav-tur.component.html',
  styleUrls: ['./sinav-tur.component.css']
})
export class SinavTurComponent implements OnInit {

  constructor(private sinavTurService: SinavTurService, private tercihTanimService: TercihDonemTanimService,public yetkiService: YetkiService, private route: ActivatedRoute, private router: Router, private validationService: ValidationService) { 
  this.sinavTurDetayService=sinavTurService;
  
  }

  sinavTurDetay: SinavTur[] = [];
  sinavTurDetayService: SinavTurService;
  siliniyor=false;
  
  tercihTanim:TercihDonemTanim=new TercihDonemTanim();
  tercihTanimid:number=0;
  @ViewChild('grid') grid?: GridComponent;
  
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.tercihTanimid = params['id'];
        this.getTercihTanimById(Number(params['id']))
      }
    });

  }


  getTercihTanimById(id: number) {
    this.tercihTanimService.getById(id).subscribe(res => {
      this.tercihTanim = res.data;
    }, err => {
      this.router.navigateByUrl('/')
    },);
  }



  silModal(sinavTurTanim: SinavTur) {
    Swal.fire({
      title: 'Sinav tür ('+sinavTurTanim.adi+') bilgilerini pasife almak istediğinize eminmisiniz ? <span class=text-danger>( İşlem geri alınamaz. )</span>',
      text: 'SinavTur SİL',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sil',
      cancelButtonText: 'İptal',
    }).then((result) => {
      if (result.value) {
        this.siliniyor = true;
        this.sinavTurService.delete(sinavTurTanim).subscribe(response => {
          this.grid?.load();
          this.yetkiService.success("SinavTur bilgileri başarıyla pasife alındı.", 1)
          this.siliniyor = false;
        }, error => {
          this.yetkiService.error("Hata oluştu.", 1)
          this.grid?.load();
          this.siliniyor = false;
        });
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("İptal esinavTurdi.", "Silme işlemi iptal esinavTurdi.", 'error')
      }
    })

  }

  
  exportexcel(): void {
    let fileName = "SinavTurBilgi.xlsx"
    var element = document.getElementById('kl_subscriptions_table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
  }

}
