import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { finalize } from 'rxjs';
import { Tanimlar } from 'src/app/models/tanimlar.model';
import { SinavEkAlan } from 'src/app/models/sinavEkAlan.model';
import { TanimlarService } from 'src/app/services/tanimlarservice';
import { SinavEkAlanService } from 'src/app/services/sinav-ek-alan.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';

@Component({
  selector: 'sinav-ek-alan-islem',
  templateUrl: './sinav-ek-alan-islem.component.html',
  styleUrls: ['./sinav-ek-alan-islem.component.css']
})

export class SinavEkAlanIslemComponent implements OnInit {
  kaydediliyor = false;
  constructor(private formBuilder: FormBuilder, public yetkiService: YetkiService, private sinavEkAlanService: SinavEkAlanService, private route: ActivatedRoute, private router: Router, private validationService: ValidationService, private datePipe: DatePipe) { }
  yukleniyor: boolean = false;
  hatalar: any = {};
  detayForm = this.formBuilder.group(new SinavEkAlan());
  
  yeni = true;
  sinavid:number=0;
  inputTypes:any[]=[];
  olcubirim:string='';
  dosyaMi=false;
  dropdownDosyaTipList:any[] = [];
  dropdownSettings:IDropdownSettings={};
  mySelectedItems:any[]=[];

  ngOnInit(): void {
    
    this.dropdownSettings = {
      idField: 'id',
      textField: 'text',
      enableCheckAll: true,
      allowSearchFilter:true,
      searchPlaceholderText:'Ara',
      selectAllText: "Tümünü seç",
      unSelectAllText: "Seçimi kaldır",
      noDataAvailablePlaceholderText: "Kayıt bulunamadı.",
      noFilteredDataAvailablePlaceholderText:"Kayıt bulunamadı.",
    };

    this.route.params.subscribe(params => {
      if (params['id'] != 'yeni') {
        this.getById(params['id']);   
      }
      else {
        this.getListInputTypes();
        this.getDosyaTypes();
        this.detayForm = this.formBuilder.group(new SinavEkAlan());
        
      }
    });

    this.route.queryParams.subscribe(params => {
      if (params['sinavid']) {
        this.sinavid = Number(params['sinavid']);
      }
    });

  }

  getById(id: number) {
    this.yukleniyor = true;
    this.sinavEkAlanService.getById(id).subscribe(res => {
      this.getListInputTypes();
      this.getDosyaTypes();
      this.yeni = false;
       
      if(res.data.tip=='Dosya'){
        this.dosyaMi=true;
      }
      this.mySelectedItems=res.data?.secilenDosyaTipler;
      this.olcuBirimDoldur(res.data?.tip);
      
      this.detayForm.patchValue(res.data);
     
      this.yukleniyor = false;
    }, err => {
      this.yukleniyor = false;
      this.router.navigateByUrl('/')
    });
  }

  olcuBirimDoldur(tip:string){
    this.olcubirim= tip=='Metin' ? 'Girilecek metin karakter uzunluğu':tip=='Numeric' ? 'Sayı' : tip=='Dosya' ? 'MB':'';
  }


  kontrol(e: any) {
    this.olcuBirimDoldur(e.target[e.target.selectedIndex].text);
    if(e.target[e.target.selectedIndex].text=='Dosya'){
      this.dosyaMi=true;
    }
    else{
      this.dosyaMi=false;
    }
  }

  getListInputTypes() {
    this.sinavEkAlanService.getListInputType().subscribe(res => {
      this.inputTypes = res.data;
    });
  }

  getDosyaTypes() {
    this.sinavEkAlanService.dosyaTipler().subscribe(res => {
      this.dropdownDosyaTipList = res.data;
    });
  }

  kaydetGuncelle() {
    this.kaydediliyor = true;
    this.hatalar = {};
    var form = this.detayForm.value;
    form.adi=form.adi?.trim();
    form.sinavTanimId=Number(this.sinavid);
    form.secilenDosyaTipler=this.mySelectedItems;
    
    this.sinavEkAlanService.addUpdate(form).pipe(finalize(() => {
    })).subscribe({
      next: res => {
        if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
        this.kaydediliyor = false;
        this.router.navigateByUrl('/sinav-ek-alan-bilgi/'+this.sinavid)
      },
      error: err => {
        this.hatalar = this.validationService.hatalar(err.error.Errors);
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
        }
        this.kaydediliyor = false;
      }
    });

  }
}

