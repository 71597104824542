<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h3 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span translate><i class="fas fa-tasks text-success me-2"
                            style="font-size: large;"></i><span></span>anasayfa
                    </span>
                </h3>
            </div>
            <div class="d-flex align-items-center gap-2 gap-lg-3">

            </div>
        </div>
    </div>
    <div id="kt_content_container" class="mx-10 justify-content-center">
        <loading [loader]="yukleniyor">
            <div class="row gy-4 g-xl-5">
                <div class="col-xxl-7 col-12"
                    *ngIf="yetkiService.yetkiVar(['Kullanici.OgrenciIsleri']) &&(sinavTanim?.anasayfaGorunurluk==true)">
                    <div class="row gx-5 gx-xl-10">
                        <!--begin::Col-->
                        <div class="col-sm-12 mb-5 mb-xl-10">
                            <div class="card card-flush h-xl-100"
                                style="border-top: 2px #00ABD8 solid; border-left: 1px #c5d5da solid;">
                                <!--begin::Header-->
                                <div class="card-header pt-5">
                                    <h3 class="card-title align-items-start flex-column">
                                        <span class="card-label fw-bolder text-dark"><i
                                                class="fas fa-file-signature me-2 fs-2"
                                                style="color: #d4af37;"></i>Aktif Form Bilgileri -
                                            {{sinavTanim?.tanim}} <span class="text-black mt-1 fw-bold fs-4">(
                                                {{sinavTanim?.sinavTarih |
                                                date:'dd.MM.yyyy'}} )</span></span>

                                        <span class="text-black mt-1 fw-bold fs-6"> <span
                                                class="fw-semibold   d-block fs-5">Form
                                                Başlangıç / Bitiş Tarihi : <span
                                                    class="fw-bold text-info  text-hover-primary fs-4">{{sinavTanim?.basvuruBaslangicTarihi
                                                    | date:'dd.MM.yyyy'}} - {{sinavTanim?.basvuruBitisTarihi |
                                                    date:'dd.MM.yyyy'}}</span></span>
                                        </span>
                                    </h3>
                                    <div class="card-toolbar">
                                        <span class="svg-icon svg-icon-2">
                                            <i class="fas fa-book text-success" style="font-size: x-large;"></i>
                                        </span>
                                    </div>
                                </div>
                                <div class="card-body pt-0 mt-2">
                                    <!--begin::Tab content-->
                                    <div class="d-flex align-items-center bg-light-success rounded p-4 mb-2">
                                        <!--begin::Icon-->
                                        <span class="svg-icon svg-icon-success me-5">
                                            <!--begin::Svg Icon | path: icons/duotune/abstract/abs027.svg-->
                                            <span class="svg-icon svg-icon-1">
                                                <i class="fas fa-handshake text-black" style="font-size: larger;"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </span>
                                        <!--end::Icon-->
                                        <!--begin::Title-->
                                        <div class="flex-grow-1 me-2">

                                            <a class=" fs-3 cursor-pointer fw-bold  fs-6"
                                                [routerLink]="['/sinav-basvurular', this.sinavTanim?.id]">
                                                <span class="fw-bold  fs-6 text-black">
                                                    Onaylanan Form sayısı
                                                </span>
                                            </a>

                                            <span class="text-muted fw-bold d-block"></span>
                                        </div>
                                        <!--end::Title-->
                                        <!--begin::Lable-->
                                        <span class="fw-bolder text-success py-1 fs-4">{{
                                            anasayfaBilgiDto.aktifSinavBilgiDto?.onayBekleyen_Sayi}}</span>
                                        <!--end::Lable-->
                                    </div>

                                    <div class="d-flex align-items-center bg-light-danger rounded p-4 mb-2">
                                        <!--begin::Icon-->
                                        <span class="svg-icon svg-icon-danger me-5">
                                            <!--begin::Svg Icon | path: icons/duotune/abstract/abs027.svg-->
                                            <span class="svg-icon svg-icon-1">
                                                <i class="fas fa-handshake text-black" style="font-size: larger;"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </span>
                                        <!--end::Icon-->
                                        <!--begin::Title-->
                                        <div class="flex-grow-1 me-2 fw-bold fs-6" style="color: black;">

                                            <a class=" fs-3 cursor-pointer fw-bold  fs-6"
                                                [routerLink]="['/sinav-basvurular', this?.sinavTanim?.id]">
                                                <span class="fw-bold  fs-6 text-black">
                                                    Tamamlanmayan form sayısı
                                                </span>
                                            </a>

                                        </div>
                                        <!--end::Title-->
                                        <!--begin::Lable-->
                                        <span class="fw-bolder text-danger py-1 fs-4">
                                            {{ anasayfaBilgiDto.aktifSinavBilgiDto?.tamamlanmayanBasvuru_Sayi}}
                                        </span>
                                        <!--end::Lable-->
                                    </div>

                                    <div class="d-flex align-items-center bg-light-primary rounded p-4 mb-2">
                                        <!--begin::Icon-->
                                        <span class="svg-icon svg-icon-danger me-5">
                                            <!--begin::Svg Icon | path: icons/duotune/abstract/abs027.svg-->
                                            <span class="svg-icon svg-icon-1">
                                                <i class="fas fa-handshake text-black" style="font-size: larger;"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </span>
                                        <!--end::Icon-->
                                        <!--begin::Title-->
                                        <div class="flex-grow-1 me-2 fw-bold fs-6" style="color: black;">

                                            <a class=" fs-3 cursor-pointer fw-bold  fs-6"
                                                [routerLink]="['/sinav-basvurular', this?.sinavTanim?.id]">
                                                <span class="fw-bold  fs-6 text-black">
                                                    Toplam form sayısı
                                                </span>
                                            </a>

                                        </div>
                                        <!--end::Title-->
                                        <!--begin::Lable-->
                                        <span class="fw-bolder text-danger py-1 fs-4">
                                            {{ anasayfaBilgiDto.aktifSinavBilgiDto?.basvuran_Sayi}}
                                        </span>
                                        <!--end::Lable-->
                                    </div>


                                    <!--end::Body-->
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-xxl-7 col-12"
                    *ngIf="yetkiService.yetkiVar(['Kullanici.Standart']) && (sinavTanim?.anasayfaGorunurluk==true)">
                    <!--begin::Card widget 18-->
                    <div class="card" style="border: 1px solid rgb(4, 70, 13);">
                        <!--begin::Body-->
                        <div class="card-body py-9">
                            <!--begin::Row-->
                            <div class="row gx-9 h-100">
                                <div class="col-sm-12">
                                    <!--begin::Wrapper-->
                                    <div class="d-flex flex-column h-100">
                                        <!--begin::Header-->
                                        <div class="mb-7">
                                            <!--begin::Headin-->
                                            <div class="d-flex flex-stack mb-6">
                                                <!--begin::Title-->
                                                <div class="me-5">
                                                    <span *ngIf="sinavTanim!=null" class="fs-2"><i
                                                            class="fas fa-file-signature me-2 fs-2"
                                                            style="color: #d4af37;"></i>{{sinavTanim?.tanim}} 
                                                        <!-- <span
                                                            class="text-info fs-2 fw-bold me-2 d-block lh-1 pb-1">
                                                            <label translate>sinavTarihi</label> :
                                                            {{sinavTanim?.sinavTarih |date:'dd.MM.yyyy'}}
                                                        </span> -->
                                                    </span>

                                                    <span *ngIf="sinavTanim==null" class="fs-2"><i
                                                            class="fas fa-file-signature me-2 fs-2"
                                                            style="color: #d4af37;"></i>Aktif sınav bulunamadı.</span>

                                                </div>
                                                <!--end::Title-->
                                                <span
                                                    *ngIf="anasayfaBilgiDto.aktifSinavBilgiDto?.basvuruZamanAktifMi; else pasif"
                                                    class="badge badge-success flex-shrink-0 align-self-center py-3 px-4 fs-5"
                                                    translate>basvuruAcik</span>
                                                <ng-template #pasif>
                                                    <span
                                                        class="badge badge-danger flex-shrink-0 align-self-center py-3 px-4 fs-5"
                                                        translate>basvuruKapali</span>
                                                </ng-template>
                                            </div>
                                            <!--end::Heading-->
                                            <!--begin::Items-->
                                            <div class="d-flex align-items-center flex-wrap d-grid gap-2">
                                                <!--begin::Item-->
                                                <div class="d-flex align-items-center me-4 ">
                                                    <!--begin::Symbol-->
                                                    <div class="symbol symbol-30px symbol-circle me-3">
                                                        <i class="far fa-calendar-alt fs-3 me-2 text-success"></i>
                                                    </div>
                                                    <!--end::Symbol-->
                                                    <!--begin::Info-->
                                                    <div class="m-0">
                                                        <span class="fw-semibold text-gray-600 d-block fs-5"
                                                            translate>basvuruBasBit</span>
                                                        <span
                                                            class="fw-bold text-gray-800 text-hover-primary fs-4">{{sinavTanim?.basvuruBaslangicTarihi
                                                            | date:'dd.MM.yyyy'}} - {{sinavTanim?.basvuruBitisTarihi |
                                                            date:'dd.MM.yyyy'}}</span>
                                                    </div>
                                                    <!--end::Info-->
                                                </div>
                                                <!--end::Item-->
                                                <!--begin::Item-->
                                                <div class="d-flex align-items-center me-4">
                                                    <!--begin::Symbol-->
                                                    <div class="symbol symbol-40px symbol-circle me-3"
                                                        style="border: 1px solid rgb(0, 195, 255); ">
                                                        <span class="symbol-label bg-default">
                                                            <!--begin::Svg Icon | path: icons/duotune/abstract/abs042.svg-->
                                                            <i class="fas fa-check-double text-success"
                                                                *ngIf="ogrSinavBasvuru!=null && ogrSinavBasvuru.basvuruTamamlanmaDurumu, else yok1"></i>
                                                            <ng-template #yok1>
                                                                <i class="fas fa-times text-danger"></i>
                                                            </ng-template>
                                                            <!--end::Svg Icon-->
                                                        </span>
                                                    </div>
                                                    <!--end::Symbol-->
                                                    <!--begin::Info-->
                                                    <div class="m-0">
                                                        <span class="fw-semibold text-gray-600 d-block fs-5"
                                                            translate>basvuruDurumu</span>
                                                        <span class="fw-bold text-gray-800 fs-5" translate
                                                            *ngIf="ogrSinavBasvuru!=null, else yok">basvuruVar
                                                            <span class="text-success" translate
                                                                *ngIf="ogrSinavBasvuru.basvuruTamamlanmaDurumu, else tamamlanmadi">
                                                                &nbsp;<i
                                                                    class="fas fa-check me-1 text-success fs-4"></i>tamamlandi
                                                            </span>
                                                            <ng-template #tamamlanmadi>
                                                                <span class="text-danger" translate>
                                                                    &nbsp;<i
                                                                        class="fas fa-exclamation-circle me-1 text-warning fs-4"></i>tamamlanmadi
                                                                </span>
                                                            </ng-template>

                                                        </span>
                                                        <ng-template #yok>
                                                            <span class="fw-bold text-gray-800 fs-5"
                                                                translate>basvuruYok</span>
                                                        </ng-template>
                                                    </div>
                                                    <!--end::Info-->
                                                </div>
                                                <!--end::Item-->
                                            </div>
                                            <!--end::Items-->
                                        </div>
                                        <!--end::Header-->
                                        <!--begin::Body-->

                                        <div class="mb-6">
                                            <!--begin::Text-->
                                            <span class="fw-semibold text-gray-600 fs-6 mb-8 d-block"
                                                translate>basUyari</span>
                                            <!--end::Text-->
                                            <!--begin::Stats-->
                                            <div class="d-flex align-items-center">

                                                <!--begin::Stat-->
                                                <div class=" rounded min-w-100px w-100 py-2 px-4 me-6 mb-3 "
                                                    style="border: 2px solid rgb(77, 215, 240);"
                                                    *ngIf="ogrSinavBasvuru!=null">
                                                    <!--begin::Date-->
                                                    <h3 class="text-center fs-5" translate>basvuruBaslik</h3>
                                                    <hr>

                                                    <div class="fw-semibold text-gray-400">
                                                        <span class="text-black fs-5"><b translate>basvurusonucu</b>
                                                            :&nbsp; &nbsp;

                                                            <span class="fw-bolder text-black  fs-5"> <i
                                                                    class="{{sonHareketDurum?.iconClass}} fs-2 me-2"></i>
                                                                {{
                                                                this.anasayfaBilgiDto.aktifSinavBilgiDto.durumAdi
                                                                }}&nbsp;
                                                            </span>
                                                            <br>
                                                            <span *ngIf="sonHareketDurum.tarih"> <b
                                                                    translate>islemTarihi</b> &nbsp;:
                                                                &nbsp;{{sonHareketDurum?.tarih | date: 'dd.MM.yyyy'}}
                                                            </span>
                                                        </span>
                                                    </div>

                                                    <div class="fw-semibold text-gray-400"
                                                        *ngIf="sonHareketDurum.kisaltma!='basvuru-onaylandi'">
                                                        <span class="text-black  fs-5"><b translate>aciklama
                                                            </b> :&nbsp; &nbsp;
                                                            <span class="text-black  fs-5">
                                                                {{ sonHareketDurum?.islemAciklama
                                                                }}</span>
                                                        </span>
                                                    </div>
                                                    <div class="fw-semibold text-gray-400">
                                                        <span class="text-black fs-5"><b translate>detayGor</b>:
                                                            <span class="fw-bolder text-black  fs-5 mx-5">
                                                                <a (click)="ayrintiModal(ogrSinavBasvuru.id,'sinav')"
                                                                    style="background-color:#2aa31f"
                                                                    title="FORM BİLGİLERİNİ GÖR"
                                                                    class="btn btn-icon   btn-sm me-1">
                                                                    <i class="fas fa-info-circle text-white"
                                                                        style="font-size:x-large;"></i></a></span>
                                                        </span>
                                                    </div>

                                                </div>
                                                <div class=" rounded min-w-100px w-100 py-2 px-4 me-6 mb-3 "
                                                    style="border: 2px solid rgb(77, 215, 240);"
                                                    *ngIf="sinavTanim?.girisBelgeleriYayinlandiMi &&ogrSinavBasvuru!=null && sonHareketDurum?.kisaltma=='basvuru-onaylandi'">
                                                    <!--begin::Date-->

                                                    <div class="fw-semibold text-gray-400"
                                                        *ngIf="sonHareketDurum.kisaltma=='basvuru-reddedildi'">
                                                        <span translate class="text-black fs-5"><b>aciklama
                                                                :</b>&nbsp; &nbsp;
                                                            <span class="text-black  fs-5">
                                                                {{ sonHareketDurum?.islemAciklama
                                                                }}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <!--end::Stat-->
                                                <!--begin::Stat-->
                                                <div *ngIf="anasayfaBilgiDto.aktifSinavBilgiDto?.basvuruZamanAktifMi && sonHareketDurum?.kisaltma!='basvuru-onaylandi'"
                                                    class="d-flex justify-content-center  min-w-100px w-100 py-2 px-4 mb-3">
                                                    <!--begin::  [queryParams]="{basvuruid: 15}"  Number-->
                                                    <a data-toggle="tooltip" *ngIf="this.ogrSinavBasvuru==null"
                                                        [routerLink]="['/ogr-sinav-basvuru', sinavTanim.id]"
                                                        data-theme="dark" title="Form girişi için tıklayınız."
                                                        class="btn  w-100  btn-hover-rise btn-sm me-1"
                                                        style="color: rgb(0, 128, 90); float: right; border: 2px solid green;"
                                                        translate>
                                                        <i class="fas fa-envelope-open-text text-success fs-2"></i>
                                                        basvuruYap
                                                    </a>
                                                    <a data-toggle="tooltip"
                                                        *ngIf="this.ogrSinavBasvuru!=null && ( this.sonHareketDurum.kisaltma=='basvuru-hatali' || sonHareketDurum.kisaltma=='beklemede')"
                                                        [routerLink]="['/ogr-sinav-basvuru', sinavTanim.id]"
                                                        [queryParams]="{basvuruid: this.ogrSinavBasvuru.id}"
                                                        data-theme="dark" title="Formu düzenlemek için tıklayınız."
                                                        class="btn  w-100  btn-hover-rise btn-sm me-1"
                                                        style="color: rgb(0, 128, 90); float: right; border: 2px solid green;"
                                                        translate>
                                                        <i class="fas fa-edit text-success fs-2"></i>
                                                        basvuruDuz
                                                    </a>
                                                    <!--end::Label-->
                                                </div>
                                                <!--end::Stat-->
                                            </div>
                                            <!--end::Stats-->
                                        </div>

                                        <!--end::Body-->
                                        <!--begin::Footer-->

                                        <!--end::Footer-->
                                    </div>
                                    <!--end::Wrapper-->
                                </div>

                                <!--end::Col-->
                            </div>


                            <!--end::Row-->
                        </div>
                        <!--end::Body-->
                    </div>
                    <!--end::Card widget 18-->
                </div>
                <!--begin::Col-->
                <div class="col-xxl-7 col-12"
                    *ngIf="yetkiService.yetkiVar(['Kullanici.Standart']) && (tercihTanim?.anasayfaGorunurluk==true)">
                    <!--begin::Card widget 18-->
                    <div class="card" style="border: 1px solid rgb(4, 70, 13);">
                        <!--begin::Body-->
                        <div class="card-body py-9">
                            <!--begin::Row-->
                            <div class="row gx-9 h-100">
                                <div class="col-sm-12">
                                    <!--begin::Wrapper-->
                                    <div class="d-flex flex-column h-100">
                                        <!--begin::Header-->
                                        <div class="mb-7">
                                            <!--begin::Headin-->
                                            <div class="d-flex flex-stack mb-6">
                                                <!--begin::Title-->
                                                <div class="me-5">
                                                    <span *ngIf="tercihTanim!=null" class="fs-2"> <i
                                                            class="fas fa-highlighter me-2"
                                                            style="font-size: large; color: #37a2d4;"></i>{{tercihTanim?.adi}}
                                                    </span>

                                                    <span *ngIf="tercihTanim==null" class="fs-2"><i
                                                            class="fas fa-file-signature me-2 fs-2"
                                                            style="color: #d4af37;"></i>Aktif tercih dönemi bulunamadı.</span>


                                                </div>
                                                <!--end::Title-->
                                                <span
                                                    *ngIf="anasayfaBilgiDto.aktifTercihDonemDto?.basvuruZamanAktifMi; else pasif"
                                                    class="badge badge-success flex-shrink-0 align-self-center py-3 px-4 fs-5"
                                                    translate>basvuruAcik</span>
                                                <ng-template #pasif>
                                                    <span
                                                        class="badge badge-danger flex-shrink-0 align-self-center py-3 px-4 fs-5"
                                                        translate>basvuruKapali</span>
                                                </ng-template>
                                            </div>
                                            <!--end::Heading-->
                                            <!--begin::Items-->
                                            <div class="d-flex align-items-center flex-wrap d-grid gap-2">
                                                <!--begin::Item-->
                                                <div class="d-flex align-items-center me-4 ">
                                                    <!--begin::Symbol-->
                                                    <div class="symbol symbol-30px symbol-circle me-3">
                                                        <i class="far fa-calendar-alt fs-3 me-2 text-success"></i>
                                                    </div>
                                                    <!--end::Symbol-->
                                                    <!--begin::Info-->
                                                    <div class="m-0">
                                                        <span class="fw-semibold text-gray-600 d-block fs-5"
                                                            translate>basvuruBasBit</span>
                                                        <span
                                                            class="fw-bold text-gray-800 text-hover-primary fs-4">{{tercihTanim?.baslangicTarihi
                                                            | date:'dd.MM.yyyy'}} - {{tercihTanim?.bitisTarihi |
                                                            date:'dd.MM.yyyy'}}</span>
                                                    </div>
                                                    <!--end::Info-->
                                                </div>
                                                <!--end::Item-->
                                                <!--begin::Item-->
                                                <div class="d-flex align-items-center me-4">
                                                    <!--begin::Symbol-->
                                                    <div class="symbol symbol-40px symbol-circle me-3"
                                                        style="border: 1px solid rgb(0, 195, 255); ">
                                                        <span class="symbol-label bg-default">
                                                            <!--begin::Svg Icon | path: icons/duotune/abstract/abs042.svg-->
                                                            <i class="fas fa-check-double text-success"
                                                                *ngIf="ogrTercihBasvuru!=null && ogrTercihBasvuru.basvuruTamamlanmaDurumu, else yok1"></i>
                                                            <ng-template #yok1>
                                                                <i class="fas fa-times text-danger"></i>
                                                            </ng-template>
                                                            <!--end::Svg Icon-->
                                                        </span>
                                                    </div>
                                                    <!--end::Symbol-->
                                                    <!--begin::Info-->
                                                    <div class="m-0">
                                                        <span class="fw-semibold text-gray-600 d-block fs-5"
                                                            translate>basvuruDurumu</span>
                                                        <span class="fw-bold text-gray-800 fs-5" translate
                                                            *ngIf="ogrTercihBasvuru!=null, else yok">basvuruVar
                                                            <span class="text-success" translate
                                                                *ngIf="ogrTercihBasvuru.basvuruTamamlanmaDurumu, else tamamlanmadi">
                                                                &nbsp;<i
                                                                    class="fas fa-check me-1 text-success fs-4"></i>tamamlandi
                                                            </span>
                                                            <ng-template #tamamlanmadi>
                                                                <span class="text-danger" translate>
                                                                    &nbsp;<i
                                                                        class="fas fa-exclamation-circle me-1 text-warning fs-4"></i>tamamlanmadi
                                                                </span>
                                                            </ng-template>

                                                        </span>
                                                        <ng-template #yok>
                                                            <span class="fw-bold text-gray-800 fs-5"
                                                                translate>basvuruYok</span>
                                                        </ng-template>
                                                    </div>
                                                    <!--end::Info-->
                                                </div>
                                                <!--end::Item-->
                                            </div>
                                            <!--end::Items-->
                                        </div>
                                        <!--end::Header-->
                                        <!--begin::Body-->

                                        <div class="mb-6">
                                            <!--begin::Text-->
                                            <span class="fw-semibold text-gray-600 fs-6 mb-8 d-block"
                                                translate>basUyari</span>
                                            <!--end::Text-->
                                            <!--begin::Stats-->
                                            <div class="d-flex align-items-center">
                                                <!--begin::Stat-->
                                                <div class=" rounded min-w-200px w-100 py-2 px-4 me-6 mb-3 "
                                                    style="border: 2px solid rgb(77, 215, 240);"
                                                    *ngIf="ogrTercihBasvuru!=null && ogrTercihBasvuru?.basvuruTamamlanmaDurumu">
                                                    <!--begin::Date-->
                                                    <h3 class="text-center fs-5" translate>basvuruBaslik</h3>
                                                    <hr>

                                                    <div class="fw-semibold text-gray-400 mt-1">
                                                        <span class="text-black fs-5"><b translate>basvurusonucu</b>
                                                            :&nbsp; &nbsp;

                                                            <span class="fw-bolder text-black  fs-5"> <i
                                                                    class="{{sonHareketDurumTercih?.iconClass}} fs-2 me-2"></i>
                                                                {{
                                                                sonHareketDurumTercih.adi
                                                                }}&nbsp;
                                                            </span>
                                                            <br>
                                                            <span *ngIf="sonHareketDurumTercih.tarih"> <b
                                                                    translate>islemTarihi</b> &nbsp;:
                                                                &nbsp;{{sonHareketDurumTercih?.tarih | date:
                                                                'dd.MM.yyyy'}} 
                                                            </span>
                                                        </span>
                                                    </div>

                                                    <div class="fw-semibold text-gray-400 mt-1"
                                                        *ngIf="sonHareketDurumTercih.kisaltma!='basvuru-onaylandi'">
                                                        <span class="text-black  fs-5"><b translate>aciklama
                                                            </b> :&nbsp; &nbsp;
                                                            <span class="text-black  fs-5">
                                                                {{ sonHareketDurumTercih?.islemAciklama
                                                                }}</span>
                                                        </span>
                                                    </div>

                                                    <div class="fw-semibold text-gray-400 mt-1">
                                                        <span class="text-black fs-5"><b translate>detayGor</b>:
                                                            <span class="fw-bolder text-black  fs-5 mx-5">
                                                                <a (click)="ayrintiModal(ogrTercihBasvuru.id,'tercih')"
                                                                    style="background-color:#1f68a3"
                                                                    title="TERCİH BAŞVURU BİLGİLERİNİ GÖR"
                                                                    class="btn btn-icon   btn-sm me-1">
                                                                    <i class="fas fa-info-circle text-white"
                                                                        style="font-size:x-large;"></i></a></span>

                                                        </span>
                                                    </div>
                                                </div>

                                                <!--end::Stat-->
                                                <!--begin::Stat-->
                                                <div *ngIf="anasayfaBilgiDto.aktifTercihDonemDto?.basvuruZamanAktifMi"
                                                    class="d-flex justify-content-center  min-w-100px w-100 py-2 px-4 mb-3">
                                                    <!--begin::  [queryParams]="{basvuruid: 15}"  Number-->
                                                    <a data-toggle="tooltip" *ngIf="this.ogrTercihBasvuru==null"
                                                        [routerLink]="['/ogr-tercih-basvuru', tercihTanim.id]"
                                                        data-theme="dark" title="Sınava başvuru yapmak için tıklayınız."
                                                        class="btn  w-100  btn-hover-rise btn-sm me-1"
                                                        style="color: #37A2D4; float: right; border: 2px solid #37A2D4;"
                                                        translate>
                                                        <i class="fas fa-envelope-open-text fs-2"
                                                            style="color:#37A2D4"></i>
                                                        basvuruYap
                                                    </a>

                                                    <a data-toggle="tooltip"
                                                        *ngIf="this.ogrTercihBasvuru!=null && ( this.sonHareketDurumTercih.kisaltma=='basvuru-hatali' || sonHareketDurumTercih.kisaltma=='beklemede')"
                                                        [routerLink]="['/ogr-tercih-basvuru', tercihTanim.id]"
                                                        [queryParams]="{basvuruid: this.ogrTercihBasvuru.id}"
                                                        data-theme="dark" title="Başvuruyu düzenlemek için tıklayınız."
                                                        class="btn  w-100  btn-hover-rise btn-sm me-1"
                                                        style="color: #37A2D4; float: right; border: 2px solid #37A2D4;"
                                                        translate>
                                                        <i class="fas fa-edit fs-2" style="color:#37A2D4"></i>
                                                        basvuruDuz
                                                    </a>
                                                    <!--end::Label-->
                                                </div>
                                                <!--end::Stat-->
                                            </div>

                                            <div class="d-flex align-items-center"
                                                *ngIf="anasayfaBilgiDto.aktifTercihDonemDto.yerlestirmeYayindami">
                                                <!--begin::Stat-->
                                                <div class=" rounded min-w-100px w-100 py-2 px-4 me-6 mb-3 "
                                                    style="border: 2px solid rgb(77, 215, 240);">
                                                    <h3 class="text-center fs-5" translate>yerlestirBaslik</h3>
                                                    <hr>
                                                    <div class="fw-semibold text-gray-400 mt-1">
                                                        <span class="text-black  fs-5"><b translate>yerlesmeDurum</b>:
                                                            <span class=" fs-5 text-gray-800 me-2">
                                                                <span
                                                                    *ngIf="ogrTercihBasvuru?.yerlestiMi;else yerlesmedi">
                                                                    <i class="fas fa-check fs-3 text-success"></i>
                                                                    (
                                                                    {{anasayfaBilgiDto.aktifTercihDonemDto.yerlestirmeNo}}
                                                                    .
                                                                    Yerleştirme / Placement
                                                                    )
                                                                </span>
                                                                <ng-template #yerlesmedi>
                                                                    <i class="fas fa-times fs-3 text-danger"></i>
                                                                </ng-template>
                                                            </span>

                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <!--end::Stats-->
                                        </div>
                                        <!--end::Footer-->
                                    </div>
                                    <!--end::Wrapper-->
                                </div>

                                <!--end::Col-->
                            </div>


                            <!--end::Row-->
                        </div>
                        <!--end::Body-->
                    </div>
                    <!--end::Card widget 18-->
                </div>
            </div>

            <div class="row gy-4 g-xl-5"
                *ngIf="yetkiService.yetkiVar(['Kullanici.OgrenciIsleri']) &&(tercihTanim?.anasayfaGorunurluk==true)">
                <div class="col-xxl-7 col-12">
                    <div class="row gx-5 gx-xl-10">
                        <!--begin::Col-->
                        <div class="col-sm-12 mb-5 mb-xl-10">
                            <div class="card card-flush h-xl-100"
                                style="border-top: 2px #00ABD8 solid; border-left: 1px #c5d5da solid;">
                                <!--begin::Header-->
                                <div class="card-header pt-5">
                                    <h3 class="card-title align-items-start flex-column">
                                        <span class="card-label fw-bolder text-dark"><i
                                                class="fas fa-file-signature me-2 fs-2"
                                                style="color: #d4af37;"></i>Aktif Tercih Bilgileri -
                                            {{tercihTanim?.adi}}</span>

                                        <span class="text-black mt-1 fw-bold fs-6"> <span
                                                class="fw-semibold   d-block fs-5">Başvuru
                                                Başlangıç / Bitiş Tarihi : <span
                                                    class="fw-bold text-info  text-hover-primary fs-4">{{tercihTanim?.baslangicTarihi
                                                    | date:'dd.MM.yyyy'}} - {{tercihTanim?.bitisTarihi |
                                                    date:'dd.MM.yyyy'}}</span></span>
                                        </span>
                                    </h3>
                                    <div class="card-toolbar">
                                        <span class="svg-icon svg-icon-2">
                                            <i class="fas fa-book text-success" style="font-size: x-large;"></i>
                                        </span>
                                    </div>
                                </div>
                                <div class="card-body pt-0 mt-2">
                                    <!--begin::Tab content-->
                                    <div class="d-flex align-items-center bg-light-success rounded p-4 mb-2">
                                        <!--begin::Icon-->
                                        <span class="svg-icon svg-icon-success me-5">
                                            <!--begin::Svg Icon | path: icons/duotune/abstract/abs027.svg-->
                                            <span class="svg-icon svg-icon-1">
                                                <i class="fas fa-handshake text-black" style="font-size: larger;"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </span>
                                        <!--end::Icon-->
                                        <!--begin::Title-->
                                        <div class="flex-grow-1 me-2">

                                            <a class=" fs-3 cursor-pointer fw-bold  fs-6"
                                                [routerLink]="['/tercih-basvurular', this.tercihTanim?.id]">
                                                <span class="fw-bold  fs-6 text-black">
                                                    Onaylanan başvuru sayısı
                                                </span>
                                            </a>

                                            <span class="text-muted fw-bold d-block"></span>
                                        </div>
                                        <!--end::Title-->
                                        <!--begin::Lable-->
                                        <span class="fw-bolder text-success py-1 fs-4">{{
                                            anasayfaBilgiDto.aktifTercihDonemDto?.onaylanan_Sayi}}</span>
                                        <!--end::Lable-->
                                    </div>

                                    <div class="d-flex align-items-center bg-light-warning rounded p-4 mb-2">
                                        <!--begin::Icon-->
                                        <span class="svg-icon svg-icon-warning me-5">
                                            <!--begin::Svg Icon | path: icons/duotune/abstract/abs027.svg-->
                                            <span class="svg-icon svg-icon-1">
                                                <i class="fas fa-handshake text-black" style="font-size: larger;"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </span>
                                        <!--end::Icon-->
                                        <!--begin::Title-->
                                        <div class="flex-grow-1 me-2 fw-bold fs-6" style="color: black;">

                                            <a class=" fs-3 cursor-pointer fw-bold  fs-6"
                                                [routerLink]="['/tercih-basvurular', this.tercihTanim?.id]">
                                                <span class="fw-bold  fs-6 text-black">
                                                    Onay bekleyen başvuru sayısı
                                                </span>
                                            </a>

                                        </div>
                                        <!--end::Title-->
                                        <!--begin::Lable-->
                                        <span class="fw-bolder text-warning py-1 fs-4">{{
                                            anasayfaBilgiDto.aktifTercihDonemDto?.onayBekleyen_Sayi}}</span>
                                        <!--end::Lable-->
                                    </div>
                                    <div class="d-flex align-items-center bg-light-danger rounded p-4 mb-2">
                                        <!--begin::Icon-->
                                        <span class="svg-icon svg-icon-danger me-5">
                                            <!--begin::Svg Icon | path: icons/duotune/abstract/abs027.svg-->
                                            <span class="svg-icon svg-icon-1">
                                                <i class="fas fa-handshake text-black" style="font-size: larger;"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </span>
                                        <!--end::Icon-->
                                        <!--begin::Title-->
                                        <div class="flex-grow-1 me-2 fw-bold fs-6" style="color: black;">

                                            <a class=" fs-3 cursor-pointer fw-bold  fs-6"
                                                [routerLink]="['/tercih-basvurular', this.tercihTanim?.id]">
                                                <span class="fw-bold  fs-6 text-black">
                                                    Tamamlanmayan başvuru sayısı
                                                </span>
                                            </a>

                                        </div>
                                        <!--end::Title-->
                                        <!--begin::Lable-->
                                        <span class="fw-bolder text-danger py-1 fs-4">
                                            {{ anasayfaBilgiDto.aktifTercihDonemDto?.tamamlanmayanBasvuru_Sayi}}
                                        </span>
                                        <!--end::Lable-->
                                    </div>

                                    <div class="d-flex align-items-center bg-light-primary rounded p-4 mb-2">
                                        <!--begin::Icon-->
                                        <span class="svg-icon svg-icon-danger me-5">
                                            <!--begin::Svg Icon | path: icons/duotune/abstract/abs027.svg-->
                                            <span class="svg-icon svg-icon-1">
                                                <i class="fas fa-handshake text-black" style="font-size: larger;"></i>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </span>
                                        <!--end::Icon-->
                                        <!--begin::Title-->
                                        <div class="flex-grow-1 me-2 fw-bold fs-6" style="color: black;">

                                            <a class=" fs-3 cursor-pointer fw-bold  fs-6"
                                                [routerLink]="['/tercih-basvurular', this.tercihTanim?.id]">
                                                <span class="fw-bold  fs-6 text-black">
                                                    Toplam başvuru sayısı
                                                </span>
                                            </a>

                                        </div>
                                        <!--end::Title-->
                                        <!--begin::Lable-->
                                        <span class="fw-bolder text-danger py-1 fs-4">
                                            {{ anasayfaBilgiDto.aktifTercihDonemDto?.basvuran_Sayi}}
                                        </span>
                                        <!--end::Lable-->
                                    </div>


                                    <!--end::Body-->
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </loading>
    </div>

    <!-- // style="  height: 100vh;  width: 100vw;" -->
    <div id="belge_modal" class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-xl" style="width: 870px;">

            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" style="border-top: #36d35d 1px solid">
                    <loading [loader]="aliniyor || aliniyorS || aliniyorTb || aliniyorKbelge">
                        <iframe id="iframe" name="iframe" frameBorder="10" [src]="previewUrl" marginHeight="0"
                            marginWidth="0" width="100%" height="600px" scrolling="auto">
                        </iframe>
                    </loading>
                </div>
            </div>
        </div>
    </div>

    <button id="openModalBasvuruAyrintiButton" [hidden]="true" data-bs-toggle="modal"
        data-bs-target="#basvuru_ayrinti_modal" data-toggle="modal">Open Modal</button>
    <div id="basvuru_ayrinti_modal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <i class="fas fa-user me-2 text-primary" style="font-size: large; padding-right:2px;"></i>
                    <h2 class="modal-title">Form Ayrıntı</h2>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" style="border-top: #36d35d 1px solid">
                    <loading [loader]="basvuruSecilen==null || basvuruSecilen?.basvuruBilgi?.adayNo==null">
                        <ogr-basvuru-ayrinti [data]="basvuruSecilen" *ngIf="basvuruSecilen">

                        </ogr-basvuru-ayrinti>
                    </loading>
                </div>
            </div>
        </div>
    </div>

    <button id="openModalBasvuruAyrintiTercihButton" [hidden]="true" data-bs-toggle="modal"
        data-bs-target="#basvuru_ayrinti_modal_tercih" data-toggle="modal">Open Modal</button>
    <div id="basvuru_ayrinti_modal_tercih" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="" style="border-top: #36d35d 1px solid">

                    <loading [loader]="basvuruSecilen?.basvuruBilgi?.basvuruNo==null">
                        <ogr-tercih-basvuru-ayrinti [data]="basvuruSecilen" *ngIf="basvuruSecilen">

                        </ogr-tercih-basvuru-ayrinti>
                    </loading>
                </div>
            </div>
        </div>
    </div>
</div>