export class SinavOdemeBilgi {
    id: number = 0;
    sinavBasvuruId: number = 0;
    dekontUrl: string = '';
    dekontAdi: string = '';
    odemeTip: number = 0;
    status: string = '';
    merchantId: string = '';
    verifyEnrollmentRequestId: string = '';
    purchAmount: string = '';
    xid: string = '';
    installmentCount: string = '';
    sessionInfo: string = '';
    purchCurrency: string = '';
    pan: string = '';
    expiryDate: string = '';
    eci: string = '';
    cavv: string = '';
    errorCode: string = '';
    errorMessage: string = '';
    tutar: number = 0;
    idt: Date = new Date();
    sdt: Date = new Date();
    arsiv: boolean = false;
}

export class KartOdemeDto 
{
    basvuruId:number=0;
    kkNo:string='';
    adSoyad:string='';
    ay:string='01';
    yil:string='2023';
    ccv:string='';
    kartTip:string='';
    tutar:number=0;
    paraBirim:string='';
    kullanimTipi:string='';
    verifyEnrollmentRequestId:string='';
}

