import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AnasayfaBilgiDto } from '../models/anasayfaBilgi.model';
import { Response } from '../models/response.model';


@Injectable({
  providedIn: 'root'
})
export class AnasayfaBilgiService {
  apiUrl = '/api/home'
  constructor(private http: HttpClient) { }
  
  getAnasayfaBilgi(ogrId:number): Observable<Response<AnasayfaBilgiDto>> {
    return this.http.get<Response<AnasayfaBilgiDto>>(this.apiUrl + '/anasayfaBilgi?ogrId='+ogrId);
  }
}
