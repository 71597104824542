import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Adres } from '../models/adres.model';
import { Response } from '../models/response.model';

@Injectable({
  providedIn: 'root'
})
export class AdresService {

  apiUrl = '/api/adres';

  constructor(private http: HttpClient) { 

  }

  iller : Adres[] = [];
  ilceler : Adres[] = [];

  tanimYukle() {
    if (this.iller.length == 0)
    {    
      this.getAll(0,'', 1, 10000).subscribe(res => {
      this.iller = res.data;
    });
  }
    if (this.ilceler.length == 0)
    {    
      this.getAll(-1,'', 1, 10000).subscribe(res => {
      this.ilceler = res.data;
    });
  }

  }

  ilVer(id: number = 0): string {
    return this.iller.find(p=>p.id==id)?.sehir_ilce_mahalle_adi ?? '';
  }

  ilceVer(id: number = 0): string {
    if (id == 0)
    {
      return 'MERKEZ'
    }
    else 
    {
      return this.ilceler.find(p=>p.id==id)?.sehir_ilce_mahalle_adi ?? '';
    }
  }

  ilcelerVer(ust_id:number=0):  Adres[] {
    var liste = this.ilceler.filter(p=>p.ust_id==ust_id);
    // merkez ekle;
    let adres = new Adres();
    adres.id = 0;
    adres.mahalle_id = '';
    adres.sehir_ilce_mahalle_adi = 'MERKEZ';
    adres.ust_id = ust_id;
    adres.maxlatitude = '';
    adres.maxlongitude = '';
    adres.minlatitude= '';
    adres.minlongitude = '';
    liste.push(adres);
    return liste;
  }


  getAll(ust_id:number=0,query: string = '', page: number = 1, rowCount: number = 10): Observable<Response<Adres[]>> {
    return this.http.get<Response<Adres[]>>(this.apiUrl + '/getList', {
      params: {
        'ust_id': ust_id,
        'aramastr': query,
        'sayfasayisi': page,
        'sayfadakisatirsayisi': rowCount
      }
    });
  }


  getById(id: number): Observable<Response<Adres>> {
    return this.http.get<Response<Adres>>(this.apiUrl + '/getById?id=' + id);
  }

  delete(dil: Adres): Observable<Response<Adres>> {
    return this.http.post<Response<Adres>>(this.apiUrl + '/delete', dil)
  }


  addUpdate(dil: Adres): Observable<Response<Adres>> {
    if (dil.id > 0) {
      return this.http.post<Response<Adres>>(this.apiUrl + "/update", dil)
    }
    else {
      return this.http.post<Response<Adres>>(this.apiUrl + "/add", dil)
    }
  }}
