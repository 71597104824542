<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span>
                        <span>
                            <i class="fas fa-highlighter text-success fs-3 me-2"></i>Tercih Dönem İşlemleri</span>

                        <span>
                            &nbsp;&nbsp;
                            <i class="fas fa-exclamation-triangle text-danger fs-4"></i>
                            Yapılan işlemler var olan tercih başvurularını etkileyebilir.</span>
                    </span>
                </h1>
            </div>
            <div class="d-flex align-items-center gap-2 gap-lg-3">
            </div>
        </div>
    </div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" class="container-fluid">
            <loading [loader]="yukleniyor">
                <form [formGroup]="detayForm" (submit)="kaydetGuncelle()">
                    <div class="card col-12 col-md-8 offset-md-2" style="border-top: 3px #77c2e9 solid;">
                        <div class="card-header">
                            <h3 class="card-title">
                                Yeni Ekle
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="form-floating mb-3">
                                <input formControlName="adi" type="text" class="form-control" id="adi"
                                    oninput="this.value = this.value.toLocaleUpperCase('TR')"
                                    style="border-color: #8fb9d5;text-transform: uppercase;" placeholder="adi"
                                    name="adi" />
                                <label style="color: #009ef7;" class="fs-4" for="adi">Dönem Tanımı <span
                                        class="text-danger me-2">*
                                    </span></label>
                                <div class="text-danger" *ngFor="let hata of hatalar['Adi']">
                                    {{hata}}
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-5 form-floating">
                                    <input formControlName="baslangicTarihi" type="datetime-local"
                                        class="form-control datepicker" id="baslangicTarihi"
                                        placeholder="BaslangicTarihi" name="baslangicTarihi"
                                        style="border-color: #8fb9d5;" [value]="detayForm.value.baslangicTarihi |
                                    date:'yyyy-MM-dd HH:mm'" />
                                    <label style="color: #009ef7;" class="fs-4 fw-bolder" for="BaslangicTarihi">
                                        Başvuru Başlangıç Tarih / Saat <span class="text-danger me-2">*
                                        </span></label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['BaslangicTarihi']">
                                        {{hata}}
                                    </div>
                                </div>
                                <div class="col-5 form-floating ">
                                    <input formControlName="bitisTarihi" type="datetime-local"
                                        class="form-control datepicker" id="bitisTarihi"
                                        placeholder="basvuruBitisTarihi" name="bitisTarihi"
                                        style="border-color: #8fb9d5;" [value]="detayForm.value.bitisTarihi |
                                    date:'yyyy-MM-dd HH:mm'" />
                                    <label style="color: #009ef7;" class="fs-4 fw-bolder" for="bitisTarihi">
                                        Başvuru Bitiş Tarih / Saat <span class="text-danger me-2">*
                                        </span></label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['BitisTarihi']">
                                        {{hata}}
                                    </div>
                                </div>
                                <div class="col-2 form-floating ">
                                    <input formControlName="donem" type="number" min="0" class="form-control" id="donem"
                                        style="border-color: #8fb9d5;" placeholder="donem" name="donem" />
                                    <label style="color: #009ef7;" class="fs-4 fw-bolder" for="donem ">
                                        Dönem <span class="text-danger me-2">*
                                        </span></label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['Donem']">
                                        {{hata}}
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-6 form-floating ">
                                    <input formControlName="puanSiniri" type="number" min="0" class="form-control"
                                        id="puanSiniri" style="border-color: #8fb9d5;" placeholder="puanSiniri"
                                        name="puanSiniri" />
                                    <label style="color: #009ef7;" class="fs-4 fw-bolder" for="donem ">
                                        Standart puan sınırı ( Yüzlük ) <span class="text-danger me-2">*
                                        </span></label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['PuanSiniri']">
                                        {{hata}}
                                    </div>
                                </div>
                                <div class="col-6 form-floating ">
                                    <input formControlName="puanSiniriTipDis" type="number" min="0" class="form-control"
                                        id="puanSiniriTipDis" style="border-color: #8fb9d5;"
                                        placeholder="puanSiniriTipDis" name="puanSiniriTipDis" />
                                    <label style="color: #009ef7;" class="fs-4 fw-bolder" for="puanSiniriTipDis ">
                                        Puan sınırı yüzlük (Tıp Fakültesi, Diş Hekimliği Fakültesi)  <span
                                            class="text-danger me-2">*
                                        </span></label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['PuanSiniriTipDis']">
                                        {{hata}}
                                    </div>
                                </div>
                            </div>
                            <div class="form-floating mb-3">
                                <input formControlName="yapilacakTercihSayisi" type="number" min="0"
                                    class="form-control" id="yapilacakTercihSayisi" style="border-color: #8fb9d5;"
                                    placeholder="yapilacakTercihSayisi" name="yapilacakTercihSayisi" />
                                <label style="color: #009ef7;" class="fs-4 fw-bolder" for="yapilacakTercihSayisi ">
                                    Adayların yapacağı tercih sayısı <span class="text-danger me-2">*
                                    </span></label>
                                <div class="text-danger" *ngFor="let hata of hatalar['YapilacakTercihSayisi']">
                                    {{hata}}
                                </div>
                            </div>
                            <div class="form-floating mb-3">
                                <input formControlName="yetkiliAdSoyad" type="text" class="form-control" id="yetkiliAdSoyad"
                                    style="border-color: #8fb9d5;" placeholder="yetkiliAdSoyad"
                                    name="yetkiliAdSoyad" />
                                <label style="color: #009ef7;" class="fs-4" for="adi">Yetkili Ad Soyad<span
                                        class="text-danger me-2">*
                                    </span></label>
                                <div class="text-danger" *ngFor="let hata of hatalar['YetkiliAdSoyad']">
                                    {{hata}}
                                </div>
                            </div>

                            <div class="form-floating mb-3">
                                <input formControlName="yetkiliUnvan" type="text" class="form-control" id="yetkiliUnvan"
                                    style="border-color: #8fb9d5;" placeholder="yetkiliUnvan"
                                    name="yetkiliUnvan" />
                                <label style="color: #009ef7;" class="fs-4" for="yetkiliUnvan">Yetkili Ünvan<span
                                        class="text-danger me-2">*
                                    </span></label>
                                <div class="text-danger" *ngFor="let hata of hatalar['YetkiliUnvan']">
                                    {{hata}}
                                </div>
                            </div>

                            <div class="form-floating mb-3">
                                <input formControlName="yetkiliUnvanEng" type="text" class="form-control" id="yetkiliUnvanEng"
                                    style="border-color: #8fb9d5;" placeholder="yetkiliUnvanEng"
                                    name="yetkiliUnvanEng" />
                                <label style="color: #009ef7;" class="fs-4" for="yetkiliUnvanEng">Yetkili Ünvan İngilizce<span
                                        class="text-danger me-2">*
                                    </span></label>
                                <div class="text-danger" *ngFor="let hata of hatalar['YetkiliUnvanEng']">
                                    {{hata}}
                                </div>
                            </div>




                            <div class="form-group row mb-2" *ngIf="yeni==false && this.paramid>0">
                                <label class="col-lg-2 col-form-label fw-bold text-info fs-6" translate>İmza resmi
                                    <span class="text-danger me-2">: *
                                    </span></label>
                                <div class="col-lg-10">
                                    <input ui-jq="filestyle" class="form-control border-warning" type="file" id="file"
                                        (change)="selectAndSave($event)" name="file" nv-file-select=""
                                        accept="image/*,.pdf,.doc,.docx" uploader="uploader" data-icon="false"
                                        style="color: #7239EA;" data-classButton="btn btn-default"
                                        data-classInput="form-control inline v-middle input-s">

                                    <a *ngIf="yeni==false" class="menu-link cursor-pointer fs-5"
                                        (click)="dosyaIndir()">Kayitli belgeyi görüntüle
                                    </a>
                                </div>
                            </div>
                            <div class="mb-2 mt-5">
                                <input type="checkbox" class="form-check-input me-2"
                                    formControlName="anasayfaGorunurluk" id="anasayfaGorunurluk"
                                    name="anasayfaGorunurluk">
                                <label class="checkbox-lg text-info text-justify" style="font-size:medium;"
                                    for="exampleCheck1">Anasayfada görünürlük
                                    ?</label>
                            </div>

                            <div class="mb-2 mt-5">
                                <input type="checkbox" class="form-check-input me-2"
                                    formControlName="ayarlarAktarilsinMi" id="ayarlarAktarilsinMi"
                                    name="ayarlarAktarilsinMi">
                                <label class="checkbox-lg text-info text-justify" style="font-size:medium;"
                                    for="exampleCheck1">Önceki tercih döneminin kontenjanları ve sınav türleri
                                    aktarılsın mı ?
                                    ?</label>
                            </div>


                            <div class="row  mb-3">
                                <div class="col-lg-2">
                                    <label for="class_type">
                                        <h5 style=" margin-top: 150px; text-align: center;"><span
                                                class=" text-info label label-primary">Tercih Başvuru
                                                Bilgilendirme<span class="text-danger me-2">*
                                                </span></span></h5>
                                    </label>
                                </div>
                                <!-- <label class="col-lg-2 control-label fw-bold text-info" style="vertical-align: middle;">Tercih tanım bilgisini gir </label> -->
                                <div class="col-lg-10">
                                    <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="model.editorData"
                                        (change)="onChange($event)" [ngModelOptions]="{standalone: true}"></ckeditor>
                                </div>
                            </div>
                        </div>
                        <div class="row card-footer">
                            <div class="col-md-6 col-6 text-left">
                                <button type="button" routerLink="/tercih-donem-bilgi" id="kt_modal_new_target_cancel"
                                    class="btn btn-danger btn-hover-rise me-3"><i class="fas fa-reply"></i> Geri
                                </button>
                            </div>
                            <div class="col-md-6 col-6 text-end">
                                <submit-button [loader]="kaydediliyor" [label]="yeni?'Kaydet':'Güncelle'"
                                    [loading]="yeni?'Kaydediliyor...':'Güncelleniyor...'">
                                </submit-button>
                            </div>
                        </div>
                    </div>

                </form>
            </loading>
        </div>
    </div>
</div>