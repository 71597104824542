import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { OgrKullanici } from 'src/app/models/ogrKullanici.model';
import { GirisService } from 'src/app/services/giris.service';
import { OgrKullaniciService } from 'src/app/services/ogrKullanici.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';
import { child } from 'src/assets/js/components/util';
import { HazircevapBilgiComponent } from '../hazircevap-bilgi/hazircevap-bilgi.component';
import { Tanimlar } from 'src/app/models/tanimlar.model';
import { Adres } from 'src/app/models/adres.model';
import { TanimlarService } from 'src/app/services/tanimlarservice';
import { AdresService } from 'src/app/services/adres.service';

@Component({
  selector: 'ogr-kullanici-bilgi',
  templateUrl: './ogr-kullanici-bilgi.component.html',
  styleUrls: ['./ogr-kullanici-bilgi.component.css']
})

export class OgrKullaniciBilgiComponent implements OnInit {
  kaydediliyor = false;
  

  constructor(private formBuilder: FormBuilder, private yetkiService: YetkiService, private ogrKullaniciService: OgrKullaniciService, private route: ActivatedRoute, private router: Router, private validationService: ValidationService, private datePipe: DatePipe, private girisService: GirisService
    , public tanimlarService: TanimlarService, public adresService: AdresService) { }
  yukleniyor: boolean = false;
  hatalar: any = {};
  detayForm = this.formBuilder.group(new OgrKullanici());
  yeni = true;
  ogrBilgi: OgrKullanici = new OgrKullanici();



  ngOnInit(): void {

    this.tanimlarService.tanimYukle();
    this.adresService.tanimYukle();
    if (this.girisService.girisBilgi().tckimlikno) {
      this.getById(Number(this.girisService.girisBilgi().tckimlikno));
    }
  }

  
  getById(id: number) {
    this.yukleniyor = true;
    this.ogrKullaniciService.getById(id).subscribe(res => {
      this.yeni = false;
      this.ogrBilgi = res.data;
      this.detayForm.patchValue(res.data);
      this.yukleniyor = false;
    }, err => {
      this.yukleniyor = false;
      this.router.navigateByUrl('/')
    });
  }


  kaydetGuncelle() {
    this.kaydediliyor = true;
    this.hatalar = {};
    var form = this.detayForm.value;

    this.ogrKullaniciService.addUpdate(form).pipe(finalize(() => {
    })).subscribe({
      next: res => {
        if (res?.message && res?.message != "") { this.yetkiService.sweetAlertSuccess(res?.message) };
        this.kaydediliyor = false;
        this.router.navigateByUrl('/')
      },
      error: err => {
        this.hatalar = this.validationService.hatalar(err.error.Errors);
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
        }
        this.kaydediliyor = false;
      }
    });

  }
}


