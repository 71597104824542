import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { finalize } from 'rxjs';
import { OgrKullanici } from 'src/app/models/ogrKullanici.model';
import { SinavTanim } from 'src/app/models/sinavTanim.model';
import { TercihBasvuruDetay, TercihBasvurularColumns } from 'src/app/models/tercihBasvuru.model';
import { TercihDonemTanim } from 'src/app/models/tercihDonemTanim.model';
import { Ulke } from 'src/app/models/ulke.model';
import { GirisService } from 'src/app/services/giris.service';
import { OgrKullaniciService } from 'src/app/services/ogrKullanici.service';
import { TercihBasvuruService } from 'src/app/services/tercih-basvuru.service';
import { TercihDonemTanimService } from 'src/app/services/tercih-donem-tanim.service';
import { UlkeService } from 'src/app/services/ulke.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
declare var bootstrap: any;

@Component({
  selector: 'tercih-basvurular',
  templateUrl: './tercih-basvurular.component.html',
  styleUrls: ['./tercih-basvurular.component.css']
})
export class TercihBasvurularComponent implements OnInit {


  constructor(private tercihBasvuruService: TercihBasvuruService, public tercihTanimService: TercihDonemTanimService, public yetkiService: YetkiService, private route: ActivatedRoute, private router: Router, private girisService: GirisService, private ogrKullaniciService: OgrKullaniciService, private formBuilder: FormBuilder, private validationService: ValidationService, private ulkeService: UlkeService) { this.service = tercihBasvuruService }
  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  tercihTanimId: number = 0;
  dtOptions: any = {};
  tercihTanim: TercihDonemTanim = new TercihDonemTanim();
  basvuruSecilen: any;

  tumBasvurular: TercihBasvuruDetay[] = [];
  bavuruTamamlanmayanGoster = true;
  base64textString: string = '';
  yeni = false;
  columnclass: TercihBasvurularColumns = new TercihBasvurularColumns();
  ogrKullaniciForm = this.formBuilder.group(new OgrKullanici());
  kaydediliyor = false;
  aramaClass: any = { basvuruTamamlamaDurum: true };
  hatalar: any = {};
  kulTc: string = '';

  ngOnInit(): void {
    this.kulTc = this.girisService.girisBilgi().tckimlikno;
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.tercihTanimId = params['id'];
        this.basvuruList();
        this.getTercihTanimById();
        this.ulkeList();
        this.tercihBasvuruListAllExcel();
      }
    });
  }


  getTercihTanimById() {
    this.yukleniyor = true;
    this.tercihTanimService.getById(this.tercihTanimId).subscribe(res => {
      this.tercihTanim = res.data;
    }, err => {
      this.router.navigateByUrl('/')
    },);
  }

  tamamlanmayanlar() {
    this.aramaClass.basvuruTamamlamaDurum = this.bavuruTamamlanmayanGoster;
    this.gridYenile();
  }

  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      const that = this;
      $('.search-input').on('keyup change', function (evt) {
        if (evt.keyCode == 13 || evt.currentTarget.id == '8' || evt.currentTarget.id == '7' || evt.currentTarget.id == '2') {
          dtInstance
            .column(this['id'])
            .search($(this).val() + "")
            .column(8).search($('#8').find(":selected").val() + '').column(7).search($('#7').find(":selected").val() + '').column(2).search($('#2').find(":selected").val() + '').draw();
        }
      });
    });
  }



  basvuruDetay: TercihBasvuruDetay[] = [];
  service: TercihBasvuruService;
  yukleniyor = false;
  pageNo: number = 0;
  basvuruList() {
    this.yukleniyor = true;
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      orderMulti: true,
      dom: 'lrtip',

      ajax: (dataTablesParameters: any, callback: (arg0: { recordsTotal: any; recordsFiltered: any; data: never[]; }) => void) => {
        this.tercihBasvuruService.getGridList(dataTablesParameters, JSON.stringify(this.aramaClass), this.tercihTanimId).pipe(finalize(() => {
          this.yukleniyor = false;
        })).subscribe({
          next: res => {
            that.basvuruDetay = res.data;
            this.pageNo= (Number(dataTablesParameters.start) / Number(dataTablesParameters.length))+1;
            callback({
              recordsTotal: res.recordCount,
              recordsFiltered: res.recordCount,
              data: res.data
            });
          },
          error: err => {
          }
        });
      },
      columns: this.columnclass.columnsdegerler1,
      language: {
        url: '/assets/image/Turkish.json',
      },
      responsive: true,
    };
  };

  gridYenile(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  getById(id: number) {
    this.hatalar = {};
    this.yukleniyor = true;
    this.ogrKullaniciService.getById(id).subscribe(res => {
      this.yeni = false;
      this.ogrKullaniciForm = this.formBuilder.group(new OgrKullanici());
      this.ogrKullaniciForm.patchValue(res.data);
      this.yukleniyor = false;
    }, err => {
      this.yukleniyor = false;
      this.router.navigateByUrl('/')
    });
  }

  exportexcel(): void {
    const currentYear = new Date('' + this.tercihTanim.baslangicTarihi);
    let fileName = currentYear.getFullYear() + " Yös Tercih Başvuruları.xlsx"
    var element = document.getElementById('kl_tumbasvurular_table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '' + currentYear.getFullYear() + " Yös Başvuruları");
    XLSX.writeFile(wb, fileName);
  }

  exportexcel2(): void {
    const currentYear = new Date('' + this.tercihTanim.baslangicTarihi);
    let fileName = currentYear.getFullYear() + " Yös Tercih Başvuruları.xlsx"
    var element = document.getElementById('kl_tercihler_table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '' + currentYear.getFullYear() + " Yös Başvuruları");
    XLSX.writeFile(wb, fileName);
  }





  inceleniyor = false;
  ayrintiModal(basvuruSecilen: any) {
    this.inceleniyor = true;
    this.tercihBasvuruService.incelemeBaslat(basvuruSecilen.basvuruBilgi.id).pipe(finalize(() => {
    })).subscribe({
      next: res => {
        this.tercihBasvuruService.dosyaBilgi(basvuruSecilen.basvuruBilgi.id, "profil").subscribe(res => {
          this.inceleniyor = false;
          this.base64textString = res?.data?.fileBase64Data;
          basvuruSecilen.base64textString = this.base64textString;
          this.basvuruSecilen = basvuruSecilen;
          document.getElementById('openModalBasvuruAyrintiButton')?.click();
          this.gridYenile();
        });
      },
      error: err => {

        this.hatalar = this.validationService.hatalar(err.error.Errors);
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertWarning(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertWarning(err.error?.Message) };
        }
      }
    });

  }

  tercihBasvuruListAllExcel() {
    this.tercihBasvuruService.getListAll(this.tercihTanimId).subscribe(res => {
      this.tumBasvurular = res.data;
    });
  }




  exportexcelTercih(): void {
    const currentYear = new Date('' + this.tercihTanim.baslangicTarihi);
    let fileName = currentYear.getFullYear() + " Yös Tercih Başvuruları.xlsx"
    var element = document.getElementById('kl_tumbasvurular_table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '' + currentYear.getFullYear() + " Yös Tercih Başvuruları");
    XLSX.writeFile(wb, fileName);
  }

  ulkeler: Ulke[] = [];
  ulkeList() {
    this.ulkeService.getAll('', 1, 300).subscribe(res => {
      this.ulkeler = res.data;
    });
  }

  incelemeBitir(basvuruSecilen: any) {
    this.tercihBasvuruService.incelemeBitir(basvuruSecilen.basvuruBilgi.id).pipe(finalize(() => {
    })).subscribe({
      next: res => {
        if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
        this.gridYenile();
      },
      error: err => {
        this.hatalar = this.validationService.hatalar(err.error.Errors);
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
        }
      }
    });



  }

}








