import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { Tanimlar } from 'src/app/models/tanimlar.model';
import { SinavBasvuru, SonHareketDurumBilgi } from 'src/app/models/sinavBasvuru.model';
import { SinavEkAlan } from 'src/app/models/sinavEkAlan.model';
import { KartOdemeDto, SinavOdemeBilgi } from 'src/app/models/sinavOdemeBilgi.model';
import { SinavTanim } from 'src/app/models/sinavTanim.model';
import { AnasayfaBilgiService } from 'src/app/services/anasayfa-bilgi.service';
import { TanimlarService } from 'src/app/services/tanimlarservice';
import { GirisService } from 'src/app/services/giris.service';
import { OgrKullaniciService } from 'src/app/services/ogrKullanici.service';
import { SinavBasvuruService } from 'src/app/services/sinav-basvuru.service';
import { SinavEkAlanService } from 'src/app/services/sinav-ek-alan.service';
import { SinavTanimService } from 'src/app/services/sinav-tanim.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';
import i from 'src/assets/plugins/formvalidation/dist/es6/plugins/Aria';

@Component({
  selector: 'ogr-sinav-basvuru',
  templateUrl: './ogr-sinav-basvuru.component.html',
  styleUrls: ['./ogr-sinav-basvuru.component.css']
})
export class OgrSinavBasvuruComponent implements OnInit {

  constructor( private sinavEkAlanService: SinavEkAlanService,  private sinavBasvuruService: SinavBasvuruService, private tanimlarService: TanimlarService, private route: ActivatedRoute, private validationService: ValidationService, private formBuilder: FormBuilder, public yetkiService: YetkiService, private router: Router, private girisService: GirisService, private datePipe: DatePipe, public sinavTanimService: SinavTanimService, private ogrKullaniciService: OgrKullaniciService,  private anasayfaBilgiService: AnasayfaBilgiService, private sanitizer: DomSanitizer, public cd: ChangeDetectorRef) { }
  step = 1;
  hatalar: any = {};
  diller: Tanimlar[] = [];
  hataYazdir: any[] = [];
  sinavid: number = 0;
  basvuruid: number = 0;

  yeni = true;
  odemeid: number = 0;
  kayitYapildiMi = false;;
  base64textString: string = '';
  sinavEkAlanlar: SinavEkAlan[] = [];
  fileProfil: any;
  fileDekont: any;

  sinavTanim: SinavTanim = new SinavTanim();
  odemeTip: number = 0;
  kayitliBasvuruBilgi: SinavBasvuru = new SinavBasvuru();
  kayitliOdemeBilgi: SinavOdemeBilgi = new SinavOdemeBilgi();

  kartBilgiDto: KartOdemeDto = new KartOdemeDto();
  kvkkCheck: boolean = false;
  basvuruOnayMsg = '';

  kaydediliyor = false;
  yukleniyor = false;
  yukleniyorSinav = false;
  dogrulaniyor = false;
  odemeKaydediliyor = false;
  yillar: any;
  bankaHtml: any;
  dogumTar: any;
  sonHareketDurum: SonHareketDurumBilgi = new SonHareketDurumBilgi();

  // bankaHtml:any='<html>       <head>         <meta name="viewport" content="width=device-width" />             <title>MpiForm</title>            <script>              function postPage() {              document.forms["frmMpiForm"].submit();              }            </script>          </head>          <body onload="javascript:postPage();">            <form action="https://3dsecure.vakifbank.com.tr/MPIAPI/ThreeDSPareq.aspx" method="post" id="frmMpiForm" name="frmMpiForm">              <input type="hidden" name="PaReq" value="5a89391a-b562-4d9e-9c39-e9b99a961b07" />                              <input type="hidden" name="TermUrl" value="https://3dsecure.vakifbank.com.tr/MPIAPI/MPI_PARes.aspx" />                              <input type="hidden" name="MD" value="16f0743e-0665-4407-8f65-f5b0d86c46de#94826273-cc53-43b0-8322-a26f54d522e5 " />              <noscript>                <input type="submit" id="btnSubmit" value="Gönder" />              </noscript>            </form>          </body>                        </html>';

  ngOnInit(): void {

    this.addMessageEvent(window);
    this.kartYilList();
    this.dilList();
    this.ogrKullaniciBilgi(0);

    this.route.params.subscribe(params => {
      if (params['id']) {
        this.sinavid = params['id'];
        this.getSinavTanimById(Number(params['id']));
      }

      this.route.queryParams.subscribe(paramsq => {
        if (paramsq['basvuruid'] && Number(paramsq['basvuruid'] > 0)) {
          this.basvuruid = Number(paramsq['basvuruid']);
          this.getById(Number(paramsq['basvuruid']));
          this.yeni = false;
        }

        if (paramsq['basvuruid'] == undefined || paramsq['basvuruid'] == null || paramsq['basvuruid'] == 0) { // yeni kayıtsa
          this.sinavEkAlanList(Number(params['id']));
        }
      })
    });
  }


  kartYilList() {
    const currentYear = (new Date()).getFullYear();
    const range = (start: any, stop: any, step: any) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
    this.yillar = range(currentYear, currentYear + 15, 1);
  }

  getSinavTanimById(id: number) {
    this.yukleniyorSinav = true;
    this.sinavTanimService.getById(id).subscribe(res => {
      this.sinavTanim = res.data;
      // Başvuru ile ilgili kontroller
      this.sinavBasvuruService.ogrBasvuruKontrol(res.data.id, this.basvuruid).subscribe(res => {
        this.yukleniyorSinav = false;
      }, err => {
        this.router.navigateByUrl('/')
      },);

    }, err => {
      this.router.navigateByUrl('/')
    },);
  }

  selectFileProfil(evt: any) {
    var files = evt.target.files;
    var file = files[0];
    var type = file.type;
    let fruits: string[] = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg', 'image/jpeg'];
    var uygunMu = fruits.indexOf(type);
    if (files && file && evt.target.files[0]?.size < 10485760 && uygunMu > -1) {
      this.fileProfil = file;
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
    else {
      this.yetkiService.sweetAlertError("Seçilen dosya resim formatında ve en fazla 10MB olmalıdır.");
      this.fileProfil = null;
    }
  }

  selectDekont(evt: any) {
    var files = evt.target.files;
    var file = files[0];
    var type = file.type;
    let dekont: string[] = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/jpeg', 'image/png'];
    var uygunMu = dekont.indexOf(type);
    if (files && file && evt.target.files[0]?.size < 10485760 && uygunMu > -1) {
      this.fileDekont = file;
    }
    else {
      this.yetkiService.sweetAlertError("Seçilen dosya formatı veya boyutu hatalı.");
      this.fileDekont = null;
    }
  }

  selectFileDynamic(evt: any, sinavEkAlan: SinavEkAlan) {
    sinavEkAlan.fileTip = evt.target.files[0]?.type;
    sinavEkAlan.fileSize = evt.target.files[0]?.size;
    sinavEkAlan.fileName = evt.target.files[0]?.name;
    if (sinavEkAlan.fileSize < 10485760) {
      sinavEkAlan.file = evt.target.files[0];
    }
    else {
      this.yetkiService.sweetAlertError("Dosya boyutu en fazla 10MB olmalıdır."); sinavEkAlan.file = null;
    }
  }

  _handleReaderLoaded(readerEvt: any) {
    var binaryString = readerEvt?.target?.result;
    this.base64textString = btoa(binaryString);
  }

  detayForm = this.formBuilder.group({
    sinavBasvuruForm: this.formBuilder.group({
      ...new SinavBasvuru(),
    }),
    odemeForm: this.formBuilder.group({
      ...new SinavOdemeBilgi(),
    })
  });

  sinavBasvuruForm(): FormGroup {
    return this.detayForm.get('sinavBasvuruForm') as FormGroup;
  }
  odemeForm(): FormGroup {
    return this.detayForm.get('odemeForm') as FormGroup;
  }

  kayitkontrol(step: number) {
    if (this.kayitYapildiMi) {
      this.step = step;
    }
  }



  getById(id: number) {
    this.yukleniyor = true;
    // Ek alan cevapları varsa getir
    this.sinavBasvuruService.sinavEkAlanCevaplarGetById(id).pipe(finalize(() => {
    })).subscribe({
      next: res => {
        this.sinavEkAlanlar = res.data;
        this.yeni = false;
        // Sınav bilgileri varsa getir
        this.sinavBasvuruService.getById(id).pipe(finalize(() => {
        })).subscribe({
          next: res => {
            this.resimBilgi(id);
            this.sinavBasvuruForm().patchValue(res.data);
            this.kayitYapildiMi = true;
            this.kayitliBasvuruBilgi = res.data;
            this.kvkkCheck = res.data.bilgiDogrulukOnayi;
            this.ogrKullaniciBilgi(res.data.ogrKullaniciId);
            this.anasayfaBilgiService.getAnasayfaBilgi(res.data?.ogrKullaniciId ?? 0).subscribe(res => {
              this.sonHareketDurum = res.data.aktifSinavBilgiDto?.sonHareketDurum;
            });
            this.yukleniyor = false;

          },
          error: err => {
            this.router.navigateByUrl('/')
          }
        });

      },
      error: err => {
        this.router.navigateByUrl('/')
      }
    });

  }

  resimBilgi(basvuruid: number) {
    this.sinavBasvuruService.dosyaBilgi(basvuruid, 0).subscribe(res => {
      this.base64textString = res?.data?.fileBase64Data;
    });
  }

  dosyaIndir(ekalanid: number) {
    this.sinavBasvuruService.dosyaBilgi(this.basvuruid, ekalanid).subscribe(res => {
      var blob = this.sinavBasvuruService.dataURItoBlob(res.data?.fileBase64Data);
      const imageFile = new File([blob], res.data?.fileName, { type: res.data?.contentType });
      var objectUrl = URL.createObjectURL(imageFile);
      var fileLink = document.createElement('a');
      fileLink.href = objectUrl;
      fileLink.download = res.data.fileName;
      fileLink.click();
    });
  }

  ogrKullaniciBilgi(ogrId: number = 0) {
    var ogrIdGiden = 0;
    if (ogrId == 0) {
      ogrIdGiden = Number(this.girisService.girisBilgi().tckimlikno);
    }
    else {
      ogrIdGiden=ogrId;
    }
    this.ogrKullaniciService.getById(ogrIdGiden).subscribe(res => {
      var ogrBilgi = res.data;
      // 
      this.dogumTar = ogrBilgi.dogum_tarihi;
      if (this.basvuruid == 0) {
      
        this.sinavBasvuruForm().get('adi')?.setValue(ogrBilgi.adi);
        this.sinavBasvuruForm().get('soyadi')?.setValue(ogrBilgi.soyadi);
        this.sinavBasvuruForm().get('dogumTarihi')?.setValue(ogrBilgi.dogum_tarihi)
        this.sinavBasvuruForm().get('ePosta')?.setValue(ogrBilgi.email);
      }
    });
  }

  kaydetGuncelle(sinavEkAlanBilgiTotal: SinavEkAlan[]) {
    this.hatalar = {};
    this.hataYazdir = [];
    this.kaydediliyor = true;

    if (this.detayForm.value.sinavBasvuruForm.engelliMi == false) {
      this.detayForm.value.sinavBasvuruForm.engelAciklama = null;
    }

    var formBasvuru = this.detayForm.value.sinavBasvuruForm;
    formBasvuru.id = this.basvuruid;
    formBasvuru.sinavTanimId = Number(this.sinavid);
    // formBasvuru.ogrKullaniciId = Number(this.girisService.girisBilgi().tckimlikno);

    const formData = new FormData();
    formData.append('basvuruid', '' + this.basvuruid);
    formData.append("fileProfil", this.fileProfil);


    if (sinavEkAlanBilgiTotal.length > 0) {
      sinavEkAlanBilgiTotal.forEach(x => {
        if (x.file) {
          formData.append("file", x.file);
        }
      })
      formData.append('ekalanlar', JSON.stringify(sinavEkAlanBilgiTotal))
    }

    formData.append("basvurualanlar", JSON.stringify(formBasvuru));

    this.sinavBasvuruService.addUpdate(formData).pipe(finalize(() => {
      this.kaydediliyor = false;
    })).subscribe({
      next: res => {
        this.basvuruid = res.data.id;

        if (this.yeni == true) {
          this.step = 4;
        }

        this.yetkiService.success(res?.message, 1);
        this.getById(res.data?.id);
      },
      error: err => {
        this.hatalar = this.validationService.hatalar(err.error.Errors);
        this.hataYazdir = [];
        this.hatalar = this.validationService.hatalar(err.error.Errors);
        for (let key in this.hatalar) {
          let hata = this.hatalar[key];
          this.yetkiService.error(hata[0], 1)
          this.hataYazdir.push(this.hatalar[key]);
        }
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
        }
      }
    });
  }

  continue() {
    this.step++;
  }

  back() {
    this.step--;
  }

  odemeTipFnc(e: any) {
    this.odemeTip = e.target.value;
  }

  odemeDekontKaydet() {
    this.odemeKaydediliyor = true;
    this.hatalar = {};
    var formOdeme = this.detayForm.value.odemeForm;

    const formData = new FormData();
    formData.append('basvuruid', '' + this.basvuruid);
    formData.append("fileDekont", this.fileDekont);
    formData.append("digeralanlar", JSON.stringify(formOdeme));
    formData.append('odemeid', '' + this.odemeid);

  }

  kartOdemeBaslat() {

    this.odemeKaydediliyor = true;
    this.hatalar = {};
    this.kartBilgiDto.basvuruId = this.basvuruid;

  }


  listener = (event: any) => {

    if (event.source.hasOwnProperty('name') && event.source?.name == 'iframe' && event.data != '' && (event.origin == "https://localhost:44328" || event.origin == "https://testyos.erbakan.edu.tr" || event.origin == "https://yos.erbakan.edu.tr")) {
      var data = event.data?.trim();

      if (data.split(':')[0] == "odemeiptal") {
        this.yetkiService.sweetAlertError(data.split(':')[1]);
      }

      if (data.split(':')[0] == "odemetamamlandi") {

        this.kartBilgiDto.verifyEnrollmentRequestId = data.split(':')[1];

      }
    }

  }


  dilList() {
    this.tanimlarService.getAll('', 1, 1000).subscribe(res => {
      this.diller = res.data;
    });
  }

  sinavEkAlanList(sinavid: number) {
    this.sinavEkAlanService.getAll('', 1, 100, sinavid).subscribe(res => {
      this.sinavEkAlanlar = res.data;
    });
  }


  basvuruTamamla() {
    this.dogrulaniyor = true;
    if (this.kvkkCheck) {
      if (this.basvuruid > 0) {
        this.basvuruOnayMsg = '';
       
          this.sinavBasvuruService.basvuruTamamla(this.basvuruid).pipe(finalize(() => {
            this.dogrulaniyor = false;
          })).subscribe({
            next: res => {
              if (res?.message && res?.message != "") { this.yetkiService.sweetAlertSuccess(res?.message) };
              this.router.navigateByUrl('/')
            },
            error: err => {
              this.hatalar = this.validationService.hatalar(err.error.Errors);
              if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
                if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
                if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
              }
            }
          });
        
      }
    }
    else {
      this.dogrulaniyor = false;
      this.basvuruOnayMsg = 'Lütfen bilgilerinizin doğruluğunu onaylayınız.';
    }
  }


  basvuruTekrarGonder() {
    this.dogrulaniyor = true;

    if (this.basvuruid > 0) {
      this.basvuruOnayMsg = '';
      if (this.odemeid > 0) {
        this.sinavBasvuruService.basvuruTekrarGonder(this.basvuruid).pipe(finalize(() => {
          this.dogrulaniyor = false;
        })).subscribe({
          next: res => {
            if (res?.message && res?.message != "") { this.yetkiService.sweetAlertSuccess(res?.message) };

            this.router.navigateByUrl('/')
          },
          error: err => {
            this.hatalar = this.validationService.hatalar(err.error.Errors);
            if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
              if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
              if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
            }
          }
        });
      }
      else {
        this.dogrulaniyor = false;
        this.basvuruOnayMsg = 'Lütfen ödeme bilgilerini giriniz. / Please enter payment information.';
      }
    }
  }

  addMessageEvent = (obj: any) => {
    var fn = this.listener;
    const type = "message";

    if (obj.addEventListener) {
      obj.addEventListener(type, fn, false);
    }
    else if (obj.attachEvent) {
      obj["e" + type + fn] = fn;
      obj[type + fn] = function () { obj["e" + type + fn](window.event); }
      obj.attachEvent("on" + type, obj[type + fn]);
    }
    else {
      obj["on" + type] = obj["e" + type + fn];
    }
  }

  removeMessageEvent = (obj: any) => {
    var fn = this.listener;
    const type = "message";

    if (obj.addEventListener) {
      obj.removeMessageEvent(type, fn, false);
    }

    else if (obj.attachEvent) {
      obj["e" + type + fn] = fn;
      obj[type + fn] = function () { obj["e" + type + fn](window.event); }
      obj.attachEvent("on" + type, obj[type + fn]);
    }
    else {
      obj["on" + type] = obj["e" + type + fn];
    }
  }
}



