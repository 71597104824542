<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h3 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span ><i class="fas fa-unlock text-primary me-2" style="font-size: large;"></i><span translate>sifreDegistir</span> 
                    </span>
                </h3>
            </div>
            <div class="d-flex align-items-center gap-2 gap-lg-3">
                <!-- <a [routerLink]="['yeni']" class="btn btn-sm btn-primary">Yeni Uygulama</a> -->
            </div>
        </div>
    </div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" class="container-fluid">
            <loading [loader]="yukleniyor">
                <form [formGroup]="detayForm" (submit)="kaydetGuncelle()">
                    <div class="card col-12 col-md-6 offset-md-3" style="border-top: 2px #77c2e9 solid;">
                      
                        <div class="card-body ">
                            <div class="form-floating mb-3">
                                <input formControlName="eskiSifre" type="password" class="form-control"
                                    id="eskiSifre" style="border-color: #8fb9d5; "
                                    placeholder="eskiSifre" name="eskiSifre"/>
                                <label style="color: #009ef7;" class="fs-4 fw-bold" for="adi" translate>mevcutPass</label>
                                <div class="text-danger" *ngFor="let hata of hatalar['eskiSifre']">
                                    {{hata}}
                                </div>
                            </div>
                            <div class="form-floating mb-3">
                                <input formControlName="yeniSifre" type="password" class="form-control"
                                    id="yeniSifre" style="border-color: #8fb9d5;"
                                    placeholder="yeniSifre" name="yeniSifre"/>
                                <label style="color: #009ef7;" class="fs-4 fw-bold"  for="adi" translate>yeniPass</label>
                                <div class="text-danger" *ngFor="let hata of hatalar['yeniSifre']">
                                    {{hata}}
                                </div>
                            </div> 
                            <div class="form-floating mb-3">
                                <input formControlName="yeniSifreTekrar" type="password" class="form-control"
                                    id="yeniSifreTekrar" style="border-color: #8fb9d5;"
                                    placeholder="yeniSifreTekrar" name="yeniSifreTekrar" />
                                <label style="color: #009ef7;" class="fs-4 fw-bold"  for="yeniSifreTekrar" translate>yeniPassRepeat</label>
                                <div class="text-danger" *ngFor="let hata of hatalar['yeniSifreTekrar']">
                                    {{hata}}
                                </div>
                            </div> 
                        </div>
                        <div class="row card-footer">
                            <div class="col-md-6 col-6 text-left">
                                <button type="button" routerLink="/" translate id="kt_modal_new_target_cancel"
                                    class="btn btn-danger btn-hover-rise me-3"><i class="fas fa-reply"></i>geri
                                </button>
                            </div>
                            <div class="col-md-6 col-6 text-end">
                                <submit-button [loader]="kaydediliyor" [label]="'Güncelle'"
                                [loading]="yeni?'Kaydediliyor...':'Güncelleniyor...'">
                            </submit-button>
                            </div>
                        </div>

                    </div>

                </form>
            </loading>
        </div>
    </div>
</div>