<div class="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto" style="border-top: 3px #00A6C9 solid; ">
    <div *ngIf="this.hataMesaj" class="alert alert-danger">
        {{this.hataMesaj}}
    </div>
    <div *ngIf="this.basariMesaj" class="alert alert-success">
        {{this.basariMesaj}}
    </div>
    <form class="form w-100" [hidden]="step!=1" novalidate="novalidate" [formGroup]="girisForm" (submit)="girisYap()">
        <div class="text-center mb-10">
            <h1 class="text-dark mb-3" style="vertical-align: middle;">
               
                <span style='margin-left:1em'>FATİH TEŞKİLAT SİSTEMİ</span> 
                <!-- <span style="float:right">
                        <button (click)="onChange('tr')" type="button" class="btn btn-danger text-white btn-sm me-2">TR</button>
                         <button (click)="onChange('en')" type="button" class="btn btn-primary btn-sm">EN</button> 
                    </span> -->
            </h1>
         
            <div class="text-black-400 fw-bold fs-4" translate>
                loginuyari1
            </div>
        
        </div>
        <div class="fv-row mb-5">
            <label class="form-label fs-6 fw-bolder text-dark" translate>eposta</label>
            <input [maxlength]="150" class="form-control form-control-lg form-control-solid" type="text"
                name="tckimlikno" formControlName="tckimlikno" autocomplete="off" style="border-color: #8fb9d5;" />
        </div>
        <div class="fv-row mb-5">
            <div class="d-flex flex-stack mb-2">
                <label class="form-label fw-bolder text-dark fs-6 mb-0" translate>sifre</label>
            </div>
            <input [maxlength]="20" class="form-control form-control-lg form-control-solid" type="password"
                name="password" formControlName="sifre" autocomplete="off" style="border-color: #8fb9d5;" />
            <div style="text-align: right;">
                <span class="form-text fs-6" style="color: #0095E8;">
                    <i class="fas fa-key me-1 text-danger"></i><a (click)="divOpenFnc(3)" class="cursor-pointer"
                        style="text-decoration: underline;" translate>sifreUnuttum</a></span>
            </div>

        </div>
        <div class="text-center">
            <button type="submit" id="kt_sign_in_submit" class="btn btn-lg btn-primary w-100 mb-2"
                [attr.data-kt-indicator]="yukleniyor ? 'on' : 'off'" [disabled]="yukleniyor">
                <i class="fas fa-sign-in-alt me-2 fs-3"></i>
                <span class="indicator-label" translate>giris</span>
                <span class="indicator-progress">Lütfen Bekleyin...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            </button>
        </div>
    </form>

    <div [hidden]="step!=1" class="text-center  mb-2 " translate>
        kayitUyari
    </div>
    <button [hidden]="step!=1" (click)="divOpenFnc(2)" class="btn btn-flex flex-center btn-danger btn-lg w-100 mb-5">
        <i class="fas fa-user-plus me-2"></i>
        <span class="indicator-label" translate> yeniKayit</span>
    </button>

    <form class="form w-100" [hidden]="step!=2" novalidate="novalidate" [formGroup]="detayKayitForm"
        (submit)="kayitOl()">
        <div class="text-center mb-10">
            <h3 class="text-dark mb-3" style="vertical-align: middle;" translate>
                <i class="fas fa-user-plus text-info fs-3 me-2"></i> yeniKayitBaslik
            </h3>
        </div>

        <div class="fv-row mb-4">
            <label class="form-label fs-6 fw-bolder text-dark" translate>ad</label>
            <input class="form-control form-control-lg form-control-solid" oninput="this.value = this.value.toLocaleUpperCase('TR')"     type="text" name="adi" formControlName="adi"
                autocomplete="off" 
                style="border-color: #8fb9d5;" />
            <div class="text-danger" *ngFor="let hata of hatalar['Adi']">
                {{hata}}
            </div>
        </div>
        <div class="fv-row mb-4" >
            <div class="d-flex flex-stack mb-2">
                <label translate class="form-label fw-bolder  text-dark fs-6 mb-0">soyad</label>
            </div>
            <input class="form-control form-control-lg form-control-solid" type="text" name="soyadi"
                formControlName="soyadi" autocomplete="off" oninput="this.value = this.value.toLocaleUpperCase('TR')"
                style="border-color: #8fb9d5;" />
            <div class="text-danger" *ngFor="let hata of hatalar['Soyadi']">
                {{hata}}
            </div>
        </div>
        <div class="fv-row mb-4" >
            <div class="d-flex flex-stack mb-2">
                <label translate class="form-label fw-bolder text-dark fs-6 mb-0">dogum_tarihi</label>
            </div>
            <input formControlName="dogum_tarihi" type="date" class="form-control datepicker" id="dogum_tarihi"
                placeholder="Doğum tarihinizi giriniz." name="dogum_tarihi" style="border-color: #8fb9d5;" />
            <div class="text-danger" *ngFor="let hata of hatalar['dogum_tarihi']">
                {{hata}}
            </div>
        </div>

        <div class="fv-row mb-4">
            <div class="d-flex flex-stack mb-2">
                <label translate class="form-label fw-bolder  text-dark fs-6 mb-0">eposta</label>
            </div>
            <input class="form-control form-control-lg form-control-solid" type="text" name="email"
                formControlName="email" oninput="this.value = this.value?.toLocaleLowerCase()" autocomplete="off"
                style="border-color: #8fb9d5;" />
            <div class="text-danger" *ngFor="let hata of hatalar['Email']">
                {{hata}}
            </div>
        </div>

        <div class="text-center">
            <button type="submit" id="kt_sign_in_submit" class="btn btn-lg btn-primary w-100 mb-5"
                [attr.data-kt-indicator]="kayitolunuyor ? 'on' : 'off'" [disabled]="kayitolunuyor">
                <i class="fas fa-address-card me-2 fs-4"></i>
                <span class="indicator-label" translate>kayit-ol</span>
                <span class="indicator-progress">Lütfen Bekleyin...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            </button>
            <button translate type="button" (click)="divOpenFnc(1)" id="kt_modal_new_target_cancel"
                class="btn btn-danger btn-hover-rise me-3"><i class="fas fa-reply"></i> giris-ekranina-don
            </button>
        </div>

        <div class="d-flex align-items-center bg-light-warning rounded p-2  mt-2">
            <!--begin::Icon-->
            <span class="svg-icon svg-icon-warning me-5">
                <!--begin::Svg Icon | path: icons/duotune/abstract/abs027.svg-->
                <span class="svg-icon svg-icon-1">
                    <i class="far fa-hand-point-right text-danger" style="font-size: larger;"></i>
                </span>
                <!--end::Svg Icon-->
            </span>
            <!--end::Icon-->
            <!--begin::Title-->
            <div class="flex-grow-1 me-2 fs-6" translate>
                yeniKayitUyari
                <span class="text-muted fw-bold d-block"></span>
            </div>
            <!--end::Title-->
            <!--begin::Lable-->
            <span class="fw-bolder text-warning py-1"></span>
            <!--end::Lable-->
        </div>




    </form>


    <div class="form w-100" [hidden]="step!=3" novalidate="novalidate" (submit)="sifremiUnuttum()">
        <h3 class="text-dark text-center mb-3" style="vertical-align: middle;" translate>
            <i class="fas fa-unlock text-info fs-3 me-2"></i>sifreYenilemeBaslik
        </h3>

        <div class="text-black-400 text-center fw-bold fs-5 mb-4" translate>
            <!-- Yeni şifrenizi göndereceğimiz <span style="color:#0095E8">e-posta adresinizi </span>giriniz. -->
            sifreYenileUyari
        </div>

        <div class="fv-row mb-4">
            <label class="form-label fs-6 fw-bolder text-dark" translate>eposta</label>
            <input class="form-control form-control-lg form-control-solid" type="text" [email]="true" name="eposta"
                [(ngModel)]="suEposta" autocomplete="off" oninput="this.value = this.value?.toLocaleLowerCase()"
                style="border-color: #8fb9d5;" />
        </div>

        <div class="d-flex justify-content-center flex-shrink-0">
            <button (click)="divOpenFnc(1)" type="button" id="kt_modal_new_target_cancel" translate
                class="btn btn-danger btn-hover-rise me-3"><i class="fas fa-reply"></i> giris-ekranina-don
            </button>

            <button type="submit" (click)="sifremiUnuttum()" class="btn btn-success"  [disabled] ="dogrulaniyor"
                [attr.data-kt-indicator]="dogrulaniyor ? 'on' : 'off'">
                <span translate class="indicator-label"><i class="fas fa-check"></i>sifreYenile</span>
                <span class="indicator-progress">Lütfen bekleyin / Please wait...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            </button>
        </div>
    </div>
</div>