import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { TercihDonemTanim } from 'src/app/models/tercihDonemTanim.model';
import { YerlestirmeTanim } from 'src/app/models/yerlestirmeTanim.model';
import { TercihDonemTanimService } from 'src/app/services/tercih-donem-tanim.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YerlestirmeTanimService } from 'src/app/services/yerlestirme-tanim.service';
import { YetkiService } from 'src/app/services/yetki.service';
import Swal from 'sweetalert2';
declare var bootstrap: any;

@Component({
  selector: 'tercih-yerlestirme-islem',
  templateUrl: './tercih-yerlestirme-islem.component.html',
  styleUrls: ['./tercih-yerlestirme-islem.component.css']
})
export class TercihYerlestirmeIslemComponent implements OnInit {
  constructor(public tercihTanimService: TercihDonemTanimService,private route: ActivatedRoute, public yetkiService: YetkiService, private validationService: ValidationService,private router: Router, private yerlestirmeTanimService: YerlestirmeTanimService, private formBuilder: FormBuilder) {
    this.yerlestirmeTanimDetayService=yerlestirmeTanimService;
   }
  tercihTanim:TercihDonemTanim=new TercihDonemTanim();
  @ViewChild('grid') grid?: GridComponent;
  hatalar: any = {};
  tercihTanimId:number=0;

  ngOnInit(): void {   
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.tercihTanimId = params['id'];
        this.getTercihTanimById(Number(params['id']));
      }
    });
  }

  yeniYerlestirme=false;
  yerlestirmeModal() {
    this.yeniYerlestirme = true;
    this.hatalar = {};
    this.detayYerlestirmeForm = this.formBuilder.group(new YerlestirmeTanim());
    document.getElementById('openModalYerlestirmeButton')?.click();
  }


 
  yapiliyor=false;
  yerlestirmeTanimDetay: YerlestirmeTanim[] = [];
  yerlestirmeTanimDetayService: YerlestirmeTanimService;
  detayYerlestirmeForm = this.formBuilder.group(new YerlestirmeTanim());
  
  yerlestirmeYap(){
    this.yapiliyor=true;
    const siradaki=Number(this.yerlestirmeTanimDetay.length+1);
    var form = this.detayYerlestirmeForm.value;
    form.tercihTanimId = Number(this.tercihTanimId);

    const datepipe: DatePipe = new DatePipe('en-US');
    let kayitBaslangicTarih = datepipe.transform(form.kayitBaslangicTarih, 'yyyy-MM-ddTHH:mm');
    form.kayitBaslangicTarih = kayitBaslangicTarih;

    let kayitBitisTarih = datepipe.transform(form.kayitBitisTarih, 'yyyy-MM-ddTHH:mm');
    form.kayitBitisTarih = kayitBitisTarih;

    this.yerlestirmeTanimService.addUpdate(form).pipe(finalize(() => {
      this.yapiliyor=false;
    })).subscribe({
      next: res => {
        if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
        bootstrap.Modal.getInstance(document.getElementById('yerlestirme_modal')).hide();
        this.grid?.load();
      },
      error: err => {        
        this.hatalar = this.validationService.hatalar(err.error.Errors);
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertWarning(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertWarning(err.error?.Message) };
        }
      }
    });
  }
  
  yerlestirmeById(id: number) {
    this.yerlestirmeTanimService.getById(id).subscribe(res => {
      this.yeniYerlestirme = false;
      this.detayYerlestirmeForm.patchValue(res.data);
      this.hatalar = {};
      document.getElementById('openModalYerlestirmeButton')?.click();
    }, err => {
      this.router.navigateByUrl('/')
    },);
  }

  yayinIslem(tanimId:number,tip:number){
  const yeni=this.yerlestirmeTanimDetay.find(c=>c.id==tanimId);
  const ifade=tip==1 ? "Yerleşen listesini yayınlamak istediğinize emin misiniz?":"Yerleşen listesini yayından kaldırmak istediğinize emin misiniz?";
  const ifadeBaslik=tip==1 ? "YERLEŞTİRME YAYINLA ("+yeni?.aciklama+")":"YERLEŞTİRME YAYINDAN KALDIR ("+yeni?.aciklama+")";
    Swal.fire({
      title: ifade,
      text: ifadeBaslik,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Evet',
      cancelButtonText: 'İptal',
    }).then((result) => {
      if (result.value) {
        this.yapiliyor=true;
        this.yerlestirmeTanimService.yayinIslem(tanimId,tip).pipe(finalize(() => {
          this.yapiliyor=false;
        })).subscribe({
          next: res => {
            if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
            this.grid?.load();
          },
          error: err => {        
            this.hatalar = this.validationService.hatalar(err.error.Errors);
            if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
              if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertWarning(err.error?.message) };
              if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertWarning(err.error?.Message) };
            }
          }
        });
      }
    })
  }

  getTercihTanimById(id: number) {
    this.tercihTanimService.getById(id).subscribe(res => {
      this.tercihTanim = res.data;
    }, err => {
      this.router.navigateByUrl('/')
    },);
  }

  siliniyor=false;
  silModal(yerlestirmeTanim: YerlestirmeTanim) {
    Swal.fire({
      title: 'Yerleştirme bilgilerini silmek istediğinize eminmisiniz ? <span class=text-danger>( İşlem geri alınamaz. )</span>',
      html: '<b>'+yerlestirmeTanim.yerlestirmeNo+'. Yerleştirme</b>',
      icon: 'error',
      showCancelButton: true,
      confirmButtonText: 'Sil',
      cancelButtonText: 'İptal',
    }).then((result) => {
      if (result.value) {
        this.siliniyor = true;
        this.yerlestirmeTanimService.delete(yerlestirmeTanim).subscribe(response => {
          this.grid?.load();
          this.yetkiService.success("Yerleştirme bilgileri başarıyla silindi.", 1)
          this.siliniyor = false;
        }, error => {
          this.yetkiService.error("Hata oluştu.", 1)
          this.grid?.load();
          this.siliniyor = false;
        });
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("İptal edildi.", "Silme işlemi iptal edildi.", 'error')
      }
    })

  }

}
