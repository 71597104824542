<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h3 class="d-flex text-dark fw-bolder fs-2 flex-column mb-0">
                    <span><i class="fas fa-highlighter me-2" style="font-size: large; color: #37a2d4;"></i>
                        {{this.tercihTanim.adi }}
                        <span>
                            &nbsp;
                            (<i class="fas fa-calendar-alt text-success fs-3"></i>
                            {{this.tercihTanim.baslangicTarihi | date:'dd.MM.yyyy HH:mm' }}</span>
                        &nbsp;-&nbsp;
                        &nbsp;&nbsp;
                        <i class="fas fa-calendar-alt text-danger fs-3"></i>
                        {{this.tercihTanim.bitisTarihi | date:'dd.MM.yyyy HH:mm' }})</span>
                    <!-- <span class="text-muted fs-7 fw-bold mt-2">Alt Başlık</span> -->
                </h3>
            </div>
            <div class="d-flex align-items-center gap-2 gap-lg-3">

                <button type="button" *ngIf="yetkiService.yetkiVar(['Kullanici.Standart'])" routerLink="/"
                    id="kt_modal_new_target_cancel" class="btn btn-danger btn-hover-rise me-3" translate><i
                        class="fas fa-reply"></i> geri
                </button>

                <button *ngIf="yetkiService.yetkiVar(['Kullanici.OgrenciIsleri'])" type="button"
                    [routerLink]="['/tercih-basvurular',tercihTanimId]" id="kt_modal_new_target_cancel"
                    class="btn btn-danger btn-hover-rise me-3" translate><i class="fas fa-reply"></i> geri
                </button>
            </div>




        </div>
    </div>

    <loading [loader]="yukleniyor">
        <div id="kt_create_account_stepper"
            class="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid mx-6 mt-2">
            <div
                class="d-flex justify-content-center bg-white rounded justify-content-xl-start flex-row-auto w-100 w-xl-250px w-xxl-350px me-7 ">

                <div class="px-6 px-lg-10 px-xxl-15 py-20">
                    <div class="stepper-nav">
                        <div class="stepper-item cursor-pointer" (click)="step=1"
                            [ngClass]="{'completed': step>1, 'current': step==1}">
                            <div class="stepper-line w-40px"></div>
                            <div class="stepper-icon w-40px h-40px"><i class="stepper-check fas fa-check"></i><span
                                    class="stepper-number">1</span></div>
                            <div class="stepper-label">
                                <h3 class="stepper-title">Başvuru Uyarı</h3>
                                <div class="stepper-desc fw-bold fs-6">Application Notice</div>
                            </div>
                        </div>
                        <div class="stepper-item cursor-pointer" (click)="step=2"
                            [ngClass]="{'completed': step>2, 'current': step==2}">
                            <div class="stepper-line w-40px"></div>
                            <div class="stepper-icon w-40px h-40px"><i class="stepper-check fas fa-check"></i><span
                                    class="stepper-number">2</span></div>
                            <div class="stepper-label">
                                <h3 class="stepper-title">Kişisel Bilgiler</h3>
                                <div class="stepper-desc fw-bold fs-6">
                                    Personal Information
                                </div>
                            </div>
                        </div>

                        <div class="stepper-item cursor-pointer"
                            (click)="kayitliBasvuruBilgi.sonStepNo >1  ?  step=3:null"
                            [ngClass]="{'completed': step>3, 'current': step==3}">
                            <div class="stepper-line w-40px"></div>
                            <div class="stepper-icon w-40px h-40px"><i class="stepper-check fas fa-check"></i><span
                                    class="stepper-number">3</span></div>
                            <div class="stepper-label">
                                <h3 class="stepper-title">Lise Bilgileri </h3>
                                <div class="stepper-desc fw-bold fs-6">High School Information
                                </div>
                            </div>
                        </div>

                        <div class="stepper-item cursor-pointer"
                            (click)="kayitliBasvuruBilgi.sonStepNo >2  ?  step=4:null"
                            [ngClass]="{'completed': step>4, 'current': step==4}">
                            <div class="stepper-line w-40px"></div>
                            <div class="stepper-icon w-40px h-40px"><i class="stepper-check fas fa-check"></i><span
                                    class="stepper-number">4</span></div>
                            <div class="stepper-label">
                                <h3 class="stepper-title">Sınavlar ve Belgeler</h3>
                                <div class="stepper-desc fw-bold fs-6">Exam Results Information
                                </div>
                            </div>
                        </div>
                        <div class="stepper-item cursor-pointer"
                            (click)="(basvuruid >0 && kayitliBasvuruBilgi.sonStepNo>3) ?  step=5:null"
                            [ngClass]="{'completed': step>5, 'current': step==5}">
                            <div class="stepper-line w-40px"></div>
                            <div class="stepper-icon w-40px h-40px"><i class="stepper-check fas fa-check"></i><span
                                    class="stepper-number">5</span></div>
                            <div class="stepper-label">
                                <h3 class="stepper-title">Seçilen Programlar</h3>
                                <div class="stepper-desc fw-bold fs-6">Selected Programs
                                </div>
                            </div>
                        </div>

                        <div (click)="(basvuruid >0 && tercihSecilenBirimler.length>0) ?  step=6:null"
                            class="stepper-item cursor-pointer" [ngClass]="{'completed': step>6, 'current': step==6}">
                            <div class="stepper-line w-40px"></div>
                            <div class="stepper-icon w-40px h-40px"><i class="stepper-check fas fa-check"></i><span
                                    class="stepper-number">6</span></div>
                            <div class="stepper-label">
                                <h3 class="stepper-title">Başvuru Ön izleme ve Onay</h3>
                                <div class="stepper-desc fw-bold fs-6">Application Preview and Approval
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row-fluid flex-center bg-white rounded">
                <form class="py-10 w-100  px-5  align-self-start" [formGroup]="detayForm">
                    <div class="current">
                        <div formGroupName="tercihBasvuruForm">
                            <div [hidden]="step!=1">
                                <div class="card mb-5 mb-xxl-8">
                                    <div class="text-center fw-bold fs-3 text-info">
                                        Başvuru Uyarı / Application Notice
                                    </div>
                                    <div class="card-body pt-9 pb-0 fs-5" [innerHTML]="tercihTanim.aciklama">


                                    </div>
                                </div>
                            </div>

                            <div [hidden]="step!=2">
                                <div class="card mb-5 mb-xxl-8">
                                    <div class="card-body pt-9 pb-0">
                                        <div class="d-flex flex-wrap flex-sm-nowrap">
                                            <!--begin: Pic-->
                                            <div class="me-7 mb-4">
                                                <div
                                                    class="symbol symbol-200px symbol-lg-200px symbol-fixed position-relative">
                                                    <img src="/assets/image/profil.png" alt="image"
                                                        *ngIf="!this.base64textString; else dolu">

                                                    <ng-template #dolu>
                                                        <img src="data:image/png;base64,{{this.base64textString}}"
                                                            alt="Secilen" />
                                                    </ng-template>
                                                    <div
                                                        class="position-absolute translate-middle bottom-20 start-0 mb-6 rounded-circle h-40px w-40px">

                                                        <button type="button" style="background-color:#009ef8"
                                                            class="btn btn-xs btn-icon btn-circle "
                                                            onclick="document.getElementById('selectedFile').click()">
                                                            <i class="far fa-edit text-white"></i></button>
                                                    </div>

                                                    <div class="text-danger"
                                                        *ngFor="let hata of hatalar['profilPhoto']">
                                                        <span class="px-10">{{hata}}</span>
                                                    </div>
                                                    <div class="text-danger  mt-5" style="width: 200px">
                                                        <span class="form-text fs-6 px-8"
                                                            style="color: #0095E8; text-align:right;" translate> <i
                                                                class="fas fa-exclamation-triangle fs-4 me-2 text-danger"></i>basvuruResimUyari
                                                        </span>
                                                    </div>
                                                </div>

                                                <input ui-jq="filestyle" type="file" id="selectedFile"
                                                    name="selectedFile" accept="image/*" data-icon="true"
                                                    style="color: #7239EA;display: none;"
                                                    data-classButton="btn btn-default"
                                                    (change)="selectFileProfil($event)"
                                                    data-classInput="form-control inline v-middle input-s">

                                            </div>
                                            <div class="flex-grow-1">
                                                <div class="mb-5">
                                                    <div class="form-group row mb-2">
                                                        <label class="col-lg-2 fw-bold text-info fs-6" translate>tcNo
                                                            <span class="text-danger me-2">: *
                                                            </span></label>
                                                        <div class="col-lg-10">
                                                            <input type="text" class="form-control border-primary"
                                                                placeholder="T.C./Y.U. Kimlik/Pasaport No"
                                                                id="tC_YU_PasaportNo" name="tC_YU_PasaportNo"
                                                                formControlName="tC_YU_PasaportNo" />
                                                            <div class="text-danger"
                                                                *ngFor="let hata of hatalar['TC_YU_PasaportNo']">
                                                                {{hata}}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row mb-2">
                                                        <label class="col-lg-2 fw-bold text-info fs-6"
                                                            translate>basvuruTip
                                                            <span class="text-danger me-2">: *
                                                            </span></label>
                                                        <div class="col-lg-10">
                                                            <select formControlName="basvuruTipId" (change)="kisaAdAl()"
                                                                class="form-select" #basvuruTipSelect id="basvuruTipId"
                                                                name="basvuruTipId" style="border-color: #8fb9d5;">
                                                                <option [ngValue]=null>Lütfen seçiniz / Please choose...
                                                                </option>
                                                                <option *ngFor="let item of basvuruTipler"
                                                                    [ngValue]="item.id">
                                                                    {{item.adi}}
                                                                </option>
                                                            </select>
                                                            <div class="text-danger"
                                                                *ngFor="let hata of hatalar['BasvuruTipId']">
                                                                {{hata}}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row mb-2"
                                                        *ngIf="basvuruTipKisaAd=='mavi-kart'">
                                                        <label class="col-lg-2 col-form-label fw-bold text-info fs-6"
                                                            translate>maviKartBelge
                                                            <span class="text-danger me-2">: *
                                                            </span></label>
                                                        <div class="col-lg-10">
                                                            <input ui-jq="filestyle" class="form-control border-warning"
                                                                type="file" id="file"
                                                                (change)="selectBelge($event,'mavi-kart')" name="file"
                                                                nv-file-select="" accept="image/*,.pdf,.doc,.docx"
                                                                uploader="uploader" data-icon="false"
                                                                style="color: #7239EA;"
                                                                data-classButton="btn btn-default"
                                                                data-classInput="form-control inline v-middle input-s">

                                                            <a *ngIf="yeni==false" class="menu-link cursor-pointer fs-5"
                                                                (click)="dosyaIndir('mavi-kart')">Kayitli belge /
                                                                Registered
                                                                document : {{
                                                                kayitliBasvuruBilgi.maviKartDosyaAdi
                                                                }}
                                                            </a>
                                                            <div class="text-danger"
                                                                *ngFor="let hata of hatalar['MaviKartBelge']">
                                                                {{hata}}
                                                            </div>
                                                        </div>

                                                        <span class="col-lg-2">

                                                        </span>
                                                        <span class="col-lg-10 fw-bold fs-6">
                                                            <i
                                                                class="fas  fa-exclamation-triangle me-1 text-danger"></i>
                                                            Dosya tipi pdf veya resim olmalıdır. / File type must be pdf
                                                            or image.
                                                        </span>
                                                    </div>

                                                    <div class="form-group row mb-2">
                                                        <label class="col-lg-2 col-form-label  text-info fs-6"
                                                            translate>kimlikPasaport<span class="text-danger me-2">: *
                                                            </span>
                                                        </label>
                                                        <div class="col-lg-10">
                                                            <input ui-jq="filestyle" class="form-control border-warning"
                                                                type="file" id="file"
                                                                (change)="selectBelge($event,'pasaport')" name="file"
                                                                nv-file-select="" accept="image/*,.pdf,.doc,.docx"
                                                                uploader="uploader" data-icon="false"
                                                                style="color: #7239EA;"
                                                                data-classButton="btn btn-default"
                                                                data-classInput="form-control inline v-middle input-s">

                                                            <a *ngIf="yeni==false" class="menu-link cursor-pointer fs-5"
                                                                (click)="dosyaIndir('pasaport')">Kayitli belge /
                                                                Registered
                                                                document : {{
                                                                kayitliBasvuruBilgi.pasaportDosyaAdi
                                                                }}
                                                            </a>
                                                            <div class="text-danger"
                                                                *ngFor="let hata of hatalar['Pasaport']">
                                                                {{hata}}
                                                            </div>
                                                        </div>
                                                        <span class="col-lg-2">

                                                        </span>
                                                        <span class="col-lg-10 fw-bold fs-6">
                                                            <i
                                                                class="fas  fa-exclamation-triangle me-1 text-danger"></i>
                                                            Dosya tipi pdf veya resim olmalıdır. / File type must be pdf
                                                            or image.
                                                        </span>
                                                    </div>

                                                    <div class="form-group row mb-2">
                                                        <label class="col-lg-2 col-form-label text-info  fs-6"
                                                            translate>ad
                                                            <span class="text-danger me-2"> : *
                                                            </span></label>
                                                        <div class="col-lg-4">
                                                            <input type="text" readonly
                                                                class="form-control border-primary"
                                                                oninput="this.value = this.value.toLocaleUpperCase('TR')"
                                                                placeholder="Ad" id="adi" name="adi"
                                                                formControlName="adi" />
                                                            <div class="text-danger"
                                                                *ngFor="let hata of hatalar['Adi']">
                                                                {{hata}}
                                                            </div>
                                                        </div>
                                                        <label class="col-lg-2 col-form-label text-info fs-6"
                                                            translate>soyad
                                                            <span class="text-danger me-2">: *</span></label>
                                                        <div class="col-lg-4">
                                                            <input type="text" readonly
                                                                class="form-control border-primary"
                                                                oninput="this.value = this.value.toLocaleUpperCase('TR')"
                                                                placeholder="Soyad" id="soyadi" name="soyadi"
                                                                formControlName="soyadi" />
                                                            <div class="text-danger"
                                                                *ngFor="let hata of hatalar['Soyadi']">
                                                                {{hata}}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row mb-2">
                                                        <label class="col-lg-2 col-form-label text-info  fs-6"
                                                            translate>babaadi
                                                            <span class="text-danger me-2"> : *
                                                            </span></label>
                                                        <div class="col-lg-4">
                                                            <input type="text" class="form-control border-primary"
                                                                oninput="this.value = this.value.toLocaleUpperCase('TR')"
                                                                placeholder="Baba Adı" id="babaAdi" name="babaAdi"
                                                                formControlName="babaAdi" />
                                                            <div class="text-danger"
                                                                *ngFor="let hata of hatalar['BabaAdi']">
                                                                {{hata}}
                                                            </div>
                                                        </div>

                                                        <label class="col-lg-2 col-form-label text-info  fs-6"
                                                            translate>anneadi
                                                            <span class="text-danger me-2"> : *
                                                            </span></label>
                                                        <div class="col-lg-4">
                                                            <input type="text" class="form-control border-primary"
                                                                oninput="this.value = this.value.toLocaleUpperCase('TR')"
                                                                placeholder="Anne Adı" id="anneAdi" name="anneAdi"
                                                                formControlName="anneAdi" />
                                                            <div class="text-danger"
                                                                *ngFor="let hata of hatalar['AnneAdi']">
                                                                {{hata}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group row mb-2">
                                            <label class="col-lg-2 col-form-label text-info  fs-6" translate>telNo
                                                <span class="text-danger me-2"> : *
                                                </span></label>
                                            <div class="col-lg-4">
                                                <input type="text" class="form-control border-primary"
                                                    placeholder="Lütfen telefon numaranızı giriniz." maxlength="10"
                                                    id="telefon1" name="telefon1" formControlName="telefon1" />
                                                <div class="text-danger" *ngFor="let hata of hatalar['Telefon1']">
                                                    {{hata}}
                                                </div>
                                            </div>


                                            <label class="col-lg-2 col-form-label text-info  fs-6" translate>telNo
                                                <span class="text-black me-2"> (2):
                                                </span></label>
                                            <div class="col-lg-4">
                                                <input type="text" class="form-control border-primary"
                                                    placeholder="Lütfen bir yakınınızın telefon numaranızı giriniz."
                                                    maxlength="10" id="telefon2" name="telefon2"
                                                    formControlName="telefon2" />
                                                <div class="text-danger" *ngFor="let hata of hatalar['Telefon2']">
                                                    {{hata}}
                                                </div>
                                            </div>

                                            <div class="col-lg-2"></div>
                                            <div class="col-lg-10"> <span class="form-text fs-6"
                                                    style="color: #0095E8; text-align:right;" translate> <i
                                                        class="fas fa-exclamation-triangle me-1 text-danger"></i>
                                                    telNoUyari</span></div>
                                        </div>

                                        <div class="form-group row mb-2">
                                            <label class="col-lg-2 col-form-label text-info  fs-6" translate>dogumTarihi
                                                <span class="text-danger me-2"> : *
                                                </span></label>
                                            <div class="col-lg-4">
                                                <input readonly formControlName="dogumTarihi" type="date"
                                                    class="form-control" id="dogumTarihi" placeholder="dogumTarihi"
                                                    name="dogumTarihi" [value]="tercihBasvuruForm().value.dogumTarihi |
                                date:'yyyy-MM-dd'" style="border-color: #8fb9d5;" class="form-control" />
                                                <div class="text-danger" *ngFor="let hata of hatalar['DogumTarihi']">
                                                    {{hata}}
                                                </div>

                                            </div>

                                            <label class="col-lg-2 col-form-label text-info  fs-6" translate>dogumYeri
                                                <span class="text-danger me-2"> : *
                                                </span></label>
                                            <div class="col-lg-4">
                                                <select formControlName="dogumYeriId" class="form-select"
                                                    #dogumYerSelect id="dogumYeriId" name="dogumYeriId"
                                                    style="border-color: #8fb9d5;">
                                                    <option [ngValue]=null>Lütfen seçiniz / Please choose...
                                                    </option>
                                                    <option *ngFor="let item of ulkeler" [ngValue]="item.id">
                                                        {{item.adi}}
                                                    </option>
                                                </select>
                                                <div class="text-danger" *ngFor="let hata of hatalar['DogumYeriId']">
                                                    {{hata}}
                                                </div>
                                            </div>

                                        </div>

                                        <div class="form-group row mb-2">
                                            <label class="col-lg-2 col-form-label text-info  fs-6" translate>cinsiyet
                                                <span class="text-danger me-2"> : *
                                                </span></label>
                                            <div class="col-lg-4">
                                                <select formControlName="cinsiyet" class="form-select" id="cinsiyet"
                                                    name="cinsiyet" style="border-color: #8fb9d5;">
                                                    <option value="">Lütfen seçiniz / Please choose... </option>
                                                    <option value="Erkek">Erkek / Male</option>
                                                    <option value="Kadın">Kadın / Woman</option>
                                                </select>
                                                <div class="text-danger" *ngFor="let hata of hatalar['Cinsiyet']">
                                                    {{hata}}
                                                </div>
                                            </div>

                                            <label class="col-lg-2 col-form-label text-info  fs-6" translate>medenihal
                                                <span class="text-danger me-2"> : *
                                                </span></label>
                                            <div class="col-lg-4">
                                                <select formControlName="medeniHal" class="form-select" id="medeniHal"
                                                    name="medeniHal" style="border-color: #8fb9d5;">
                                                    <option value="">Lütfen seçiniz / Please choose... </option>
                                                    <option value="Bekar">Bekar / Single</option>
                                                    <option value="Evli">Evli / Married</option>
                                                </select>
                                                <div class="text-danger" *ngFor="let hata of hatalar['MedeniHal']">
                                                    {{hata}}
                                                </div>
                                            </div>

                                        </div>

                                        <div class="form-group row mb-2">
                                            <label class="col-lg-2 col-form-label text-info  fs-6" translate>ilkUyruk
                                                <span class="text-danger me-2"> : *
                                                </span></label>
                                            <div class="col-lg-4">
                                                <select formControlName="ilkUyrukId" class="form-select" #ilkUyrukSelect
                                                    id="ilkUyrukId" name="ilkUyrukId" style="border-color: #8fb9d5;">
                                                    <option [ngValue]=null>Lütfen seçiniz / Please choose...
                                                    </option>
                                                    <option *ngFor="let item of ulkeler" [ngValue]="item.id">
                                                        {{item.adi}}
                                                    </option>
                                                </select>
                                                <div class="text-danger" *ngFor="let hata of hatalar['IlkUyrukId']">
                                                    {{hata}}
                                                </div>
                                            </div>

                                            <label class="col-lg-2 fw-bold text-info  fs-6" translate>nasilSahipOldu
                                                <span class="text-danger me-2"> : *
                                                </span></label>
                                            <div class="col-lg-4">
                                                <select formControlName="ilkVatandaslikNasilSahipOldu"
                                                    class="form-select" id="ilkVatandaslikNasilSahipOldu"
                                                    name="ilkVatandaslikNasilSahipOldu" style="border-color: #8fb9d5;">
                                                    <option value="">Lütfen seçiniz / Please choose... </option>
                                                    <option value="Doğumla">Doğumla / By Birth</option>
                                                    <option value="Başvuru ile">Başvuru ile / By Applicaion</option>
                                                </select>
                                                <div class="text-danger"
                                                    *ngFor="let hata of hatalar['IlkVatandaslikNasilSahipOldu']">
                                                    {{hata}}
                                                </div>
                                            </div>

                                        </div>

                                        <div class="form-group row mb-2">
                                            <label class="col-lg-2 col-form-label text-info  fs-6" translate>digerUyruk
                                            </label>
                                            <div class="col-lg-4">
                                                <select formControlName="ikinciUyrukId" class="form-select"
                                                    #ilkUyrukSelect id="ikinciUyrukId" name="ikinciUyrukId"
                                                    style="border-color: #8fb9d5;">
                                                    <option [ngValue]=null>Lütfen seçiniz / Please choose...
                                                    </option>
                                                    <option *ngFor="let item of ulkeler" [ngValue]="item.id">
                                                        {{item.adi}}
                                                    </option>
                                                </select>
                                                <div class="text-danger" *ngFor="let hata of hatalar['IkinciUyrukId']">
                                                    {{hata}}
                                                </div>
                                            </div>

                                            <ng-container *ngIf="tercihBasvuruForm().value.ikinciUyrukId>0">
                                                <label class="col-lg-2 fw-bold text-info  fs-6"
                                                    translate>nasilSahipOldu2
                                                </label>
                                                <div class="col-lg-4">
                                                    <select formControlName="ikinciVatandaslikNasilSahipOldu"
                                                        class="form-select" id="ikinciVatandaslikNasilSahipOldu"
                                                        name="ikinciVatandaslikNasilSahipOldu"
                                                        style="border-color: #8fb9d5;">
                                                        <option value="">Lütfen seçiniz / Please choose... </option>
                                                        <option value="Doğumla">Doğumla / By Birth</option>
                                                        <option value="Başvuru ile">Başvuru ile / By Applicaion</option>
                                                    </select>
                                                    <div class="text-danger"
                                                        *ngFor="let hata of hatalar['IkinciVatandaslikNasilSahipOldu']">
                                                        {{hata}}
                                                    </div>
                                                </div>
                                            </ng-container>



                                        </div>

                                        <div class="col-md-12 text-center mt-10">
                                            <button type="button" class="btn btn-success" [disabled]="kaydediliyor"
                                                (click)="kaydetGuncelle()"
                                                [attr.data-kt-indicator]="kaydediliyor ? 'on' : 'off'">
                                                <span class="indicator-label" translate><i
                                                        class="fas fa-check"></i>kaydet</span>
                                                <span class="indicator-progress">Kaydediliyor...
                                                    <span
                                                        class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div [hidden]="step!=3">
                                <div class="card mb-5 mb-xxl-8">
                                    <div class="card-body pt-9 pb-0">

                                        <div class="form-group row mb-2">
                                            <label class="col-lg-4 fw-bold text-info  fs-6" translate>noterKonsolosBelge
                                                <span class="text-danger me-2"> : *
                                                </span></label>
                                            <div class="col-lg-8">
                                                <input ui-jq="filestyle" class="form-control border-warning" type="file"
                                                    id="file" (change)="selectBelge($event,'lise-mez-asama-diploma')"
                                                    name="file" nv-file-select="" accept="image/*,.pdf,.doc,.docx"
                                                    uploader="uploader" data-icon="false" style="color: #7239EA;"
                                                    data-classButton="btn btn-default"
                                                    data-classInput="form-control inline v-middle input-s">

                                                <a *ngIf="yeni==false" class="menu-link cursor-pointer fs-5"
                                                    (click)="dosyaIndir('lise-mez-asama-diploma')">Kayitli belge /
                                                    Registered
                                                    document : {{
                                                    kayitliBasvuruBilgi.liseMezAsamaDiplomaDosyaAdi
                                                    }}
                                                </a>
                                                <div class="text-danger" *ngFor="let hata of hatalar['LiseMezAsama']">
                                                    {{hata}}
                                                </div>
                                            </div>
                                            <span class="col-lg-4">

                                            </span>
                                            <span class="col-lg-8 fw-bold fs-6">
                                                <i class="fas  fa-exclamation-triangle me-1 text-danger"></i>
                                                Dosya tipi pdf veya resim olmalıdır. / File type must be pdf or image.
                                            </span>
                                        </div>

                                        <div class="form-group row mb-3">
                                            <label class="col-lg-4 col-form-label text-info  fs-6"
                                                translate>lisenotdokum
                                                <span class="text-danger me-2"> : *
                                                </span></label>
                                            <div class="col-lg-8">
                                                <input ui-jq="filestyle" class="form-control border-warning" type="file"
                                                    id="file" (change)="selectBelge($event,'lise-not-dokumu')"
                                                    name="file" nv-file-select="" accept="image/*,.pdf,.doc,.docx"
                                                    uploader="uploader" data-icon="false" style="color: #7239EA;"
                                                    data-classButton="btn btn-default"
                                                    data-classInput="form-control inline v-middle input-s">

                                                <a *ngIf="yeni==false" class="menu-link cursor-pointer fs-5"
                                                    (click)="dosyaIndir('lise-not-dokumu')">Kayitli belge /
                                                    Registered
                                                    document : {{
                                                    kayitliBasvuruBilgi.liseNotDokumuDosyaAdi
                                                    }}
                                                </a>
                                                <div class="text-danger" *ngFor="let hata of hatalar['LiseNotDokumu']">
                                                    {{hata}}
                                                </div>

                                            </div>
                                            <span class="col-lg-4">

                                            </span>
                                            <span class="col-lg-8 fw-bold fs-6">
                                                <i class="fas  fa-exclamation-triangle me-1 text-danger"></i>
                                                Dosya tipi pdf veya resim olmalıdır. / File type must be pdf or image.
                                            </span>
                                        </div>

                                        <div class="form-group row mb-3">
                                            <label class="col-lg-2 fw-bold text-info  fs-6" translate>liseulke
                                                <span class="text-danger me-2"> : *
                                                </span></label>
                                            <div class="col-lg-10">
                                                <select formControlName="liseUlkeId" class="form-select"
                                                    #mezunUlkeSelect id="liseUlkeId" name="liseUlkeId"
                                                    style="border-color: #8fb9d5;">
                                                    <option [ngValue]=null>Lütfen seçiniz / Please choose...
                                                    </option>
                                                    <option *ngFor="let item of ulkeler" [ngValue]="item.id">
                                                        {{item.adi}}
                                                    </option>
                                                </select>
                                                <div class="text-danger" *ngFor="let hata of hatalar['LiseUlkeId']">
                                                    {{hata}}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group row mb-3">
                                            <label class="col-lg-2 fw-bold text-info  fs-6" translate>liseadi
                                                <span class="text-danger me-2"> : *
                                                </span></label>
                                            <div class="col-lg-4">
                                                <input type="text" class="form-control border-primary"
                                                    placeholder="Mezun olduğunuz lisenin adı" id="liseAdi"
                                                    name="liseAdi" formControlName="liseAdi" />
                                                <div class="text-danger" *ngFor="let hata of hatalar['LiseAdi']">
                                                    {{hata}}
                                                </div>
                                            </div>

                                            <label class="col-lg-2 col-form-label text-info  fs-6"
                                                translate>liseMezuniyetYil
                                                <span class="text-danger me-2"> : *
                                                </span></label>
                                            <div class="col-lg-4">
                                                <input type="number" min="0" class="form-control border-primary"
                                                    placeholder="Mezuniyet Yılı" id="liseMezuniyetYil"
                                                    name="liseMezuniyetYil" formControlName="liseMezuniyetYil" />
                                                <div class="text-danger"
                                                    *ngFor="let hata of hatalar['LiseMezuniyetYil']">
                                                    {{hata}}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group row mb-2">
                                            <label class="col-lg-2 col-form-label fw-bold text-info  fs-6"
                                                translate>notSistemi
                                                <span class="text-danger me-2"> : *
                                                </span></label>
                                            <div class="col-lg-4">
                                                <select formControlName="liseNotSistemId" class="form-select"
                                                    #notSistemSelect id="liseNotSistemId" name="liseNotSistemId"
                                                    style="border-color: #8fb9d5;">
                                                    <option [ngValue]=null>Lütfen seçiniz / Please choose...
                                                    </option>
                                                    <option *ngFor="let item of notSistemler" [ngValue]="item.id">
                                                        {{item.adi}}
                                                    </option>
                                                </select>
                                                <div class="text-danger"
                                                    *ngFor="let hata of hatalar['LiseNotSistemId']">
                                                    {{hata}}
                                                </div>
                                            </div>

                                            <ng-container *ngIf="tercihBasvuruForm().value.liseNotSistemId==1">
                                                <label class="col-lg-2 col-form-label text-info  fs-6" translate>Lütfen
                                                    belirtiniz / Please specify
                                                    <span class="text-danger me-2"> : *
                                                    </span></label>
                                                <div class="col-lg-4">
                                                    <input type="number" min="0" class="form-control border-primary"
                                                        placeholder="Lütfen belirtiniz" id="liseNotSistemAciklama"
                                                        name="liseNotSistemAciklama"
                                                        formControlName="liseNotSistemAciklama" />
                                                    <div class="text-danger"
                                                        *ngFor="let hata of hatalar['LiseNotSistemAciklama']">
                                                        {{hata}}
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>

                                        <div class="form-group row mb-2">
                                            <label class="col-lg-2 col-form-label fw-bold text-info  fs-6"
                                                translate>notOrtalama
                                                <span class="text-danger me-2"> : *
                                                </span></label>
                                            <div class="col-lg-4">
                                                <input type="number" (keyup)="onKeypressEventLise()" min="0"
                                                    class="form-control border-primary"
                                                    placeholder="Lise not ortalamanız" id="liseNotOrtalamasi"
                                                    name="liseNotOrtalamasi" formControlName="liseNotOrtalamasi" />
                                                <div class="text-danger"
                                                    *ngFor="let hata of hatalar['LiseNotOrtalamasi']">
                                                    {{hata}}
                                                </div>
                                            </div>
                                        </div>

                                        <ng-container
                                            *ngIf="(basvuruTipKisaAd=='tc-uyruklu-yurtdisi-lise'||(basvuruTipKisaAd=='mavi-kart' && tercihBasvuruForm().value.liseUlkeId!=190 && tercihBasvuruForm().value.liseUlkeId!=null)) ">
                                            <div class="form-group row mb-2">
                                                <label class="col-lg-4 fw-bold text-info  fs-6"
                                                    translate>liseOgrenimGosterir
                                                    <span class="text-danger me-2"> : *
                                                    </span></label>
                                                <div class="col-lg-8">
                                                    <input ui-jq="filestyle" class="form-control border-warning"
                                                        type="file" id="file"
                                                        (change)="selectBelge($event,'lise-ogrenim-gordugu')"
                                                        name="file" nv-file-select="" accept="image/*,.pdf,.doc,.docx"
                                                        uploader="uploader" data-icon="false" style="color: #7239EA;"
                                                        data-classButton="btn btn-default"
                                                        data-classInput="form-control inline v-middle input-s">

                                                    <a *ngIf="yeni==false" class="menu-link cursor-pointer fs-5"
                                                        (click)="dosyaIndir('lise-ogrenim-gordugu')">Kayitli belge /
                                                        Registered
                                                        document : {{
                                                        kayitliBasvuruBilgi.liseOgrenimGorduguDosyaAdi
                                                        }}
                                                    </a>
                                                    <div class="text-danger"
                                                        *ngFor="let hata of hatalar['LiseOgrenimGordugu']">
                                                        {{hata}}
                                                    </div>
                                                </div>
                                                <span class="col-lg-4">

                                                </span>
                                                <span class="col-lg-8 fw-bold fs-6">
                                                    <i class="fas  fa-exclamation-triangle me-1 text-danger"></i>
                                                    Dosya tipi pdf veya resim olmalıdır. / File type must be pdf or
                                                    image.
                                                </span>
                                            </div>
                                        </ng-container>

                                        <div class="col-md-12 text-center mt-10">
                                            <button type="button" class="btn btn-success" [disabled]="kaydediliyor"
                                                (click)="kaydetGuncelle()"
                                                [attr.data-kt-indicator]="kaydediliyor ? 'on' : 'off'">
                                                <span class="indicator-label" translate><i
                                                        class="fas fa-check"></i>kaydet</span>
                                                <span class="indicator-progress">Kaydediliyor...
                                                    <span
                                                        class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div [hidden]="step!=4">
                                <div class="card mb-5 mb-xxl-8">
                                    <div class="card-body pt-9 pb-0">
                                        <div class="form-group row mb-2">
                                            <label class="col-lg-2 col-form-label fw-bold text-info  fs-6"
                                                translate>sinavTur
                                                <span class="text-danger me-2"> : *
                                                </span></label>
                                            <div class="col-lg-10">
                                                <select (change)="bosalt()" formControlName="sinavTurId"
                                                    class="form-select" #sinavTurSelect id="sinavTurId"
                                                    name="sinavTurId" style="border-color: #8fb9d5;">
                                                    <option [ngValue]=null>Lütfen seçiniz / Please choose...
                                                    </option>
                                                    <option *ngFor="let item of sinavTurler" [ngValue]="item.id">
                                                        {{item.adi}}
                                                    </option>
                                                </select>
                                                <div class="text-danger" *ngFor="let hata of hatalar['SinavTurId']">
                                                    {{hata}}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group row mb-2"
                                            *ngIf="tercihBasvuruForm().value.sinavTurId==3">
                                            <label class="col-lg-2 col-form-label fw-bold text-info  fs-6"
                                                translate>notSistemi
                                                <span class="text-danger me-2"> : *
                                                </span></label>
                                            <div class="col-lg-4">
                                                <select formControlName="sinavNotSistemId" class="form-select"
                                                    #notSistemSelect id="sinavNotSistemId" name="sinavNotSistemId"
                                                    style="border-color: #8fb9d5;">
                                                    <option [ngValue]=null>Lütfen seçiniz / Please choose...
                                                    </option>
                                                    <option *ngFor="let item of notSistemler" [ngValue]="item.id">
                                                        {{item.adi}}
                                                    </option>
                                                </select>
                                                <div class="text-danger"
                                                    *ngFor="let hata of hatalar['SinavNotSistemId']">
                                                    {{hata}}
                                                </div>
                                            </div>

                                            <ng-container *ngIf="tercihBasvuruForm().value.sinavNotSistemId==1">
                                                <label class="col-lg-2 col-form-label text-info  fs-6" translate>Please
                                                    specify
                                                    <span class="text-danger me-2"> : *
                                                    </span></label>
                                                <div class="col-lg-4">
                                                    <input type="number" min="0" class="form-control border-primary"
                                                        placeholder="Lütfen belirtiniz" id="sinavNotSistemAciklama"
                                                        name="sinavNotSistemAciklama"
                                                        formControlName="sinavNotSistemAciklama" />
                                                    <div class="text-danger"
                                                        *ngFor="let hata of hatalar['SinavNotSistemAciklama']">
                                                        {{hata}}
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>

                                        <div class="form-group row mb-2">
                                            <label class="col-lg-2 col-form-label fw-bold text-info  fs-6"
                                                translate>sinavPuan
                                                <span class="text-danger me-2"> : *
                                                </span></label>
                                            <div class="col-lg-4">
                                                <input (keyup)="onKeypressEventSinav()" type="number" min="0"
                                                    class="form-control border-primary" placeholder="Puanınız"
                                                    id="sinavPuan" name="sinavPuan" formControlName="sinavPuan" />
                                                <div class="text-danger" *ngFor="let hata of hatalar['SinavPuan']">
                                                    {{hata}}
                                                </div>
                                            </div>

                                            <label class="col-lg-2 col-form-label fw-bold text-info  fs-6"
                                                translate>Hesaplanan yüzdelik puan
                                                <span class="text-danger me-2"> : *
                                                </span></label>
                                            <div class="col-lg-4">
                                                <input readonly type="number" min="0" class="form-control border-info"
                                                    placeholder="Hesaplanan yüzdelik puan" id="sinavPuanYuzluk"
                                                    name="sinavPuanYuzluk" formControlName="sinavPuanYuzluk" />
                                                <div class="text-danger"
                                                    *ngFor="let hata of hatalar['SinavPuanYuzluk']">
                                                    {{hata}}
                                                </div>
                                                <span class=" fw-bold fs-6">
                                                    <i class="fas  fa-exclamation-triangle me-1 text-danger"></i>
                                                    {{tercihTanim.puanSiniri}} ile 100 arasında olması gerekir. / It
                                                    must be between {{tercihTanim.puanSiniri}} and 100.
                                                </span>
                                            </div>
                                        </div>


                                        <div class="form-group row mb-2"  *ngIf="yetkiService.yetkiVar(['TercihBasvuru.Update'])">
                                            <label class="col-lg-2 col-form-label fw-bold text-info  fs-6"
                                                translate>Yüzlük sınav puanı
                                                <span class="text-danger me-2"> : *
                                                </span></label>
                                            <div class="col-lg-4">
                                                <input  type="number" min="0"
                                                    class="form-control border-primary" placeholder="SinavPuanYuzluk"
                                                    id="sinavPuan" name="sinavPuan" formControlName="sinavPuanYuzluk" />
                                            </div>

                                            <label class="col-lg-2 col-form-label fw-bold text-info  fs-6"
                                                translate>Değerlendirme Puanı
                                                <span class="text-danger me-2"> : *
                                                </span></label>
                                            <div class="col-lg-4">
                                                <input  type="number" min="0" class="form-control border-info"
                                                    placeholder="Değerlendirme puanı" id="degerlendirmePuan"
                                                    name="degerlendirmePuan" formControlName="degerlendirmePuan" /> 
                                            </div>
                                        </div>
                                        <div class="form-group row mb-3">
                                            <label class="col-lg-4 col-form-label text-info  fs-6"
                                                translate>sinacSonucYukle
                                                <span class="text-danger me-2"> : *
                                                </span></label>
                                            <div class="col-lg-8">
                                                <input ui-jq="filestyle" class="form-control border-warning" type="file"
                                                    id="file" (change)="selectBelge($event,'sinav-sonuc')" name="file"
                                                    nv-file-select="" accept="image/*,.pdf,.doc,.docx"
                                                    uploader="uploader" data-icon="false" style="color: #7239EA;"
                                                    data-classButton="btn btn-default"
                                                    data-classInput="form-control inline v-middle input-s">

                                                <a *ngIf="yeni==false" class="menu-link cursor-pointer fs-5"
                                                    (click)="dosyaIndir('sinav-sonuc')">Kayitli belge /
                                                    Registered
                                                    document : {{
                                                    kayitliBasvuruBilgi.sinavSonucDosyaAdi
                                                    }}
                                                </a>
                                                <div class="text-danger" *ngFor="let hata of hatalar['SinavSonuc']">
                                                    {{hata}}
                                                </div>
                                            </div>
                                            <span class="col-lg-4">

                                            </span>
                                            <span class="col-lg-8 fw-bold fs-6">
                                                <i class="fas  fa-exclamation-triangle me-1 text-danger"></i>
                                                Dosya tipi pdf veya resim olmalıdır. / File type must be pdf or image.
                                            </span>
                                        </div>


                                        <div class="col-md-12 text-center mt-10">
                                            <button type="button" class="btn btn-success" [disabled]="kaydediliyor"
                                                (click)="kaydetGuncelle()"
                                                [attr.data-kt-indicator]="kaydediliyor ? 'on' : 'off'">
                                                <span class="indicator-label" translate><i
                                                        class="fas fa-check"></i>kaydet</span>
                                                <span class="indicator-progress">Kaydediliyor...
                                                    <span
                                                        class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div formGroupName="secilenBirimForm">
                            <div [hidden]="step!=5">
                                <div class="card mb-5 mb-xxl-8">
                                    <div class="card-body pt-9 pb-0 ">
                                        <div class="offset-1 col-10">
                                            <div class="form-group row mb-2">
                                                <label class="col-lg-3 col-form-label fw-bold text-info  fs-6">
                                                </label>
                                                <div class="col-lg-9 text-danger  fs-4">
                                                    * {{tercihSecilenBirimler.length+1}}. <span translate>
                                                        tercihUyari
                                                    </span>
                                                    {{this.tercihTanim.yapilacakTercihSayisi}} )
                                                </div>
                                            </div>
                                            <div class="form-group row mb-2">
                                                <label class="col-lg-3 col-form-label fw-bold text-info  fs-6"
                                                    translate>birim
                                                    <span class="text-danger me-2"> : *
                                                    </span></label>
                                                <div class="col-lg-9">
                                                    <select formControlName="fakId" class="form-select" #fakSelect
                                                        id="fakId" name="fakId" (change)="bolumList()"
                                                        style="border-color: #8fb9d5;">
                                                        <option [ngValue]=null>Lütfen seçiniz / Please choose...
                                                        </option>
                                                        <option *ngFor="let item of tercihBirim"
                                                            [ngValue]="item.birimid">
                                                            {{item.birimadi}}
                                                        </option>
                                                    </select>
                                                    <div class="text-danger" *ngFor="let hata of hatalar['FakId']">
                                                        {{hata}}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row mb-2" *ngIf="secilenBirimForm().value.fakId>0">
                                                <label class="col-lg-3 col-form-label fw-bold text-info  fs-6"
                                                    translate>bolum
                                                    <span class="text-danger me-2"> : *
                                                    </span></label>
                                                <div class="col-lg-9">
                                                    <select (change)="programList()" formControlName="bolId"
                                                        class="form-select" #fakSelect id="bolId" name="bolId"
                                                        style="border-color: #8fb9d5;">
                                                        <option [ngValue]=null>Lütfen seçiniz / Please choose...
                                                        </option>

                                                        <option [ngValue]=999999>Tüm programlar / All programs
                                                        </option>

                                                        <option *ngFor="let item of tercihBolum"
                                                            [ngValue]="item.birimid">
                                                            {{item.birimadi}}
                                                        </option>
                                                    </select>
                                                    <div class="text-danger" *ngFor="let hata of hatalar['BolId']">
                                                        {{hata}}
                                                    </div>
                                                </div>
                                            </div>

                                            <loading [loader]="geliyor">
                                                <div class="form-group row mb-2"
                                                    *ngIf="secilenBirimForm().value.bolId>0">
                                                    <label class="col-lg-3 col-form-label fw-bold text-info  fs-6"
                                                        translate>program
                                                        <span class="text-danger me-2"> : *
                                                        </span></label>
                                                    <div class="col-lg-9">
                                                        <select formControlName="programId" class="form-select"
                                                             id="programId" name="programId"
                                                            style="border-color: #8fb9d5;">
                                                            <option [ngValue]=null>Lütfen seçiniz / Please choose...
                                                            </option>
                                                            <option *ngFor="let item of tercihAnabilimDali"
                                                                [ngValue]="item.birimid">
                                                                {{item.birimadi}}
                                                            </option>
                                                        </select>
                                                        <div class="text-danger"
                                                            *ngFor="let hata of hatalar['ProgramId']">
                                                            {{hata}}
                                                        </div>
                                                    </div>


                                                </div>
                                            </loading>

                                            <div class="row">
                                                <div class="col-md-4 text-center mt-10">
                                                    <span class="fw-bold fs-5 text-primary"
                                                        style="float: left;vertical-align: middle;" translate>
                                                        <a translate class="cursor-pointer" data-bs-toggle="modal"
                                                            data-bs-target="#kontenjan_modal" data-toggle="modal" style="text-decoration: underline
                                                        ;">
                                                            kontenjanList
                                                        </a>
                                                    </span>
                                                </div>

                                                <div class="col-md-8 text-center mt-10">
                                                    <button type="button" class="btn btn-success"
                                                        [disabled]="kaydediliyor" (click)="birimKaydetGuncelle()"
                                                        [attr.data-kt-indicator]="kaydediliyor ? 'on' : 'off'">
                                                        <span class="indicator-label" translate><i
                                                                class="fas fa-check"></i>ekle</span>
                                                        <span class="indicator-progress">Kaydediliyor...
                                                            <span
                                                                class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="col-md-12">
                                            <div class="card-body">
                                                <div class="row  mb-4">
                                                    <loading [loader]="(kaydediliyor || gelenBirim) || siliniyor">
                                                        <div class="col-lg-12">
                                                            <div style="border-top: #f5c6c6 1px solid; background-color:white; padding:1rem"
                                                                cdkDropList cdkDropListGroupBaslik
                                                                (cdkDropListDropped)="dropTercih($event)">
                                                                <table
                                                                    class="table table-hover table-responsive gn-middle table-row-bordered fs-6 gy-1">
                                                                    <tr>
                                                                        <th colspan="5"
                                                                            class="text-center fs-3 text-info fw-bold">
                                                                            Tercih
                                                                            Birim Listesi / Preferred Unit List<span
                                                                                class="fs-6 text-danger me-2"> *&nbsp;
                                                                                <span
                                                                                    translate>tercihUyari2</span></span>
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th style="width: 2%;" class="fw-bolder"></th>
                                                                        <th style="width: 7%; text-align: center;"
                                                                            class="fw-bolder">S. No</th>
                                                                        <th style="width: 28%;" class="fw-bolder"
                                                                            translate>
                                                                            birim
                                                                        </th>
                                                                        <th style="width: 30%;" class="fw-bolder"
                                                                            translate>bolum
                                                                        </th>
                                                                        <th style="width: 31%;" class="fw-bolder"
                                                                            translate>
                                                                            program
                                                                        </th>
                                                                        <th style="width: 2%; text-align: center;"
                                                                            class="fw-bolder">#</th>
                                                                    </tr>

                                                                    <tr *ngFor="let item of tercihSecilenBirimler;let i=index;"
                                                                        cdkDrag>
                                                                        <td>
                                                                            <span class=" text-gray-800 fs-5 fw-bolder">
                                                                                <i class="fas fa-arrows-alt text-warning cursor-pointer me-2"
                                                                                    cdkDragHandle
                                                                                    style="font-size:16px ;margin: 2px;"></i>
                                                                            </span>
                                                                        </td>
                                                                        <td style=" text-align: center;"><b> {{
                                                                                item.siraNo
                                                                                }}</b>
                                                                        </td>
                                                                        <td>{{ item.fakAdi }}</td>
                                                                        <td>{{ item.bolAdi }}</td>
                                                                        <td>{{ item.progAdi }}</td>
                                                                        <td style=" text-align: center;">
                                                                            <button type="button"
                                                                                style="background-color:#f1416c;"
                                                                                (click)="silModal(item)"
                                                                                class="btn btn-icon btn-hover-rise btn-active-color-danger btn-sm"
                                                                                title="Tercih sil">
                                                                                <i
                                                                                    class="fas fa-trash-alt text-white"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>

                                                                </table>
                                                            </div>
                                                        </div>
                                                    </loading>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [hidden]="step!=6">
                            <div class="card mb-5 mb-xxl-8">
                                <div class="card-body pt-9 pb-0">
                                    <div class="card-header text-center fs-3 text-info">
                                        Başvuru Ön İzleme / Application Preview
                                    </div>
                                    <div class="col-xl-8 offset-2 mt-3">
                                        <div
                                            class="notice d-flex bg-light-success rounded border-danger border border-dashed h-lg-100 p-6">
                                            <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap ">
                                                <div class="mb-3 mb-md-0 fw-bold">
                                                    <h3 class="text-danger fw-bolder text-center"
                                                        *ngIf="kayitliBasvuruBilgi.basvuruTamamlanmaDurumu==false"><i
                                                            class="fas fa-exclamation-triangle text-warning  fs-3 me-2"></i>
                                                        Önemli
                                                        Not! Başvurunuz henüz tamamlanmadı. / Important note! Your
                                                        application is not complete yet.</h3>

                                                    <h3 class="text-success fw-bolder text-center "
                                                        *ngIf="kayitliBasvuruBilgi.basvuruTamamlanmaDurumu"><i
                                                            class="fas fa-check text-success fs-3 me-2"></i> Başvuru
                                                        tamamlandı. / Application completed.
                                                    </h3>




                                                    <div class="fs-6  pe-7" *ngIf="kayitliBasvuruBilgi.id==0">
                                                        <p style="text-indent: 10px;text-align: justify;" translate>
                                                            onizlemeOnayUyari </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 mt-5 text-center"
                                            *ngIf="kayitliBasvuruBilgi.bilgiDogrulukOnayi==false">
                                            <input type="checkbox" class="form-check-input me-2" id="bilgiDogrulukOnayi"
                                                [(ngModel)]="kvkkCheck" [ngModelOptions]="{standalone: true}">
                                            <label class="checkbox-lg text-primary text-justify"
                                                style="font-size:medium;" for="exampleCheck1" translate>
                                                &nbsp;&nbsp;bilgiOnay</label>
                                            <div class="col-12 mt-5 text-center text-danger fs-4"
                                                *ngIf="this.basvuruOnayMsg">{{this.basvuruOnayMsg}}</div>
                                        </div>

                                        <div class="col-12 mt-5 text-center ">
                                            <h3 class="text-danger fw-bolder"><i
                                                    class="fas fa-exclamation-triangle text-warning  fs-3 me-2"></i>
                                                <span translate>basvuruBelgeUyari</span>
                                            </h3>
                                            <div class="fw-semibold text-gray-400 "
                                                *ngIf="kayitliBasvuruBilgi.basvuruTamamlanmaDurumu && basvuruid>0">
                                                <button type="button" class=" btn  btn-hover-rise btn-sm me-1"
                                                    title="Başvuru belgesi almak için tıklayınız." [disabled]="aliniyor"
                                                    (click)="tercihBasvuruBelgesiAl(basvuruid)"
                                                    [attr.data-kt-indicator]="aliniyor ? 'on' : 'off'"
                                                    style="color: rgb(0, 128, 90);  border: 2px solid green;"
                                                    data-bs-toggle="modal" data-bs-target="#belge_modal"
                                                    data-toggle="modal">
                                                    <span class="indicator-label" translate> <i
                                                            class="fas fa-file-alt text-success fs-2"></i>basvuruBelgeAlBtn</span>
                                                    <span class="indicator-progress">Lütfen bekleyiniz /
                                                        Please wait...
                                                        <span
                                                            class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>


                                        <div *ngIf="kayitliBasvuruBilgi.basvuruTamamlanmaDurumu==false"
                                            class="col-md-12 text-center mt-10">
                                            <button type="button" class="btn btn-success" (click)="basvuruTamamla()"
                                                [attr.data-kt-indicator]="dogrulaniyor ? 'on' : 'off'">
                                                <span class="indicator-label"><i class="fas fa-check"></i>Başvuruyu
                                                    Tamamla / Complete Application</span>
                                                <span class="indicator-progress">Başvuru tamamlanıyor.
                                                    <span
                                                        class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                        </div>

                                        <div *ngIf="step==6 && sonHareketDurum.kisaltma=='basvuru-hatali' "
                                            class="col-md-12 text-center mt-10">
                                            <button type="button" class="btn btn-success"
                                                (click)="basvuruTekrarGonder()"
                                                [attr.data-kt-indicator]="dogrulaniyor ? 'on' : 'off'">
                                                <span class="indicator-label"><i class="fas fa-check"></i>Başvurumu
                                                    Tekrar Gönder /
                                                    Submit My Application Again</span>
                                                <span class="indicator-progress">Başvuru tamamlanıyor.
                                                    <span
                                                        class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-stack pt-1">
                        <button type="button" *ngIf="step>1" (click)="back()"
                            class="btn btn-danger btn-hover-rise me-3 " translate><i class="fas fa-arrow-left"></i>
                            geri
                        </button>

                        <button type="button" *ngIf="kayitliBasvuruBilgi.sonStepNo >=step && step<5"
                            (click)="continue()" class="btn btn-primary btn-hover-rise me-3">
                            <span class="indicator-label" translate> devam <i class="fas fa-arrow-right"></i>
                            </span>
                        </button>

                        <button type="button" *ngIf="step==5 && tercihSecilenBirimler.length>0" (click)="continue()"
                            class="btn btn-primary btn-hover-rise me-3">
                            <span class="indicator-label" translate> devam <i class="fas fa-arrow-right"></i>
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </loading>
</div>



<div id="belge_modal" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl" style="width: 870px;">

        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" style="border-top: #36d35d 1px solid">
                <loading [loader]="aliniyor">
                    <iframe id="iframe" name="iframe" frameBorder="10" [src]="previewUrl" marginHeight="0"
                        marginWidth="0" width="100%" height="600px" scrolling="auto">
                    </iframe>
                </loading>
            </div>
        </div>
    </div>
</div>



<div id="kontenjan_modal" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <i class="fas fa-list me-2 text-primary" style="font-size: large; padding-right:2px;"></i>
                <h2 class="modal-title">Kontenjan Listesi / Quota List </h2>


                <div class="d-flex align-items-center ms-1 ms-lg-3">
                    <span style="float:right;vertical-align: middle;" class="mx-5">
                        <button class="btn btn-sm  btn-shadow-hover btn-secondary fs-6  border border-primary"
                            (click)="exportexcel()" style="color: green;">
                            <i class="fas fa-file-excel " style="color: green;"></i>
                            Excel
                        </button>
                    </span>
                </div>

                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div class="modal-body" style="border-top: #36d35d 1px solid">
                <table class="table  gn-middle table-row-bordered fs-6 gy-1" id="kl_subscriptions_table">
                    <thead>
                        <tr class="text-start fw-bolder fs-6 gs-0 " style="color:#cd7f32">
                            <th class="text-center">S.No</th>
                            <th translate>birim</th>
                            <th translate>bolum</th>
                            <th translate>program</th>
                            <th class="text-center" translate>maviKartKontenjan</th>
                            <th class="text-center" translate>toplamKontenjan</th>

                        </tr>
                    </thead>
                    <tbody class="text-gray-600 ">
                        <!-- dblclick -->
                        <tr *ngFor="let item of kontenjanLar;let i=index;" style="color: black;">
                            <td class="text-center" style="vertical-align: middle;">
                                <b>{{ i+1 }}</b>
                            </td>
                            <td style="vertical-align: middle;">
                                {{ item.fakAdi }}
                            </td>
                            <td style="vertical-align: middle;">
                                {{ item.bolAdi }}
                            </td>
                            <td style="vertical-align: middle;">
                                {{ item.progAdi }}
                            </td>
                            <td style="vertical-align: middle;" class="text-center">
                                {{ item.maviKartKontenjan }}
                            </td>
                            <td style="vertical-align: middle;" class="text-center">
                                {{ item.toplamKontenjan }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>