<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-2 flex-column mb-0">
                    <span style="font-size: medium;">
                        <i class="far fa-map me-2 fs-3" style="color: #d4af37;"></i>Tercih Başvuru
                        Listesi &nbsp;(
                        {{this.tercihTanim.adi }}
                        <span>
                            &nbsp;
                            <i class="fas fa-calendar-alt text-success fs-3"></i>
                            {{this.tercihTanim.baslangicTarihi | date:'dd.MM.yyyy HH:mm' }}</span>
                        &nbsp;-&nbsp;
                        &nbsp;&nbsp;
                        <i class="fas fa-calendar-alt text-danger fs-3"></i>
                        {{this.tercihTanim.bitisTarihi | date:'dd.MM.yyyy HH:mm' }})</span>

                    <!-- <span class="text-muted fs-7 fw-bold mt-2">Alt Başlık</span> -->
                </h1>

            </div>
            <div class="d-flex align-items-center gap-2 gap-lg-3">
                <button class="btn btn-sm  btn-shadow-hover btn-secondary fs-6  border border-primary"
                    (click)="exportexcel2()" style="color: green;">
                    <i class="fas fa-file-excel " style="color: green;"></i>
                    Tercih birim listesini al
                </button>
                <button class="btn btn-sm  btn-shadow-hover btn-secondary fs-6  border border-primary"
                    (click)="exportexcel()" style="color: green;">
                    <i class="fas fa-file-excel " style="color: green;"></i>
                    Tercih başvuru listesini al
                </button>
                <button type="button" routerLink="/tercih-donem-bilgi" id="kt_modal_new_target_cancel"
                    class="btn btn-danger btn-hover-rise me-3"><i class="fas fa-reply"></i> Geri
                </button>
            </div>
        </div>
    </div>

    <div class="post d-flex flex-column-fluid mb-10" id="kt_post">
        <div id="kt_content_container" class="container-fluid">
            <div class="card " style="border-top: 3px rgb(146, 199, 224) solid; ">
                <div class="card-body" style="max-width:100%; overflow-x: scroll;">
                    <div class="d-flex align-items-center bg-light-warning rounded p-2 mb-1" style="border: 1px solid #e98da8;">
                        <div class="flex-grow-1 me-2 fs-5" style="text-align: justify;">
            
                            <span> <i class="fas fa-exclamation-triangle me fs-5 text-danger me-2"></i>Arama alanlarına aramak istediğiniz ifadeyi girdikten sonra <b>Enter</b> tuşuna basınız. Arama kutusunu boşaltıp tekrar <b>Enter</b> tuşuna bastığınızda o alanla ilgili filtreleme kaybolacaktır.</span>
                        </div>
                        <span class="fw-bolder text-warning py-1"></span>
                    </div>
                    <table datatable [dtOptions]="dtOptions" class="row-border hover" >
                        <thead>
                            <tr>
                                <th></th>
                                <th><input class="form-control search-input" style="border-color: #8fb9d5;" id="1"
                                        type="text" placeholder="Aday No" />
                                </th>
                                <th>
                                    <select class="form-select search-input" id="2" style="border-color: #8fb9d5;">
                                        <option value="">Tümü</option>
                                        <option *ngFor="let item of ulkeler" value="{{item.id}}">
                                            {{item.adi}}
                                        </option>
                                    </select>
                                </th>
                                <th><input class="form-control search-input" style="border-color: #8fb9d5;" id="3"
                                        type="text" placeholder="Adı" />
                                </th>
                                <th><input class="form-control search-input" style="border-color: #8fb9d5;" id="4"
                                        type="text" placeholder="Soyadı" />
                                </th>
                                <th></th>
                                <th></th>
                                <th>
                                    <select class="form-select search-input" id="7" style="border-color: #8fb9d5;">
                                        <option value="">Tümü</option>
                                        <option value="True">Yerleşti</option>
                                        <option value="False">Yerleşmedi</option>
                                    </select>
                                </th>
                                <th>
                                    <select class="form-select search-input" id="8" style="border-color: #8fb9d5;">
                                        <option value="">Tümü</option>
                                        <option value="Beklemede / Pending">Beklemede</option>
                                        <option value="Onaylandı / Approved">Onaylandı</option>
                                        <option value="Reddedildi / Denied">Reddedildi</option>
                                        <option value="Hatalı Başvuru / Faulty Application">Hatalı Başvuru</option>
                                        <option value="Başvuru tamamlanmadı. / Application not completed.">Başvuru tamamlanmadı</option>
                                    </select>
                                </th>
                                <th>
                                    <label style="text-align: left;"
                                        class="form-check form-check-custom form-check-solid form-switch ">
                                        <input type="checkbox" name="katildiMi" id="katildiMi" class="form-check-input"
                                            [(ngModel)]="bavuruTamamlanmayanGoster" (change)="tamamlanmayanlar()"
                                            [value]="bavuruTamamlanmayanGoster" style="border:2px  green solid"
                                            [ngStyle]="{'background-color': bavuruTamamlanmayanGoster==false  ? 'red':bavuruTamamlanmayanGoster==true  ? 'green':'' }">

                                        <span *ngIf="bavuruTamamlanmayanGoster"
                                            class="form-check-label fs-7 text-black">
                                            Başvurusu tamamlanmayanları gösterme
                                        </span>
                                        <span *ngIf="bavuruTamamlanmayanGoster==false"
                                            class="form-check-label fs-7 text-black">
                                            Başvurusu tamamlanmayanları göster
                                        </span>
                                    </label>

                                </th>
                            </tr>
                            <tr>
                                <th class="min-w-10px">#</th>
                                <th class="min-w-75px">Aday No</th>
                                <th class="min-w-75px">Uyruk</th>
                                <th class="min-w-75px">Adı</th>
                                <th class="min-w-75px">Soyadı</th>
                                <th class="min-w-25px text-center">Sınav Puan (Yüzlük )</th>
                                <th class="min-w-25px text-center">Değerlendirme Puanı</th>
                                <th class="min-w-25px text-center">Yerleşme Durumu</th>
                                <th class="min-w-200px">Durum</th>
                                <th class="text-center">Başvuru Tarihi</th>        
                                <th style="min-width:250px;"></th>
                            </tr>
                        </thead>

                        <tbody *ngIf="basvuruDetay?.length!= 0">
                            <tr *ngFor="let basvuru of basvuruDetay;let i=index;">
                                <td class="text-info text-left fs-5" style="vertical-align: middle;">
                                    <b>{{(i+1) + (this.pageNo-1)*25}}</b>
                                </td>
                                <td>
                                    {{ basvuru?.basvuruNo }}
                                </td>
                                <td>
                                    {{ basvuru?.ilkUyrukAdi }}
                                </td>
                                <td>
                                    {{ basvuru?.adi }}
                                </td>
                                <td>
                                    {{ basvuru?.soyadi }}
                                </td>
                                <td class="text-center">
                                    <b>{{ basvuru?.sinavPuanYuzluk }}</b>
                                </td>
                                <td class="text-center">
                                    <b> {{ basvuru?.degerlendirmePuan }}</b>
                                </td>

                                <td class="text-center">
                                    <span *ngIf="basvuru.yerlesmeDurum;else yerlesmedi">
                                        <i class="fas fa-check fs-3 text-success"></i>
                                    </span>
                                    <ng-template #yerlesmedi>

                                        <i class="fas fa-times fs-3 text-danger"></i>
                                    </ng-template>
                                </td>
                                <td class="text-center">
                                    <span class="fw-bolder text-info"> <i
                                            class="{{basvuru.sonHareketDurum?.iconClass}} fs-3 me-2"></i> {{
                                        basvuru.durumAdi
                                        }}</span>
                                </td>
                                <td class="text-center">
                                    <span class="fw-bolder text-info"> {{
                                        basvuru?.basvuruTarih | date:'dd.MM.yyyy HH:mm'
                                        }}</span>
                                </td>
                                <td class="text-center">
                                    <div class="d-flex justify-content-center flex-shrink-0" style="width:250px">

                                        <span
                                            title="{{basvuru.basvuruBilgi?.sonInceleyenBilgi}} tarafından incelenmektedir."
                                            class="btn btn-icon" *ngIf="basvuru.basvuruBilgi?.inceleniyor">
                                            <i class="fas fa-info me- 2 fs-1 text-danger  blink_me"></i>
                                        </span>

                                        <a *ngIf="yetkiService.yetkiVar(['TercihBasvuru.BasvuruDetayGor'])"
                                        (click)="ayrintiModal(basvuru)" style="background-color:#2aa31f"
                                            title="BAŞVURU BİLGİLERİNİ GÖR" class="btn btn-icon   btn-sm me-1"
                                            data-bs-toggle="modal">
                                            <i class="fas fa-info-circle text-white" style="font-size:x-large;"></i></a>

                                        <a *ngIf="yetkiService.yetkiVar(['TercihBasvuru.Update'])" class="btn btn-primary btn-sm me-1"
                                            [routerLink]="['/ogr-tercih-basvuru', tercihTanim.id]"
                                            [queryParams]="{basvuruid: basvuru.basvuruBilgi?.id}"
                                            title="BAŞVURU DÜZENLE">
                                            Başvuru düzenle
                                        </a>



                                        <a *ngIf="basvuru.basvuruBilgi?.inceleniyor && ( basvuru.basvuruBilgi?.sonInceleyenTc==kulTc || yetkiService.yetkiVar(['TercihBasvuru.IncelemeBitir']))"
                                            (click)="incelemeBitir(basvuru)" style="background-color:#a31f1f"
                                            title="İNCELEMEYİ BİTİR" class="btn btn-icon btn-sm">
                                            <i class="fas fa-times text-white" style="font-size:x-large;"></i></a>
                                    </div>

                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="basvuruDetay?.length == 0">
                            <tr>
                                <td colspan="11" class="no-data-available text-center">Herhangi bir veri bulunamadı.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<button id="openModalBasvuruAyrintiButton"   [hidden]="true"   data-bs-toggle="modal" data-bs-target="#basvuru_ayrinti_modal"
data-toggle="modal">Open Modal</button>

<div id="basvuru_ayrinti_modal" data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="" style="border-top: #36d35d 1px solid">
                <loading [loader]="inceleniyor">
                    <ogr-tercih-basvuru-ayrinti [data]="basvuruSecilen" *ngIf="basvuruSecilen" (myevent)="gridYenile()">

                    </ogr-tercih-basvuru-ayrinti>
                </loading>
            </div>
        </div>
    </div>
</div>



<table class="table table-responsive gn-middle table-row-bordered fs-7 gy-2" id="kl_tercihler_table"
    style="display: none;" *ngIf="tumBasvurular.length>0">
    <thead>
        <tr class="text-start fw-bolder fs-7 gs-0 " style="color:#cd7f32">
            <th class="min-w-125px">Aday No</th>
            <th class="min-w-125px">Fakülte</th>
            <th class="min-w-125px">Bölüm</th>
            <th class="min-w-125px">Program</th>
            <th class="min-w-125px">Sıra no</th>
        </tr>
    </thead>
    <tbody class="text-gray-600 ">
        <!-- dblclick -->
        <ng-container *ngFor="let item of tumBasvurular;let i=index;">
            <tr *ngFor="let itemYeni of item.tercihSecilenBirimList;let a=index;" style="color: black;">
                <td>
                    {{ ''+item?.basvuruBilgi?.basvuruNo }}
                </td>
                <td>
                    {{ ''+itemYeni?.fakAdi }}
                </td>
                <td>
                    {{ ''+itemYeni?.bolAdi }}
                </td>
                <td>
                    {{ ''+itemYeni?.progAdi }}
                </td>
                <td>
                    {{ ''+itemYeni?.siraNo }}
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>





<table class="table table-responsive gn-middle table-row-bordered fs-7 gy-2" id="kl_tumbasvurular_table"
    style="display: none;" *ngIf="tumBasvurular.length>0">
    <thead>
        <tr class="text-start fw-bolder fs-7 gs-0 " style="color:#cd7f32">
            <th class="min-w-20px">#</th>
            <th class="min-w-125px">Aday No</th>
            <th class="min-w-125px">Kimlik Numarası</th>
            <th class="min-w-125px">Adı</th>
            <th class="min-w-125px">Soyadı</th>
            <th class="min-w-125px">Uyruk</th>
            <th class="min-w-125px">Sahip olma</th>
            <th class="min-w-125px">Doğum Tarihi</th>
            <th class="min-w-125px">Cinsiyet</th>
            <th class="min-w-125px">Medeni Hali</th>
            <th class="min-w-125px">Telefon</th>
            <th class="min-w-125px">Sınav Türü</th>
            <th class="min-w-125px">Sınav Puanı</th>
            <th class="min-w-125px">Sınav Puanı Yüzlük</th>
            <th class="min-w-125px">Değerlendirme Puanı</th>
            <th class="min-w-200px text-center">Durum</th>
            <th class="min-w-125px">Yerleşme durumu</th>
            <th class="min-w-125px">Yerleştirme no</th>
            <th class="min-w-125px">Yerleştirtiği Fakülte</th>
            <th class="min-w-125px">Yerleştirtiği Bölüm</th>
            <th class="min-w-125px">Yerleştirtiği Program</th>
            <th class="min-w-125px">Tercih sıra no</th>

        </tr>
    </thead>
    <tbody class="text-gray-600 ">
        <!-- dblclick -->
        <tr *ngFor="let item of tumBasvurular;let i=index;" style="color: black;">
            <td class="text-info text-left fs-5" style="vertical-align: middle;">
                <b>{{i+1}}</b>
            </td>
            <td>
                {{ ''+item?.basvuruBilgi?.basvuruNo }}
            </td>
            <td>
                {{ item?.basvuruBilgi?.adi }}
            </td>
            <td>
                {{ item?.basvuruBilgi?.soyadi }}
            </td>
            <td>
                {{ item?.ilkUyrukAdi }}
            </td>
            <td>
                {{ item?.basvuruBilgi?.ilkVatandaslikNasilSahipOldu }}
            </td>
            <td>
                {{ item?.basvuruBilgi?.dogumTarihi |date:'dd.MM.yyyy' }}
            </td>

            <td>
                {{ item?.basvuruBilgi?.cinsiyet }}
            </td>
            <td>
                {{ item?.basvuruBilgi?.medeniHal }}
            </td>
            <td>
                {{ item?.basvuruBilgi?.telefon1 }}
            </td>
            <td>
                {{ item?.sinavTurAdi }}
            </td>
            <td>
                {{ item?.basvuruBilgi?.sinavPuan }}
            </td>
            <td>
                {{ item?.basvuruBilgi?.sinavPuanYuzluk }}
            </td>
            <td>
                {{ item?.basvuruBilgi?.degerlendirmePuan }}
            </td>



            <td class="text-center">
                <span class="fw-bolder text-info"> <i class="{{item.sonHareketDurum?.iconClass}} fs-3 me-2"></i> {{
                    item.sonHareketDurum?.adi
                    }}</span>
            </td>
            <td>
                <span *ngIf="item?.basvuruBilgi?.yerlestiMi">Evet</span>
                <span *ngIf="!item?.basvuruBilgi?.yerlestiMi">Hayır</span>
            </td>
            <td>
                {{ item?.basvuruBilgi?.yerlestirmeNo }}
            </td>
            <td>
                {{ item?.basvuruBilgi?.yerlestigiFakAd }}
            </td>
            <td>
                {{ item?.basvuruBilgi?.yerlestigiBolAd }}
            </td>
            <td>
                {{ item?.basvuruBilgi?.yerlestigiProgAd }}
            </td>
            <td>
                {{ item?.basvuruBilgi?.yerlestigiTercihSiraNo }}
            </td>
        </tr>
    </tbody>
</table>