import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GirisService } from 'src/app/services/giris.service';
import { YetkiService } from 'src/app/services/yetki.service';

@Component({
  selector: 'sol-menu',
  templateUrl: './sol-menu.component.html',
  styleUrls: ['./sol-menu.component.css']
})
export class SolMenuComponent implements OnInit {

  constructor(private _yetkiService: YetkiService, private girisService: GirisService, private router: Router,public translateService: TranslateService) {
    this.yetkiService = _yetkiService;
  }
  yetkiService: YetkiService;
  ngOnInit(): void {

  }

  public get translationFormTypeScript(): string {
    return this.translateService.instant("example5.fromTypeScript");
  }

  public onChange(selectedLanguage: string): void {
    this.translateService.use(selectedLanguage);
  }

}

