import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Response } from '../models/response.model';
import { Token } from '../models/token.model';
import { Kullanici } from '../models/kullanici.model';
import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class GirisService {

  kullanici: Kullanici = new Kullanici();
  constructor(private http: HttpClient) { }

  apiUrl = '/api/auth'

  giris(tckimlikno: string, sifre: string): Observable<Response<Token>> {
    return this.http.post<Response<Token>>(this.apiUrl + '/login', {
      tckimlikno,
      sifre
    });
  }

  girisEdevletLogin(girisdurum: string): Observable<Response<Token>> {
    return this.http.post<Response<Token>>(this.apiUrl + '/loginedevlet?girisdurum=' + girisdurum, {});
  }

  girisNebisLogin(data: string): Observable<Response<Token>> {
    return this.http.post<Response<Token>>(this.apiUrl + '/NebisLogin?Data=' + data, {});
  }


  refreshToken(userUid: string): Observable<Response<Token>> {
    return this.http.post<Response<Token>>(this.apiUrl + '/refreshtoken?useruid=' + userUid, {});
  }

  cikis(): Observable<any> {
    return this.http.get<any>('/api/kullanici/cikis');
  }

  girisBilgi():Kullanici{
    // if (this.cookieByName()) {
    //   this.kullanici = jwtDecode(this?.cookieByName()) as Kullanici;
    // }
    // return this.kullanici;
    const auth = localStorage.getItem('auth');
    if (auth != null) {
      this.kullanici = jwtDecode(auth) as Kullanici;
    }  
    return this.kullanici;
  }
 
  cookieByName():any{
    var cookie=Object.fromEntries(document.cookie.split('; ').map(v=>v.split(/=(.*)/s).map(decodeURIComponent)))
    if (cookie?.authYosNeu) {
     return cookie?.authYosNeu;
    }
  }


}
