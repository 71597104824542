import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '../models/response.model';
import { SinavTur } from '../models/sinavTur.model';

@Injectable({
  providedIn: 'root'
})
export class SinavTurService {
  apiUrl = '/api/sinavTur';

  constructor(private http: HttpClient) { 

  }

  getAll(query: string = '', page: number = 1, rowCount: number = 10,tercihTanimid: number = 0): Observable<Response<SinavTur[]>> {
    return this.http.get<Response<SinavTur[]>>(this.apiUrl + '/getList', {
      params: {
        'aramastr': query,
        'sayfasayisi': page,
        'sayfadakisatirsayisi': rowCount,
        'tercihTanimid': tercihTanimid,
      }
    });
  }

  getListAll(): Observable<Response<SinavTur[]>> {
    return this.http.get<Response<SinavTur[]>>(this.apiUrl + '/getListAll');
  }

  getById(id: number): Observable<Response<SinavTur>> {
    return this.http.get<Response<SinavTur>>(this.apiUrl + '/getById?id=' + id);
  }

  delete(sinavTur: SinavTur): Observable<Response<SinavTur>> {
    return this.http.post<Response<SinavTur>>(this.apiUrl + '/delete', sinavTur)
  }


  addUpdate(sinavTur: SinavTur): Observable<Response<SinavTur>> {
    if (sinavTur.id > 0) {
      return this.http.post<Response<SinavTur>>(this.apiUrl + "/update", sinavTur)
    }
    else {
      return this.http.post<Response<SinavTur>>(this.apiUrl + "/add", sinavTur)
    }
  }
}