export class SinavEkAlan {
    id:number=0;
    sinavTanimId:number=0;
    label:string='';
    tip:string='';
    kabulDosyaUzantilari:string='';

    deger:any;
    fileTip: string = '';
    fileSize: number = 0;
    
    filePath:string='';
    file:any;
    fileName: string = '';
    

    minUzunluk:number=0;
    maxUzunluk:number=0;
    zorunluluk:boolean=true;
    secilenDosyaTipler:any[]=[];
}