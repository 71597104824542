import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { SinavTanim } from 'src/app/models/sinavTanim.model';
import { SinavYer } from 'src/app/models/sinavYer.model';
import { SinavTanimService } from 'src/app/services/sinav-tanim.service';
import { SinavYerService } from 'src/app/services/sinav-yer.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'giris-belge-islem',
  templateUrl: './giris-belge-islem.component.html',
  styleUrls: ['./giris-belge-islem.component.css']
})
export class GirisBelgeIslemComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute,public sinavTanimService: SinavTanimService,public sinavYerService: SinavYerService, public yetkiService: YetkiService, private validationService: ValidationService) { }
  yukleniyor:boolean=false;
  sinavid:number=0;
  sinavTanim:SinavTanim =new SinavTanim();
  yerlestirmeTip:string='dilegoresirali';
  yapiliyor:boolean=false;
  sinavYerler: SinavYer[] = [];
  sinavYerId:number=0;
  hatalar: any = {};
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.sinavid = params['id'];
        this.getSinavBilgi();
        this.sinavYerList();
      }
    });
  }

  getSinavBilgi() {
    this.yukleniyor = true;
    this.sinavTanimService.getById(this.sinavid).subscribe(res => {
      this.sinavTanim = res.data;
    }, err => {
      this.router.navigateByUrl('/')
    },);
  }

  sinavYerList() {
    this.sinavYerService.getAll('', 1, 1000, this.sinavid).subscribe(res => {
      this.sinavYerler = res.data;
    });
  }


  yerlestirmeYap(){
    Swal.fire({
      title: 'Sınav yerleştirmesi yapmak istediğinize emin misiniz ? <span class=text-danger>(Seçilen parametrelere göre daha önce yaptığınız yerleştirme bilgileri silinecektir. )</span>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Evet',
      cancelButtonText: 'İptal',
    }).then((result) => {
      if (result.value) {
        this.yapiliyor=true;
        this.hatalar = {};
        this.sinavTanimService.sinavYerlestirmeYap(this.sinavid,this.yerlestirmeTip,this.sinavYerId).pipe(finalize(() => {
          this.yapiliyor=false;      
        })).subscribe({
          next: res => {
            if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
            this.router.navigateByUrl('/giris-belge-islem/'+this.sinavid)
            this.getSinavBilgi();
          },
          error: err => {
            this.hatalar = this.validationService.hatalar(err.error.Errors);
            if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
              if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
              if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
            }
          }
        });

      }
    })
  }


  girisBelgeYayinla(tip:number){

    const ifade=tip==1 ? "Bu işlemden sonra öğrenciler giriş belgelerini alabileceklerdir. Giriş belgelerini yayınlamak istediğinize emin misiniz ?":"Giriş belgelerini yayından kaldırmak istediğinize emin misiniz?";
   
    Swal.fire({
      title: ifade,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Evet',
      cancelButtonText: 'İptal',
    }).then((result) => {
      if (result.value) {
        this.yapiliyor=true;
        this.hatalar = {};
        this.sinavTanimService.sinavGirisBelgeYayinla(this.sinavid,tip).pipe(finalize(() => {
          this.yapiliyor=false;      
        })).subscribe({
          next: res => {
            if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
            this.router.navigateByUrl('/giris-belge-islem/'+this.sinavid)
            this.getSinavBilgi();
          },
          error: err => {
            this.hatalar = this.validationService.hatalar(err.error.Errors);
            if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
              if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
              if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
            }
          }
        });

      }
    })
  }

}
