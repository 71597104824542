import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { HazirCevap } from 'src/app/models/hazirCevap.model';
import { HazirCevapService } from 'src/app/services/hazircevap.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';

@Component({
  selector: 'hazircevap-islem',
  templateUrl: './hazircevap-islem.component.html',
  styleUrls: ['./hazircevap-islem.component.css']
})
export class HazircevapIslemComponent implements OnInit {
  kaydediliyor = false;

  constructor(private formBuilder: FormBuilder, private yetkiService: YetkiService, private hazirCevapService: HazirCevapService, private route: ActivatedRoute, private router: Router, private validationService: ValidationService, private datePipe: DatePipe) { }
  yukleniyor: boolean = false;
  hatalar: any = {};
  detayForm = this.formBuilder.group(new HazirCevap());
  yeni = true;
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['id'] != 'yeni') {
        this.getById(params['id']);
      }
      else {
        this.detayForm = this.formBuilder.group(new HazirCevap());
      }
    });
  }

  getById(id: number) {
    this.yukleniyor = true;
    this.hazirCevapService.getById(id).subscribe(res => {
      this.yeni = false;
      this.detayForm.patchValue(res.data);
      this.yukleniyor = false;
    }, err => {
      this.yukleniyor = false;
      this.router.navigateByUrl('/')
    });
  }


  kaydetGuncelle() {
    this.kaydediliyor = true;
    this.hatalar = {};
    var form = this.detayForm.value;
    this.hazirCevapService.addUpdate(form).pipe(finalize(() => {
      this.kaydediliyor = false;
    })).subscribe({
      next: res => {
        if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
        this.router.navigateByUrl('/hazircevap-bilgi')
      },
      error: err => {
        this.hatalar = this.validationService.hatalar(err.error.Errors);
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
        }
      }
    });
  }
}
