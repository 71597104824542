import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '../models/response.model';
import { SinavBasvuru, SinavBasvuruDetay, SonHareketDurumBilgi } from '../models/sinavBasvuru.model';
import { SinavEkAlan } from '../models/sinavEkAlan.model';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class SinavBasvuruService {
  apiUrl = '/api/sinavbasvuru';

  constructor(private http: HttpClient) { 

  }

  getAll(query: string = '', page: number = 1, rowCount: number = 10,sinavid:number): Observable<Response<SinavBasvuruDetay[]>> {
    return this.http.get<Response<SinavBasvuruDetay[]>>(this.apiUrl + '/getList', {
      params: {
        'aramastr': query,
        'sayfasayisi': page,
        'sayfadakisatirsayisi': rowCount,
        'sinavid': sinavid
      }
    });
  }

  getListAll(sinavid:number): Observable<Response<SinavBasvuruDetay[]>> {
    return this.http.post<Response<SinavBasvuruDetay[]>>(this.apiUrl + '/getSinavBasvuruTopluDetayList?sinavid='+sinavid,{});
  }

  getGridList(dataTablesParameters: any,jsondata:any,sinavid:number,ogrId:number=0,basvuruid:number=0): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/getGridList', dataTablesParameters, {
      params: {
        'jsondata':jsondata,
        'sinavid': sinavid,
        'ogrId':ogrId,
        'basvuruid':basvuruid
      }
    });
  }

  getById(id: number): Observable<Response<SinavBasvuru>> {
    return this.http.get<Response<SinavBasvuru>>(this.apiUrl + '/getById?id=' + id);
  }

  delete(sinavbasvuru: SinavBasvuru): Observable<Response<SinavBasvuru>> {
    return this.http.post<Response<SinavBasvuru>>(this.apiUrl + '/delete', sinavbasvuru)
  }

  sinavEkAlanCevapAddUpdate(sinavEkAlan: FormData): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/sinavEkAlanCevaplar', sinavEkAlan)
  }

  sinavEkAlanCevaplarGetById(basvuruid:number): Observable<Response<SinavEkAlan[]>> {
    return this.http.get<Response<SinavEkAlan[]>>(this.apiUrl + '/sinavEkAlanCevaplarGetById?id=' + basvuruid)
  }

  dosyaBilgi(basvuruid: number, sinavEkAlanid:number):Observable<any> {
    return this.http.get<any>(this.apiUrl + "/fileInfo/?basvuruid="+basvuruid+"&sinavEkAlanid="+sinavEkAlanid)
  }

  ogrBasvuruKontrol(sinavid: number, basvuruid:number):Observable<any> {
    return this.http.get<any>(this.apiUrl + "/ogrBasvuruKontrol/?sinavid="+sinavid+"&basvuruid="+basvuruid)
  }

  basvuruTamamla(basvuruid:number):Observable<any> {
    return this.http.post<any>(this.apiUrl + "/basvuruTamamla/?basvuruid="+basvuruid,{})
  }

  basvuruTekrarGonder(basvuruid:number):Observable<any> {
    return this.http.post<any>(this.apiUrl + "/basvuruTekrarGonder/?basvuruid="+basvuruid,{})
  }

  basvuruSonucKaydet(sonHareketDurumBilgi:SinavBasvuruDetay): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/basvuruSonucKaydet', sonHareketDurumBilgi)
  }

  incelemeBaslat(basvuruid: number):Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + "/incelemeBaslat/?basvuruid="+basvuruid,{})
  }

  incelemeBitir(basvuruid: number):Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + "/incelemeBitir/?basvuruid="+basvuruid,{})
  }


  dataURItoBlob(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array]);
    return blob;
  }
  


  addUpdate(sinavBasvuruFdata: FormData): Observable<Response<SinavBasvuru>> {
    if (sinavBasvuruFdata.get('basvuruid')!= "0") {
      return this.http.post<Response<SinavBasvuru>>(this.apiUrl + "/update", sinavBasvuruFdata)
    }
    else {
      return this.http.post<Response<SinavBasvuru>>(this.apiUrl + "/add", sinavBasvuruFdata)
    }
  }

  exelIndir() :Observable<Response<any>>{
    let fileName = "Yös Başvuruları.xlsx"
    var element = document.getElementById('kl_tumbasvurular_table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
    return this.http.get<Response<any>>("")
  }


}