<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h3 class="d-flex text-dark fw-bolder fs-2 flex-column mb-0">
                    <span><i class="fas fa-file-signature me-2" style="font-size: large; color: #d4af37;"></i>
                        {{this.sinavTanim.tanim }}
                        <span>
                            &nbsp;&nbsp;
                            <i class="fas fa-calendar-alt text-success fs-3"></i>
                            {{this.sinavTanim.sinavTarih | date:'dd.MM.yyyy HH:mm' }}</span>

                        <span>
                            &nbsp;&nbsp;
                            <i class="fas fa-exclamation-triangle text-danger fs-4"></i>
                            Yapılan işlemler var olan sınav başvurularını etkileyebilir.</span>
                    </span>
                    <!-- <span class="text-muted fs-7 fw-bold mt-2">Alt Başlık</span> -->
                </h3>

            </div>
            <div class="d-flex align-items-center gap-2 gap-lg-3">
                <button type="button" routerLink="/sinav-bilgi" id="kt_modal_new_target_cancel"
                    class="btn btn-danger btn-hover-rise me-3"><i class="fas fa-reply"></i> Geri
                </button>
            </div>
        </div>
    </div>

    <div id="kt_content_container" class="mx-10 justify-content-center">
        <div class="row gy-5 g-xl-10">
            <!--begin::Col-->
            <div class="col-xxl-6">
                <!--begin::Row-->
                <div class="row gx-5 gx-xl-10">
                    <!--begin::Col-->
                    <div class="col-sm-12 mb-5 mb-xl-10">
                        <!--begin::List widget 1-->
                        <div class="card card-flush h-xl-500"
                            style="border-top: 2px #00ABD8 solid; border-left: 1px #c5d5da solid;">
                            <!--begin::Header-->
                            <div class="card-header pt-0">
                                <!--begin::Title-->
                                <h3 class="card-title align-items-start flex-column">
                                    <span class="card-label fw-bolder text-dark">Sınav Merkezleri </span>
                                    <span class="text-danger mt-1 fw-bold fs-7">Bina eklemek için sınav merkezi
                                        seçiniz.</span>

                                </h3>
                                <!--end::Title-->
                                <!--begin::Toolbar-->
                                <div class="card-toolbar">
                                    <a *ngIf="yetkiService.yetkiVar(['SinavMerkez.Add'])" class="btn btn-sm btn-hover-rise  btn-shadow-hover fs-6" (click)="sinavYerEkle()"
                                        style="background-color: #77c2e9; color: white;">
                                        <i class="fas fa-plus" style="color: white;"></i>
                                        <!--end::Svg Icon-->Ekle
                                    </a>
                                </div>
                                <!--end::Toolbar-->
                            </div>


                            <div style="overflow:auto;width: 100%;" class="px-3">
                                <table class="table   table-responsive gn-middle table-row-bordered fs-7 gy-1"
                                    id="kl_subscriptions_table">
                                    <thead>
                                        <tr class="text-start fw-bolder fs-7 gs-0 " style="color:#cd7f32">
                                            <th class="text-center" style="width: 5%;">#</th>
                                            <th class="text-center">Tanım</th>
                                            <th class="text-center">Bina Sayısı</th>
                                            <th class="text-center"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-gray-600 ">
                                        <!-- dblclick -->
                                        <tr *ngFor="let item of sinavYerDetay;let i=index;" style="color: black;"
                                            [ngStyle]="{'background-color': secilenSinavYer.id == item.id ? '#b6d8ea':null}"
                                            (click)="sinavYeriSec(item)" class="cursor-pointer">
                                            <td class="text-info text-left text-center "
                                                style="vertical-align: middle;">
                                                <b>{{i+1}}</b>
                                            </td>
                                            <td class="text-center  fw-bold" style="vertical-align: middle;">
                                                {{ item.ulkeAdi }} / {{ item.adi }}
                                            </td>
                                            <td class="text-center  fw-bold text-info" style="vertical-align: middle;">
                                                {{ item.binaSayisi }}
                                            </td>

                                            <td style="vertical-align: middle;">
                                                <div class="d-flex flex-shrink-0 " style="float: right;">
                                                    <a *ngIf="yetkiService.yetkiVar(['SinavMerkez.Update'])" (click)="sinavYerGetById(item.id)"
                                                        style="background-color:#009ef8"
                                                        class="btn btn-icon btn-hover-rise  btn-active-color-primary btn-sm me-1"
                                                        title="Sınav merkezi düzenle">
                                                        <i class="far fa-edit text-white"></i>
                                                    </a>
                                                    <button *ngIf="yetkiService.yetkiVar(['SinavMerkez.Delete'])"
                                                    type="button" style="background-color:#f1416c;"
                                                        (click)="silSinavYerModal(item)"
                                                        class="btn btn-icon btn-hover-rise btn-active-color-danger btn-sm"
                                                        title="Sınav Merkezi sil">
                                                        <i class="fas fa-trash-alt text-white"></i>
                                                    </button>

                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!--end::Body-->
                        </div>
                        <!--end::LIst widget 1-->
                    </div>
                    <!--end::Col-->
                    <!--begin::Col-->

                    <!--end::Col-->
                </div>
            </div>
            <div class="col-xxl-6 mb-5 mb-xl-10">
                <div class="row gx-5 gx-xl-10">
                    <!--begin::Col-->
                    <div class="col-sm-12 mb-5 mb-xl-10">
                        <!--begin::List widget 1-->
                        <div class="card card-flush h-xl-500"
                            style="border-top: 2px #00ABD8 solid; border-left: 1px #c5d5da solid;">
                            <!--begin::Header-->
                            <div class="card-header pt-0">
                                <!--begin::Title-->
                                <h3 class="card-title align-items-start flex-column">
                                    <span class="card-label fw-bolder text-dark"><i
                                            class="far fa-building me-2 fs-4 text-info"></i>Binalar <span
                                            *ngIf="secilenSinavYer.id>0" class="fs-7"> ({{secilenSinavYer.adi}})</span>
                                    </span>
                                    <span class="text-danger mt-1 fw-bold fs-7">Salon eklemek için bina seçiniz. </span>
                                </h3>
                                <!--end::Title-->
                                <!--begin::Toolbar-->
                                <div class="card-toolbar">
                                    <a *ngIf="secilenSinavYer.id>0 && yetkiService.yetkiVar(['SinavMerkez.Add'])"
                                        class="btn btn-sm btn-hover-rise btn-shadow-hover fs-6" (click)="binaEkle()"
                                        style="background-color: #77c2e9; color: white;">
                                        <i class="fas fa-plus" style="color: white;"></i>
                                        <!--end::Svg Icon-->Ekle
                                    </a>
                                </div>
                                <!--end::Toolbar-->
                            </div>
                            <div style="overflow:auto;width: 100%;" class="px-3">
                                <table class="table   table-responsive gn-middle table-row-bordered fs-7 gy-1"
                                    id="kl_subscriptions_table">
                                    <thead>
                                        <tr class="text-start fw-bolder fs-7 gs-0 " style="color:#cd7f32">
                                            <th class="text-center" style="width: 5%;">#</th>
                                            <th class="text-center">Tanım</th>
                                            <th class="text-center">Salon Sayısı</th>
                                            <th class="text-center">Adres</th>
                                            <th class="text-center"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-gray-600 ">
                                        <!-- dblclick -->
                                        <tr *ngFor="let item of binaDetay;let i=index;" style="color: black;"
                                            [ngStyle]="{'background-color': secilenBina.id == item.id ? 'rgb(239 224 175)':null}"
                                            (click)="binaSec(item)" class="cursor-pointer">
                                            <td class="text-info text-left text-center" style="vertical-align: middle;">
                                                <b>{{i+1}}</b>
                                            </td>
                                            <td class="text-center  fw-bold" style="vertical-align: middle;">
                                                {{ item.adi }}
                                            </td>
                                            <td class="text-center text-info fw-bold" style="vertical-align: middle;">
                                                {{ item.salonSayisi }}
                                            </td>
                                            <td class="text-center  fw-bold" style="vertical-align: middle;">
                                                {{ item.adres }}
                                            </td>

                                            <td style="vertical-align: middle;">
                                                <div class="d-flex flex-shrink-0 " style="float: right;">
                                                    <a *ngIf="yetkiService.yetkiVar(['SinavMerkez.Update'])"
                                                    (click)="binaById(item.id)" style="background-color:#009ef8"
                                                        class="btn btn-icon btn-hover-rise  btn-active-color-primary btn-sm me-1"
                                                        title="Bina düzenle">
                                                        <i class="far fa-edit text-white"></i>
                                                    </a>
                                                    <button *ngIf="yetkiService.yetkiVar(['SinavMerkez.Delete'])"
                                                    type="button" style="background-color:#f1416c;"
                                                        (click)="silBinaModal(item)"
                                                        class="btn btn-icon btn-hover-rise btn-active-color-danger btn-sm"
                                                        title="Bina sil">
                                                        <i class="fas fa-trash-alt text-white"></i>
                                                    </button>

                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!--end::Body-->
                        </div>
                        <!--end::LIst widget 1-->
                    </div>
                    <!--end::Col-->
                    <!--begin::Col-->

                    <!--end::Col-->
                </div>
                <div class="row gx-5 gx-xl-10">
                    <!--begin::Col-->
                    <div class="col-sm-12 mb-5 mb-xl-10">
                        <div class="d-flex align-items-center bg-light-warning rounded p-3 mb-3" style="border: 1px solid #e98da8;">
                            <div class="flex-grow-1 me-2 fs-5" style="text-align: justify;">
                                <span> <i class="fas fa-exclamation-triangle me fs-5 text-danger me-2"></i>
                                    En fazla dil müracaatından başlanarak <b>salon yerleştirme sıra numarasına</b> göre yerleştirme yapılmaktadır.</span>
                            </div>
                            <span class="fw-bolder text-warning py-1"></span>
                        </div>
                        <!--begin::List widget 1-->
                        <div class="card card-flush h-xl-500"
                            style="border-top: 2px #00ABD8 solid; border-left: 1px #c5d5da solid;">
                            <!--begin::Header-->
                            <div class="card-header pt-0">
                                <!--begin::Title-->
                                <h3 class="card-title align-items-start flex-column">
                                    <span class="card-label fw-bolder text-dark"><i
                                            class="fas fa-door-open fs-4 text-primary me-2"></i>Salonlar <span
                                            *ngIf="secilenBina.id>0" class="fs-7"> ({{secilenSinavYer.adi}} -
                                            {{secilenBina.adi}})</span>
                                    </span>
                                    <span class="text-danger mt-1 fw-bold fs-7">Salon listesi</span>
                                </h3>
                                <!--end::Title-->
                                <!--begin::Toolbar-->
                                <div class="card-toolbar">
                                    <a  *ngIf="secilenBina.id>0 && yetkiService.yetkiVar(['SinavMerkez.Add'])"
                                        class="btn btn-sm btn-hover-rise  btn-shadow-hover fs-6" (click)="salonEkle()"
                                        style="background-color: #77c2e9; color: white;">
                                        <i class="fas fa-plus" style="color: white;"></i>
                                        <!--end::Svg Icon-->Ekle
                                    </a>
                                </div>
                                <!--end::Toolbar-->
                            </div>
                            <div style="overflow:auto;width: 100%;" class="px-3">
                                <table class="table   table-responsive gn-middle table-row-bordered fs-7 gy-1"
                                    id="kl_subscriptions_table">
                                    <thead>
                                        <tr class="text-start fw-bolder fs-7 gs-0 " style="color:#cd7f32">
                                            <th class="text-center" style="width: 5%;">Y.S.NO</th>
                                            <th class="text-center">Tanım</th>
                                            <th class="text-center">Kontenjan</th>
                                            <th class="text-center">Bulunduğu Kat</th>
                                            <th class="text-center">Öğr. Sayısı</th>
                                            <th class="text-center"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-gray-600 ">
                                        <!-- dblclick -->
                                        <tr *ngFor="let item of salonDetay;let i=index;" style="color: black;"
                                            [ngStyle]="{'background-color': secilenSalon.id == item.id ? 'rgb(227 225 220)':null}"
                                            (click)="salonSec(item)" class="cursor-pointer">
                                            <td class="text-info text-left text-center fs-5"
                                                style="vertical-align: middle;">
                                                <b> {{ item.yerlestirmeSiraNo }}</b>
                                            </td>
                                            <td class="text-center  fw-bold" style="vertical-align: middle;">
                                                {{ item.adi }}
                                            </td>
                                            <td class="text-center  fw-bold" style="vertical-align: middle;">
                                                {{ item.kontenjan }}
                                            </td>
                                            <td class="text-center  fw-bold" style="vertical-align: middle;">
                                                {{ item.adres }}
                                            </td>
                                            <td class="text-center text-info fw-bold" style="vertical-align: middle;">
                                                {{ item.sinavaGirecekOgrenciSayisi }}
                                            </td>

                                            <td style="vertical-align: middle;">
                                                <div class="d-flex flex-shrink-0 " style="float: right;">
                                                    <a *ngIf="yetkiService.yetkiVar(['SinavMerkez.Update'])" (click)="salonById(item.id)" style="background-color:#009ef8"
                                                        class="btn btn-icon btn-hover-rise  btn-active-color-primary btn-sm me-1"
                                                        title="Salon düzenle">
                                                        <i class="far fa-edit text-white"></i>
                                                    </a>
                                                    <button *ngIf="item.sinavaGirecekOgrenciSayisi==0 && yetkiService.yetkiVar(['SinavMerkez.Delete'])" type="button" style="background-color:#f1416c;"
                                                        (click)="silSalonModal(item)"
                                                        class="btn btn-icon btn-hover-rise btn-active-color-danger btn-sm me-1"
                                                        title="Salon sil">
                                                        <i class="fas fa-trash-alt text-white"></i>
                                                    </button>

                                                    <a (click)="salonOgrList(item.id)"
                                                        class="btn btn-warning btn-hover-rise btn-sm text-black "
                                                        title="Salon öğrenci listesi al">
                                                        <i class="fas fa-users text-black"></i>
                                                        Öğrenci Listesi
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!--end::Body-->
                        </div>
                        <!--end::LIst widget 1-->
                    </div>
                    <!--end::Col-->
                    <!--begin::Col-->

                    <!--end::Col-->
                </div>

            </div>
        </div>

    </div>

    <div class="form-group row mt-5" *ngIf="previewUrl">
        <iframe id="iframe" name="iframe" frameBorder="10" [src]="previewUrl" marginHeight="0" marginWidth="0"
            width="100%" height="500px" scrolling="auto">
        </iframe>
    </div>

    <button id="openSinavYeriModalButton"   [hidden]="true"   data-bs-toggle="modal" data-bs-target="#sinav_yeri_modal" data-toggle="modal">Open Modal</button>

    <div id="sinav_yeri_modal" class="modal fade" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered  modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <i class="fas fa-bookmark text-danger me-2" style="font-size: large; padding-right:2px;"></i>
                    <h2 class="modal-title" *ngIf="yeniSinavYer">Sınav Merkezi Ekle</h2>
                    <h2 class="modal-title" *ngIf="!yeniSinavYer">Sınav Merkezi Güncelle</h2>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form [formGroup]="detaySinavYerForm" (submit)="sinavYeriKaydetGuncelle()">
                    <div class="modal-body">
                        <div class="form-floating mb-3">
                            <input formControlName="adi" type="text" class="form-control" id="adi"
                                style="border-color: #8fb9d5;text-transform: uppercase" placeholder="adi" name="adi" />
                            <label style="color: #009ef7;" class="fs-4" for="adi">Sınav Merkezi Adı</label>
                            <div class="text-danger" *ngFor="let hata of hatalar['Adi']">
                                {{hata}}
                            </div>
                        </div>

                        <div class="form-floating mb-3">
                            <select formControlName="ulkeId" class="form-select" id="ulkeId" name="ulkeId"
                                style="border-color: #8fb9d5;">
                                <option [ngValue]="0">Lütfen Seçiniz... </option>
                                <option *ngFor="let item of ulkeler" [ngValue]="item.id">{{item.adi}}
                                </option>
                            </select>
                            <label style="color: #009ef7;" class="fs-4" for="ulkeId"><b>Ülke Seçiniz</b></label>
                            <div class="text-danger" *ngFor="let hata of hatalar['UlkeId']">
                                {{hata}}
                            </div>
                        </div>


                        <div class="card-footer">
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <submit-button [loader]="kaydediliyor" [label]="yeniSinavYer?'Kaydet':'Güncelle'"
                                        [loading]="yeniSinavYer?'Kaydediliyor...':'Güncelleniyor...'">
                                    </submit-button>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <button id="openBinaModalButton"   [hidden]="true"   data-bs-toggle="modal" data-bs-target="#bina_modal" data-toggle="modal">Open Modal</button>
    <div id="bina_modal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered  modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <i class="fas fa-bookmark text-danger me-2" style="font-size: large; padding-right:2px;"></i>
                    <h2 class="modal-title" *ngIf="yeniBina">Bina Ekle ({{secilenSinavYer.adi}})</h2>
                    <h2 class="modal-title" *ngIf="!yeniBina">Bina Güncelle ({{secilenSinavYer.adi}})</h2>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form [formGroup]="detayBinaForm" (submit)="binaKaydetGuncelle()">
                    <div class="modal-body">
                        <div class="form-floating mb-3">
                            <input formControlName="adi" type="text" class="form-control" id="adi"
                                style="border-color: #8fb9d5;text-transform: uppercase" placeholder="adi" name="adi" />
                            <label style="color: #009ef7;" class="fs-4" for="adi">Bina Adı</label>
                            <div class="text-danger" *ngFor="let hata of hatalar['Adi']">
                                {{hata}}
                            </div>
                        </div>
                        <div class="form-floating mb-3">
                            <input formControlName="adres" type="text" class="form-control" id="adi"
                                style="border-color: #8fb9d5;" placeholder="adres" name="adres" />
                            <label style="color: #009ef7;" class="fs-4" for="adres">Adres</label>
                            <div class="text-danger" *ngFor="let hata of hatalar['Adres']">
                                {{hata}}
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <submit-button [loader]="kaydediliyor" [label]="yeniBina?'Kaydet':'Güncelle'"
                                        [loading]="yeniBina?'Kaydediliyor...':'Güncelleniyor...'">
                                    </submit-button>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <button id="openSalonModalButton"   [hidden]="true"   data-bs-toggle="modal" data-bs-target="#salon_modal" data-toggle="modal">Open Modal</button>
    <div id="salon_modal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered  modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <i class="fas fa-bookmark text-danger me-2" style="font-size: large; padding-right:2px;"></i>
                    <h2 class="modal-title" *ngIf="yeniSalon">Salon Ekle ({{secilenSinavYer.adi}} - {{secilenBina.adi}})
                    </h2>
                    <h2 class="modal-title" *ngIf="!yeniSalon">Salon Güncelle ({{secilenSinavYer.adi}} -
                        {{secilenBina.adi}})</h2>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form [formGroup]="detaySalonForm" (submit)="salonKaydetGuncelle()">
                    <div class="modal-body">
                        <div class="form-floating mb-3">
                            <input formControlName="adi" type="text" class="form-control" id="adi"
                                style="border-color: #8fb9d5;" oninput="this.value = this.value.toLocaleUpperCase('TR')" placeholder="adi" name="adi" />
                            <label style="color: #009ef7;" class="fs-4" for="adi">Salon Adı</label>
                            <div class="text-danger" *ngFor="let hata of hatalar['Adi']">
                                {{hata}}
                            </div>
                        </div>
                        <div class="form-floating mb-3">
                            <input formControlName="kontenjan" type="number" class="form-control" id="kontenjan"
                                style="border-color: #8fb9d5;" placeholder="kontenjan" name="kontenjan" min="0" />
                            <label style="color: #009ef7;" class="fs-4 fw-bolder" for="kontenjan">Kontenjan
                            </label>
                            <div class="text-danger" *ngFor="let hata of hatalar['Kontenjan']">
                                {{hata}}
                            </div>
                        </div>
                        <div class="form-floating mb-3">
                            <input formControlName="yerlestirmeSiraNo" type="number" class="form-control" id="yerlestirmeSiraNo"
                                style="border-color: #8fb9d5;" placeholder="yerlestirmeSiraNo" name="yerlestirmeSiraNo" min="0" />
                            <label style="color: #009ef7;" class="fs-4 fw-bolder" for="kontenjan">Yerleştirme Sıra No
                            </label>
                            <div class="text-danger" *ngFor="let hata of hatalar['YerlestirmeSiraNo']">
                                {{hata}}
                            </div>
                        </div>

                        <div class="form-floating mb-3">
                            <input formControlName="adres" type="text" class="form-control" id="adi"
                                style="border-color: #8fb9d5;" placeholder="adres" name="adres" />
                            <label style="color: #009ef7;" class="fs-4" for="adres">Bulunduğu kat</label>
                            <div class="text-danger" *ngFor="let hata of hatalar['Adres']">
                                {{hata}}
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <submit-button [loader]="kaydediliyor" [label]="yeniSalon?'Kaydet':'Güncelle'"
                                        [loading]="yeniSalon?'Kaydediliyor...':'Güncelleniyor...'">
                                    </submit-button>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <button id="openSalonOgrModalButton"   [hidden]="true"   data-bs-toggle="modal" data-bs-target="#salon_ogr_modal" data-toggle="modal">Open Modal</button>
    <div id="salon_ogr_modal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered  modal-xl">
            <div class="modal-content">
                <div class="modal-header text-center">


                    <span class="fw-bolder"><i
                            class="fas fa-door-open fs-4 text-primary me-2"></i>{{this.secilenSinavYer.adi}} -
                        {{this.secilenBina.adi}} - {{this.secilenSalon.adi}}


                        <button *ngIf="this.salonOgrDetay.length>0" type="button"
                            class="btn btn-warning btn-hover-rise btn-sm text-black mx-2 " [disabled]="aliniyor"
                            (click)="salonBelgeAl()" [attr.data-kt-indicator]="aliniyor ? 'on' : 'off'">
                            <span class="indicator-label"><i class="fas fa-print text-black"></i>Yazdır</span>
                            <span class="indicator-progress">Belge hazırlanıyor...
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>


                    </span>

                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <table class="table  gn-middle table-row-bordered fs-6 gy-1" id="kl_subscriptions_table">
                        <thead>
                            <tr class="text-start fw-bolder fs-6 gs-0 " style="color:#cd7f32">
                                <th class="text-center">S.No</th>
                                <th class="text-center">Aday No</th>
                                <th class="text-center">Adı Soyadı</th>
                                <th class="text-center">Uyruk</th>
                                <th class="text-center">Engel Durumu</th>
                                <th class="text-center">Kimlik No</th>
                                <th class="text-center">D. Tarihi</th>
                                <th class="text-center">Cinsiyet</th>
                                <th class="text-center">S. Dili</th>
                                <th class="text-end">
                                    <span>Seç &nbsp;<input type="checkbox" class="form-check-input"
                                            [(ngModel)]="selectedAll" (change)="selectAll();"
                                            *ngIf="salonOgrDetay.length>0"></span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="text-gray-600 ">
                            <!-- dblclick -->
                            <tr *ngFor="let item of salonOgrDetay;let i=index;" style="color: black;">
                                <td class="text-center" style="vertical-align: middle;">
                                    <b>{{ item.salonSiraNo }}</b>
                                </td>
                                <td class="text-center" style="vertical-align: middle;">
                                    {{ item.adayNo }}
                                </td>
                                <td class="text-center" style="vertical-align: middle;">
                                    {{ item.adi }} {{ item.soyadi }}
                                </td>
                                <td class="text-center" style="vertical-align: middle;">
                                    {{ item.uyruk }}
                                </td>
                                <td class="text-center" style="vertical-align: middle;">
                                    {{item.engelDurumu}}
                                </td>
                                <td class="text-center" style="vertical-align: middle;">
                                    {{ item.tc_yukk_pasaportNo }}
                                </td>
                                <td class="text-center" style="vertical-align: middle;">
                                    {{ item.dogumTarihi }}
                                </td>

                                <td class="text-center  " style="vertical-align: middle;">
                                    {{ item.cinsiyet }}
                                </td>
                                <td class="text-center text-info fw-bold" style="vertical-align: middle;">
                                    {{ item.sinavDili }}
                                </td>

                                <td class="text-center">
                                    <input type="checkbox" class="form-check-input mt-2" [value]="item.basvuruId"
                                        (change)="ogrSec($event,item)" [(ngModel)]="item.selected"
                                        (change)="checkIfAllSelected();"
                                        [checked]="ogrSecilenId.indexOf(item.basvuruId)>=0">
                                    <label class="form-check-label checkbox-lg text-black text-justify"
                                        style="font-size:medium;" for="exampleCheck1"></label>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="card-footer" style="border-top: 1px rgb(46, 146, 177) solid">
                        <div class="row">
                            <!-- sadece izne gönderilecek kadrolarda bu bilgiler girilecek -->
                            <div class="col-md-8">
                                <div class="form-floating mb-3 ">
                                    <select class="form-select" id="haraketDurumId" name="haraketDurumId"
                                        style="border-color: #8fb9d5;" [(ngModel)]="secilenSalonId">
                                        <option [ngValue]="0">Lütfen Seçiniz... </option>
                                        <option *ngFor="let item of salonListYeni" [ngValue]="item.id">
                                            {{item.adi}}
                                        </option>
                                    </select>
                                    <label style="color: #f7004a;" class="fs-6" for="haraketDurumId"><b>Seçilen
                                            öğrencilerin taşınacağı salonu seçiniz</b></label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <button type="button" (click)="salonOgrTasi()" id="kt_modal_new_target_cancel"
                                    class="btn btn-success btn-sm btn-hover-rise me-3"
                                    title="Seçilen salona öğrecileri taşı"><i class="fas fa-reply"></i>Öğrecileri taşı
                                </button>
                            </div>
                        </div>

                    </div>




                </div>


            </div>
        </div>
    </div>

</div>