import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '../models/response.model';
import { KartOdemeDto, SinavOdemeBilgi } from '../models/sinavOdemeBilgi.model';




@Injectable({
  providedIn: 'root'
})
export class SinavOdemeBilgiService {
  apiUrl = '/api/sinavodemebilgi';

  constructor(private http: HttpClient) { 

  }

  getAll(query: string = '', page: number = 1, rowCount: number = 10): Observable<Response<SinavOdemeBilgi[]>> {
    return this.http.get<Response<SinavOdemeBilgi[]>>(this.apiUrl + '/getList', {
      params: {
        'aramastr': query,
        'sayfasayisi': page,
        'sayfadakisatirsayisi': rowCount
      }
    });
  }

  getListAll(): Observable<Response<SinavOdemeBilgi[]>> {
    return this.http.get<Response<SinavOdemeBilgi[]>>(this.apiUrl + '/getListAll');
  }

  getById(id: number): Observable<Response<SinavOdemeBilgi>> {
    return this.http.get<Response<SinavOdemeBilgi>>(this.apiUrl + '/getById?basvuruid=' + id);
  }

  delete(sinavodemebilgi: SinavOdemeBilgi): Observable<Response<SinavOdemeBilgi>> {
    return this.http.post<Response<SinavOdemeBilgi>>(this.apiUrl + '/delete', sinavodemebilgi)
  }

  dosyaBilgi(id: number):Observable<any> {
    return this.http.get<any>(this.apiUrl + "/fileInfo/?basvuruid="+id)
  }

  odemeKayit(odemeKayit: FormData): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/odemeKayit', odemeKayit)
  }

  kartOdemeBaslat(kartOdemeDto: KartOdemeDto): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/odemeBaslat', kartOdemeDto)
  }

  kartOdemeEkle(kartOdemeDto: KartOdemeDto): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/odemeAdd', kartOdemeDto)
  }

  kartOdemeListesiGetir(basTar: any,bitTar:any):Observable<Response<any[]>> {
    return this.http.post<Response<any[]>>(this.apiUrl + '/odemebilgilerikontrol/?tarih1='+basTar+'&tarih2='+bitTar, {})
  }
  
}