<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                   Tanım İşlemleri
                    <!-- <span class="text-muted fs-7 fw-bold mt-2">Alt Başlık</span> -->
                </h1>
            </div>
            <div class="d-flex align-items-center gap-2 gap-lg-3">
                <!-- <a [routerLink]="['yeni']" class="btn btn-sm btn-primary">Yeni Uygulama</a> -->
            </div>
        </div>
    </div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" class="container-fluid">
            <loading [loader]="yukleniyor">
                <form [formGroup]="detayForm" (submit)="kaydetGuncelle()">
                    <div class="card col-12 col-md-6 offset-md-3" style="border-top: 4px #77c2e9 solid;">
                        <div class="card-header">
                            <h3 class="card-title">
                                Yeni Ekle
                            </h3>
                        </div>
                        <div class="card-body ">

                            <div class="form-floating mb-3">
                                <select formControlName="tip" class="form-select"
                                id="tip" name="tip"
                                style="border-color: #8fb9d5;">
                                <option value="">Lütfen seçiniz </option>
                                <option value="TEŞKİLAT">TEŞKİLAT</option>
                                <option value="ÜNVAN">ÜNVAN</option>
                                <option value="KOMİSYON">KOMİSYON</option>
                                <option value="BİRİM">BİRİM</option>
                                <option value="ALT BİRİM">ALT BİRİM</option>
                            </select>
                            <label style="color: #009ef7;" class="fs-4 fw-bold"  for="tip">Tip</label>
                            <div class="text-danger"
                                *ngFor="let hata of hatalar['tip']">
                                {{hata}}
                            </div>



                            </div>
                            <div class="form-floating mb-3">
                                <input formControlName="aciklama" type="text" class="form-control"
                                    id="aciklama" style="border-color: #8fb9d5; text-transform: uppercase;"
                                    placeholder="aciklama" name="aciklama" oninput="this.value = this.value.toLocaleUpperCase('TR')"/>
                                <label style="color: #009ef7;" class="fs-4 fw-bold"  for="aciklama">Açıklama</label>
                                <div class="text-danger" *ngFor="let hata of hatalar['aciklama']">
                                    {{hata}}
                                </div>
                            </div>
                        </div>
                        <div class="row card-footer">
                            <div class="col-md-6 col-6 text-left">
                                <button type="button" routerLink="/tanimlar" id="kt_modal_new_target_cancel"
                                    class="btn btn-danger btn-hover-rise me-3"><i class="fas fa-reply"></i> Geri
                                </button>
                            </div>
                            <div class="col-md-6 col-6 text-end">
                                <submit-button [loader]="kaydediliyor" [label]="yeni?'Kaydet':'Güncelle'"
                                [loading]="yeni?'Kaydediliyor...':'Güncelleniyor...'">
                            </submit-button>
                            </div>
                        </div>

                    </div>

                </form>
            </loading>
        </div>
    </div>
</div>