import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { TercihDonemTanim } from 'src/app/models/tercihDonemTanim.model';
import { YerlestirmeTanim } from 'src/app/models/yerlestirmeTanim.model';
import { TercihDonemTanimService } from 'src/app/services/tercih-donem-tanim.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YerlestirmeTanimService } from 'src/app/services/yerlestirme-tanim.service';
import { YetkiService } from 'src/app/services/yetki.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
declare var bootstrap: any;

@Component({
  selector: 'tercih-kayit-islem',
  templateUrl: './tercih-kayit-islem.component.html',
  styleUrls: ['./tercih-kayit-islem.component.css']
})
export class TercihKayitIslemComponent implements OnInit {
  constructor(public tercihTanimService: TercihDonemTanimService,private route: ActivatedRoute, public yetkiService: YetkiService, private validationService: ValidationService,private router: Router, private formBuilder: FormBuilder) {
   }
  tercihTanim:TercihDonemTanim=new TercihDonemTanim();
  @ViewChild('grid') grid?: GridComponent;
  hatalar: any = {};
  tercihTanimId:number=0;
  fileSonuc: any;
  kaydediliyor=false;
  siliniyor=false;
  ngOnInit(): void {   
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.tercihTanimId =Number(params['id']);
        this.getTercihTanimById(Number(params['id']));
      }
    });
  }

  getTercihTanimById(id: number) {
    this.tercihTanimService.getById(id).subscribe(res => {
      this.tercihTanim = res.data;
    }, err => {
      this.router.navigateByUrl('/')
    },);
  }

  ExelData: any;
  selectKayit(evt: any) {
    var files = evt.target.files;
    var file = files[0];
    var type = file.type;
    let dekont: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    var uygunMu = dekont.indexOf(type);
    if (files && file && evt.target.files[0]?.size < 10485760 && uygunMu > -1) {
      this.fileSonuc = file;
    }
    else {
      this.yetkiService.sweetAlertError("Seçilen dosya formatı veya boyutu hatalı.");
      this.fileSonuc = null;
    }
  }

  arrayBuffer: any;
  excelReadList: any[] = [];
  kayitListeYukle() {
    if (this.fileSonuc) {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result;
        var data = new Uint8Array(this.arrayBuffer);
        var arr = new Array();
        for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");
        var workbook = XLSX.read(bstr, { type: "binary" });
        var first_sheet_name = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[first_sheet_name];
        this.excelReadList = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      }
      fileReader.readAsArrayBuffer(this.fileSonuc);

      Swal.fire({
        title: 'Kayıt listesi yüklenecektir. Emin misiniz?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'İptal',
      }).then((result) => {
        if (result.value) {
          this.kaydediliyor = true;
          this.hatalar = {};
          const kayitlisteSablonDtoJson = JSON.stringify(this.excelReadList);
          this.tercihTanimService.kayitListesiYukle(kayitlisteSablonDtoJson).pipe(finalize(() => {
            this.kaydediliyor = false;
          })).subscribe({
            next: res => {
              if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
            },
            error: err => {
              this.hatalar = this.validationService.hatalar(err.error.Errors);
              if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
                if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
                if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
              }
            }
          });
        }
      })

    }
    else {
      this.yetkiService.sweetAlertError("Seçilen dosya bulunamadı.");
    }
  }


  kayitSil() {
    Swal.fire({
      title: 'Tüm kayıtlar silinecektir. İşlemi yapmak istediğinize emin misiniz?  <span class=text-danger>( İşlem geri alınamaz. )</span>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Evet',
      cancelButtonText: 'İptal',
    }).then((result) => {
      if (result.value) {
        this.siliniyor = true;
        this.hatalar = {};
        this.tercihTanimService.kayitlariSil(this.tercihTanimId).pipe(finalize(() => {
          this.siliniyor = false;
        })).subscribe({
          next: res => {
            if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
            // this.getSinavBilgi(); // grid oluşturulup güncellenecek.
          },
          error: err => {
            this.hatalar = this.validationService.hatalar(err.error.Errors);
            if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
              if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
              if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
            }
          }
        });
      }
    })

  }

}







