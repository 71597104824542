import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GirisGuard } from './guards/giris.guard';
import { TercihBasvuruTip } from './models/tercihBasvuruTip.model';
import { GirisFormComponent } from './modules/giris/giris-form/giris-form.component';
import { GirisComponent } from './modules/giris/giris.component';
import { BulunamadiComponent } from './modules/hata/bulunamadi/bulunamadi.component';
import { AnasayfaComponent } from './modules/site/anasayfa/anasayfa.component';
import { SiteComponent } from './modules/site/site.component';
import { BelgeDogrulaComponent } from './modules/yonetim/belge-dogrula/belge-dogrula.component';
import { TanimlarComponent } from './modules/yonetim/tanimlar/tanimlar.component';
import { TanimlarIslemComponent } from './modules/yonetim/tanimlar/tanimlar-islem/tanimlar-islem.component';
import { GirisBelgeIslemComponent } from './modules/yonetim/giris-belge-islem/giris-belge-islem.component';
import { HazircevapBilgiComponent } from './modules/yonetim/hazircevap-bilgi/hazircevap-bilgi.component';
import { HazircevapIslemComponent } from './modules/yonetim/hazircevap-bilgi/hazircevap-islem/hazircevap-islem.component';
import { IletisimBilgiComponent } from './modules/yonetim/iletisim-bilgi/iletisim-bilgi.component';
import { IletisimIslemComponent } from './modules/yonetim/iletisim-bilgi/iletisim-islem/iletisim-islem.component';
import { OgrBasvurularimComponent } from './modules/yonetim/ogr-basvurularim/ogr-basvurularim.component';
import { OgrKullaniciBilgiComponent } from './modules/yonetim/ogr-kullanici-bilgi/ogr-kullanici-bilgi.component';
import { OgrSifreIslemComponent } from './modules/yonetim/ogr-kullanici-bilgi/ogr-sifre-islem/ogr-sifre-islem.component';
import { OgrKullanicilarComponent } from './modules/yonetim/ogr-kullanicilar/ogr-kullanicilar.component';
import { OgrSinavBasvuruComponent } from './modules/yonetim/ogr-sinav-basvuru/ogr-sinav-basvuru.component';
import { OgrTercihBasvuruComponent } from './modules/yonetim/ogr-tercih-basvuru/ogr-tercih-basvuru.component';
import { OgrTercihBasvurularimComponent } from './modules/yonetim/ogr-tercih-basvurularim/ogr-tercih-basvurularim.component';
import { SinavBasvurularComponent } from './modules/yonetim/sinav-basvurular/sinav-basvurular.component';
import { SinavBilgiComponent } from './modules/yonetim/sinav-bilgi/sinav-bilgi.component';
import { SinavIslemComponent } from './modules/yonetim/sinav-bilgi/sinav-islem/sinav-islem.component';
import { SinavEkAlanBilgiComponent } from './modules/yonetim/sinav-ek-alan-bilgi/sinav-ek-alan-bilgi.component';
import { SinavEkAlanIslemComponent } from './modules/yonetim/sinav-ek-alan-bilgi/sinav-ek-alan-islem/sinav-ek-alan-islem.component';
import { SinavTurIslemComponent } from './modules/yonetim/sinav-tur/sinav-tur-islem/sinav-tur-islem.component';
import { SinavTurComponent } from './modules/yonetim/sinav-tur/sinav-tur.component';
import { SinavYerlerBilgiComponent } from './modules/yonetim/sinav-yerler-bilgi/sinav-yerler-bilgi.component';
import { SonucBelgeIslemComponent } from './modules/yonetim/sonuc-belge-islem/sonuc-belge-islem.component';
import { TercihBasvuruTipBilgiComponent } from './modules/yonetim/tercih-basvuru-tip-bilgi/tercih-basvuru-tip-bilgi.component';
import { TercihBasvuruTipIslemComponent } from './modules/yonetim/tercih-basvuru-tip-bilgi/tercih-basvuru-tip-islem/tercih-basvuru-tip-islem.component';
import { TercihBasvurularComponent } from './modules/yonetim/tercih-basvurular/tercih-basvurular.component';
import { TercihDonemBilgiComponent } from './modules/yonetim/tercih-donem-bilgi/tercih-donem-bilgi.component';
import { TercihDonemIslemComponent } from './modules/yonetim/tercih-donem-bilgi/tercih-donem-islem/tercih-donem-islem.component';
import { TercihKontenjanBilgiComponent } from './modules/yonetim/tercih-kontenjan-bilgi/tercih-kontenjan-bilgi.component';
import { TercihKontenjanIslemComponent } from './modules/yonetim/tercih-kontenjan-bilgi/tercih-kontenjan-islem/tercih-kontenjan-islem.component';
import { TercihYerlesenBilgiComponent } from './modules/yonetim/tercih-yerlesen-bilgi/tercih-yerlesen-bilgi.component';
import { TercihYerlestirmeIslemComponent } from './modules/yonetim/tercih-yerlestirme-islem/tercih-yerlestirme-islem.component';

import { YonetimAnasayfaComponent } from './modules/yonetim/yonetim-anasayfa/yonetim-anasayfa.component';
import { YonetimComponent } from './modules/yonetim/yonetim.component';
import { TercihKayitIslemComponent } from './modules/yonetim/tercih-kayit-islem/tercih-kayit-islem.component';
import { KartOdemeKontrolComponent } from './modules/yonetim/kart-odeme-kontrol/kart-odeme-kontrol.component';
import { UyelerComponent } from './modules/yonetim/uyeler/uyeler.component';
import { UyelerIslemComponent } from './modules/yonetim/uyeler/uyeler-islem/uyeler-islem.component';

const routes: Routes = [
  {
    path: 'giris', component: GirisComponent, children: [
      { path: '', component: GirisFormComponent }
    ]
  },

  {
    path: 'belge-dogrula', component: BelgeDogrulaComponent, children: []
  },

  {
    path: 'site', component: SiteComponent, children: [
      { path: '', component: AnasayfaComponent }
    ]
  },
  {
    path: '', component: YonetimComponent, children: [
      { path: '', component: YonetimAnasayfaComponent, canActivate: [GirisGuard] },
      {
        path: 'sinav-bilgi', children: [
          { path: '', component: SinavBilgiComponent, canActivate: [GirisGuard], data: { roller: ['Formİşlemleri.Görme'] } },
          { path: ':id', component: SinavIslemComponent, canActivate: [GirisGuard], data: { roller: ['Formİşlemleri.Ekle','Formİşlemleri.Düzenle'] } },
        ]
      },
      {
        path: 'sinav-yerler-bilgi', children: [
          { path: ':id', component: SinavYerlerBilgiComponent, canActivate: [GirisGuard], data: { roller: ['SinavMerkez.GetList'] } },
        ]
      },

      {
        path: 'tanimlar', children: [
          { path: '', component: TanimlarComponent, canActivate: [GirisGuard], data: { roller: ['GenelTanımlar.Görme'] } },
          { path: ':id', component: TanimlarIslemComponent, canActivate: [GirisGuard], data: { roller: ['GenelTanımlar.Ekle','GenelTanımlar.Düzenle'] } },
        ]
      },

      {
        path: 'uyeler', children: [
          { path: '', component: UyelerComponent, canActivate: [GirisGuard], data: { roller: ['Üyeler.Görme'] } },
          { path: ':id', component: UyelerIslemComponent, canActivate: [GirisGuard], data: { roller: ['Üyeler.Ekle','Üyeler.Düzenle'] } },
        ]
      },

      {
        path: 'hazircevap-bilgi', children: [
          { path: '', component: HazircevapBilgiComponent, canActivate: [GirisGuard], data: { roller: ['HazirCevap.GetList'] } },
           { path: ':id', component: HazircevapIslemComponent, canActivate: [GirisGuard], data: { roller: ['HazirCevap.Add'] } },
        ]
      },



      //'Kullanici.OgrenciIsleri','Kullanici.Standart'
      {
        path: 'ogr-sinav-basvuru', children: [
          { path: ':id', component: OgrSinavBasvuruComponent, canActivate: [GirisGuard], data: { roller: ['Kullanici.Standart','Formİşlemleri.Düzenle'] } },
        ]
      },

      {
        path: 'iletisim-bilgi', children: [
          { path: '', component: IletisimBilgiComponent, canActivate: [GirisGuard], data: { roller: ['Kullanici.Standart'] } },
          { path: ':id', component: IletisimIslemComponent, canActivate: [GirisGuard], data: { roller: ['Kullanici.Standart'] } },
        ]
      },

      {
        path: 'sinav-ek-alan-bilgi', children: [
          { path: ':id', component: SinavEkAlanBilgiComponent, canActivate: [GirisGuard], data: { roller: ['FormTanimEkAlan.Görme'] } },
        ]
      },
      {
        path: 'sinav-ek-alan-islem', children: [
          { path: ':id', component: SinavEkAlanIslemComponent, canActivate: [GirisGuard], data: { roller: ['FormTanimEkAlan.Ekle','FormTanimEkAlan.Düzenle'] } },
        ]
      },
      {
        path: 'ogr-kullanici-bilgi', children: [
          { path: '', component: OgrKullaniciBilgiComponent, canActivate: [GirisGuard], data: { roller: ['Kullanici.Standart'] } },
        ]
      },
      {
        path: 'sinav-basvurular', children: [
          { path: ':id', component: SinavBasvurularComponent, canActivate: [GirisGuard], data: { roller: ['Formİşlemleri.Görme'] } },
        ]
      },
      {
        path: 'ogr-basvurularim', children: [
          { path: '', component: OgrBasvurularimComponent, canActivate: [GirisGuard], data: { roller: ['Kullanici.Standart'] } },
        ]
      },
      {
        path: 'ogr-sifre-islem', children: [
          { path: '', component: OgrSifreIslemComponent, canActivate: [GirisGuard], data: { roller: ['Kullanici.Standart'] } },
        ]
      },
      {
        path: 'ogr-kullanicilar', children: [
          { path: '', component: OgrKullanicilarComponent, canActivate: [GirisGuard], data: { roller: ['OgrKullanici.MenuGorme'] } },
        ]
      },
      {
        path: 'ogr-tercih-basvurularim', children: [
          { path: '', component: OgrTercihBasvurularimComponent, canActivate: [GirisGuard], data: { roller: ['Kullanici.Standart'] } },
        ]
      },

      {
        path: 'tercih-yerlestirme-islem', children: [
          { path: ':id', component: TercihYerlestirmeIslemComponent, canActivate: [GirisGuard], data: { roller: ['TercihYerlestirme.MenuGorme'] } },
        ]
      },

      {
        path: 'tercih-yerlesen-bilgi', children: [
          { path: ':id', component: TercihYerlesenBilgiComponent, canActivate: [GirisGuard], data: { roller: ['TercihYerlestirme.MenuGorme'] } },
        ]
      },

      {
        path: 'tercih-kayit-islem', children: [
          { path: ':id', component: TercihKayitIslemComponent, canActivate: [GirisGuard], data: { roller: ['TercihYerlestirme.MenuGorme'] } },
        ]
      },
      {
        path: 'kart-odeme-kontrol', children: [
          { path: '', component: KartOdemeKontrolComponent, canActivate: [GirisGuard], data: { roller: ['SinavBasvuru.OdemeSorgula'] } },
        ]
      },

    ]
  },
  {
    path: '**',
    component: BulunamadiComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
