<!--begin::Aside-->
<div id="kt_aside" class="aside aside-light aside-hoverable" data-kt-drawer="true" data-kt-drawer-name="aside"
    data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start"
    data-kt-drawer-toggle="#kt_aside_mobile_toggle">
    <!--begin::Brand-->
    <div class="flex-column-auto text-center mt-5" id="kt_aside_logo">
        <!--begin::Logo-->
        <a [routerLink]="['/']">
            <img alt="Logo" src="/assets/image/agd_logo_yatay.png" class="h-200px logo" />
        </a>
    </div>
   
    <!--begin::Aside menu-->
    <div class="aside-menu flex-column-fluid">
        <!--begin::Aside Menu-->
        <div class="hover-scroll-overlay-y my-5 my-lg-5" id="kt_aside_menu_wrapper" data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-height="auto"
            data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer" data-kt-scroll-wrappers="#kt_aside_menu"
            data-kt-scroll-offset="0">
            <!--begin::Menu-->
            <div class="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
                id="#kt_aside_menu" data-kt-menu="true" data-kt-menu-expand="false">
                <div class="menu-item">
                    <a class="menu-link" [routerLink]="['.']" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">
                        <span class="menu-icon">
                            <i class="fas fa-paste text-white fs-4"></i>
                        </span>
                        <span translate class="menu-title">anasayfa</span>
                    </a>
                </div>
                <div class="menu-item" *ngIf="yetkiService.yetkiVar(['Kullanici.Standart'])">
                    <a class="menu-link" [routerLink]="['ogr-kullanici-bilgi']">
                        <span class="menu-icon">
                            <i class="fas fa-user  fs-4"></i>
                        </span>
                        <span translate class="menu-title">profilBilgi</span>
                    </a>
                </div>

                <div class="menu-item" *ngIf="yetkiService.yetkiVar(['Kullanici.Standart'])">
                    <a class="menu-link" [routerLink]="['ogr-basvurularim']">
                        <span class="menu-icon">
                            <i class="fas fa-history  fs-4"></i>
                        </span>
                        <span translate class="menu-title">sinavBasvurularim</span>
                    </a>
                </div>

        
                <div class="menu-item" *ngIf="yetkiService.yetkiVar(['Kullanici.Standart'])">
                    <a class="menu-link" [routerLink]="['iletisim-bilgi']">
                        <span class="menu-icon">
                            <i class="far fa-envelope fs-4"></i>
                        </span>
                        <span translate class="menu-title" translate>iletisim</span>
                    </a>
                </div>

                <div class="menu-item" *ngIf="yetkiService.yetkiVar(['Kullanici.Standart'])">
                    <a class="menu-link" [routerLink]="['ogr-sifre-islem']">
                        <span class="menu-icon">
                            <i class="fas fa-unlock fs-4"></i>
                        </span>
                        <span translate class="menu-title" translate>sifreDegistir</span>
                    </a>
                </div>


                <div class="menu-item" *ngIf="yetkiService.yetkiVar(['Üyeler.Görme'])">
                    <a class="menu-link" [routerLink]="['uyeler']">
                        <span class="menu-icon">
                            <i class="fas fa-users fs-4"></i>
                        </span>
                        <span translate class="menu-title" translate>Üyeler</span>
                    </a>
                </div>

                <div data-kt-menu-trigger="click" class="menu-item menu-accordion">
                    <span class="menu-link" *ngIf="yetkiService.yetkiVar(['SistemSabit.MenuGorme'])">
                        <span class="menu-icon">
                            <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-->
                            <span class="svg-icon svg-icon-2">
                                <i class="fas fa-tools fs-4"></i>
                            </span>
                            <!--end::Svg Icon-->
                        </span>
                        <span class="menu-title">Sistem Sabitleri</span>
                        <span class="menu-arrow"></span>
                    </span>

                    <div class="menu-sub menu-sub-accordion menu-active-bg" *ngIf="yetkiService.yetkiVar(['GenelTanımlar.Görme'])">
                        <div class="menu-item">
                            <a class="menu-link" [routerLink]="['tanimlar']">
                                <span class="menu-bullet">
                                    <span class="fas fa-globe"></span>
                                </span>
                                <span class="menu-title">Genel Tanımlar</span>
                            </a>
                        </div>
                    </div>

                </div>

                <div data-kt-menu-trigger="click" class="menu-item menu-accordion"
                   >
                    <span class="menu-link"  *ngIf="yetkiService.yetkiVar(['FormTanim.Görme'])">
                        <span class="menu-icon">
                            <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-->
                            <span class="svg-icon svg-icon-2">
                                <i class="fas fa-tasks fs-4"></i>
                            </span>
                            <!--end::Svg Icon-->
                        </span>
                        <span class="menu-title">Form İşlemleri</span>
                        <span class="menu-arrow"></span>
                    </span>
                    <div class="menu-sub menu-sub-accordion menu-active-bg" *ngIf="yetkiService.yetkiVar(['FormTanim.Görme'])">
                        <div class="menu-item">
                            <a class="menu-link" [routerLink]="['sinav-bilgi']" routerLinkActive="active">
                                <span class="menu-bullet">
                                    <span class="fas fa-list"></span>
                                </span>
                                <span class="menu-title">Form Listesi</span>
                            </a>
                        </div>

                    </div>
                </div>



                <div data-kt-menu-trigger="click" class="menu-item menu-accordion"
                    >
                    <span class="menu-link" *ngIf="yetkiService.yetkiVar(['OgrKullanici.MenuGorme'])">
                        <span class="menu-icon">
                            <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-->
                            <span class="svg-icon svg-icon-2">
                                <i class="fas fa-user fs-4"></i>
                            </span>
                            <!--end::Svg Icon-->
                        </span>
                        <span class="menu-title">Üye Kullanıcı İşlemleri</span>
                        <span class="menu-arrow"></span>
                    </span>
                    <div class="menu-sub menu-sub-accordion menu-active-bg" *ngIf="yetkiService.yetkiVar(['OgrKullanici.Islem'])">

                        <div class="menu-item">
                            <a class="menu-link" [routerLink]="['ogr-kullanicilar']" routerLinkActive="active">
                                <span class="menu-bullet">
                                    <span class="fas fa-list"></span>
                                </span>
                                <span class="menu-title">Üye Listesi</span>
                            </a>
                        </div>
                    </div>
                </div>
                <!--end::Menu-->
            </div>
            <!--end::Aside Menu-->
        </div>
        <!--end::Aside menu-->
        <!--begin::Footer-->
        <div class="aside-footer flex-column-auto pt-5 pb-7 px-5" id="kt_aside_footer">
            <a href="https://www.agd.org.tr" class="btn btn-custom btn-primary w-100" data-bs-toggle="tooltip"
                data-bs-trigger="hover" data-bs-dismiss-="click" title="Sevgi ve Kardeşliğin Teminatı"
                target="_blank">
                <span class="btn-label"> AGD</span>
                <!--begin::Svg Icon | path: icons/duotune/general/gen005.svg-->

                <!--end::Svg Icon-->
            </a>
        </div>
        <!--end::Footer-->
    </div>
    <!--end::Aside-->