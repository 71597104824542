import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import * as KTDrawer from 'src/assets/js/components/drawer';
import * as KTMenu from 'src/assets/js/components/menu';
import * as KTScroll from 'src/assets/js/components/scroll';
import * as KTScrolltop from 'src/assets/js/components/scrolltop';
import * as KTSticky from 'src/assets/js/components/sticky';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        KTMenu.hideDropdowns();
      } else if (event instanceof NavigationEnd) {
        KTMenu.createInstances();
        KTDrawer.createInstances();
        KTScroll.createInstances();
        KTScrolltop.createInstances();
        KTSticky.createInstances();
      }
    });
  }

  title = 'Web';
}
