type Nullable<T> = T | null;
export class TercihKontenjan {
    id:number=0;
    tercihTanimId:number=0;
    
    fakId:Nullable<number> | undefined=null;
    bolId:Nullable<number> | undefined=null;
    programId:Nullable<number> | undefined=null;
    
    fakAdi:string='';
    bolAdi:string='';
    progAdi:string='';

    kayitAdres:string='';
    toplamKontenjan:number=0;

    maviKartVarMi:boolean=false;
    maviKartYuzde:number=0;
    maviKartKontenjan:number=0;

    uyrukSiniriVarMi:boolean=false;
    uyrukYuzde:number=0;
    uyrukKontenjan:number=0;
    kayitTarihi:Date=new Date();
    sonGuncellemeTarihi:Date=new Date();
    arsiv:boolean=false;
}

