type Nullable<T> = T | null;
export class SinavTanim {
    id:number=0;
    tanim:string='';
    aciklama: string = '';
    anasayfaGorunurluk=false;
    sinavTarih?:  Nullable<Date> = null;
    sayi:number=0;
    basvuruBaslangicTarihi:Nullable<Date> = null;
    basvuruBitisTarihi:Nullable<Date> = null;
    tabloKayitTarihi:Nullable<Date> = null;
    sonGuncellemeTarihi:Nullable<Date> = null;
    arsiv:boolean=false;
    ayarlarAktarilsinMi:boolean=true;
    girisBelgeleriYayinlandiMi:boolean=false;
    sonucBelgeleriYayinlandiMi:boolean=false;

    sinavYerlestirmesiYapildiMi:boolean=false;
    sinavYerleriTanimlandiMi:boolean=false;

    yerlestirmeTarihi:Nullable<Date> = null;
    sonucYuklemeTarihi:Nullable<Date> = null;
    ucret:number=0;
    basvuruSayi:number=0;
    aciklamaEng:string='';
}

export class SinavSonucColumns {
    columnsdegerler = [
        {
            title: '#',
            data: 'dataIndex',
            render: function () {
                return null;
            },
        },
        {
            name: "adayNo",
            title: 'Aday No',
            data: 'adayNo',
        },
        {
            name: "adiSoyadi",
            title: 'Adı Soyadı',
            data: 'adiSoyadi',
        },
        {
            name: "dogruSayisi",
            title: 'DogruSayisi',
            data: 'dogruSayisi',
        },
        {
            name: "yanlisSayisi",
            title: 'Yanlış Sayısı',
            data: 'yanlisSayisi',
        },
        {
            name: "puan",
            title: 'Puan',
            data: 'puan',
        },

        {
            title: '#',
            data: 'dataIndex',
            render: function () {
                return null;
            },
        },
    ];
}


export class RaporTanim {
    id:string='';
    kod:string='';
    data:string='';
    format:number=0;
    name:string='';
}