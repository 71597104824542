import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { finalize } from 'rxjs';
import { SinavBasvuruDetay, SinavBasvurularColumns } from 'src/app/models/sinavBasvuru.model';
import { RaporTanim, SinavTanim } from 'src/app/models/sinavTanim.model';
import { SinavYer } from 'src/app/models/sinavYer.model';
import { Ulke } from 'src/app/models/ulke.model';
import { GirisService } from 'src/app/services/giris.service';
import { SinavBasvuruService } from 'src/app/services/sinav-basvuru.service';
import { SinavTanimService } from 'src/app/services/sinav-tanim.service';
import { YetkiService } from 'src/app/services/yetki.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

@Component({
  selector: 'ogr-basvurularim',
  templateUrl: './ogr-basvurularim.component.html',
  styleUrls: ['./ogr-basvurularim.component.css']
})

export class OgrBasvurularimComponent implements OnInit {

  constructor(private sinavBasvuruService: SinavBasvuruService, public sinavTanimService: SinavTanimService, public yetkiService: YetkiService,public girisService: GirisService, private route: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer) { this.service = sinavBasvuruService }
  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  sinavid: number = 0;
  dtOptions: any = {};
  sinavTanim: SinavTanim = new SinavTanim();
  basvuruSecilen: any;
  ulkeler: Ulke[] = [];
  sinavYerler: SinavYer[] = [];
  base64textString: string = '';

  
  rapor: RaporTanim = new RaporTanim();
  aliniyor = false;
  aliniyorS = false;
  previewUrl: any = this.sanitizer.bypassSecurityTrustResourceUrl("");
  columnclass: SinavBasvurularColumns = new SinavBasvurularColumns();
  ngOnInit(): void {
    this.basvuruList();
  }

  

  basvuruDetay: SinavBasvuruDetay[] = [];
  service: SinavBasvuruService;
  yukleniyor = false;
  aramaClass:any={ basvuruTamamlamaDurum: false };
  basvuruList() {
    this.yukleniyor = true;
    const that = this;
    const ogrId=Number(this.girisService.girisBilgi().tckimlikno);
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      orderMulti: true,
      dom: 'lrtip',
      ajax: (dataTablesParameters: any, callback: (arg0: { recordsTotal: any; recordsFiltered: any; data: never[]; }) => void) => {
        this.sinavBasvuruService.getGridList(dataTablesParameters, JSON.stringify(this.aramaClass), this.sinavid,ogrId).pipe(finalize(() => {
          this.yukleniyor = false;
        })).subscribe({
          next: res => {
            that.basvuruDetay = res.data;
            callback({
              recordsTotal: res.recordCount,
              recordsFiltered: res.recordCount,
              data: res.data
            });
          },
          error: err => {
          }
        });
      },
      columns: this.columnclass.columnsdegerler,
      language: {
        url: '/assets/image/Turkish.json',
      },
      responsive: true,
    };
  };


  ayrintiModal(basvuruSecilen: any) {
    this.sinavBasvuruService.dosyaBilgi(basvuruSecilen.basvuruBilgi.id, 0).subscribe(res => {
      this.base64textString = res?.data?.fileBase64Data;
      basvuruSecilen.base64textString = this.base64textString;
      this.basvuruSecilen = basvuruSecilen;
    });
  }


  belgeTip='';
}

