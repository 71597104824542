<!-- <div class="content d-flex flex-column flex-column-fluid"> -->
<div class="toolbar mb-5" style="height: 8%">
    <div class="container-fluid d-flex flex-stack">
        <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">

            <h1 class="d-flex text-dark fw-bolder fs-2 flex-column mb-0">
                <span>
                    <i class="fas fa-comment-dots me-2" style="font-size: large; color: #d4af37;"></i>Hazır Cevap
                    Listesi</span>
                <!-- <span class="text-muted fs-7 fw-bold mt-2">Alt Başlık</span> -->
            </h1>
        </div>

        <div class="d-flex align-items-center gap-2 gap-lg-3">
            <button type="button" routerLink="/" id="kt_modal_new_target_cancel"
                class="btn btn-danger btn-hover-rise me-3"><i class="fas fa-reply"></i> Geri
            </button>
            <button class="btn btn-sm  btn-shadow-hover btn-secondary fs-6  border border-primary"
                (click)="exportexcel()" style="color: green;">
                <i class="fas fa-file-excel " style="color: green;"></i>
                Excel
            </button>
            <a *ngIf="yetkiService.yetkiVar(['HazirCevap.Add'])" class="btn btn-sm  btn-shadow-hover fs-6"
                [routerLink]="['yeni']" style="background-color: #77c2e9; color: white;">
                <i class="fas fa-comment-dots" style="color: white;"></i>
                <!--end::Svg Icon-->Yeni Cevap Ekle
            </a>

        </div>
    </div>
</div>

<div class="post d-flex flex-column-fluid mt-10 mb-10" id="kt_post">
    <div id="kt_content_container" class="container-fluid">

        <div class="d-flex align-items-center bg-light-warning rounded p-3 mb-3" style="border: 1px solid #e98da8;">
            <div class="flex-grow-1 me-2 fs-5" style="text-align: justify;">

                <span> <i class="fas fa-exclamation-triangle me fs-5 text-danger me-2"></i>Lütfen ilgili metni
                    İngilizcesi ile birlikte giriniz. Örneğin <b>TÜRKÇE / TURKISH</b></span>
            </div>
            <span class="fw-bolder text-warning py-1"></span>
        </div>


        <grid #grid [service]="hazirCevapDetayService" [(data)]="hazirCevapDetay" [rowCount]="10">
            <div style="overflow:auto;width: 100%;">
                <table class="table table-responsive gn-middle table-row-bordered fs-7 gy-2"
                    id="kl_subscriptions_table">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 " style="color:#cd7f32">
                            <th class="text-center" style="width: 5%;">#</th>
                            <th class="text-center">Cevap</th>
                            <th class="text-center mx-4" style="float: right;">işlemler</th>
                        </tr>
                    </thead>
                    <tbody class="text-gray-600 ">
                        <!-- dblclick -->
                        <tr *ngFor="let item of hazirCevapDetay;let i=index;" style="color: black;">
                            <td class="text-info text-left text-center fs-5" style="vertical-align: middle;">
                                <b>{{(i+1) + (grid.page-1)*10}}</b>
                            </td>
                            <td class="text-center fs-5" style="vertical-align: middle;">
                                {{ item.aciklama }}
                            </td>


                            <td style="vertical-align: middle; float: right;">
                                <div class="d-flex justify-content-center flex-shrink-0">
                                    <a *ngIf="yetkiService.yetkiVar(['HazirCevap.Update'])" [routerLink]="[item.id]"
                                        style="background-color:#009ef8"
                                        class="btn btn-icon btn-hover-rise  btn-active-color-primary btn-sm me-1"
                                        title="Cevap düzenle">
                                        <i class="far fa-edit text-white"></i>
                                    </a>

                                    <button *ngIf="yetkiService.yetkiVar(['HazirCevap.Delete'])" type="button"
                                        style="background-color:#f1416c;" (click)="silModal(item)"
                                        class="btn btn-icon btn-hover-rise btn-active-color-danger btn-sm"
                                        title="Cevap sil" data-bs-toggle="modal">
                                        <i class="fas fa-trash-alt text-white"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </grid>
    </div>
</div>