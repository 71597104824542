import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { finalize } from 'rxjs';
import { GenelDataTableDto, SinavBasvuruDetay, SinavBasvurularColumns } from 'src/app/models/sinavBasvuru.model';
import { RaporTanim, SinavTanim } from 'src/app/models/sinavTanim.model';
import { SinavYer } from 'src/app/models/sinavYer.model';
import { TercihBasvuruDetay, TercihBasvurularColumns } from 'src/app/models/tercihBasvuru.model';
import { TercihDonemTanim } from 'src/app/models/tercihDonemTanim.model';
import { Ulke } from 'src/app/models/ulke.model';
import { GirisService } from 'src/app/services/giris.service';
import { SinavBasvuruService } from 'src/app/services/sinav-basvuru.service';
import { SinavTanimService } from 'src/app/services/sinav-tanim.service';
import { SinavYerService } from 'src/app/services/sinav-yer.service';
import { TercihBasvuruService } from 'src/app/services/tercih-basvuru.service';
import { UlkeService } from 'src/app/services/ulke.service';
import { YetkiService } from 'src/app/services/yetki.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
declare var bootstrap: any;

@Component({
  selector: 'ogr-tercih-basvurularim',
  templateUrl: './ogr-tercih-basvurularim.component.html',
  styleUrls: ['./ogr-tercih-basvurularim.component.css']
})
export class OgrTercihBasvurularimComponent implements OnInit {

  constructor(private tercihBasvuruService: TercihBasvuruService, public sinavTanimService: SinavTanimService, public yetkiService: YetkiService,public girisService: GirisService, private route: ActivatedRoute, private router: Router, private ulkeService: UlkeService, private sinavYerService: SinavYerService,  private sanitizer: DomSanitizer) { this.service = tercihBasvuruService }
  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  tercihid: number = 0;
  dtOptions: any = {};
  tercihTanim: TercihDonemTanim = new TercihDonemTanim();
  basvuruSecilen: any;
  base64textString: string = '';
  yukleniyor1=false;
  
  rapor: RaporTanim = new RaporTanim();
  aliniyor = false;
  aliniyorS = false;
  previewUrl: any = this.sanitizer.bypassSecurityTrustResourceUrl("");
  columnclass: TercihBasvurularColumns = new TercihBasvurularColumns();

  ngOnInit(): void {
    this.basvuruList();
  }


  ayrintiModal(basvuruSecilen: any) {
    this.tercihBasvuruService.dosyaBilgi(basvuruSecilen.basvuruBilgi.id,"profil").subscribe(res => {
      this.base64textString = res?.data?.fileBase64Data;
      basvuruSecilen.base64textString = this.base64textString;
      this.basvuruSecilen = basvuruSecilen;
    });
  }


  basvuruDetay: TercihBasvuruDetay[] = [];
  service: TercihBasvuruService;
  yukleniyor = false;
  aramaClass:any={ basvuruTamamlamaDurum: false };

  basvuruList() {
   
    this.yukleniyor = true;
    const that = this;
    const ogrId=Number(this.girisService.girisBilgi().tckimlikno);
   
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      orderMulti: true,
      dom: 'lrtip',
      ajax: (dataTablesParameters: any, callback: (arg0: { recordsTotal: any; recordsFiltered: any; data: never[]; }) => void) => {
        this.tercihBasvuruService.getGridList(dataTablesParameters, JSON.stringify(this.aramaClass), this.tercihid,ogrId).pipe(finalize(() => {
          this.yukleniyor = false;
        })).subscribe({
          next: res => {
            that.basvuruDetay = res.data;
            callback({
              recordsTotal: res.recordCount,
              recordsFiltered: res.recordCount,
              data: res.data
            });
          },
          error: err => {
          }
        });
      },
      columns: this.columnclass.columnsdegerler1,
      language: {
        url: '/assets/image/Turkish.json',
      },
      responsive: true,
    };

  };


  aliniyorKbelge=false;
  kabulBelgesiAl(basvuruid:number) {
    this.aliniyorKbelge = true;
    this.tercihBasvuruService.kabulBelgesiAl(basvuruid).pipe(finalize(() => {
    })).subscribe({
      next: res => {
        // format 0=pdf, 1=html view
        this.rapor.format = 1;
        this.rapor.data = res.data.jsonData;
        this.rapor.id = "21"; //rapor id
        this.rapor.kod="99M7VIKJ";
        this.rapor.name = "NEÜYÖS Kabul Belgesi";
        this.sinavTanimService.download("https://neurapor.erbakan.edu.tr/app/raporlama", this.rapor).pipe(finalize(() => {
          this.aliniyorKbelge = false;
        })).subscribe({
          next: res1 => {
            this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res1.data.url);
            if (res?.message && res?.message != "") { this.yetkiService.success(res.message, 1) };
            //  window.open(res1.url);
          },
          error: err => {
            if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
              if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
              if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
            }
          }
        });

      },
      error: err => {
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
        }
      }
    });
  }
  

  
}
