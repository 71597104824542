<div class="row">
    <div class="col-md-9">
        <div class="card-body">
            <div class="row  mb-4">
                <label class="col-lg-4 fw-bold text-info">E-Posta</label>
                <div class="col-lg-8">
                    <span class=" fs-6 text-gray-800 me-2">{{data.email}}
                        <span class="badge text-black " style="border: 1px solid blue;"><i
                                class="fas fa-mobile-alt me-2 text-primary  fs-2"></i>{{data.cepno}}</span>
                    </span>

                </div>

            </div>
            <div class="row  mb-4">
                <label class="col-lg-4 fw-bold text-info" ><span
                        translate>Ad Soyad</span></label>
                <div class="col-lg-8">
                    <span class=" fs-6 text-gray-800 me-2">{{data.adi}}&nbsp;{{data.soyadi}}
                        &nbsp; <span class="badge badge-warning fs-6">&nbsp;{{data.basvuruBilgi?.cinsiyet}}
                        </span>
                    </span>
                </div>
            </div>
            <div class="row  mb-4">
                <label class="col-lg-4 fw-bold text-info" >Kayıt Tarih</label>
                <div class="col-lg-8">
                    <span class=" fs-6 text-gray-800 me-2 fw-bolder">
                        {{data.basvuruBilgi.kayitTarihi | date:'dd.MM.yyy HH:mm'}}</span>
                </div>
            </div>

            <div class="row  mb-4" *ngFor="let ekAlan of data?.sinavBasvuruEkAlanCevaplar;let i=index;">
                <label class="col-lg-4 fw-bold text-info" translate>{{ekAlan.label}}</label>
                <div class="col-lg-8 fv-row" *ngIf="ekAlan.tip=='Dosya'">
                    <span class=" text-gray-800 fs-6 fw-bold">
                        <a class="menu-link cursor-pointer fs-5"
                            (click)="dosyaIndirEkAlan(data.basvuruBilgi?.id,ekAlan?.sinavEkAlanId)">{{ekAlan.dosyaAdi}}
                        </a>
                    </span>
                    <span class=" text-gray-800 fs-6 fw-bold" *ngIf="ekAlan.dosyaUrl!='' &&  ekAlan.dosyaUrl!=null">
                        <a class="menu-link cursor-pointer fs-5 text-danger"
                            (click)="dosyaBilgiEkAlan(data.basvuruBilgi?.id,ekAlan?.sinavEkAlanId,'Dosya'+ekAlan.id)">Göster
                            / Show
                        </a>
                    </span>
                </div>

                <div class="row" *ngIf=" data.dosyaAd=='Dosya'+ekAlan.id">
                    <iframe [src]="data.pathReportString" width="100%" height="500" frameborder="0" marginwidth="0"
                        marginheight="0"
                        style="border:1px solid #CCC; border-width:1px; margin-bottom:5px; max-width: 100%; min-width: 100%;"
                        allowfullscreen> </iframe>
                </div>


                <div class="col-lg-8 fv-row" *ngIf="ekAlan.tip!='Dosya'">
                    <span class=" text-gray-800 fs-6 fw-bold">
                        {{ekAlan.cevap}}
                    </span>
                </div>
            </div>

        

            <div class="row  mb-4">
                <label class="col-lg-4 fw-bold text-info" translate>tamamlanmaDurumu</label>
                <div class="col-lg-8 fv-row">

                    <span *ngIf="data?.basvuruBilgi.basvuruTamamlanmaDurumu" class="fw-bolder text-success" translate>
                        <i Class="fas fa-check text-success me-1"></i>tamamlandi</span>
                    <span *ngIf="!data?.basvuruBilgi.basvuruTamamlanmaDurumu" class="fw-bolder text-danger" translate>
                        <i class="fas fa-times text-danger me-1"></i>tamamlanmadi</span>
                </div>
            </div>

            <div class="row  mb-4">
                <label class="col-lg-4 fw-bold text-info" translate>onayDurumu</label>
                <div class="col-lg-8 fv-row">

                    <span class="fw-bolder text-black" style=" word-wrap:break-word;   display:block;
                        width:100%;">
                        <i class="{{data?.sonHareketDurum?.iconClass}} fs-4 me-2"></i>
                        {{
                        data?.sonHareketDurum?.adi
                        }}&nbsp;({{data?.sonHareketDurum?.tarih | date:'dd.MM.yyyy'}}) -
                        {{data?.sonHareketDurum?.islemAciklama}}
                    </span>
                </div>
            </div>

        </div>
    </div>
    <div class="col-md-3">
        <div class="card-body">
            <div class="col-lg-12 fv-row symbol symbol-400px symbol-lg-400px symbol-fixed position-relative">
                <span class="  fs-6"><img style="width: 100%;" src="data:image/png;base64,{{data.base64textString}}"
                        alt="Secilen" /></span>
            </div>
        </div>
    </div>


    <div class="col-md-12" *ngIf="yetkiService.yetkiVar(['Form.Degerlendirme'])">
        <div class="card-body">
            <div class="row  mb-4">
                <label class="col-lg-2 text-info fw-bold">İşlem Yap :
                    <br>
                    <br>
                    <span class="fw-bolder text-black">
                        <i class="{{data?.sonHareketDurum?.iconClass}} fs-4 me-2"></i>
                        {{
                        data?.sonHareketDurum?.adi
                        }}
                    </span>
                </label>


                <div class="col-lg-10" style="border: 1px solid rgb(124, 219, 236);">
                    <table class="table">
                        <tr>
                            <td>
                                <div class="d-flex justify-content-center flex-shrink-0">
                                    <label style="text-align: center;"
                                        class="form-check form-check-custom form-check-solid form-switch mb-5 me-3">
                                        <input type="radio"
                                            [ngStyle]="{'background-color':data?.sonHareketDurum?.kisaltma=='basvuru-onaylandi'  ? '#50CD89':''}"
                                            id="onyRadio" name="onyRadio" class="form-check-input"
                                            value="basvuru-onaylandi" [(ngModel)]="data?.sonHareketDurum.kisaltma"
                                            style="border:2px  green solid">
                                        <span class="form-check-label fw-bold fs-5 text-success">Onay
                                        </span>
                                    </label>
                                </div>
                            </td>

                            <td>
                                <div class="d-flex justify-content-center flex-shrink-0">
                                    <label style="text-align: center;"
                                        class="form-check form-check-custom form-check-solid form-switch mb-5 me-3">
                                        <input type="radio" value="basvuru-reddedildi"
                                            [ngStyle]="{'background-color': data.sonHareketDurum?.kisaltma=='basvuru-reddedildi'  ? 'red':''}"
                                            name="onyRadio" id="onyRadio" [(ngModel)]="data?.sonHareketDurum.kisaltma"
                                            class="form-check-input" style="border:2px  red solid">
                                        <span class="form-check-label fs-5  fw-bold text-danger">Red
                                        </span>
                                    </label>
                                </div>


                            </td>
                            <td>
                                <div class="d-flex justify-content-center flex-shrink-0">
                                    <label style="text-align: center;"
                                        class="form-check form-check-custom form-check-solid form-switch mb-5 me-3">
                                        <input type="radio" value="basvuru-hatali"
                                            [ngStyle]="{'background-color': data.sonHareketDurum?.kisaltma=='basvuru-hatali'  ? 'yellow':''}"
                                            name="onyRadio" id="onyRadio" [(ngModel)]="data?.sonHareketDurum.kisaltma"
                                            class="form-check-input" style="border:2px  rgb(227, 227, 25) solid">
                                        <span class="form-check-label fs-5  fw-bold text-danger">Hatalı başvuru
                                        </span>
                                    </label>
                                </div>

                            </td>

                        </tr>
                        <tr>
                            <td colspan="3">
                                <ng-container *ngIf="data?.sonHareketDurum?.kisaltma=='basvuru-reddedildi' || data?.sonHareketDurum?.kisaltma=='basvuru-hatali'">
                                    <div class="row mb-4">
                                      <label class="col-lg-2 col-form-label fs-5 fw-bold text-info" translate>Cevap</label>
                                      <div class="col-lg-10">
                                        <select class="form-select" (change)="cevapYaz(cevapSelect.options[cevapSelect.selectedIndex].text)"  #cevapSelect id="cevapid" name="cevapname"
                                        style="border-color: #8fb9d5;">
                                        <option [ngValue]=null>Lütfen seçiniz / Please choose...
                                        </option>
                                        
                                        <option *ngFor="let item of hazirCevaplar" [ngValue]="item.id">
                                          {{item.aciklama}}
                                        </option>
                                        <option [ngValue]=0>
                                          Diğer
                                        </option>
                                      </select>
                                      </div>
                                    </div>
          
                                    <div class="row  mb-4">
                                      <label class="col-lg-2  fs-5 fw-bold text-info" translate></label>
                                      <div class="col-lg-10">
                                        <textarea 
                                          style="border-color: #18dfbe;width: 100%; background-color: white;" name="icerik"
                                          id="icerik"  [disabled]="cevapPasiflik" [(ngModel)]="data?.sonHareketDurum.islemAciklama" 
                                          class="form-control" maxlength="1000" cols="10" rows="8"
                                          placeholder="Lütfen red nedenini belirtiniz."></textarea>
                                      </div>
                                    </div>
                                  </ng-container>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">
                                <div class="d-flex justify-content-center flex-shrink-0">
                                    <button type="button" id="kt_sign_in_submit"
                                        *ngIf="data?.sonHareketDurum?.kisaltma!='beklemede'"
                                        style="border: 2px solid green;"
                                        class="btn btn-block btn-active-color-primary btn-sm  text-center"
                                        title="Kaydet" [disabled]="kaydediliyor"
                                        [attr.data-kt-indicator]="kaydediliyor ? 'on' : 'off'"
                                        (click)="islemKaydet(data)">
                                        <i class="fas fa-check text-primary"></i>
                                        Kaydet
                                        <span class="indicator-progress">
                                            Kaydediliyor...
                                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>