<div class="toolbar mb-5" >
    <div class="container-fluid d-flex flex-stack">
        <div class="page-title d-flex flex-column align-items-start me-3  mb-lg-0">
            <h1 class="d-flex text-dark fw-bolder fs-2 flex-column mb-0">
                <span>
                    <i class="fab fa-cc-visa me-2" style="font-size: large; color: #d4af37;"></i>Kredi Kartı Ödeme Kontrol</span>

                <span class="text-muted fs-5 fw-bold mt-2"><i class="fas fa-exclamation-triangle fs-4 text-danger me-2"></i>Sadece başarılı olarak sonuçlanan ödemeler gelmektedir. <span class="text-black"><i class="fas fa-exclamation-triangle fs-4 text-danger me-2"></i>Lütfen Başlangıç ve Bitiş tarihi arasındaki süre farkı en fazla 5 gün olacak şekilde filtreleme yapınız.</span></span>
            </h1>
        </div>  
    </div>
</div>


<div class="post d-flex flex-column-fluid mt-10 mb-20" id="kt_post">
    <div id="kt_content_container" class="container-fluid">
        <div class="card" style="border-top: 2px #77c2e9 solid; ">
            <div class="card-body" style="padding:1.5rem">
                <div class="row align-items-center justify-content-center">
                    <div class="col-md-3 col-12">
                        <div class="form-floating mb-3">
                            <input type="date" [(ngModel)]="basTar" [value]="basTar |
                            date:'yyyy-MM-dd'" class="form-control datepicker" id="baslangicTarih"
                                placeholder="toplantiTarih" name="sinavTarih" style="border-color: #8fb9d5;" />
                            <label style="color: #009ef7;" class="fs-4 fw-bolder" for="sinavTarih">
                                Başlangıç Tarihi</label>
                        </div>
                    </div>
                    <div class="col-md-3 col-12">
                        <div class="form-floating mb-3">
                            <input type="date" [(ngModel)]="bitTar" [value]="bitTar |
                            date:'yyyy-MM-dd'" class="form-control datepicker" id="bitisTarih"
                                placeholder="toplantiTarih" name="sinavTarih" style="border-color: #8fb9d5;"/>

                            <label style="color: #009ef7;" class="fs-4 fw-bolder" for="sinavTarih">
                                Bitiş Tarihi</label>
                        </div>
                    </div>
                    <div class="col-md-3 col-12 mb-2 text-center">
                        <button type="button" style="border: 2px solid rgb(52, 207, 241);" (click)="kartOdemeListesiniGetir()" class="btn btn-secondary btn-shadow-hover fs-6 align-middle"
                            [disabled]="yukleniyor" [attr.data-kt-indicator]="yukleniyor ? 'on' : 'off'">
                            <span class="indicator-label"> <i class="fas fa-sync fs-6"
                                    style="color: rgb(235, 22, 22)"></i>Ödeme Listesini Getir</span>
                            <span class="indicator-progress" style="color: rgb(86, 22, 235);">Ödeme Listesi
                                Yükleniyor...
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                    <div class="col-md-3 col-12">
                        <div class="input-group">
                            <div class="form-floating form-floating-group flex-grow-1">
                                <input type="text"   (input)="searchChange($event)" class="form-control" name="code1"
                                    style="border-color:#77C2E9;" placeholder="Ara" #searchTextGelen>
                                <label for="code1">ARA</label>
                            </div>
                            <span class="input-group-text"><i class="fas fa-search me-2"></i></span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-body py-4" style="border-top: #f5c6c6 1px solid; background-color:white;">
                <loading [loader]="yukleniyor">
                    <div style="overflow:auto;width: 100%;">
                        <table class="table   table-responsive gn-middle table-row-bordered fs-6 gy-2"
                            id="pl_subscriptions_table">
                            <thead>  
                                <tr class="text-start fw-bolder fs-6  gs-1 " style="color:#009ef7;">
                                    <th class="text-center" style="width: 2%;">Sıra No</th>
                                    <th class="text-center" style="vertical-align: middle;">T.C.K.N./Y.U. Kimlik</th>
                                    <th class="text-center" style="vertical-align: middle;">TransactionId</th>
                                    <th class="text-center" style="vertical-align: middle;">Adı Soyadı</th>
                                    <th class="text-center" style="vertical-align: middle;">İşlem Tarihi</th>
                                    <th class="text-center" style="vertical-align: middle;">Tutar</th>
                                    <th class="text-center" style="vertical-align: middle;">İşlem Sonucu</th>
                                </tr>
                            </thead>
                            <tbody class="text-gray-600 fw-bold">
                                <tr *ngFor="let item of odemeListesiYeni;let i=index;">
                                    <td class="text-center" style="vertical-align: middle;"> 
                                        {{ i+1 }}
                                    </td>
                                    <td class="text-center" style="vertical-align: middle;"> 
                                        {{ item.TCYUPNumber }}  </td>
                                        <td class="text-center" style="vertical-align: middle;">
                                            {{ item.TransactionId}}  </td>        
                                    <td class="text-center" style="vertical-align: middle;">
                                        {{ item.AdiSoyadi }}  </td>
                                    <td class="text-center"  style="vertical-align: middle;">
                                        {{ item.RequestInsertTime | date:'dd.MM.yyyy HH:mm' }}
                                    </td>
                                    <td class="text-center" style="vertical-align: middle;">
                                        {{ item.Amount }}
                                    </td>
                                    <td class="text-center" style="vertical-align: middle;">
                                        <span *ngIf=" item.ResultCode=='0000'" style="color: green;">Başarılı</span>
                                        <span *ngIf=" item.ResultCode!='0000'" style="color: red;">Hatalı</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </loading>
            </div>
        </div>
    </div>
</div>