<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h3 class="d-flex text-dark fw-bolder fs-2 flex-column mb-0">
                    <span><i class="fas fa-file-signature me-2" style="font-size: large; color: #d4af37;"></i>
                        {{this.sinavTanim.tanim }}
                        <span>
                            &nbsp;&nbsp;
                            <i class="fas fa-calendar-alt text-success fs-3"></i>
                            {{this.sinavTanim.sinavTarih | date:'dd.MM.yyyy HH:mm' }} - Form Listesi</span>
                    </span>
                    <!-- <span class="text-muted fs-7 fw-bold mt-2">Alt Başlık</span> -->
                </h3>

            </div>
            <div class="d-flex align-items-center gap-2 gap-lg-3">
                <button class="btn btn-sm  btn-shadow-hover btn-secondary fs-6  border border-primary"
                    (click)="exportexcel()" style="color: green;">
                    <i class="fas fa-file-excel " style="color: green;"></i>
                    Tüm listeyi excel olarak al
                </button>
                <button type="button" routerLink="/sinav-bilgi" id="kt_modal_new_target_cancel"
                    class="btn btn-danger btn-hover-rise me-3"><i class="fas fa-reply"></i> Geri
                </button>
            </div>
        </div>
    </div>

    <div class="post d-flex flex-column-fluid mb-10" id="kt_post">
        <div id="kt_content_container" class="container-fluid">
            <!-- <form (submit)="filterById()">
            <label>
              Min
              <input type="text" name="adi" id="adi" [(ngModel)]="basvuruTanimlar.adi" />
            </label>
            
            <button class="btn btn-primary " type="submit">Filter by ID</button>
          </form>   -->

            <div class="card " style="border-top: 3px rgb(146, 199, 224) solid; overflow: auto;max-width: 100%;">
                <div class="card-body table-responsive" style="width: 100%;">
                    <div class="d-flex align-items-center bg-light-warning rounded p-2 mb-1" style="border: 1px solid #e98da8;">
                        <div class="flex-grow-1 me-2 fs-5" style="text-align: justify;">
            
                            <span> <i class="fas fa-exclamation-triangle me fs-5 text-danger me-2"></i>Arama alanlarına aramak istediğiniz ifadeyi girdikten sonra <b>Enter</b> tuşuna basınız. Arama kutusunu boşaltıp tekrar <b>Enter</b> tuşuna bastığınızda o alanla ilgili filtreleme kaybolacaktır.</span>
                        </div>
                        <span class="fw-bolder text-warning py-1"></span>
                    </div>
                    <table datatable [dtOptions]="dtOptions" class="row-border hover">
                        <thead>
                            <tr>
                           
                                <th><input class="form-control search-input" style="border-color: #8fb9d5;" id="2"
                                        type="text" placeholder="Ad" />
                                </th>
                                <th><input class="form-control search-input" style="border-color: #8fb9d5;" id="3"
                                        type="text" placeholder="Soyad" />
                                </th>
                                <th>
                                    <select class="form-select search-input" id="5" style="border-color: #8fb9d5;">
                                        <option value="">Tümü</option>
                                        <option *ngFor="let item of ulkeler" value="{{item.id}}">
                                            {{item.adi}}
                                        </option>
                                    </select>
                                </th>
                                <th>
                                    <select class="form-select search-input" id="6" style="border-color: #8fb9d5;">
                                        <option value="">Tümü</option>
                                        <option *ngFor="let item of sinavYerler"
                                            value="{{item.ulkeAdi+' / '+item.adi}}">
                                            {{ item.ulkeAdi+' / '+item.adi}}
                                        </option>
                                    </select>
                                </th>
                                                               <th>
                                    <select class="form-select search-input" id="8" style="border-color: #8fb9d5;">
                                        <option value="">Tümü</option>
                                        <option value="Beklemede ">Form Tamamlandı</option>
                                        <option value="Form tamamlanmadı.">Form tamamlanmadı</option>
                                    </select>
                                </th>
                                <th>

                                    <label style="text-align: left;"
                                        class="form-check form-check-custom form-check-solid form-switch ">
                                        <input type="checkbox" name="katildiMi" id="katildiMi" class="form-check-input"
                                            [(ngModel)]="bavuruTamamlanmayanGoster" (change)="tamamlanmayanlar()"
                                            [value]="bavuruTamamlanmayanGoster" style="border:2px  green solid"
                                            [ngStyle]="{'background-color': bavuruTamamlanmayanGoster==false  ? 'red':bavuruTamamlanmayanGoster==true  ? 'green':'' }">

                                        <span *ngIf="bavuruTamamlanmayanGoster"
                                            class="form-check-label fs-7 text-black">
                                            Form tamamlanmayanları gösterme
                                        </span>
                                        <span *ngIf="bavuruTamamlanmayanGoster==false"
                                            class="form-check-label fs-7 text-black">
                                            Form tamamlanmayanları göster
                                        </span>
                                    </label>
                                </th>
                               
                            </tr>

                            <tr>
                                <th class="min-w-20px">#</th>
                                <th class="min-w-75px">Aday Ndsfdsfo</th>
                                <th class="min-w-75px">Adıddddd</th>
                                <th class="min-w-75px">Soyadı</th>
                                <th class="min-w-75px">durum</th>
                                <th class="min-w-75px">Uyruk</th>
                            </tr>

                        </thead>
                        
                        <tbody *ngIf="basvuruDetay?.length!= 0">
                            <tr *ngFor="let basvuru of basvuruDetay;let i=index;">
                                <td class="text-info text-left fs-5" style="vertical-align: middle;">
                                    <b>{{(i+1) + (this.pageNo-1)*10}}</b>
                                </td>
                         
                                <td>
                                     {{ basvuru?.adi }}
                                </td>
                                <td>
                                    {{ basvuru?.soyadi }}
                                </td>

                           
                                <td>
                                    <span class="fw-bolder text-info"> <i
                                            class="{{basvuru.sonHareketDurum?.iconClass}} fs-3 me-2"></i> {{
                                        basvuru.durumAdi
                                        }}</span>
                                </td>
                                <td>
                                    <span class="fw-bolder text-info"> {{
                                        basvuru.basvuruBilgi?.kayitTarihi | date:'dd.MM.yyyy HH:mm'
                                        }}</span>
                                </td>

                                <td>
                                    <div class="d-flex justify-content-center flex-shrink-0" style="width:250px">

                                 

                                        <a  *ngIf="yetkiService.yetkiVar(['Formİşlemleri.Düzenle'])"
                                        (click)="ayrintiModal(basvuru)" style="background-color:#2aa31f"
                                            title="FORM KAYIT GÖR" class="btn btn-icon   btn-sm me-1">
                                            <i class="fas fa-info-circle text-white" style="font-size:x-large;"></i>
                                        </a>

                                        <button *ngIf="yetkiService.yetkiVar(['Formİşlemleri.Düzenle'])"   [routerLink]="['/ogr-sinav-basvuru', sinavTanim.id]"
                                            [queryParams]="{basvuruid: basvuru.basvuruBilgi?.id}" type="button"
                                            class="btn btn-primary btn-sm me-1">
                                            Form Kayıt düzenle
                                        </button>

                                       

                                    </div>
                                </td>

                            </tr>
                        </tbody>
                        <tbody *ngIf="basvuruDetay?.length == 0">
                            <tr>
                                <td colspan="10" class="no-data-available text-center">Herhangi bir veri bulunamadı.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<button id="openAyrintiModalButton"   [hidden]="true"   data-bs-toggle="modal" data-bs-target="#basvuru_ayrinti_modal"
data-toggle="modal">Open Modal</button>
<div id="basvuru_ayrinti_modal" data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <i class="fas fa-user me-2 text-primary" style="font-size: large; padding-right:2px;"></i>
                <h2 class="modal-title">Başvuru Ayrıntı</h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" style="border-top: #36d35d 1px solid">
                <ogr-basvuru-ayrinti [data]="basvuruSecilen" *ngIf="basvuruSecilen" (myevent)="gridYenile()">

                </ogr-basvuru-ayrinti>
            </div>
        </div>
    </div>
</div>

<table class="table   table-responsive gn-middle table-row-bordered fs-7 gy-2" id="kl_tumbasvurular_table"
    style="display: none;" *ngIf="tumBasvurular.length>0">
    <thead>
        <tr class="text-start fw-bolder fs-7 gs-0 " style="color:#cd7f32">
            <th class="min-w-20px">#</th>
            <th class="min-w-125px">Adı</th>
            <th class="min-w-125px">Soyadı</th>
            <th class="min-w-125px">İl</th>
            <th class="min-w-125px">İlçe</th>
            <th class="min-w-125px">Ünvan</th>
            <th class="min-w-125px">Doğum Tarihi</th>
            <th class="min-w-125px">Doğum Yeri</th>
            <th class="min-w-125px">Cinsiyet</th>
            <th class="min-w-125px">Telefon</th>
            <th class="min-w-125px">Adres</th>
            <th class="min-w-200px text-center">Durum</th>

        </tr>
    </thead>
    <tbody class="text-gray-600 ">
        <!-- dblclick -->
        <tr *ngFor="let item of tumBasvurular;let i=index;" style="color: black;">
            <td class="text-info text-left fs-5" style="vertical-align: middle;">
                <b>{{i+1}}</b>
            </td>
         
            <td>
                {{ item?.basvuruBilgi?.adi }}
            </td>
            <td>
                {{ item?.basvuruBilgi?.soyadi }}
            </td>

            <td>
ödeme tip
            </td>
            <td class="text-center">
                <span class="fw-bolder text-info"> <i class="{{item.sonHareketDurum?.iconClass}} fs-3 me-2"></i> {{
                    item.sonHareketDurum?.adi
                    }}</span>
            </td>
        </tr>
    </tbody>
</table>

<div id="belge_modal" data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl" style="width: 870px;">

        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" style="border-top: #36d35d 1px solid">
                <loading [loader]="aliniyor ">
                    <iframe id="iframe" name="iframe" frameBorder="10" [src]="previewUrl" marginHeight="0"
                        marginWidth="0" width="100%" height="600px" scrolling="auto">
                    </iframe>
                </loading>
            </div>
        </div>
    </div>
</div>