import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NotSistem } from '../models/notSistem.model';
import { Response } from '../models/response.model';


@Injectable({
  providedIn: 'root'
})
export class NotSistemService {
  apiUrl = '/api/notSistem';

  constructor(private http: HttpClient) { 

  }

  getAll(query: string = '', page: number = 1, rowCount: number = 10): Observable<Response<NotSistem[]>> {
    return this.http.get<Response<NotSistem[]>>(this.apiUrl + '/getList', {
      params: {
        'aramastr': query,
        'sayfasayisi': page,
        'sayfadakisatirsayisi': rowCount
      }
    });
  }


  getById(id: number): Observable<Response<NotSistem>> {
    return this.http.get<Response<NotSistem>>(this.apiUrl + '/getById?id=' + id);
  }

  delete(notSistem: NotSistem): Observable<Response<NotSistem>> {
    return this.http.post<Response<NotSistem>>(this.apiUrl + '/delete', notSistem)
  }


  addUpdate(notSistem: NotSistem): Observable<Response<NotSistem>> {
    if (notSistem.id > 0) {
      return this.http.post<Response<NotSistem>>(this.apiUrl + "/update", notSistem)
    }
    else {
      return this.http.post<Response<NotSistem>>(this.apiUrl + "/add", notSistem)
    }
  }
}