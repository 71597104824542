<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h3 class="d-flex text-dark fw-bolder fs-2 flex-column mb-0">
                    <span><i class="fas fa-file-signature me-2" style="font-size: large; color: #d4af37;"></i>
                        Geçmiş Formlar 
                    </span>
                </h3>

            </div>
            <div class="d-flex align-items-center gap-2 gap-lg-3">
                <button type="button" routerLink="/" id="kt_modal_new_target_cancel"
                    class="btn btn-danger btn-hover-rise me-3" translate><i class="fas fa-reply"></i>geri
                </button>
            </div>
        </div>
    </div>

    <div class="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" class="container-fluid mb-10">
            <div class="card " style="border-top: 3px rgb(146, 199, 224) solid;">
                <div class="card-body table-responsive" style="width: 100%;">
                    <table datatable [dtOptions]="dtOptions" class="row-border hover">
                        <thead>
                            <tr>
                                <th class="min-w-150px text-center"></th>
                                <th class="min-w-75px">d</th>
                                <th class="min-w-75px">d</th>
                                <th class="min-w-75px">c</th>
                                <th class="min-w-125px">c</th>
                                <th class="min-w-125px">c</th>
                                <th class="min-w-125px" style="display: none;"> c</th>
                                <th class="min-w-125px" style="display: none;">c</th>
                  

                            </tr>

                        </thead>
                        <tbody *ngIf="basvuruDetay?.length!= 0">
                            <tr *ngFor="let basvuru of basvuruDetay;let i=index;">
                                <td class="text-info text-left fs-5" style="vertical-align: middle;">
                                    <b> <span class="fw-bolder text-info"> {{
                                        basvuru.basvuruBilgi?.kayitTarihi | date:'dd.MM.yyyy HH:mm'
                                        }}</span></b>
                                </td>
                                                                <td>
                                    {{ basvuru?.adi }}
                                </td>
                                <td>
                                    {{ basvuru?.soyadi }}
                                </td>
                                <td style="display: none;">
                                    ödeme tip
                                </td>
                                <td class="text-center">
                                    <span class="fw-bolder text-info"> <i
                                            class="{{basvuru.sonHareketDurum?.iconClass}} fs-3 me-2"></i> {{
                                        basvuru.durumAdi
                                        }}</span>
                                </td>

                                <td>
                                    <span class="fw-bolder text-info"> {{
                                        basvuru.basvuruBilgi?.kayitTarihi | date:'dd.MM.yyyy HH:mm'
                                        }}</span>
                                </td>
                                <td>
                                    <div class="d-flex justify-content-center flex-shrink-0">
                                        <a (click)="ayrintiModal(basvuru)" style="background-color:#2aa31f"
                                            data-toggle="tooltip" data-theme="dark" title="BAŞVURU BİLGİLERİNİ GÖR"
                                            class="btn btn-icon   btn-sm me-1" data-bs-toggle="modal"
                                            data-bs-target="#basvuru_ayrinti_modal">
                                            <i class="fas fa-info-circle text-white" style="font-size:x-large;"></i></a>
                                        
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="basvuruDetay?.length == 0">
                            <tr>
                                <td colspan="10" class="no-data-available text-center">Herhangi bir veri bulunamadı.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>



<div id="basvuru_ayrinti_modal" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <i class="fas fa-user me-2 text-primary" style="font-size: large; padding-right:2px;"></i>
                <h2 class="modal-title">Başvuru Ayrıntı</h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" style="border-top: #36d35d 1px solid">
                <ogr-basvuru-ayrinti [data]="basvuruSecilen" *ngIf="basvuruSecilen">

                </ogr-basvuru-ayrinti>
            </div>
        </div>
    </div>
</div>

<div id="belge_modal" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl" style="width: 870px;">
        
        <div class="modal-content">
            
            <div class="modal-header">
                <i class="fas fa-user me-2 text-primary" style="font-size: large; padding-right:2px;"></i>
            <h2 class="modal-title">{{belgeTip}}</h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" style="border-top: #36d35d 1px solid">
                <loading [loader]="aliniyor || aliniyorS"> 
                    <iframe id="iframe" name="iframe" frameBorder="10" [src]="previewUrl" marginHeight="0"
                        marginWidth="0" width="100%" height="600px" scrolling="auto"> 
                    </iframe>
            </loading>
            </div>
        </div>
    </div>
</div>
