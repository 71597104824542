import { SinavBasvuru, SonHareketDurumBilgi } from "./sinavBasvuru.model";
import { SinavTanim } from "./sinavTanim.model";
import { TercihBasvuru } from "./tercihBasvuru.model";
import { TercihDonemTanim } from "./tercihDonemTanim.model";

export class AnasayfaBilgiDto {
    aktifSinavBilgiDto: AktifSinavBilgiDto = new AktifSinavBilgiDto();
    aktifTercihDonemDto: AktifTercihDonemDto = new AktifTercihDonemDto();
}

export class AktifSinavBilgiDto {
    basvuran_Sayi: number = 0;
    onaylanan_Sayi: number = 0;
    reddedilen_Sayi: number = 0;
    onayBekleyen_Sayi: number = 0;
    hatali_Sayi: number = 0;
    tamamlanmayanBasvuru_Sayi: number = 0;
    durumAdi: string = '';
    basvuruZamanAktifMi: boolean = false;
    ogrSinavBasvuruBilgi: SinavBasvuru = new SinavBasvuru();
    sonHareketDurum: SonHareketDurumBilgi = new SonHareketDurumBilgi();
    sinavTanim: SinavTanim = new SinavTanim();
}

export class AktifTercihDonemDto {
    basvuran_Sayi: number = 0;
    onaylanan_Sayi: number = 0;
    reddedilen_Sayi: number = 0;
    onayBekleyen_Sayi: number = 0;
    hatali_Sayi: number = 0;
    tamamlanmayanBasvuru_Sayi: number = 0;
    yerlestirmeNo: number = 0;
    yerlestirmeYayindami: boolean = false;
    tercihSonHareketDurumBilgi: SonHareketDurumBilgi = new SonHareketDurumBilgi();
    basvuruZamanAktifMi: boolean = false;
    ogrTercihBasvuruBilgi: TercihBasvuru = new TercihBasvuru();
    tercihDonemTanim: TercihDonemTanim = new TercihDonemTanim();
}
