import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '../models/response.model';
import { SinavSonucSablonDto } from '../models/sinavSonucSablonDto.model';
import { RaporTanim, SinavTanim } from '../models/sinavTanim.model';

@Injectable({
  providedIn: 'root'
})
export class SinavTanimService {
  apiUrl = '/api/sinav';

  constructor(private http: HttpClient) {

  }

  getAll(query: string = '', page: number = 1, rowCount: number = 10): Observable<Response<SinavTanim[]>> {
    return this.http.get<Response<SinavTanim[]>>(this.apiUrl + '/getList', {
      params: {
        'aramastr': query,
        'sayfasayisi': page,
        'sayfadakisatirsayisi': rowCount
      }
    });
  }

  getListAll(): Observable<Response<SinavTanim[]>> {
    return this.http.get<Response<SinavTanim[]>>(this.apiUrl + '/getListAll');
  }

  getById(id: number): Observable<Response<SinavTanim>> {
    return this.http.get<Response<SinavTanim>>(this.apiUrl + '/getById?id=' + id);
  }

  delete(sinavBilgi: SinavTanim): Observable<Response<SinavTanim>> {
    return this.http.post<Response<SinavTanim>>(this.apiUrl + '/delete', sinavBilgi)
  }

  sonSinavSayisi(): Observable<Response<any>> {
    return this.http.get<Response<any>>(this.apiUrl + '/sonSinavSayisi')
  }

  addUpdate(sinavBilgi: SinavTanim): Observable<Response<SinavTanim>> {
    if (sinavBilgi.id > 0) {
      return this.http.post<Response<SinavTanim>>(this.apiUrl + "/update", sinavBilgi)
    }
    else {
      return this.http.post<Response<SinavTanim>>(this.apiUrl + "/add", sinavBilgi)
    }
  }

  sinavYerlestirmeYap(sinavid: number = 0, yerlestirmeTip: string = '', sinavyerid: number = 0): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/sinavYerlestirmeYap?sinavid=' + sinavid + '&yerlestirmeTip=' + yerlestirmeTip + '&sinavyerid=' + sinavyerid, {});
  }

  sonuclariYukle(sinavSonucSablonDtoJson: string): Observable<Response<SinavSonucSablonDto[]>> {
    return this.http.post<Response<any>>(this.apiUrl + '/sinavSonucYukle?sinavSonucSablonDtoJson=' + sinavSonucSablonDtoJson, {});
  }

  sonuclariSil(sinavid: number): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/sinavSonucSil?sinavid=' + sinavid, {});
  }

  getGridSonucList(dataTablesParameters: any, jsondata: any, sinavid: number): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/sinavSonucGridList', dataTablesParameters, {
      params: {
        'jsonekdata': jsondata,
        'sinavid': sinavid
      }
    });
  }

  sinavGirisBelgeAl(sinavid: number,ogrid:number): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/sinavGirisBelgeAl?sinavid=' + sinavid+'&ogrId='+ogrid, {});
  }

  sonucBelgeAl(sinavid: number,ogrid:number): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/sonucBelgeAl?sinavid=' + sinavid+'&ogrId='+ogrid, {});
  }

  salonOgrList(sinavid: number, salonId: number): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/salonOgrList?sinavid=' + sinavid + '&salonid=' + salonId, {});
  }

  salonBelgeAl(salonOgrListBelge:any[]): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/salonBelgeAl', salonOgrListBelge);
  }

  sinavSonucBelgeYayinla(sinavid: number,tip:number): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/sinavSonucBelgeYayinla?sinavid=' + sinavid+'&tip='+tip, {});
  }


  sinavGirisBelgeYayinla(sinavid: number,tip:number): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/sinavGirisBelgeYayinla?sinavid=' + sinavid+'&tip='+tip, {});
  }

  belgeDogrula(tip:string='',no:string='',gS:number=0): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/belgeDogrula?tip=' + tip+'&no='+no+'&gs='+gS, {});
  }

  // id: string = '', data: string = '', view: boolean = false,
  download(url: string = '',rapor:RaporTanim): Observable<any> {
    return this.http.post<any>(url, rapor);
  }

}







