<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h3 class="d-flex text-dark fw-bolder fs-2 flex-column mb-0">
                    <span><i class="fas fa-file-signature me-2" style="font-size: large; color: #d4af37;"></i>
                        {{this.sinavTanim.tanim }}
                        <span>
                            &nbsp;&nbsp;
                            <i class="fas fa-calendar-alt text-success fs-3"></i>
                            {{this.sinavTanim.sinavTarih | date:'dd.MM.yyyy HH:mm' }} - Sonuç Belgesi İşlemleri</span>
                    </span>
                    <!-- <span class="text-muted fs-7 fw-bold mt-2">Alt Başlık</span> -->
                </h3>

            </div>
            <div class="d-flex align-items-center gap-2 gap-lg-3">
                <button type="button" routerLink="/sinav-bilgi" id="kt_modal_new_target_cancel"
                    class="btn btn-danger btn-hover-rise me-3"><i class="fas fa-reply"></i> Geri
                </button>
            </div>
        </div>
    </div>

    <div class="post d-flex flex-column-fluid mb-10" id="kt_post">
        <div id="kt_content_container" class="container-fluid">
            <div class="card col-10 offset-1" style="border-top: 3px rgb(146, 199, 224) solid;">
                <div class="card-body" style="width: 100%;">

                    <div class="form-group row mb-2">
                        <label class="col-lg-3 col-form-label text-info fs-6">Sonuç excel dosyasını seç :
                            <span class="text-danger me-2"> *
                            </span></label>
                        <div class="col-lg-9">
                            <input ui-jq="filestyle" class="form-control border-warning" type="file" id="file"
                                (change)="selectSonuc($event)" name="file" nv-file-select=""
                                accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                uploader="uploader" data-icon="false" style="color: #7239EA;"
                                data-classButton="btn btn-default"
                                data-classInput="form-control inline v-middle input-s">
                            <a class="menu-link cursor-pointer fs-5" target="_blank"
                                href="assets/dosyalar/Sınav sonuc format.xlsx">
                                <span class="text-danger"><i class="fas fa-hand-point-right text-info me-2"></i>Sonuç
                                    dosya formatını indir ( Lütfen sınav sonuçlarını bu dosya formatına göre yükleyiniz.
                                    Aksi takdirde hatalı bir işlem olacaktır.).</span>
                            </a>
                        </div>
                    </div>


                    <div class="form-group row mb-5">
                        <label class="col-lg-3 col-form-label text-info fs-6">
                        </label>
                        <div class="col-lg-9 text-center">
                            <button  *ngIf="yetkiService.yetkiVar(['SinavBasvuru.SonucKaydet'])"
                            type="button" class="btn btn-success" [disabled]="kaydediliyor"
                                (click)="sonucListeYukle()" [attr.data-kt-indicator]="kaydediliyor ? 'on' : 'off'">
                                <span class="indicator-label"><i class="fas fa-check"></i>Kaydet
                                </span>
                                <span class="indicator-progress">Kaydediliyor...
                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                        <span style="font-size: medium;">
                            &nbsp;&nbsp;
                            Sonuç yüklenme durumu : <b>
                                <span *ngIf="sinavTanim.sonucYuklemeTarihi, else yerlestirmeYok">
                                    Sonuçlar kaydedildi. </span> </b>
                            <ng-template #yerlestirmeYok>
                                <span class="text-danger">Sonuçlar yüklenmedi.</span>
                            </ng-template>
                        </span>
                        <span style="font-size: medium;">
                            &nbsp;&nbsp;
                            <i class="fas fa-calendar-alt text-success fs-3"></i>
                            Sonuç yükleme tarihi : <b> {{this.sinavTanim.sonucYuklemeTarihi | date:'dd.MM.yyyy HH:mm'
                                }}</b></span>

                    </div>


                    <div class="col-8 offset-2 text-center mt-6">
                        <span style="font-size: medium;">
                            &nbsp;&nbsp;
                            Sonuç belgeleri yayınlanma durumu : <b>
                                <span class="" *ngIf="sinavTanim.sonucBelgeleriYayinlandiMi, else yayinlamaYok">
                                    Sonuç belgeleri yayınlandı. </span> </b>
                            <ng-template #yayinlamaYok>
                                <span class="text-danger">Sonuç belgeleri yayınlanmadı.</span>
                            </ng-template>
                        </span>
                        <br>
                        <div class="d-flex justify-content-center flex-shrink-0">

                            <button *ngIf="sinavTanim.sonucBelgeleriYayinlandiMi==false && yetkiService.yetkiVar(['SinavBasvuru.SonucBelgeYayinla'])" type="button"
                                class="btn btn-success mt-3 me-2" [disabled]="yayinlaniyor"
                                (click)="sonucBelgeYayinla(1)" [attr.data-kt-indicator]="yayinlaniyor ? 'on' : 'off'">
                                <span class="indicator-label"><i class="fas fa-poll fs-3"></i>Sonuçları yayınla
                                </span>
                                <span class="indicator-progress">Yayınlanıyor...
                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>

                            <button *ngIf="sinavTanim.sonucBelgeleriYayinlandiMi==true && yetkiService.yetkiVar(['SinavBasvuru.SonucBelgeYayinla'])" type="button"
                                class="btn btn-danger mt-3 me-2" [disabled]="yayinlaniyor"
                                (click)="sonucBelgeYayinla(0)" [attr.data-kt-indicator]="yayinlaniyor ? 'on' : 'off'">
                                <span class="indicator-label"><i class="fas fa-poll fs-3"></i>Yayından kaldır
                                </span>
                                <span class="indicator-progress">Kaldırılıyor...
                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>



                            <button  *ngIf="yetkiService.yetkiVar(['SinavBasvuru.SonucSil'])"
                            type="button" class="btn btn-danger mt-3" [disabled]="siliniyor"
                                (click)="sonucSil()" [attr.data-kt-indicator]="siliniyor ? 'on' : 'off'">
                                <span class="indicator-label"><i class="fas fa-poll fs-3"></i>Sonuçları sil
                                </span>
                                <span class="indicator-progress">Tüm sonuçlar siliniyor...
                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <br>
   
    <div class="post d-flex flex-column-fluid mb-10" id="kt_post">
        <div id="kt_content_container" class="container-fluid">
            <div class="card offset-1 col-10  px-5 py-5" style="border-top: 3px rgb(146, 199, 224) solid; overflow:auto;">
                <table datatable [dtOptions]="dtOptions" class="row-border hover ">
                    <thead>
                        <tr>
                            <th class="min-w-125px">#</th>
                            <th class="min-w-125px">Aday No</th>
                            <th class="min-w-125px">Adı Soyadı</th>
                            <th class="min-w-125px">Doğru Sayısı</th>
                            <th class="min-w-125px">Yanliş Sayısı</th>
                            <th class="min-w-125px">Puan</th>
                            <th class="min-w-125px">#</th>
                        </tr>

                    </thead>
                    <tbody *ngIf="sonucDetay?.length!= 0">
                        <tr *ngFor="let basvuru of sonucDetay;let i=index;">
                            <td class="text-info text-left fs-5" style="vertical-align: middle;">
                                <b>{{i+1}}</b>
                            </td>
                            <td>
                                {{ basvuru?.adayNo }}
                            </td>
                            <td>
                                {{ basvuru?.adiSoyadi }}
                            </td>
                            <td>
                                {{ basvuru?.dogruSayisi }}
                            </td>
                            <td>
                                {{ basvuru?.yanlisSayisi }}
                            </td>
                            <td class="text-info  fw-bolder">
                                {{ basvuru?.puan }}
                            </td>

                            <td class="text-info  fw-bolder">
                                <button type="button" class="btn btn-success btn-sm" [disabled]="belgealiniyor"
                                    (click)="sonucBelgesiAl(basvuru.ogrId)"
                                    [attr.data-kt-indicator]="belgealiniyor ? 'on' : 'off'" data-bs-toggle="modal"
                                    data-bs-target="#belge_modal" data-toggle="modal">
                                    <span class="indicator-label">
                                        <i class="fas fa-file-word"></i>Belge al
                                    </span>
                                    <span class="indicator-progress">Bekleyiniz...
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="sonucDetay?.length == 0">
                        <tr>
                            <td colspan="10" class="no-data-available text-center">Herhangi bir veri bulunamadı.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</div>



<div id="belge_modal" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl"  style="width: 870px;">

        <div class="modal-content">

            <div class="modal-header">
                <i class="fas fa-user me-2 text-primary" style="font-size: large; padding-right:2px;"></i>
                <h2 class="modal-title">{{belgeTip}}</h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" style="border-top: #36d35d 1px solid">
                <div *ngIf="previewUrl;else bekleg">
                    <iframe id="iframe" name="iframe" frameBorder="10" [src]="previewUrl" marginHeight="0"
                        marginWidth="0" width="100%" height="600px" scrolling="auto">
                    </iframe>
                </div>
                <ng-template #bekleg>
                    <span>Lütfen bekleyiniz / Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </ng-template>

            </div>
        </div>
    </div>
</div>