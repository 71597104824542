import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { NotSistem } from 'src/app/models/notSistem.model';
import { SinavTur } from 'src/app/models/sinavTur.model';
import { TercihBasvuru, TercihSecilenBirim } from 'src/app/models/tercihBasvuru.model';
import { TercihBasvuruTip } from 'src/app/models/tercihBasvuruTip.model';
import { TercihDonemTanim } from 'src/app/models/tercihDonemTanim.model';
import { Ulke } from 'src/app/models/ulke.model';
import { TanimlarService } from 'src/app/services/tanimlarservice';
import { GirisService } from 'src/app/services/giris.service';
import { NotSistemService } from 'src/app/services/not-sistem.service';
import { OgrKullaniciService } from 'src/app/services/ogrKullanici.service';
import { SinavTurService } from 'src/app/services/sinav-tur.service';
import { TercihBasvuruTipService } from 'src/app/services/tercih-basvuru-tip.service';
import { TercihBasvuruService } from 'src/app/services/tercih-basvuru.service';
import { TercihDonemTanimService } from 'src/app/services/tercih-donem-tanim.service';
import { TercihSecilenBirimService } from 'src/app/services/tercih-secilen-birim.service';
import { UlkeService } from 'src/app/services/ulke.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';
import Swal from 'sweetalert2';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AnasayfaBilgiService } from 'src/app/services/anasayfa-bilgi.service';
import { GenelDataTableDto, SonHareketDurumBilgi } from 'src/app/models/sinavBasvuru.model';
import { Kullanici } from 'src/app/models/kullanici.model';
import { RaporTanim } from 'src/app/models/sinavTanim.model';
import { SinavTanimService } from 'src/app/services/sinav-tanim.service';
import { TercihKontenjanService } from 'src/app/services/tercih-kontenjan.service';
import { TercihKontenjan } from 'src/app/models/tercihKontenjan.model';
import * as XLSX from 'xlsx';

@Component({
  selector: 'ogr-tercih-basvuru',
  templateUrl: './ogr-tercih-basvuru.component.html',
  styleUrls: ['./ogr-tercih-basvuru.component.css']
})
export class OgrTercihBasvuruComponent implements OnInit {

  constructor(private ulkeService: UlkeService, private kontenjanService: TercihKontenjanService, private tanimlarService: TanimlarService, private route: ActivatedRoute, private validationService: ValidationService, private formBuilder: FormBuilder, public yetkiService: YetkiService, private router: Router, private girisService: GirisService, private sinavTurService: SinavTurService, private datePipe: DatePipe, public tercihTanimService: TercihDonemTanimService, private ogrKullaniciService: OgrKullaniciService, public cd: ChangeDetectorRef, public tercihBasvuruService: TercihBasvuruService, private notSistemService: NotSistemService, public tercihBasvuruTipService: TercihBasvuruTipService, private tercihSecilenBirimService: TercihSecilenBirimService, private anasayfaBilgiService: AnasayfaBilgiService, private sinavTanimService: SinavTanimService, private sanitizer: DomSanitizer) { }
  step = 1;
  yukleniyor = false;
  tercihTanim: TercihDonemTanim = new TercihDonemTanim();
  tercihTanimId: number = 0;
  basvuruid: number = 0;
  base64textString: string = '';
  hatalar: any = {};
  hataYazdir: any[] = [];

  fileProfil: any;
  filePass: any;
  fileMaviKart: any;
  fileLiseMezAsamaDiploma: any;
  fileLiseNotDokumu: any;
  fileLiseOgrenimGordugu: any;
  fileSinavSonuc: any;

  ulkeler: Ulke[] = [];
  tercihSecilenBirimler: TercihSecilenBirim[] = [];
  sinavTurler: SinavTur[] = [];
  notSistemler: NotSistem[] = [];
  kontenjanLar: TercihKontenjan[] = [];
  basvuruTipler: TercihBasvuruTip[] = [];
  basvuruTipKisaAd: string | undefined = '';
  kayitliBasvuruBilgi: TercihBasvuru = new TercihBasvuru();
  sonHareketDurum: SonHareketDurumBilgi = new SonHareketDurumBilgi();
  kaydediliyor = false;
  yeni = true;
  secilenFakId: number = 0;
  tercihBirim: any[] = [];
  tercihBolum: any[] = [];
  tercihAnabilimDali: any[] = [];

  kvkkCheck: boolean = false;
  basvuruOnayMsg = '';
  kullanici: Kullanici = new Kullanici();
  detayForm = this.formBuilder.group({
    tercihBasvuruForm: this.formBuilder.group({
      ...new TercihBasvuru(),
    }),
    secilenBirimForm: this.formBuilder.group({
      ...new TercihSecilenBirim(),
    })
  });

  tercihBasvuruForm(): FormGroup {
    return this.detayForm.get('tercihBasvuruForm') as FormGroup;
  }
  secilenBirimForm(): FormGroup {
    return this.detayForm.get('secilenBirimForm') as FormGroup;
  }


  birimList(tercihId: number) {
    this.tercihBirim = [];
    this.tercihTanimId = tercihId;
    this.secilenBirimForm().get('fakId')?.setValue(null);
    this.tercihBirim.push({ id: null, text: "Lütfen Seçiniz" })
    this.tercihBasvuruService.birimGetirOgrTercih(0, tercihId).subscribe(res => {
      this.tercihBirim = res.data;
    });
  }

  bolumList() {
    this.tercihBolum = [];
    this.tercihBolum.push({ id: null, text: "Lütfen Seçiniz" })
    const fakid = this.secilenBirimForm().value.fakId;
    this.secilenFakId = this.secilenBirimForm().value.fakId;
    this.secilenBirimForm().get('bolId')?.setValue(null);
    this.secilenBirimForm().get('programId')?.setValue(null);
    if (fakid != null) {
      this.tercihBasvuruService.birimGetirOgrTercih(fakid, this.tercihTanimId).subscribe(res => {
        this.tercihBolum = res.data;
        if (this.tercihBolum.length === 0) {
          this.tercihBolum = [];
          this.tercihAnabilimDali = [];
        }
      });
    }
  }

  geliyor = false;
  programList() {
    this.geliyor = true;
    this.tercihAnabilimDali = [];
    this.tercihAnabilimDali.push({ id: null, text: "Lütfen Seçiniz" })
    const bolid = this.secilenBirimForm().value.bolId;
    this.secilenBirimForm().get('programId')?.setValue(null);
    if (bolid != null) {
      this.tercihBasvuruService.birimGetirOgrTercih(bolid, this.tercihTanimId, this.secilenFakId).subscribe(res => {
        if (res.data?.length > 0) {
          this.tercihAnabilimDali = res.data;
        }
        else {
          this.tercihAnabilimDali = this.tercihBolum;
        }
        this.geliyor = false;
      });
    }
    else {
      this.geliyor = false;
      this.tercihAnabilimDali = [];
    }
  }

  ngOnInit(): void {
    this.ogrKullaniciBilgi(0);
    this.ulkeList();
    this.tercihBasvuruTipList();
    this.notSistemList();
    this.tercihBasvuruForm().get('sinavPuanYuzluk')?.setValue(0);
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.birimList(Number(params['id']));
        this.tercihTanimId = params['id'];
        this.kontenjanList(Number(params['id']));
        this.sinavTurList(Number(params['id']));
        this.getTercihTanimById(Number(params['id']));
      }

      this.route.queryParams.subscribe(params => {
        if (params['basvuruid'] && Number(params['basvuruid'] > 0)) {
          this.basvuruid = Number(params['basvuruid']);
          this.getById(Number(params['basvuruid']));
          this.secilenBirimList(Number(params['basvuruid']));
          this.yeni = false;
        }

        if (params['step987']) {
          this.step=Number(params['step987']);
        }
      })
    });
  }

  selectFileProfil(evt: any) {
    var files = evt.target.files;
    var file = files[0];
    var type = file.type;
    let images: string[] = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg', 'image/jpeg'];
    var uygunMu = images.indexOf(type);
    if (files && file && evt.target.files[0]?.size < 10485760 && uygunMu > -1) {
      this.fileProfil = file;
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
    else {
      this.yetkiService.sweetAlertError("Seçilen dosya resim formatında ve en fazla 10MB olmalıdır.");
      this.fileProfil = null;
    }
  }


  exportexcel(): void {
    const currentYear = new Date('' + this.tercihTanim.baslangicTarihi);
    let fileName = currentYear.getFullYear() + " kontenjan listesi.xlsx"
    var element = document.getElementById('kl_subscriptions_table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '' + currentYear.getFullYear() + " Kontenjan listesi");
    XLSX.writeFile(wb, fileName);
  }

  selectBelge(evt: any, dosyaTip: string) {
    var files = evt.target.files;
    var file = files[0];
    var type = file.type;
    let dekont: string[] = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/jpeg', 'image/png'];
    var uygunMu = dekont.indexOf(type);
    if (files && file && evt.target.files[0]?.size < 10485760 && uygunMu > -1) {
      if (dosyaTip == 'pasaport') {
        this.filePass = file;
      }
      if (dosyaTip == 'mavi-kart') {
        this.fileMaviKart = file;
      }
      if (dosyaTip == 'lise-mez-asama-diploma') {
        this.fileLiseMezAsamaDiploma = file;
      }
      if (dosyaTip == 'lise-not-dokumu') {
        this.fileLiseNotDokumu = file;
      }
      if (dosyaTip == 'lise-ogrenim-gordugu') {
        this.fileLiseOgrenimGordugu = file;
      }
      if (dosyaTip == 'sinav-sonuc') {
        this.fileSinavSonuc = file;
      }
    }
    else {
      this.yetkiService.sweetAlertError("Seçilen dosya formatı veya boyutu hatalı. / The selected file format or size is incorrect.");
      if (dosyaTip == 'pasaport') {
        this.filePass = null;
      }
      if (dosyaTip == 'mavi-kart') {
        this.fileMaviKart = null;
      }
      if (dosyaTip == 'lise-mez-asama-diploma') {
        this.fileLiseMezAsamaDiploma = null;
      }
      if (dosyaTip == 'lise-not-dokumu') {
        this.fileLiseNotDokumu = null;
      }
      if (dosyaTip == 'lise-ogrenim-gordugu') {
        this.fileLiseOgrenimGordugu = null;
      }
      if (dosyaTip == 'sinav-sonuc') {
        this.fileSinavSonuc = null;
      }
    }
  }

  _handleReaderLoaded(readerEvt: any) {
    var binaryString = readerEvt?.target?.result;
    this.base64textString = btoa(binaryString);
  }

  ulkeList() {
    this.ulkeService.getAll('', 1, 300).subscribe(res => {
      this.ulkeler = res.data;
    });
  }

  kontenjanList(tercihid: number) {
    this.kontenjanService.getAll('', 1, 1000, tercihid).subscribe(res => {
      this.kontenjanLar = res.data;
    });
  }

  secilenNotSistemiLise: number = 0;
  onKeypressEventLise() {
    if (this.tercihBasvuruForm().value.liseNotSistemId == 1) {
      this.secilenNotSistemiLise = this.tercihBasvuruForm().value?.liseNotSistemAciklama;
    }
    else {
      this.secilenNotSistemiLise = this.notSistemler.find(p => p.id == this.tercihBasvuruForm().value?.liseNotSistemId)?.deger ?? 0;
    }

    var girilenPuan = this.tercihBasvuruForm().value?.liseNotOrtalamasi;
    if (girilenPuan > this.secilenNotSistemiLise) {
      this.yetkiService.sweetAlertError("Girilen not ortalaması 0 ile " + this.secilenNotSistemiLise + " aralığında olmalıdır. / The grade point average entered must be between 0 and " + this.secilenNotSistemiLise + ".");
      this.tercihBasvuruForm().get('liseNotOrtalamasi')?.setValue(0);
    }
  }

  yuzdePuan: number = 0;
  secilenNotSistemiSinav: number = 0;
  onKeypressEventSinav() {
    if (this.tercihBasvuruForm().value.sinavTurId == 3) {
      if (this.tercihBasvuruForm().value.sinavNotSistemId == 1) {
        this.secilenNotSistemiSinav = this.tercihBasvuruForm().value?.sinavNotSistemAciklama;
      }
      else {
        this.secilenNotSistemiSinav = this.notSistemler.find(p => p.id == this.tercihBasvuruForm().value?.sinavNotSistemId)?.deger ?? 0;
      }
    }

    else {
      this.secilenNotSistemiSinav = this.sinavTurler.find(p => p.id == this.tercihBasvuruForm().value?.sinavTurId)?.notSistemi ?? 0;
    }

    var girilenPuan = this.tercihBasvuruForm().value?.sinavPuan;
    if (girilenPuan > this.secilenNotSistemiSinav) {
      this.yetkiService.sweetAlertError("Girilen sınav puanı 0 ile " + this.secilenNotSistemiSinav + " aralığında olmalıdır. / The exam score entered must be between 0 and " + this.secilenNotSistemiSinav + ".");
      this.tercihBasvuruForm().get('sinavPuan')?.setValue(0);
      this.tercihBasvuruForm().get('sinavPuanYuzluk')?.setValue(0);
    }
    else {
      this.yuzdePuan = (100 * girilenPuan) / this.secilenNotSistemiSinav;
      this.tercihBasvuruForm().get('sinavPuanYuzluk')?.setValue(this.yuzdePuan);
    }
  }

  gelenBirim = false;
  secilenBirimList(basvuruid: number) {
    this.gelenBirim = true;
    this.tercihSecilenBirimService.getAll('', 1, 300, basvuruid).subscribe(res => {
      this.tercihSecilenBirimler = res.data;
      this.gelenBirim = false;
    });
  }

  notSistemList() {
    this.notSistemService.getAll('', 1, 300).subscribe(res => {
      this.notSistemler = res.data;
    });
  }

  sinavTurList(tercihTanimId: number) {
    this.sinavTurService.getAll('', 1, 300, tercihTanimId).subscribe(res => {
      this.sinavTurler = res.data;
    });
  }

  tercihBasvuruTipList() {
    this.tercihBasvuruTipService.getAll('', 1, 300).subscribe(res => {
      this.basvuruTipler = res.data;
    });
  }

  kisaAdAl() {
    this.basvuruTipKisaAd = this.basvuruTipler.find(p => p.id == this.tercihBasvuruForm().value.basvuruTipId ?? 0)?.kisaltma;
  }

  getTercihTanimById(id: number) {
    this.yukleniyor = true;
    this.tercihTanimService.getById(id).subscribe(res => {
      this.tercihTanim = res.data;
      // Başvuru ile ilgili kontroller
      this.tercihBasvuruService.ogrBasvuruKontrol(res.data.id, this.basvuruid).subscribe(res => {
        this.yukleniyor = false;
      }, err => {
        this.router.navigateByUrl('/')
      },);

    }, err => {
      this.router.navigateByUrl('/')
    },);
  }

  back() {
    this.step--;
  }

  continue() {
    this.step++;
  }



  ogrKullaniciBilgi(ogrId: number = 0) {
    var ogrIdGiden = 0;
    if (ogrId == 0) {
      ogrIdGiden = Number(this.girisService.girisBilgi().tckimlikno);
    }
    else {
      ogrIdGiden = ogrId;
    }
    this.ogrKullaniciService.getById(Number(this.girisService.girisBilgi().tckimlikno)).subscribe(res => {
      var ogrBilgi = res.data;
      // 
      if (this.basvuruid == 0) {
        this.tercihBasvuruForm().get('adi')?.setValue(ogrBilgi.adi);
        this.tercihBasvuruForm().get('soyadi')?.setValue(ogrBilgi.soyadi);
        this.tercihBasvuruForm().get('dogumTarihi')?.setValue(ogrBilgi.dogum_tarihi)
      }
    });
  }

  dosyaBilgi(basvuruid: number) {
    this.tercihBasvuruService.dosyaBilgi(basvuruid, "profil").subscribe(res => {
      this.base64textString = res?.data?.fileBase64Data;
    });
  }

  getById(id: number) {
    this.yukleniyor = true;
    this.tercihBasvuruService.getById(id).subscribe(res => {
      this.yukleniyor = false;
      this.dosyaBilgi(id);
      this.tercihBasvuruForm().patchValue(res.data);
      this.ogrKullaniciBilgi(res.data.ogrKullaniciId);
      this.kvkkCheck = res.data.bilgiDogrulukOnayi;
      this.yuzdePuan = res.data?.sinavPuanYuzluk ?? 0;
      this.yeni = false;
      this.tercihBasvuruTipService.getAll('', 1, 300).subscribe(res1 => {
        this.basvuruTipKisaAd = res1.data.find(p => p.id == res.data.basvuruTipId ?? 0)?.kisaltma;
      });

      this.anasayfaBilgiService.getAnasayfaBilgi(res.data?.ogrKullaniciId ?? 0).subscribe(res => {
        this.sonHareketDurum = res.data.aktifTercihDonemDto?.tercihSonHareketDurumBilgi;
      });


      this.kayitliBasvuruBilgi = res.data;
    }, err => {
      this.router.navigateByUrl('/')
    });
  }

  dosyaIndir(dosyaTip: string) {
    this.tercihBasvuruService.dosyaBilgi(this.basvuruid, dosyaTip).subscribe(res => {
      var blob = this.tercihBasvuruService.dataURItoBlob(res.data?.fileBase64Data);
      const imageFile = new File([blob], res.data?.fileName, { type: res.data?.contentType });
      var objectUrl = URL.createObjectURL(imageFile);
      var fileLink = document.createElement('a');
      fileLink.href = objectUrl;
      fileLink.download = res.data.fileName;
      fileLink.click();
    });
  }


  kaydetGuncelle() {
    this.hatalar = {};
    this.hataYazdir = [];
    this.kaydediliyor = true;

    var formBasvuru = this.tercihBasvuruForm().value;
    formBasvuru.id = this.basvuruid;
    formBasvuru.tercihTanimId = Number(this.tercihTanimId);
    // formBasvuru.ogrKullaniciId = Number(this.girisService.girisBilgi().tckimlikno);

    const formData = new FormData();
    if (this.basvuruTipKisaAd == 'mavi-kart') {
      formData.append("fileMaviKart", this.fileMaviKart);
    }
    else {
      this.fileMaviKart = null;
    }


    if (formBasvuru.sinavTurId != 3) {
      formBasvuru.sinavNotSistemId = null;
      formBasvuru.sinavNotSistemAciklama = null;
    }


    if (this.basvuruTipKisaAd == 'tc-uyruklu-yurtdisi-lise' || (this.basvuruTipKisaAd == 'mavi-kart' && this.tercihBasvuruForm().value.liseUlkeId != 190 && this.tercihBasvuruForm().value.liseUlkeId != null)) {
      formData.append("fileLiseOgrenimGordugu", this.fileLiseOgrenimGordugu);
    }
    else {
      this.fileLiseOgrenimGordugu = null;
    }

    if (formBasvuru.liseNotSistemId != Number(1)) {
      formBasvuru.liseNotSistemAciklama = '';
    }

    formData.append('stepNo', '' + this.step);
    formData.append('basvuruid', '' + this.basvuruid);

    formData.append("fileProfil", this.fileProfil);
    formData.append("filePass", this.filePass);

    formData.append("fileSinavSonuc", this.fileSinavSonuc);

    formData.append("fileLiseMezAsamaDiploma", this.fileLiseMezAsamaDiploma);
    formData.append("fileLiseNotDokumu", this.fileLiseNotDokumu);
    formData.append("basvurualanlar", JSON.stringify(formBasvuru));

    var minPuan = this.tercihTanim.puanSiniri ?? 0;
    var hesaplananPuan = this.yuzdePuan ?? 0;

    if (this.step == 4 && hesaplananPuan < minPuan) {
      this.kaydediliyor = false;
      this.yetkiService.sweetAlertError("Hesaplanan yüzdelik puan ez az " + minPuan + " olmalıdır. Aksi halde başvurunuz kabul edilmeyecektir. / The calculated percentage score must be at least " + minPuan + ". Otherwise, your application will not be accepted.");
    }
    else  {
      this.tercihBasvuruService.addUpdate(formData).pipe(finalize(() => {
        this.kaydediliyor = false;
      })).subscribe({
        next: res => {
          this.basvuruid = res.data?.id;
          this.yetkiService.success(res?.message, 1);
          this.getById(res.data?.id);
          if (this.yeni == true) {
            this.step = 3;
          }
        },
        error: err => {
          this.hatalar = this.validationService.hatalar(err.error.Errors);
          this.hataYazdir = [];
          this.hatalar = this.validationService.hatalar(err.error.Errors);
          for (let key in this.hatalar) {
            let hata = this.hatalar[key];
            this.yetkiService.error(hata[0], 1)
            this.hataYazdir.push(this.hatalar[key]);
          }
          if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
            if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
            if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
          }
        }
      });
    }
  }

  bosalt() {
    this.tercihBasvuruForm().get('sinavPuan')?.setValue(0);
    this.tercihBasvuruForm().get('sinavPuanYuzluk')?.setValue(0);
  }

  birimKaydetGuncelle() {
    this.hatalar = {};
    this.hataYazdir = [];
    this.kaydediliyor = true;
    var formBirimSecilen = this.secilenBirimForm().value;
    formBirimSecilen.tercihBasvuruId = Number(this.basvuruid);
    var maxTercihSayisi = this.tercihTanim.yapilacakTercihSayisi;
    var minPuanTipDis = this.tercihTanim.puanSiniriTipDis ?? 0;
    var hesaplananPuan = this.yuzdePuan ?? 0;


    if (hesaplananPuan < minPuanTipDis && ( formBirimSecilen.programId ==888 ||  formBirimSecilen.programId==190)) {
      this.kaydediliyor = false;
      this.yetkiService.sweetAlertWarning("Tıp Fakültesi ve Diş Hekimliği programlarını tercih etmek için hesaplanan yüzdelik puanınız ez az " + minPuanTipDis + " olmalıdır. / To choose the Faculty of Medicine and Dentistry programs, your calculated percentage score must be at least " + minPuanTipDis + ".");
    }
    
    else if (this.tercihSecilenBirimler.length < maxTercihSayisi) {
      this.tercihSecilenBirimService.addUpdate(formBirimSecilen).pipe(finalize(() => {
        this.kaydediliyor = false;
      })).subscribe({
        next: res => {
          this.yetkiService.success(res?.message, 1);
          this.secilenBirimList(this.basvuruid);
        },
        error: err => {
          this.hatalar = this.validationService.hatalar(err.error.Errors);
          this.hataYazdir = [];
          this.hatalar = this.validationService.hatalar(err.error.Errors);
          for (let key in this.hatalar) {
            let hata = this.hatalar[key];
            this.yetkiService.error(hata[0], 1)
            this.hataYazdir.push(this.hatalar[key]);
          }
          if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
            if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
            if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
          }
        }
      });
    }
    else {
      this.kaydediliyor = false;
      this.yetkiService.sweetAlertError("<span class='fs-3'>Yapılacak en fazla tercih sayısı  / Maximum number of choices to be made: " + '<b>' + maxTercihSayisi + '</b>' + '</span>');
    }
  }

  siliniyor = false;
  silModal(tercihSecilenBirim: TercihSecilenBirim) {
    Swal.fire({
      title: 'Tercih bilgilerini silnek istediğinize emin misiniz ? <span class=text-danger>( İşlem geri alınamaz. )</span>',
      text: tercihSecilenBirim.fakAdi + "/" + tercihSecilenBirim.bolAdi + "/" + tercihSecilenBirim.progAdi,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sil',
      cancelButtonText: 'İptal',
    }).then((result) => {
      if (result.value) {
        this.siliniyor = true;
        this.tercihSecilenBirimService.delete(tercihSecilenBirim).subscribe(response => {
          this.yetkiService.success("Tercih bilgileri başarıyla silindi.", 1)
          this.secilenBirimList(this.basvuruid);
          this.siliniyor = false;
        }, error => {
          this.yetkiService.error("Hata oluştu.", 1)
          this.siliniyor = false;
        });
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("İptal edildi.", "Silme işlemi iptal edildi.", 'error')
      }
    })

  }

  dropTercih(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.tercihSecilenBirimler, event.previousIndex, event.currentIndex);
    this.yukleniyor = true;
    if (event.previousIndex != event.currentIndex) {
      this.tercihSecilenBirimService.tercihSiraNoDegistir(this.tercihSecilenBirimler).pipe(finalize(() => {
      })).subscribe({
        next: res => {
          if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
          this.secilenBirimList(this.basvuruid);
          this.yukleniyor = false;
        },
        error: err => {
          if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
            if (err.error?.message && err.error?.message != "") { this.yetkiService.error(err.error?.message, 1) };
            if (err.error?.Message && err.error?.Message != "") { this.yetkiService.error(err.error?.Message, 1) };
          }
        }
      });
    }
    else {
      this.yukleniyor = false;
    }

  }

  dogrulaniyor = false;
  basvuruTamamla() {
    this.dogrulaniyor = true;
    if (this.kvkkCheck) {
      if (this.basvuruid > 0) {
        this.basvuruOnayMsg = '';
        if (this.tercihSecilenBirimler.length > 0 && this.kayitliBasvuruBilgi.sonStepNo > 3) {
          this.tercihBasvuruService.basvuruTamamla(this.basvuruid).pipe(finalize(() => {
            this.dogrulaniyor = false;
          })).subscribe({
            next: res => {
              if (res?.message && res?.message != "") { this.yetkiService.sweetAlertSuccess(res?.message) };
              this.router.navigateByUrl('/')
            },
            error: err => {
              this.hatalar = this.validationService.hatalar(err.error.Errors);
              if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
                if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
                if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
              }
            }
          });
        }
        else {
          this.dogrulaniyor = false;
          this.basvuruOnayMsg = 'Lütfen bilgilerinizi eksiksiz giriniz.';
        }
      }
    }
    else {
      this.dogrulaniyor = false;
      this.basvuruOnayMsg = 'Lütfen bilgilerinizin doğruluğunu onaylayınız.';
    }
  }

  basvuruTekrarGonder() {
    this.dogrulaniyor = true;
    if (this.basvuruid > 0) {
      this.basvuruOnayMsg = '';
      if (this.tercihSecilenBirimler.length > 0 && this.kayitliBasvuruBilgi.sonStepNo > 3) {
        this.tercihBasvuruService.basvuruTekrarGonder(this.basvuruid).pipe(finalize(() => {
          this.dogrulaniyor = false;
        })).subscribe({
          next: res => {
            if (res?.message && res?.message != "") { this.yetkiService.sweetAlertSuccess(res?.message) };
            this.router.navigateByUrl('/')
          },
          error: err => {
            this.hatalar = this.validationService.hatalar(err.error.Errors);
            if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
              if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
              if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
            }
          }
        });
      }
      else {
        this.dogrulaniyor = false;
        this.basvuruOnayMsg = 'Lütfen bilgilerinizi eksiksiz giriniz. / Please enter your information completely.';
      }
    }

  }


  aliniyor = false;
  basvuruSecilen: any;
  rapor: RaporTanim = new RaporTanim();
  previewUrl: any = this.sanitizer.bypassSecurityTrustResourceUrl("");
  dataTablesParameters: GenelDataTableDto = new GenelDataTableDto();
  tercihBasvuruBelgesiAl(basvuruid: number) {
    this.aliniyor = true;
    this.tercihBasvuruService.getGridListRapor(this.dataTablesParameters, "", this.tercihTanim.id, Number(this.kullanici.tckimlikno), 0, basvuruid).pipe(finalize(() => {
    })).subscribe({
      next: res => {
        this.basvuruSecilen = res.data;
        var json = JSON.stringify(this.basvuruSecilen)
        this.rapor.format = 1;
        this.rapor.kod="UTYD8A4V";
        this.rapor.data = json;
        this.rapor.id = "17"; //rapor id
        this.rapor.name = "NEÜYÖS " + 2015 + "Tercih başvuru belgesi";
        this.sinavTanimService.download("https://neurapor.erbakan.edu.tr/app/raporlama", this.rapor).pipe(finalize(() => {
          this.aliniyor = false;
        })).subscribe({
          next: res1 => {
            this.aliniyor = false;
            this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res1.data.url);
            if (res?.message && res?.message != "") { this.yetkiService.success(res.message, 1) };
            //  window.open(res1.url);
          },
          error: err => {
            if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
              if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
              if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
            }
          }
        });

      },
      error: err => {
      }
    })

  }

}
