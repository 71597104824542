import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { finalize } from 'rxjs';
import { SinavSonucSablonDto } from 'src/app/models/sinavSonucSablonDto.model';
import { RaporTanim, SinavSonucColumns, SinavTanim } from 'src/app/models/sinavTanim.model';
import { SinavYer } from 'src/app/models/sinavYer.model';
import { SinavTanimService } from 'src/app/services/sinav-tanim.service';
import { SinavYerService } from 'src/app/services/sinav-yer.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
@Component({
  selector: 'sonuc-belge-islem',
  templateUrl: './sonuc-belge-islem.component.html',
  styleUrls: ['./sonuc-belge-islem.component.css']
})
export class SonucBelgeIslemComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, public sinavTanimService: SinavTanimService, public sinavYerService: SinavYerService, public yetkiService: YetkiService, private validationService: ValidationService, private sanitizer: DomSanitizer) { }
  yukleniyor: boolean = false;
  sinavid: number = 0;
  sinavTanim: SinavTanim = new SinavTanim();

  kaydediliyor: boolean = false;
  yayinlaniyor: boolean = false;
  belgealiniyor: boolean = false;
  siliniyor: boolean = false;

  sinavYerler: SinavYer[] = [];
  sonucDetay: SinavSonucSablonDto[] = [];
  columnclass: SinavSonucColumns = new SinavSonucColumns();

  sinavYerId: number = 0;
  hatalar: any = {};
  fileSonuc: any;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;

  dtOptions: any = {};
  belgeTip = '';
  aliniyorS = false;
  previewUrl: any = this.sanitizer.bypassSecurityTrustResourceUrl("");
  rapor: RaporTanim = new RaporTanim();
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.sinavid = params['id'];
        this.getSinavBilgi();
        this.sonucList();
      }
    });
  }

  gridYenile(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  getSinavBilgi() {
    this.yukleniyor = true;
    this.sinavTanimService.getById(this.sinavid).subscribe(res => {
      this.sinavTanim = res.data;
    }, err => {
      this.router.navigateByUrl('/')
    },);
  }

  sonucList() {
    this.yukleniyor = true;
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      orderMulti: true,
      //  dom: 'lrtip',

      ajax: (dataTablesParameters: any, callback: (arg0: { recordsTotal: any; recordsFiltered: any; data: never[]; }) => void) => {
        this.sinavTanimService.getGridSonucList(dataTablesParameters, JSON.stringify(""), this.sinavid).pipe(finalize(() => {
          this.yukleniyor = false;
        })).subscribe({
          next: res => {
            that.sonucDetay = res.data;
            callback({
              recordsTotal: res.recordCount,
              recordsFiltered: res.recordCount,
              data: res.data
            });
          },
          error: err => {
          }
        });
      },
      columns: this.columnclass.columnsdegerler,
      language: {
        url: '/assets/image/Turkish.json',
      },
      responsive: true,
    };
  };


  ExelData: any;
  selectSonuc(evt: any) {
    var files = evt.target.files;
    var file = files[0];
    var type = file.type;
    let dekont: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    var uygunMu = dekont.indexOf(type);
    if (files && file && evt.target.files[0]?.size < 10485760 && uygunMu > -1) {
      this.fileSonuc = file;
    }
    else {
      this.yetkiService.sweetAlertError("Seçilen dosya formatı veya boyutu hatalı.");
      this.fileSonuc = null;
    }
  }

  arrayBuffer: any;
  excelReadList: any[] = [];

  sonucListeYukle() {
    if (this.fileSonuc) {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result;
        var data = new Uint8Array(this.arrayBuffer);
        var arr = new Array();
        for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");
        var workbook = XLSX.read(bstr, { type: "binary" });
        var first_sheet_name = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[first_sheet_name];
        this.excelReadList = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      }
      fileReader.readAsArrayBuffer(this.fileSonuc);

      Swal.fire({
        title: 'Sonuç listesi yüklenecektir. Emin misiniz?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'İptal',
      }).then((result) => {
        if (result.value) {
          this.kaydediliyor = true;
          this.hatalar = {};
          const sinavSonucSablonDtoJson = JSON.stringify(this.excelReadList);
          this.sinavTanimService.sonuclariYukle(sinavSonucSablonDtoJson).pipe(finalize(() => {
            this.kaydediliyor = false;
          })).subscribe({
            next: res => {
              if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
              this.gridYenile();
            },
            error: err => {
              this.hatalar = this.validationService.hatalar(err.error.Errors);

              if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
                if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
                if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
              }
            }
          });
        }
      })

    }
    else {
      this.yetkiService.sweetAlertError("Seçilen dosya bulunamadı.");
    }
  }

  sonucSil() {
    Swal.fire({
      title: 'Tüm sonuçlar silinecektir. İşlemi yapmak istediğinize emin misiniz?  <span class=text-danger>( İşlem geri alınamaz. )</span>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Evet',
      cancelButtonText: 'İptal',
    }).then((result) => {
      if (result.value) {
        this.siliniyor = true;
        this.hatalar = {};
        this.sinavTanimService.sonuclariSil(this.sinavid).pipe(finalize(() => {
          this.siliniyor = false;
        })).subscribe({
          next: res => {
            if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
            // this.getSinavBilgi(); // grid oluşturulup güncellenecek.
          },
          error: err => {
            this.hatalar = this.validationService.hatalar(err.error.Errors);
            if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
              if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
              if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
            }
          }
        });
      }
    })

  }

  sonucBelgeYayinla(tip:number) {
    const ifade=tip==1 ? "Bu işlemden sonra öğrenciler sonuc belgelerini alabileceklerdir. Sonuç belgelerini yayınlamak istediğinize emin misiniz ?":"Sonuç belgelerini yayından kaldırmak istediğinize emin misiniz?";
    Swal.fire({
      title: ifade,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Evet',
      cancelButtonText: 'İptal',
    }).then((result) => {
      if (result.value) {
        this.yayinlaniyor = true;
        this.hatalar = {};
        this.sinavTanimService.sinavSonucBelgeYayinla(this.sinavid,tip).pipe(finalize(() => {
          this.yayinlaniyor = false;
        })).subscribe({
          next: res => {
            if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
            this.router.navigateByUrl('/sonuc-belge-islem/' + this.sinavid)
            this.getSinavBilgi();
          },
          error: err => {
            this.hatalar = this.validationService.hatalar(err.error.Errors);
            if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
              if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
              if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
            }
          }
        });

      }
    })
  }

  sonucBelgesiAl(ogrId: number = 0) {
    this.belgeTip = 'Sınav Sonuç Belgesi / Exam Result Document';
    this.aliniyorS = true;
    this.sinavTanimService.sonucBelgeAl(this.sinavTanim.id, Number(ogrId)).pipe(finalize(() => {
      this.aliniyorS = false;
    })).subscribe({
      next: res => {
        // format 0=pdf, 1=html view
        this.rapor.format = 1;
        this.rapor.data = res.data.jsonData;
        this.rapor.id = "3"; //rapor id
        this.rapor.kod="X7SRRW7B";
        const datepipe: DatePipe = new DatePipe('en-US');
        let sinavYili = datepipe.transform(this.sinavTanim.sinavTarih, 'YYYY');
        this.rapor.name = "NEÜYÖS "+sinavYili+" Sonuç Belgesi";

        this.sinavTanimService.download("https://neurapor.erbakan.edu.tr/app/raporlama", this.rapor).pipe(finalize(() => {
        })).subscribe({
          next: res1 => {
            this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res1.data.url);
            //  window.open(res1.url);
            if (res?.message && res?.message != "") { this.yetkiService.success('Sonuç belgesi başarıyla oluşturuldu.', 1) };
          },
          error: err => {
            if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
              if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
              if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
            }
          }
        });


       
      },
      error: err => {
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
        }
      }
    });

  }

}
