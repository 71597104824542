import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';
import { OgrKullanici } from 'src/app/models/ogrKullanici.model';
import { GirisService } from 'src/app/services/giris.service';
import { OgrKullaniciService } from 'src/app/services/ogrKullanici.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';
import i from 'src/assets/plugins/formvalidation/dist/es6/plugins/Aria';
import { TranslateService } from "@ngx-translate/core";



@Component({
  selector: 'giris-form',
  templateUrl: './giris-form.component.html',
  styleUrls: ['./giris-form.component.css']
})
export class GirisFormComponent {

  constructor(private girisService: GirisService, private router: Router, private formBuilder: FormBuilder, private validationService: ValidationService, private yetkiService: YetkiService, private ogrKullaniciService: OgrKullaniciService, public translateService: TranslateService) {
    this.translateService.addLangs(["tr", "en"]);
  }

  public get translationFormTypeScript(): string {
    return this.translateService.instant("example5.fromTypeScript");
  }
  public onChange(selectedLanguage: string): void {
    this.translateService.use(selectedLanguage);
  }

  yukleniyor = false;
  yukleniyorEdevlet = false;
  hataMesaj: string = '';
  stringEdevletTc: string = "";
  yeniKayit: boolean = false;
  hatalar: any = {};
  detayKayitForm = this.formBuilder.group(new OgrKullanici());
  kayitolunuyor = false;
  basariMesaj = "";
  step: number = 1;
  suEposta: string = '';
  dogrulaniyor = false;
  girisForm = new FormGroup({
    'tckimlikno': new FormControl(''),
    'sifre': new FormControl('')
  });

  ngOnInit(): void {
    //document.cookie = 'authYosNeu=';
    localStorage.removeItem('auth');

    var searchstring = window.location.search.substring(0, 500);
    var splitted = searchstring.split("=", 2);
    var control = splitted[0].replace("?", "");
    if (control == "UserUid" && splitted[1]) {
      this.ogrKullaniciService.ogrKullaniciDogrula(splitted[1]).pipe(finalize(() => {
      })).subscribe({
        next: res => {
          if (res?.message && res?.message != "") {
            this.yetkiService.sweetAlertSuccess
              (res?.message)
          };
          this.basariMesaj = res?.message;
          this.hataMesaj = '';
          this.router.navigate(['/giris']);
        },
        error: err => {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
          this.hataMesaj = err.error.message;
          this.basariMesaj = '';
        }
      });
    }
  }

  girisYap() {
    this.yukleniyor = true;
    document.cookie = 'authYosNeu=';
    this.girisService.giris(this.girisForm.controls['tckimlikno'].value, this.girisForm.controls['sifre'].value).subscribe(res => {
      this.hataMesaj = '';
      // localStorage.setItem('auth', JSON.stringify(res.data));

      //document.cookie = 'authYosNeu=' + JSON.stringify(res.data);   
      localStorage.setItem('auth', JSON.stringify(res.data));
      //console.log(document.cookie);
      this.router.navigate(['/']);
    }, err => {
      this.hataMesaj = err.error.message;
      this.basariMesaj = '';
      this.yukleniyor = false;
    });
  }


  

  kayitOl() {


    this.kayitolunuyor = true;
    this.hatalar = {};
    var form = this.detayKayitForm.value;
    form.tanim = form.tanim?.trim();
    const datepipe: DatePipe = new DatePipe('en-US');

    console.log('kayit',form);

    let dogum_tarihi = datepipe.transform(form.dogum_tarihi, 'yyyy-MM-dd');
    form.dogum_tarihi = dogum_tarihi;

    this.ogrKullaniciService.addUpdate(form).pipe(finalize(() => {
    })).subscribe({
      next: res => {
        if (res?.message && res?.message != "") { this.yetkiService.sweetAlertSuccess(res?.message) };
        this.kayitolunuyor = false;
        this.basariMesaj = res?.message;
        this.yetkiService.sweetAlertSuccess(res?.message);
        this.suEposta = '';
        this.hataMesaj = '';
        this.step = 1;
      },
      error: err => {
        this.hatalar = this.validationService.hatalar(err.error.Errors);
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
        }
        this.kayitolunuyor = false;
        this.hataMesaj = err.error?.message;
        this.basariMesaj = '';
      }
    });
  }






 
  sifremiUnuttum() {
    this.dogrulaniyor = true;
    if (this.suEposta?.trim()) {
      this.ogrKullaniciService.sifreUnuttum(this.suEposta).pipe(finalize(() => {
      })).subscribe({
        next: res => {
          this.hataMesaj = "";
          this.basariMesaj = 'Yeni şifreniz mail adresinize gönderildi.';
          this.yetkiService.success('Yeni şifreniz mail adresinize gönderildi.', 1);
          this.step = 1;
          this.dogrulaniyor = false;
        },
        error: err => {
          if (err?.error?.message && err.error?.message != "") { this.hataMesaj = err?.error?.message };
          if (err?.error?.Message && err.error?.Message != "") { this.hataMesaj = err?.error?.Message };
          this.hataMesaj = err.error?.message;
          this.dogrulaniyor = false;
        }
      });
    }
    else {
      this.yetkiService.error('E posta alanı boş geçilemez.', 1);
      this.dogrulaniyor = false;
    }
  }


  divOpenFnc(tip: number) {
    this.step = tip;
  }
}
