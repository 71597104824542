import { SonHareketDurumBilgi } from "./sinavBasvuru.model";
import { TercihDonemTanim } from "./tercihDonemTanim.model";

type Nullable<T> = T | null;
export class TercihBasvuru {
    id: number = 0;
    basvuruNo: string = '';
    kabulMektupNo: string = '';
    ogrKullaniciId: number = 0;
    tercihTanimId: number = 0;
    basvuruTipId: Nullable<number> = null;
    fotoUrl: string = '';
   
    pasaportUrl: string = '';
    pasaportDosyaAdi: string = '';

    adi: string = '';
    soyadi: string = '';
    babaAdi: string = '';
    anneAdi: string = '';
    dogumYeriId?: Nullable<number> = null;
    dogumTarihi?: Nullable<Date> = null;
    cinsiyet: string = '';
    medeniHal: string = '';
    ilkUyrukId: Nullable<number> = null;
    ilkVatandaslikNasilSahipOldu: string = '';
    ikinciUyrukId: Nullable<number> = null;
    ikinciVatandaslikNasilSahipOldu: string = '';

    maviKartUrl: string = '';
    maviKartDosyaAdi: string = '';

    liseOgrenimGorduguUlkedeBulundugunuGosterirBelgeUrl: string = '';
    liseOgrenimGorduguDosyaAdi: string = '';

    telefon1: string = '';
    telefon2: string = '';
    adres: string = '';
    yerlestiMi: boolean = false;
    yerlestirmeTanimId: number = 0;

    yerlestigiFakId: number = 0;
    yerlestigiBolumId: number = 0;
    yerlestigiProgramId: number = 0;

    yerlestigiFakAd: string = '';
    yerlestigiBolAd: string = '';
    yerlestigiProgAd: string = '';
    yerlestirmeNo:number=0;
    yerlestigiTercihSiraNo: number = 0;

    kayitOlduMu: boolean = false;
    kayitProgramTarih?: Nullable<Date> = null;

    liseMezAsamaDiplomaUrl: string = '';
    liseMezAsamaDiplomaDosyaAdi: string = '';

    liseNotDokumuUrl: string = '';
    liseNotDokumuDosyaAdi: string = '';

    liseUlkeId: Nullable<number> = null;
    liseAdi: string = '';
    liseMezuniyetYil: number = 0;
    liseNotSistemId: Nullable<number> = null;
    liseNotSistemAciklama: number=0;

    liseNotOrtalamasi: number = 0;
    sinavTurId: Nullable<number> = null;
    sinavNotSistemId: Nullable<number> = null;
    sinavNotSistemAciklama: number=0;
    sinavPuan: Nullable<number> = null;
    sinavPuanYuzluk:  Nullable<number> = null;
    sinavSonucUrl: string = '';
    sinavSonucDosyaAdi: string = '';
    degerlendirmePuan: Nullable<number> = null;
    tabloKayitTarihi?: Nullable<Date> = null;
    sonGuncellemeTarihi?: Nullable<Date> = null;
    basvuruTamamlanmaDurumu: boolean = false;
    bilgiDogrulukOnayi: boolean = false;
    sonStepNo: number = 1;
    Ip: string = '';
    macAdres: string = '';

    inceleniyor: boolean = false;
    sonInceleyenBilgi: string = '';
    sonInceleyenTc: string = '';

    arsiv?: boolean = false;
}

export class TercihSecilenBirim {
    id: number = 0;
    tercihBasvuruId: number = 0;
    siraNo: number = 0;
    fakId: Nullable<number> = null;
    bolId: Nullable<number> = null;
    programId: Nullable<number> = null;
    fakAdi: string = '';
    bolAdi: string = '';
    progAdi: string = '';
}

export class TercihBasvuruDetay {
    tercihTanim: TercihDonemTanim = new TercihDonemTanim();
    basvuruTipAdi: string = '';
    dogumYerAdi: string = '';
    ilkUyrukAdi: string = '';
    mezunUlkeAdi: string = '';
    notSistemAdi: string = '';
    digerUyrukAdi: string = '';
    sinavTurAdi: string = '';
    sinavNotSistemAdi: string = '';
    tercihSecilenBirimList: TercihSecilenBirim[] = [];
    basvuruBilgi: TercihBasvuru = new TercihBasvuru();
    sonHareketDurum: SonHareketDurumBilgi = new SonHareketDurumBilgi();
    hareketBilgileri: TercihHareket[] = [];
    basvuruNo: string = '';
    ePosta: string = '';
    adi: string = '';
    soyadi: string = '';
    durumAdi: string = '';
    degerlendirmePuan: number = 0;
    sinavPuan: number = 0;
    sinavPuanYuzluk:number=0;
    yerlesmeDurum: boolean = false;
    basvuruTarih: Date = new Date();
    dogumTarih?: Nullable<Date> = null;
    tercihSiraNo: string = '';
    yerlestigiFakAd: string = '';
    yerlestigiBolAd: string = '';
    yerlestigiProgAd: string = '';
    yerlestirmeYayindami: boolean = false;
}


export class TercihHareket {
    id: number = 0;
    tercihBasvuruId: number = 0;
    tercihHareketDurumId: number = 0;
    kayitTarihi: Date = new Date();
    islemYapanTc: string = '';
    islemYapanAdSoyad: string = '';
    ip: string = '';
    aciklama: string = '';
    arsiv: boolean = false;
}

export class TercihHareketDurum {
    id: number = 0;
    siraNo: number = 0;
    durumAdi: string = '';
    kisaltma: string = '';
    iconClass: string = '';
    arsiv: boolean = false;
}

export class TercihBasvurularColumns {
    columnsdegerler1 = [
        {
            title: '#',
            data: 'dataIndex',
            render: function () {
                return null;
            },
        },
        {
            name: "basvuruNo",
            title: 'Basvuru No',
            data: 'basvuruNo',
        },
        {
            name: "ilkUyrukAdi",
            title: 'Uyruk',
            data: 'ilkUyrukAdi',
        },
        {
            name: "adi",
            title: 'Adı',
            data: 'adi',
        },
        {
            name: "soyadi",
            title: 'Soyadı',
            data: 'soyadi',
        },

        {
            name: "sinavPuanYuzluk",
            title: 'Sınav Puan (Yüzlük)',
            data: 'sinavPuanYuzluk',
        },
        {
            name: "degerlendirmePuan",
            title: 'Değerlendirme Puan',
            data: 'degerlendirmePuan',
        },

        {
            name: "yerlesmeDurum",
            title: 'Yerleşme Durumu',
            data: 'yerlesmeDurum',
        },
        {
            name: "durumAdi",
            title: 'Başvuru Durumu',
            data: 'durumAdi',
        },
        {
            name: "basvuruTarih",
            title: 'Başvuru Tarihi',
            data: 'basvuruTarih',
        },

        {
            title: '#',
            data: '',
            render: function () {
                return null;
            },
        },
    ];
}


export class TercihYerlesenlerColumns {
    columnsdegerler = [
        {
            title: '#',
            data: 'dataIndex',
            render: function () {
                return null;
            },
        },
        {
            name: "basvuruNo",
            title: 'Basvuru No',
            data: 'basvuruNo',
        },
        // {
        //     name: "ePosta",
        //     title: 'E-posta',
        //     data: 'ePosta',
        // },
        {
            name: "adi",
            title: 'Adı',
            data: 'adi',
        },
        {
            name: "soyadi",
            title: 'Soyadı',
            data: 'soyadi',
        },
        {
            name: "dogumTarih",
            title: 'Doğum Tarihi',
            data: 'dogumTarih',
        },
        {
            name: "ilkUyrukAdi",
            title: 'Uyruk',
            data: 'ilkUyrukAdi',
        },
        {
            name: "sinavTurAdi",
            title: 'Sınav Türü',
            data: 'sinavTurAdi',
        },
        {
            name: "sinavPuanYuzluk",
            title: 'Sınav Puan Yüzlük',
            data: 'sinavPuanYuzluk',
        },
        {
            name: "degerlendirmePuan",
            title: 'Değerlendirme Puan',
            data: 'degerlendirmePuan',
        },
        {
            name: "yerlestigiFakAd",
            title: 'Fakülte',
            data: 'yerlestigiFakAd',
        },
        {
            name: "yerlestigiBolAd",
            title: 'Bölüm',
            data: 'yerlestigiBolAd',
        },
        {
            name: "yerlestigiProgAd",
            title: 'Program',
            data: 'yerlestigiProgAd',
        },
        {
            name: "tercihSiraNo",
            title: 'Tercih SiraNo',
            data: 'tercihSiraNo',
        },
    ];
}



