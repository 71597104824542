
<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h3 class="d-flex text-dark fw-bolder fs-2 flex-column mb-0">
                    <span><i class="fas fa-file-signature me-2" style="font-size: large; color: #d4af37;"></i>
                        {{this.sinavTanim.tanim }}
                        <span>
                            &nbsp;&nbsp;
                            <i class="fas fa-calendar-alt text-success fs-3"></i>
                            {{this.sinavTanim.sinavTarih | date:'dd.MM.yyyy HH:mm' }} - Giriş Belgesi İşlemleri</span>
                    </span>
                    <!-- <span class="text-muted fs-7 fw-bold mt-2">Alt Başlık</span> -->
                </h3>

            </div>
            <div class="d-flex align-items-center gap-2 gap-lg-3">
                <button type="button" routerLink="/sinav-bilgi" id="kt_modal_new_target_cancel"
                    class="btn btn-danger btn-hover-rise me-3"><i class="fas fa-reply"></i> Geri
                </button>
            </div>
        </div>
    </div>

    <div class="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" class="container-fluid">
            <div class="d-flex align-items-center bg-light-warning rounded p-3 mb-3" style="border: 1px solid #e98da8;">
                <div class="flex-grow-1 me-2 fs-5" style="text-align: justify;">
    
                    <span> <i class="fas fa-exclamation-triangle me fs-5 text-danger me-2"></i>
                        En fazla dil müracaatından başlanarak <b>salon yerleştirme sıra numarasına</b> göre yerleştirme yapılmaktadır.</span>
                </div>
                <span class="fw-bolder text-warning py-1"></span>
            </div>
            <div class="card col-10 offset-1" style="border-top: 3px rgb(146, 199, 224) solid;">
                <div class="card-body" style="width: 100%;">
                    <div class="form-group row mb-2">

                        <label class="col-lg-3 col-form-label text-info fs-6">Yerleştirme tipini seçiniz :
                            <span class="text-danger me-2"> *
                            </span>
                        </label>
                        <div class="col-lg-9">
                            <select [(ngModel)]="yerlestirmeTip" class="form-select" id="yerlestirmeTip"
                                name="yerlestirmeTip" style="border-color: #8fb9d5;">
                                <option value="dilegoresirali">Dile göre sıralı</option>
                                <option value="dilegoreayrisalon">Dile göre ayrı salon</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row mb-2">
                        <label class="col-lg-3 col-form-label text-info fs-6">Sınav Yeri Seçiniz :
                            <span class="text-danger me-2"> *
                            </span></label>
                        <div class="col-lg-9">
                            <select class="form-select" id="sinavYerId" name="sinavYerId" [(ngModel)]="sinavYerId"
                                style="border-color: #8fb9d5;">
                                <option [ngValue]="0">Tümü</option>
                                <option *ngFor="let item of sinavYerler" [ngValue]="item.id">
                                    {{item.ulkeAdi}} /
                                    {{item.adi}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row mb-5">
                        <label class="col-lg-3 col-form-label text-info fs-6">
                        </label>
                        <div class="col-lg-9 text-center">
                            <button *ngIf="yetkiService.yetkiVar(['SinavBasvuru.YerlestirmeYap'])"
                            type="button" class="btn btn-success" [disabled]="yapiliyor"
                                (click)="yerlestirmeYap()" [attr.data-kt-indicator]="yapiliyor ? 'on' : 'off'">
                                <span class="indicator-label"><i class="fas fa-check"></i>Sınav yerleştirmesi yap
                                </span>
                                <span class="indicator-progress">Yerleştirme yapılıyor...
                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                        <span style="font-size: medium;">
                            &nbsp;&nbsp;
                            Yerleştirme durumu : <b>
                                <span class="text-success" *ngIf="sinavTanim.sinavYerlestirmesiYapildiMi, else yerlestirmeYok">
                                    Yerleştirme yapıldı. </span> </b>
                            <ng-template #yerlestirmeYok>
                                <span class="text-danger">Yerleştirme yok.</span>
                            </ng-template>
                        </span>
                        <span style="font-size: medium;">
                            &nbsp;&nbsp;
                            <i class="fas fa-calendar-alt text-success fs-3"></i>
                            Son yerleştirme tarihi : <b> {{this.sinavTanim.yerlestirmeTarihi | date:'dd.MM.yyyy HH:mm'
                                }}</b></span>

                        <div class="col-8 offset-2 text-center mt-6">
                            <span style="font-size: medium;">
                                &nbsp;&nbsp;
                                Giriş belgeleri yayınlanma durumu : <b>
                                    <span class="text-success" *ngIf="sinavTanim.girisBelgeleriYayinlandiMi, else yayinlamaYok">
                                        Giriş belgeleri yayınlandı.</span> </b>
                                <ng-template #yayinlamaYok>
                                    <span class="text-danger">Giriş belgeleri yayınlanmadı.</span>
                                </ng-template>
                            </span>
                            <br>
                            <button  *ngIf="sinavTanim.girisBelgeleriYayinlandiMi==false && yetkiService.yetkiVar(['SinavBasvuru.GirisBelgeYayinla'])" type="button" class="btn btn-primary mt-3" [disabled]="yapiliyor"
                                (click)="girisBelgeYayinla(1)" [attr.data-kt-indicator]="yapiliyor ? 'on' : 'off'">
                                <span class="indicator-label"><i class="far fa-file-pdf fs-3"></i>Giriş belgelerini yayınla
                                </span>
                                <span class="indicator-progress">Yayınlanıyor...
                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>

                            <button *ngIf="sinavTanim.girisBelgeleriYayinlandiMi==true && yetkiService.yetkiVar(['SinavBasvuru.GirisBelgeYayinla'])" type="button" class="btn btn-danger mt-3" [disabled]="yapiliyor"
                            (click)="girisBelgeYayinla(0)" [attr.data-kt-indicator]="yapiliyor ? 'on' : 'off'">
                            <span class="indicator-label"><i class="far fa-file-pdf fs-3"></i>Yayından kaldır
                            </span>
                            <span class="indicator-progress">Kaldırılıyor...
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>