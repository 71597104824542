import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OgrKullanici } from '../models/ogrKullanici.model';
import { Response } from '../models/response.model';




@Injectable({
  providedIn: 'root'
})
export class OgrKullaniciService {
  apiUrl = '/api/ogrkullanici';

  constructor(private http: HttpClient) { 

  }

  getAll(query: string = '', page: number = 1, rowCount: number = 10): Observable<Response<OgrKullanici[]>> {
    return this.http.get<Response<OgrKullanici[]>>(this.apiUrl + '/getList', {
      params: {
        'aramastr': query,
        'sayfasayisi': page,
        'sayfadakisatirsayisi': rowCount
      }
    });
  }

  getListAll(): Observable<Response<OgrKullanici[]>> {
    return this.http.get<Response<OgrKullanici[]>>(this.apiUrl + '/getListAll');
  }

  getById(id: number): Observable<Response<OgrKullanici>> {
    return this.http.get<Response<OgrKullanici>>(this.apiUrl + '/getById?id=' + id);
  }

  delete(ogrkullanici: OgrKullanici): Observable<Response<OgrKullanici>> {
    return this.http.post<Response<OgrKullanici>>(this.apiUrl + '/delete', ogrkullanici)
  }

  ogrKullaniciDogrula(userUid: string): Observable<Response<OgrKullanici>> {
    return this.http.post<Response<OgrKullanici>>(this.apiUrl + '/ogrKullaniciDogrula?UserUid='+userUid,{})
  }

  sifreUnuttum(eposta: string): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/OgrSifremiUnuttum/?Eposta='+eposta,{})
  }

  ogrSifreDegistir(ogrkullanici: OgrKullanici): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/ogrSifreDegistir',ogrkullanici)
  }

  ogrSifreDegistirAdmin(ogrkullanici: OgrKullanici): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/ogrSifreDegistirAdmin',ogrkullanici)
  }


  getGridList(dataTablesParameters: any,jsondata:any): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/ogrKullaniciGridList', dataTablesParameters, {
      params: {
        'jsondata':jsondata
      }
    });
  }



  addUpdate(ogrkullanici: OgrKullanici): Observable<Response<OgrKullanici>> {
    if (ogrkullanici.id > 0) {
      return this.http.post<Response<OgrKullanici>>(this.apiUrl + "/update", ogrkullanici)
    }
    else {
      return this.http.post<Response<OgrKullanici>>(this.apiUrl + "/add", ogrkullanici)
    }
  }
}