import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { finalize } from 'rxjs';
import { AktifSinavBilgiDto, AnasayfaBilgiDto } from 'src/app/models/anasayfaBilgi.model';
import { Kullanici } from 'src/app/models/kullanici.model';
import { GenelDataTableDto, SinavBasvuru, SonHareketDurumBilgi } from 'src/app/models/sinavBasvuru.model';
import { RaporTanim, SinavTanim } from 'src/app/models/sinavTanim.model';
import { TercihBasvuru } from 'src/app/models/tercihBasvuru.model';
import { TercihDonemTanim } from 'src/app/models/tercihDonemTanim.model';
import { AnasayfaBilgiService } from 'src/app/services/anasayfa-bilgi.service';
import { GirisService } from 'src/app/services/giris.service';
import { SinavBasvuruService } from 'src/app/services/sinav-basvuru.service';
import { SinavTanimService } from 'src/app/services/sinav-tanim.service';
import { TercihBasvuruService } from 'src/app/services/tercih-basvuru.service';
import { YetkiService } from 'src/app/services/yetki.service';
import { date } from 'src/assets/plugins/formvalidation/dist/js/locales/ar_MA';
import Swal from 'sweetalert2'


declare var google: any;
declare var bootstrap: any;

@Component({
  selector: 'yonetim-anasayfa',
  templateUrl: './yonetim-anasayfa.component.html',
  styleUrls: ['./yonetim-anasayfa.component.css']
})
export class YonetimAnasayfaComponent implements OnInit {

  constructor(private anasayfaBilgiService: AnasayfaBilgiService, private sinavTanimService: SinavTanimService, private girisService: GirisService, public yetkiService: YetkiService, private sinavBasvuruService: SinavBasvuruService, private tercihBasvuruService: TercihBasvuruService, private router: Router, private sanitizer: DomSanitizer) { }
  anasayfaBilgiDto: AnasayfaBilgiDto = new AnasayfaBilgiDto();
  yukleniyor = false;
  yukleniyor1 = false;

  sinavTanim: SinavTanim = new SinavTanim();
  ogrSinavBasvuru: SinavBasvuru = new SinavBasvuru();

  tercihTanim: TercihDonemTanim = new TercihDonemTanim();
  ogrTercihBasvuru: TercihBasvuru = new TercihBasvuru();

  sonHareketDurum: SonHareketDurumBilgi = new SonHareketDurumBilgi();
  sonHareketDurumTercih: SonHareketDurumBilgi = new SonHareketDurumBilgi();
  kullanici: Kullanici = new Kullanici();
  aliniyor = false;
  aliniyorS = false;
  basvuruSecilen: any;
  base64textString: any;
  tercihBasvuruBelge: any;

  previewUrl: any = this.sanitizer.bypassSecurityTrustResourceUrl("");
  ngOnInit(): void {
    this.getAnasayfaBilgi();
  }


  getAnasayfaBilgi() {
    this.yukleniyor = true;
    this.anasayfaBilgiService.getAnasayfaBilgi(Number(this.girisService.girisBilgi().tckimlikno)).subscribe(res => {
      this.yukleniyor = false;
      this.anasayfaBilgiDto = res.data;

      this.sinavTanim = res.data.aktifSinavBilgiDto?.sinavTanim;
      this.ogrSinavBasvuru = res.data.aktifSinavBilgiDto?.ogrSinavBasvuruBilgi;
      this.sonHareketDurum = res.data.aktifSinavBilgiDto?.sonHareketDurum;

      this.tercihTanim = res.data.aktifTercihDonemDto?.tercihDonemTanim;
      this.ogrTercihBasvuru = res.data.aktifTercihDonemDto?.ogrTercihBasvuruBilgi;
      this.sonHareketDurumTercih = res.data.aktifTercihDonemDto?.tercihSonHareketDurumBilgi;

      this.kullanici = this.girisService.girisBilgi();
    });
  }


  rapor: RaporTanim = new RaporTanim();
  aliniyorTb=false;
  tercihBasvuruBelgesiAl(basvuruid: number) {
    this.aliniyorTb=true;
    this.tercihBasvuruService.getGridListRapor(this.dataTablesParameters, "", this.tercihTanim.id, Number(this.kullanici.tckimlikno), 0, basvuruid).pipe(finalize(() => {
    })).subscribe({
      next: res => {
        this.basvuruSecilen = res.data;
        var json = JSON.stringify(this.basvuruSecilen)
        this.rapor.format = 1;
        this.rapor.data = json;
        this.rapor.kod="UTYD8A4V";
        this.rapor.id = "17"; //rapor id
        this.rapor.name = "NEÜYÖS Tercih başvuru belgesi";
        this.sinavTanimService.download("https://neurapor.erbakan.edu.tr/app/raporlama", this.rapor).pipe(finalize(() => {
          this.aliniyorTb = false;
        })).subscribe({
          next: res1 => {
            this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res1.data.url);
            if (res?.message && res?.message != "") { this.yetkiService.success(res.message, 1) };
            //  window.open(res1.url);
          },
          error: err => {
            if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
              if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
              if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
            }
          }
        });

      },
      error: err => {
      }
    })

  }


  aliniyorKbelge=false;
  kabulBelgesiAl(basvuruid:number) {
    this.aliniyorKbelge = true;
    this.tercihBasvuruService.kabulBelgesiAl(basvuruid).pipe(finalize(() => {
    })).subscribe({
      next: res => {
        // format 0=pdf, 1=html view
        this.rapor.format = 1;
        this.rapor.data = res.data.jsonData;
        this.rapor.id = "21"; //rapor id
        this.rapor.kod="99M7VIKJ";
        this.rapor.name = "NEÜYÖS Kabul Belgesi";
        this.sinavTanimService.download("https://neurapor.erbakan.edu.tr/app/raporlama", this.rapor).pipe(finalize(() => {
          this.aliniyorKbelge = false;
        })).subscribe({
          next: res1 => {
            this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res1.data.url);
            if (res?.message && res?.message != "") { this.yetkiService.success(res.message, 1) };
            //  window.open(res1.url);
          },
          error: err => {
            if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
              if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
              if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
            }
          }
        });

      },
      error: err => {
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
        }
      }
    });
  }
  
  girisBelgesiAl() {
    const datepipe: DatePipe = new DatePipe('en-US');
    let sinavYili = datepipe.transform(this.sinavTanim.sinavTarih, 'YYYY');

    this.aliniyor = true;
    this.sinavTanimService.sinavGirisBelgeAl(this.sinavTanim.id, Number(this.girisService.girisBilgi().tckimlikno)).pipe(finalize(() => {
    })).subscribe({
      next: res => {
        // format 0=pdf, 1=html view
        this.rapor.format = 1;
        this.rapor.data = res.data.jsonData;
        this.rapor.kod="RX4H1VI7";
        this.rapor.id = "2"; //rapor id
        this.rapor.name = "NEÜYÖS " + sinavYili + " Giris Belgesi";
        this.sinavTanimService.download("https://neurapor.erbakan.edu.tr/app/raporlama", this.rapor).pipe(finalize(() => {
          this.aliniyor = false;
        })).subscribe({
          next: res1 => {
            this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res1.data.url);
            if (res?.message && res?.message != "") { this.yetkiService.success(res.message, 1) };
            //  window.open(res1.url);
          },
          error: err => {
            if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
              if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
              if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
            }
          }
        });

      },
      error: err => {
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
        }
      }
    });

  }

  sonucBelgesiAl() {
    this.aliniyorS = true;
    this.sinavTanimService.sonucBelgeAl(this.sinavTanim.id, Number(this.girisService.girisBilgi().tckimlikno)).pipe(finalize(() => {
    })).subscribe({
      next: res => {
        // format 0=pdf, 1=html view
        this.rapor.format = 1;
        this.rapor.data = res.data.jsonData;
        this.rapor.kod="X7SRRW7B";
        this.rapor.id = "3"; //rapor id
        const datepipe: DatePipe = new DatePipe('en-US');
        let sinavYili = datepipe.transform(this.sinavTanim.sinavTarih, 'YYYY');
        this.rapor.name = "NEÜYÖS" + sinavYili + " Sonuç Belgesi";
        this.sinavTanimService.download("https://neurapor.erbakan.edu.tr/app/raporlama", this.rapor).pipe(finalize(() => {
          this.aliniyorS = false;
        })).subscribe({
          next: res1 => {
            this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res1.data.url);
            //  window.open(res1.url);
            if (res?.message && res?.message != "") { this.yetkiService.success(res.message, 1) };
          },
          error: err => {
            if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
              if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
              if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
            }
          }
        });
      },
      error: err => {
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
        }
      }
    });

  }

  dtOptions: any;
  basvuruDetay: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  ayrintiModal(basvuruid: number, tip: string) {
    this.yukleniyor1 = true;
    if (tip == 'sinav') {
      this.basvuruBilgiGetirSinav(basvuruid);
    }
    if (tip == 'tercih') {
      this.basvuruBilgiGetirTercih(basvuruid);
    }
  }

  dataTablesParameters: GenelDataTableDto = new GenelDataTableDto();
  basvuruBilgiGetirSinav(basvuruid: number) {
    document.getElementById('openModalBasvuruAyrintiButton')?.click();
    this.sinavBasvuruService.getGridList(this.dataTablesParameters, "", this.sinavTanim.id, Number(this.kullanici.tckimlikno),basvuruid).pipe(finalize(() => {
      this.yukleniyor1 = false;
    })).subscribe({
      next: res => {

        this.basvuruSecilen = res.data[0];
        this.sinavBasvuruService.dosyaBilgi(basvuruid, 0).subscribe(res => {
          this.base64textString = res?.data?.fileBase64Data;
          this.basvuruSecilen.base64textString = this.base64textString;
        });

      },
      error: err => {
      }
    })
  }

  basvuruBilgiGetirTercih(basvuruid: number) {
    document.getElementById('openModalBasvuruAyrintiTercihButton')?.click();
    this.tercihBasvuruService.getGridList(this.dataTablesParameters, "", this.tercihTanim.id, Number(this.kullanici.tckimlikno), 0, basvuruid).pipe(finalize(() => {
      this.yukleniyor1 = false;
    })).subscribe({
      next: res => {
        // this.basvuruSecilen = res.data.find((p: { basvuruBilgi: { id: number; }; }) => p.basvuruBilgi.id == basvuruid);
        this.basvuruSecilen = res.data[0];
        this.tercihBasvuruService.dosyaBilgi(basvuruid, "profil").subscribe(res => {
          this.base64textString = res?.data?.fileBase64Data;
          this.basvuruSecilen.base64textString = this.base64textString;
          var json = JSON.stringify(this.basvuruSecilen)
          const test = '{"tercihBasvuruBelge":' + json + '}'
        });
      },
      error: err => {
      }
    })
  }
}
