import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { HazirCevap } from 'src/app/models/hazirCevap.model';
import { Iletisim } from 'src/app/models/iletisim.model';
import { HazirCevapService } from 'src/app/services/hazircevap.service';
import { IletisimService } from 'src/app/services/iletisim.service';
import { SinavTanimService } from 'src/app/services/sinav-tanim.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';


@Component({
  selector: 'iletisim-bilgi',
  templateUrl: './iletisim-bilgi.component.html',
  styleUrls: ['./iletisim-bilgi.component.css']
})
export class IletisimBilgiComponent implements OnInit {


  constructor(private iletisimService: IletisimService, public yetkiService: YetkiService, private route: ActivatedRoute, private router: Router, private validationService: ValidationService) { 
    this.iletisimDetayService=iletisimService;
  
  }

  iletisimDetay: Iletisim[] = [];
  iletisimDetayService: IletisimService;
  siliniyor=false;
  @ViewChild('grid') grid?: GridComponent;
  
  ngOnInit(): void {
  
  }
 
  exportexcel(): void {
    let fileName = "Mesaj Bilgi.xlsx"
    var element = document.getElementById('kl_subscriptions_table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
  }

}
