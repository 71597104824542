<div id="kt_body"  style="background-image: url('/assets/image/loginarka.jpg') ;background-color:#A2D9E9 ;min-height: 100%">

    <div class="d-flex flex-column flex-root">
        <!--begin::Authentication - Sign-in -->
        <div class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"  >
            <!--begin::Content-->
            <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                <!--begin::Logo-->
                <a href="/" class="mb-12">
                    <img alt="Logo" src="assets/image/mgv_logo.png" class="h-150px" />
                </a>
                <!--end::Logo-->
                <router-outlet></router-outlet>
            </div>
            <!--end::Content-->
            <!--begin::Footer-->
            <!-- <div class="d-flex flex-center flex-column-auto p-10">
                <div class="d-flex align-items-center fw-bold fs-6">
                    <a href="/" class="text-muted text-hover-primary px-2">Anasayfa</a>
                </div>
            </div> -->
            <!--end::Footer-->
        </div>
        <!--end::Authentication - Sign-in-->
    </div>
</div>