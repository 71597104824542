import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '../models/response.model';
import { YerlestirmeTanim } from '../models/yerlestirmeTanim.model';

@Injectable({
  providedIn: 'root'
})
export class YerlestirmeTanimService {
  apiUrl = '/api/yerlestirmeTanim';

  constructor(private http: HttpClient) { 

  }

  getAll(query: string = '', page: number = 1, rowCount: number = 10,tercihTanimid: number = 0): Observable<Response<YerlestirmeTanim[]>> {
    return this.http.get<Response<YerlestirmeTanim[]>>(this.apiUrl + '/getList', {
      params: {
        'aramastr': query,
        'sayfasayisi': page,
        'sayfadakisatirsayisi': rowCount,
        'tercihTanimid': tercihTanimid,
      }
    });
  }

  getListAll(): Observable<Response<YerlestirmeTanim[]>> {
    return this.http.get<Response<YerlestirmeTanim[]>>(this.apiUrl + '/getListAll');
  }

  getById(id: number): Observable<Response<YerlestirmeTanim>> {
    return this.http.get<Response<YerlestirmeTanim>>(this.apiUrl + '/getById?id=' + id);
  }

  delete(yerlestirmeTanim: YerlestirmeTanim): Observable<Response<YerlestirmeTanim>> {
    return this.http.post<Response<YerlestirmeTanim>>(this.apiUrl + '/delete', yerlestirmeTanim)
  }

  yayinIslem(tanimId:number,tip:number): Observable<Response<YerlestirmeTanim>> {
    return this.http.post<Response<YerlestirmeTanim>>(this.apiUrl + '/yayinIslem?tanimId='+tanimId+'&tip='+tip, {})
  }

  // yerlestirmeYap(yerlestirmeTanim: YerlestirmeTanim): Observable<Response<any>> {
  //   return this.http.post<Response<any>>(this.apiUrl + "/yerlestirmeYap", yerlestirmeTanim)
  // }

  addUpdate(yerlestirmeTanim: YerlestirmeTanim): Observable<Response<YerlestirmeTanim>> {
    if (yerlestirmeTanim.id > 0) {
      return this.http.post<Response<YerlestirmeTanim>>(this.apiUrl + "/update", yerlestirmeTanim)
    }
    else {
      return this.http.post<Response<YerlestirmeTanim>>(this.apiUrl + "/yerlestirmeYap", yerlestirmeTanim)
    }
  }
}