import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HazirCevap } from '../models/hazirCevap.model';
import { Response } from '../models/response.model';

@Injectable({
  providedIn: 'root'
})
export class HazirCevapService {
  apiUrl = '/api/hazircevap';

  constructor(private http: HttpClient) { 

  }

  getAll(query: string = '', page: number = 1, rowCount: number = 10): Observable<Response<HazirCevap[]>> {
    return this.http.get<Response<HazirCevap[]>>(this.apiUrl + '/getList', {
      params: {
        'aramastr': query,
        'sayfasayisi': page,
        'sayfadakisatirsayisi': rowCount
      }
    });
  }


  getById(id: number): Observable<Response<HazirCevap>> {
    return this.http.get<Response<HazirCevap>>(this.apiUrl + '/getById?id=' + id);
  }

  delete(hazircevap: HazirCevap): Observable<Response<HazirCevap>> {
    return this.http.post<Response<HazirCevap>>(this.apiUrl + '/delete', hazircevap)
  }

  addUpdate(hazircevap: HazirCevap): Observable<Response<HazirCevap>> {
    if (hazircevap.id > 0) {
      return this.http.post<Response<HazirCevap>>(this.apiUrl + "/update", hazircevap)
    }
    else {
      return this.http.post<Response<HazirCevap>>(this.apiUrl + "/add", hazircevap)
    }
  }
}