import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '../models/response.model';
import { Iletisim } from '../models/iletisim.model';



@Injectable({
  providedIn: 'root'
})
export class IletisimService {
  apiUrl = '/api/iletisim';

  constructor(private http: HttpClient) { 

  }

  getAll(query: string = '', page: number = 1, rowCount: number = 10): Observable<Response<Iletisim[]>> {
    return this.http.get<Response<Iletisim[]>>(this.apiUrl + '/getList', {
      params: {
        'aramastr': query,
        'sayfasayisi': page,
        'sayfadakisatirsayisi': rowCount
      }
    });
  }

  getListAll(): Observable<Response<Iletisim[]>> {
    return this.http.get<Response<Iletisim[]>>(this.apiUrl + '/getListAll');
  }

  getById(id: number): Observable<Response<Iletisim>> {
    return this.http.get<Response<Iletisim>>(this.apiUrl + '/getById?id=' + id);
  }

  delete(iletisim: Iletisim): Observable<Response<Iletisim>> {
    return this.http.post<Response<Iletisim>>(this.apiUrl + '/delete', iletisim)
  }

  addUpdate(iletisim: Iletisim): Observable<Response<Iletisim>> {
    if (iletisim.id > 0) {
      return this.http.post<Response<Iletisim>>(this.apiUrl + "/update", iletisim)
    }
    else {
      return this.http.post<Response<Iletisim>>(this.apiUrl + "/add", iletisim)
    }
  }
}