<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <loading [loader]="yukleniyorSinav">
        <div class="toolbar mb-5">
            <div class="container-fluid d-flex flex-stack">
                <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                    <h3 class="d-flex text-dark fw-bolder fs-2 flex-column mb-0">
                        <span><i class="fas fa-file-signature me-2" style="font-size: large; color: #d4af37;"></i>
                            {{this.sinavTanim.tanim }}
                            <span>
                                &nbsp;&nbsp;
                                <i class="fas fa-calendar-alt text-success fs-3"></i>
                                {{this.sinavTanim.sinavTarih | date:'dd.MM.yyyy HH:mm' }}</span>
                        </span>
                        <!-- <span class="text-muted fs-7 fw-bold mt-2">Alt Başlık</span> -->
                    </h3>

                </div>

                <div class="d-flex align-items-center gap-2 gap-lg-3">
                    <button type="button" *ngIf="yetkiService.yetkiVar(['Kullanici.Standart'])" routerLink="/"
                        id="kt_modal_new_target_cancel" class="btn btn-danger btn-hover-rise me-3" translate><i
                            class="fas fa-reply"></i> geri
                    </button>

                    <button type="button" *ngIf="yetkiService.yetkiVar(['Formİşlemleri.GetList'])"
                        [routerLink]="['/sinav-basvurular',sinavid]" id="kt_modal_new_target_cancel"
                        class="btn btn-danger btn-hover-rise me-3" translate><i class="fas fa-reply"></i> geri
                    </button>
                </div>

            </div>
        </div>
    </loading>

    <loading [loader]="yukleniyor">
        <div id="kt_create_account_stepper"
            class="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid col-12 col-md-10 offset-md-1 mt-10">
            <div
                class="d-flex justify-content-center bg-white rounded justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9 ">

                <div class="px-6 px-lg-10 px-xxl-15 py-20">
                    <div class="stepper-nav">
                        <div class="stepper-item cursor-pointer" (click)="step=1"
                            [ngClass]="{'completed': step>1, 'current': step==1}">
                            <div class="stepper-line w-40px"></div>
                            <div class="stepper-icon w-40px h-40px"><i class="stepper-check fas fa-check"></i><span
                                    class="stepper-number">1</span></div>
                            <div class="stepper-label">
                                <h3 class="stepper-title">Form Giriş Açıklama</h3>
                                <!-- <div class="stepper-desc fw-bold fs-6">Personal Information</div> -->
                            </div>
                        </div>
                        <div class="stepper-item cursor-pointer" (click)="step=2"
                            [ngClass]="{'completed': step>2, 'current': step==2}">
                            <div class="stepper-line w-40px"></div>
                            <div class="stepper-icon w-40px h-40px"><i class="stepper-check fas fa-check"></i><span
                                    class="stepper-number">2</span></div>
                            <div class="stepper-label">
                                <h3 class="stepper-title">Üye Bilgileri</h3>
                            </div>
                        </div>
                        <div class="stepper-item cursor-pointer" (click)="step=3"
                            [ngClass]="{'completed': step>3, 'current': step==3}">
                            <div class="stepper-line w-40px"></div>
                            <div class="stepper-icon w-40px h-40px"><i class="stepper-check fas fa-check"></i><span
                                    class="stepper-number">3</span></div>
                            <div class="stepper-label">
                                <h3 class="stepper-title">Form Alanlar</h3>
                            </div>
                        </div>
                        <div class="stepper-item cursor-pointer" (click)="step=4"
                            [ngClass]="{'completed': step>4, 'current': step==4}">
                            <div class="stepper-line w-40px"></div>
                            <div class="stepper-icon w-40px h-40px"><i class="stepper-check fas fa-check"></i><span
                                    class="stepper-number">4</span></div>
                            <div class="stepper-label">
                                <h3 class="stepper-title">Form Ön izleme ve Onay</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row-fluid flex-center bg-white rounded">
                <form class="py-10 w-100  px-5  align-self-start" [formGroup]="detayForm"
                    (submit)="kaydetGuncelle(sinavEkAlanlar)">
                    <div class="current">
                        <div formGroupName="sinavBasvuruForm">

                            <div [hidden]="step!=1">
                                <div class="card mb-5 mb-xxl-8">
                                    <div class="card-body pt-9 pb-0">

                                        <div class="card-body pt-9 pb-0 fs-5" [innerHTML]="sinavTanim.aciklama">


                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div [hidden]="step!=2">
                                <div class="card mb-5 mb-xxl-8">
                                    <div class="card-body pt-9 pb-0">
                                        <!--begin::Details-->
                                        <div class="d-flex flex-wrap flex-sm-nowrap">
                                            <!--begin: Pic-->
                                            <div class="flex-grow-1">
                                                <div class="mb-15">
                                                    <div class="form-group row mb-2">
                                                        <label class="col-lg-3 col-form-label text-info  fs-6"
                                                            translate>ad
                                                            <span class="text-danger me-2"> : *
                                                            </span></label>
                                                        <div class="col-lg-9">
                                                            <input type="text" readonly
                                                                class="form-control border-primary"
                                                                oninput="this.value = this.value.toLocaleUpperCase('TR')"
                                                                placeholder="Ad" id="adi" name="adi"
                                                                formControlName="adi" />
                                                            <div class="text-danger"
                                                                *ngFor="let hata of hatalar['Adi']">
                                                                {{hata}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-2">
                                                        <label class="col-lg-3 col-form-label text-info fs-6"
                                                            translate>soyad
                                                            <span class="text-danger me-2">: *</span></label>
                                                        <div class="col-lg-9">
                                                            <input type="text" readonly
                                                                class="form-control border-primary"
                                                                oninput="this.value = this.value.toLocaleUpperCase('TR')"
                                                                placeholder="Soyad" id="soyadi" name="soyadi"
                                                                formControlName="soyadi" />
                                                            <div class="text-danger"
                                                                *ngFor="let hata of hatalar['Soyadi']">
                                                                {{hata}}
                                                            </div>
                                                        </div>
                                                    </div>

                                             
                                                </div>
                                            </div>
                                        </div>

                                                                           </div>
                                </div>
                            </div>

                            <div [hidden]="step!=3">
                                <div class="card mb-5 mb-xxl-8">
                                    <div class="card-body pt-9 pb-0">

                                        <div class="form-group row mb-2"
                                            *ngFor="let itemInput of sinavEkAlanlar;let i=index">
                                            <label class="col-lg-3 col-form-label text-info fs-6">{{itemInput.label}} :
                                                <span class="text-danger me-2" *ngIf="itemInput.zorunluluk">
                                                    *</span></label>
                                            <div class="col-lg-9">
                                                <div *ngIf="itemInput.tip=='Metin'">
                                                    <input type="text" [(ngModel)]="itemInput.deger"
                                                        [ngModelOptions]="{standalone: true}"
                                                        maxlength="{{itemInput.maxUzunluk}}"
                                                        oninput="this.value = this.value.toLocaleUpperCase('TR')"
                                                        class="form-control border-primary" name="value{{i}}"
                                                        placeholder="{{itemInput.label}}" id="cvp{{itemInput.id}}" />
                                                    <div class="text-danger"
                                                        *ngFor="let hata of hatalar['cvp'+itemInput.id]">
                                                        {{hata}}
                                                    </div>
                                                </div>

                                                <div *ngIf="itemInput.tip=='Numeric'">
                                                    <input type="number" min="{{itemInput.minUzunluk}}"
                                                        max="{{itemInput.maxUzunluk}}"
                                                        [ngModelOptions]="{standalone: true}"
                                                        [(ngModel)]="itemInput.deger"
                                                        onKeyPress="if(this.value.length==10)  return false;"
                                                        class="form-control border-primary" name="value{{i}}"
                                                        placeholder="{{itemInput.label}}" id="cvp{{itemInput.id}}" />
                                                    <div class="text-danger"
                                                        *ngFor="let hata of hatalar['cvp'+itemInput.id]">
                                                        {{hata}}
                                                    </div>
                                                </div>

                                                <div *ngIf="itemInput.tip=='Dosya'">
                                                    <input ui-jq="filestyle" class="form-control border-warning"
                                                        type="file" id="file"
                                                        (change)="selectFileDynamic($event,itemInput)" name="file"
                                                        nv-file-select="" accept="{{itemInput.kabulDosyaUzantilari}}"
                                                        uploader="uploader" data-icon="false" style="color: #7239EA;"
                                                        data-classButton="btn btn-default"
                                                        data-classInput="form-control inline v-middle input-s"
                                                        id="cvp{{itemInput.id}}">
                                                    <div class="text-danger"
                                                        *ngFor="let hata of hatalar['cvp'+itemInput.id]">
                                                        {{hata}}
                                                    </div>
                                                    <a class="menu-link cursor-pointer fs-5"
                                                        (click)="dosyaIndir(itemInput.id)">Seçilen dosya(selected file)
                                                        : {{
                                                        itemInput?.fileName
                                                        }}</a>
                                                </div>


                                            </div>
                                        </div>


                                        <div class="text-center  mt-10">

                                            <button *ngIf="step==3" type="submit" class="btn btn-success"
                                                [disabled]="kaydediliyor"
                                                [attr.data-kt-indicator]="kaydediliyor ? 'on' : 'off'">

                                                <span *ngIf="yeni" class="indicator-label" translate><i
                                                        class="fas fa-check"></i>kaydetO</span>
                                                <span *ngIf="!yeni" class="indicator-label" translate><i
                                                        class="fas fa-check"></i>guncelleB</span>

                                                <span class="indicator-progress">Kaydediliyor...
                                                    <span
                                                        class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>


                        <div [hidden]="step!=4">
                            <div class="card-header text-center fs-3 text-info">
                                Form Ön İzleme
                            </div>
                            <div class="col-xl-8 offset-2 mt-3">
                                <div
                                    class="notice d-flex bg-light-success rounded border-danger border border-dashed h-lg-100 p-6">
                                    <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap ">
                                        <div class="mb-3 mb-md-0 fw-bold">
                                            <h3 class="text-danger fw-bolder text-center"
                                                *ngIf="kayitliBasvuruBilgi.basvuruTamamlanmaDurumu==false"><i
                                                    class="fas fa-exclamation-triangle text-warning  fs-3 me-2"></i>
                                                Önemli
                                                Not! Fom girişi henüz tamamlanmadı.</h3>

                                            <h3 class="text-success fw-bolder text-center "
                                                *ngIf="kayitliBasvuruBilgi.basvuruTamamlanmaDurumu"><i
                                                    class="fas fa-check text-success fs-3 me-2"></i> Form girişi tamamlandı.
                                            </h3>
                                            <div class="fs-6  pe-7" *ngIf="kayitliBasvuruBilgi.id==0">
                                                <p style="text-indent: 10px;text-align: justify;" translate>
                                                    onizlemeOnayUyari </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="col-xl-8 offset-2 mt-3">
                                <div class="col-12">
                                    <div class="card card-flush flex-row-fluid " style="border: 1px solid blue;">
                                        <div class="card-body">
                                            <div class="row">
                                                <!-- <div class="form-group ">
                                                    <label class="col-lg-5 col-form-label text-info"
                                                        translate>profilFoto
                                                    </label>
                                                    <label class="col-lg-7 col-form-label"
                                                        *ngIf="this.base64textString; else fotoYok"> <span
                                                            class="mx-3"><i
                                                                class="fas fa-check text-success fs-3"></i></span></label>

                                                    <ng-template #fotoYok>
                                                        <label class="col-lg-7 col-form-label"> :<span class="mx-3"><i
                                                                    class="fas fs-3 fa-check text-danger"></i></span></label>
                                                    </ng-template>
                                                </div> -->
                                              
                                                <div class="form-group ">
                                                    <label class="col-lg-5 col-form-label text-info"
                                                        translate>adSoyad</label>
                                                    <label class="col-lg-7 col-form-label"><span
                                                            class="mx-3">{{kayitliBasvuruBilgi.adi}} &nbsp;
                                                            {{kayitliBasvuruBilgi.soyadi}}</span></label>
                                                </div>
                                               
                                                                                           </div>
                                        </div>
                                    </div>


                                </div>
                                

                                <div class="col-12 mt-5 text-center"
                                    *ngIf="kayitliBasvuruBilgi.bilgiDogrulukOnayi==false">
                                    <input type="checkbox" class="form-check-input me-2" id="bilgiDogrulukOnayi"
                                        [(ngModel)]="kvkkCheck" [ngModelOptions]="{standalone: true}">
                                    <label class="checkbox-lg text-primary text-justify" style="font-size:medium;"
                                        for="exampleCheck1" translate> &nbsp;&nbsp;bilgiOnay</label>
                                </div>
                                <span class="col-12 mt-5 text-center text-danger fs-4"
                                    *ngIf="this.basvuruOnayMsg">{{this.basvuruOnayMsg}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-stack pt-10">

                        <button type="button" *ngIf="step>1" (click)="back()"
                            class="btn btn-danger btn-hover-rise me-3 " translate><i class="fas fa-arrow-left"></i>
                            geri
                        </button>

                        <button *ngIf="step<3 " type="button" (click)="continue()"
                            class="btn btn-primary btn-hover-rise me-3">
                            <span class="indicator-label" translate> devam <i class="fas fa-arrow-right"></i>
                            </span>
                        </button>

                        <button *ngIf="step==3  && kayitliBasvuruBilgi.id > 0" type="button" (click)="continue()"
                            class="btn btn-primary btn-hover-rise me-3">
                            <span class="indicator-label" translate> devam <i class="fas fa-arrow-right"></i>
                            </span>
                        </button>

                        <div *ngIf="step==4 && kayitliBasvuruBilgi.basvuruTamamlanmaDurumu==false && kayitliBasvuruBilgi.id > 0" class="mx-5">
                            <button type="button" class="btn btn-success" (click)="basvuruTamamla()"
                                [attr.data-kt-indicator]="dogrulaniyor ? 'on' : 'off'">
                                <span class="indicator-label"><i class="fas fa-check"></i>Form Girişini Tamamla</span>
                                <span class="indicator-progress">Form girişi tamamlanıyor.
                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>

                        <div *ngIf="step==4 && this.sonHareketDurum.kisaltma=='basvuru-hatali' " class="mx-5">
                            <button type="button" class="btn btn-success" (click)="basvuruTekrarGonder()"
                                [attr.data-kt-indicator]="dogrulaniyor ? 'on' : 'off'">
                                <span class="indicator-label"><i class="fas fa-check"></i>Formu Tekrar Gönder</span>
                                <span class="indicator-progress">Form tamamlanıyor.
                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>


                    </div>
                </form>
            </div>
        </div>
    </loading>
</div>