<div class="post d-flex flex-column-fluid mt-10 mx-10" id="kt_post">
    <div id="kt_content_container">
        <div class="card" style="border-top: 3px rgb(146, 199, 224) solid;">
            <div class="card-header">
                <h3 class="card-title text-black text-center" style="max-width: 850px;">
                    <i class="fa-check fs-3 fas me-2 text-success"></i> Aşağıda sınav sonuç bilgileri verilen öğrenci NEÜYÖS sisteminde kayıtlı olarak
                    bulunmaktadır. / The student whose exam results are given below is registered in the NEÜYÖS system.</h3>
            </div>

            <div class="card-body">
                <iframe id="iframe" name="iframe" frameBorder="10" [src]="previewUrl" marginHeight="0" marginWidth="0"
                    width="100%" height="750px" scrolling="auto">
                </iframe>

            </div>
        </div>
    </div>
</div>