<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    Sınav Tür İşlemleri
                    <!-- <span class="text-muted fs-7 fw-bold mt-2">Alt Başlık</span> -->
                </h1>
            </div>
            <div class="d-flex align-items-center gap-2 gap-lg-3">
                <!-- <a [routerLink]="['yeni']" class="btn btn-sm btn-primary">Yeni Uygulama</a> -->
            </div>
        </div>
    </div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" class="container-fluid">
            <loading [loader]="yukleniyor">
                <form [formGroup]="detayForm" (submit)="kaydetGuncelle()">
                    <div class="card col-12 col-md-8 offset-md-2" style="border-top: 3px #77c2e9 solid;">
                        <div class="card-header">
                            <h3 class="card-title">
                                Yeni Ekle
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="form-floating mb-3">
                                <input formControlName="adi" type="text" class="form-control" id="adi"
                                    oninput="this.value = this.value.toLocaleUpperCase('TR')"
                                    style="border-color: #8fb9d5;text-transform: uppercase;" placeholder="adi"
                                    name="adi" />
                                <label style="color: #009ef7;" class="fs-4" for="adi">Sınav Türü</label>
                                <div class="text-danger" *ngFor="let hata of hatalar['Adi']">
                                    {{hata}}
                                </div>
                            </div>
                          

                            <div class="form-floating mb-3">
                                <input formControlName="katsayi" type="number" class="form-control" id="katsayi"
                                  
                                    style="border-color: #8fb9d5;" placeholder="katsayi"
                                    name="katsayi" />
                                <label style="color: #009ef7;" class="fs-4" for="katsayi">Katsayı</label>
                                <div class="text-danger" *ngFor="let hata of hatalar['Katsayi']">
                                    {{hata}}
                                </div>
                            </div>

                        </div>
                        <div class="row card-footer">
                            <div class="col-md-6 col-6 text-left">
                                <button type="button" [routerLink]="['/sinav-tur',this.tercihid]"
                                    id="kt_modal_new_target_cancel" class="btn btn-danger btn-hover-rise me-3"><i
                                        class="fas fa-reply"></i> Geri
                                </button>
                            </div>
                            <div class="col-md-6 col-6 text-end">
                                <submit-button *ngIf="yetkiService.yetkiVar(['TercihSinavTur.Add'])"
                                [loader]="kaydediliyor" [label]="yeni?'Kaydet':'Güncelle'"
                                    [loading]="yeni?'Kaydediliyor...':'Güncelleniyor...'">
                                </submit-button>
                            </div>
                        </div>


                    </div>

                </form>
            </loading>
        </div>
    </div>
</div>