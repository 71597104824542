<!-- <div class="content d-flex flex-column flex-column-fluid"> -->

<div class="toolbar mb-5" style="height: 8%">
    <div class="container-fluid d-flex flex-stack">
        <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">

            <h1 class="d-flex text-dark fw-bolder fs-2 flex-column mb-0">
                <span style="font-size: medium;">
                    <i class="far fa-map me-2 fs-3" style="color: #d4af37;"></i>Sınav Tür
                    Listesi &nbsp;(
                    {{this.tercihTanim.adi }}
                    <span>
                        &nbsp;
                        <i class="fas fa-calendar-alt text-success fs-3"></i>
                        {{this.tercihTanim.baslangicTarihi | date:'dd.MM.yyyy HH:mm' }}</span>
                    &nbsp;-&nbsp;
                    &nbsp;&nbsp;
                    <i class="fas fa-calendar-alt text-danger fs-3"></i>
                    {{this.tercihTanim.bitisTarihi | date:'dd.MM.yyyy HH:mm' }})</span>
                <!-- <span class="text-muted fs-7 fw-bold mt-2">Alt Başlık</span> -->
            </h1>
            <span class="fs-3">
                &nbsp;&nbsp;
                <i class="fas fa-exclamation-triangle text-danger fs-3"></i>
                Yapılan işlemler var olan tercih başvurularını etkileyebilir.</span>
        </div>

        <div class="d-flex align-items-center gap-2 gap-lg-3">
            <button type="button" routerLink="/tercih-donem-bilgi" id="kt_modal_new_target_cancel"
            class="btn btn-danger btn-hover-rise me-3"><i class="fas fa-reply"></i> Geri
        </button>
            <button class="btn btn-sm  btn-shadow-hover btn-secondary fs-6  border border-primary"
                (click)="exportexcel()" style="color: green;">
                <i class="fas fa-file-excel " style="color: green;"></i>
                Excel
            </button>
            <a *ngIf="yetkiService.yetkiVar(['TercihSinavTur.Add'])"
            class="btn btn-sm  btn-shadow-hover fs-6" [routerLink]="['/sinav-tur-islem','yeni']"
                [queryParams]="{tercihid: tercihTanimid}" style="background-color: #77c2e9; color: white;">
                <i class="fas fa-plus" style="color: white;"></i>
                <!--end::Svg Icon-->Yeni Sınav Türü Ekle
            </a>
        </div>
    </div>
</div>

<div class="post d-flex flex-column-fluid mt-10" id="kt_post">
    <div id="kt_content_container" class="container-fluid">
        <grid #grid [service]="sinavTurDetayService" [id]="tercihTanimid" [(data)]="sinavTurDetay" [rowCount]="10">
            <div style="width: 100%;overflow-x: auto;height: 500px;">
                <table class="table   table-responsive gn-middle table-row-bordered fs-7 gy-2"
                    id="kl_subscriptions_table">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 " style="color:#cd7f32">
                            <th class="text-center" style="width: 5%;">#</th>
                            <th class="text-center">Adi</th>
                            <th class="text-center">Katsayi</th>
                            <th class="text-center">Not Sistemi</th>
                            <th class="text-center">#</th>
                        </tr>
                    </thead>
                    <tbody class="text-gray-600 ">
                        <!-- dblclick -->
                        <tr *ngFor="let item of sinavTurDetay;let i=index;" style="color: black;">
                            <td class="text-info text-left text-center fs-5" style="vertical-align: middle;">
                                <b>{{(i+1) + (grid.page-1)*10}}</b>
                            </td>
                            <td class="text-center fs-5" style="vertical-align: middle;">
                                {{ item.adi }}
                            </td>
                            <td class="text-center fw-bold fs-5" style="vertical-align: middle;">
                                {{ item.katsayi }}
                            </td>
                            <td class="text-center fw-bold fs-5" style="vertical-align: middle;">
                                {{ item.notSistemi }}
                            </td>

                            <!-- [queryParams]="{basvuruid: 15}" -->
                            <td class="text-center" style="vertical-align: middle;">
                                <div class="d-flex justify-content-center flex-shrink-0">
                                    <a *ngIf="yetkiService.yetkiVar(['TercihSinavTur.Update'])" [routerLink]="['/sinav-tur-islem',item.id]"
                                        [queryParams]="{tercihid: tercihTanimid}" style="background-color:#009ef8"
                                        class="btn btn-icon btn-hover-rise  btn-active-color-primary btn-sm me-1"
                                        title="Sınav türü düzenle">
                                        <i class="far fa-edit text-white"></i>
                                    </a>
                                    
                                    <button *ngIf="yetkiService.yetkiVar(['TercihSinavTur.Delete'])" type="button" style="background-color:#f1416c;" (click)="silModal(item)"
                                        class="btn btn-icon btn-hover-rise btn-active-color-danger btn-sm"
                                        title="Sınav türü sil" data-bs-toggle="modal" data-bs-target="#sil_modal">
                                        <i class="fas fa-trash-alt text-white"></i>
                                    </button>

                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </grid>
    </div>
</div>