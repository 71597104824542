import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { finalize } from 'rxjs';
import { HazirCevap } from 'src/app/models/hazirCevap.model';
import { SinavEkAlan } from 'src/app/models/sinavEkAlan.model';
import { HazirCevapService } from 'src/app/services/hazircevap.service';
import { SinavBasvuruService } from 'src/app/services/sinav-basvuru.service';
import { SinavOdemeBilgiService } from 'src/app/services/sinavOdemeBilgi.service';
import { YetkiService } from 'src/app/services/yetki.service';
import { SinavBasvurularComponent } from '../../sinav-basvurular/sinav-basvurular.component';
declare var bootstrap: any;

@Component({
  selector: 'ogr-basvuru-ayrinti',
  templateUrl: './ogr-basvuru-ayrinti.component.html',
  styleUrls: ['./ogr-basvuru-ayrinti.component.css']
})
export class OgrBasvuruAyrintiComponent implements OnInit {

  constructor(private sinavBasvuruService: SinavBasvuruService, 
    private odemeBilgiService: SinavOdemeBilgiService, 
    public yetkiService: YetkiService, 
    private sanitizer: DomSanitizer,
    private hazirCevapService: HazirCevapService) { }
  @Input() data: any;

  @Output() myevent: EventEmitter<any> = new EventEmitter();
  kaydediliyor: boolean = false;

  @ViewChild('sinav-basvurular') sinavBasvuruComponent?: SinavBasvurularComponent;
  //pathReportString: any;
  ngOnInit(): void {
    this.data.pathReportString=this.sanitizer.bypassSecurityTrustResourceUrl("");
    this.hazirCevapList();
    this.cevapPasiflik=true;
  }

  dekontIndirOdemeDekont(basvuruid: number) {
    this.odemeBilgiService.dosyaBilgi(basvuruid).subscribe(res => {
      var blob = this.sinavBasvuruService.dataURItoBlob(res.data?.fileBase64Data);
      const imageFile = new File([blob], res.data?.fileName, { type: res.data?.contentType });
      var objectUrl = URL.createObjectURL(imageFile);
      var fileLink = document.createElement('a');
      fileLink.href = objectUrl;
      fileLink.download = res.data.fileName;
      fileLink.click();
    });
  }


  dosyaIndirEkAlan(basvuruid: number, ekalanid: number = 0) {
    this.sinavBasvuruService.dosyaBilgi(basvuruid, ekalanid).subscribe(res => {
      var blob = this.sinavBasvuruService.dataURItoBlob(res.data?.fileBase64Data);
      const imageFile = new File([blob], res.data?.fileName, { type: res.data?.contentType });
      var objectUrl = URL.createObjectURL(imageFile);
      var fileLink = document.createElement('a');
      fileLink.href = objectUrl;
      fileLink.download = res.data.fileName;
      fileLink.click();
    });
  }


  
  dosyaBilgiEkAlan(basvuruid: number, ekalanid: number = 0, dosyaAd: string) {
    this.data.dosyaAd = dosyaAd;
   
    this.sinavBasvuruService.dosyaBilgi(basvuruid, ekalanid).subscribe(res => {
      var blob = this.sinavBasvuruService.dataURItoBlob(res.data?.fileBase64Data);
      const imageFile = new File([blob], res.data?.fileName, { type: res.data?.contentType });
      this.data.pathReportString = this.sanitizer.bypassSecurityTrustResourceUrl('data:' + res.data?.contentType + ';base64,' + res.data?.fileBase64Data);
    });
  }

  hazirCevaplar: HazirCevap[] = [];
  hazirCevapList() {
    this.hazirCevapService.getAll('', 1, 1000).subscribe(res => {
      this.hazirCevaplar = res.data;
    });
  }



  dosyaBilgiDekont(basvuruid: number, dosyaAd: string) {
    this.data.dosyaAd = dosyaAd;
    this.odemeBilgiService.dosyaBilgi(basvuruid).subscribe(res => {
      var blob = this.sinavBasvuruService.dataURItoBlob(res.data?.fileBase64Data);
      const imageFile = new File([blob], res.data?.fileName, { type: res.data?.contentType });
      this.data.pathReportString = this.sanitizer.bypassSecurityTrustResourceUrl('data:' + res.data?.contentType + ';base64,' + res.data?.fileBase64Data);
    });
  }

  cevapPasiflik = true;
  cevapYaz(gelen: string) {
    if (gelen == 'Diğer') {
      this.cevapPasiflik = false;
      this.data.sonHareketDurum.islemAciklama = '';
    }
    else {
      this.cevapPasiflik = true;
      this.data.sonHareketDurum.islemAciklama = gelen;
    }
  }

  isBlank(str: string) {
    return (!str || /^\s*$/.test(str));
  }

  @Output() puanDegistir = new EventEmitter<any>();
  islemKaydet(data: any) {
    this.kaydediliyor = true;

    var kontrol = ((data.sonHareketDurum.kisaltma == 'basvuru-hatali' || data.sonHareketDurum.kisaltma == 'basvuru-reddedildi') && this.isBlank(data.sonHareketDurum.islemAciklama) == true
    ) ? true : false;

    if (kontrol == false) {
      this.sinavBasvuruService.basvuruSonucKaydet(data).pipe(finalize(() => {
        this.kaydediliyor = false;
      })).subscribe({
        next: res => {
          if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };

          bootstrap.Modal.getInstance(document.getElementById('basvuru_ayrinti_modal')).hide();
          this.myevent.emit();

          // this.getOylamaMaddeDetayBySenatoId(this.senatoid);
        },
        error: err => {
          if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
            if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
            if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
          }
        }
      });
    }
    else {
      this.kaydediliyor = false;
      this.yetkiService.sweetAlertError("Lütfen red nedenini belirtiniz.")
    }
  }

}
