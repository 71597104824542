export class SinavYer {
    id:number=0;
    sinavTanimId:number=0;
    ulkeId:number=0;
    ulkeAdi:string='';
    adi:string='';
    binaSayisi:number=0;
    arsiv:boolean=false;
}

export class Bina {
    id:number=0;
    sinavYerid:number=0;
    adi:string='';
    adres:string='';
    salonSayisi:number=0;
    arsiv:boolean=false;
}

export class Salon {
    id:number=0;
    binaId:number=0;
    kontenjan:number=0;
    yerlestirmeSiraNo:number=0;
    sinavaGirecekOgrenciSayisi:number=0;
    adi:string='';
    adres:string='';
    arsiv:boolean=false;
}



export class SalonTasimaIslemDto {
    salonId:number=0;
    eskiSalonId:number=0;
    basvurularId: number[] = []; 
}

