import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { SinavEkAlan } from 'src/app/models/sinavEkAlan.model';
import { SinavTanim } from 'src/app/models/sinavTanim.model';
import { SinavEkAlanService } from 'src/app/services/sinav-ek-alan.service';
import { SinavTanimService } from 'src/app/services/sinav-tanim.service';
import { YetkiService } from 'src/app/services/yetki.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'sinav-ek-alan-bilgi',
  templateUrl: './sinav-ek-alan-bilgi.component.html',
  styleUrls: ['./sinav-ek-alan-bilgi.component.css']
})
export class SinavEkAlanBilgiComponent implements OnInit {

  constructor(private sinavTanimService: SinavTanimService,private sinavEkAlanService: SinavEkAlanService,private router: Router,private route: ActivatedRoute,public yetkiService: YetkiService) {
    this.sinavEkAlanDetayService=sinavEkAlanService;

   }
  sinavTanim:SinavTanim=new SinavTanim();

  sinavEkAlanDetay: SinavEkAlan[] = [];
  sinavEkAlanDetayService: SinavEkAlanService;
  sinavid:number=0;
  @ViewChild('grid') grid?: GridComponent;
  
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.sinavid = params['id'];
        this.getSinavTanimById(this.sinavid)
      }
    });
  }


  getSinavTanimById(id: number) {
    this.sinavTanimService.getById(id).subscribe(res => {
      this.sinavTanim = res.data;
    }, err => {
      this.router.navigateByUrl('/')
    },);
  }

  silModal(sinavEkAlan: SinavEkAlan) {
    Swal.fire({
      title: 'Sınav ek alan bilgilerini pasife almak istediğinize eminmisiniz ? <span class=text-danger>( İşlem geri alınamaz. )</span>',
      text: sinavEkAlan.label,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sil',
      cancelButtonText: 'İptal',
    }).then((result) => {
      if (result.value) {
        this.sinavEkAlanService.delete(sinavEkAlan).subscribe(response => {
          this.grid?.load();
          this.yetkiService.success("Sınav ek alan bilgileri başarıyla pasife alındı.", 1)
        }, error => {
          this.yetkiService.error("Hata oluştu.", 1)
        });
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("İptal edildi.", "Silme işlemi iptal edildi.", 'error')
      }
    })

  }


}
