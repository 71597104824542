import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '../models/response.model';
import { TercihSecilenBirim } from '../models/tercihBasvuru.model';



@Injectable({
  providedIn: 'root'
})
export class TercihSecilenBirimService {
  apiUrl = '/api/tercihSecilenBirim';

  constructor(private http: HttpClient) { 

  }

  getAll(query: string = '', page: number = 1, rowCount: number = 10,basvuruid: number = 0): Observable<Response<TercihSecilenBirim[]>> {
    return this.http.get<Response<TercihSecilenBirim[]>>(this.apiUrl + '/getList', {
      params: {
        'aramastr': query,
        'sayfasayisi': page,
        'sayfadakisatirsayisi': rowCount,
        'basvuruid': basvuruid
      }
    });
  }

  tercihSiraNoDegistir(tercihSecilenBirimler: TercihSecilenBirim[]): Observable<Response<any[]>> {
    return this.http.post<Response<any>>(this.apiUrl + "/tercihSiraNoDegistir", tercihSecilenBirimler)
  }


  getById(id: number): Observable<Response<TercihSecilenBirim>> {
    return this.http.get<Response<TercihSecilenBirim>>(this.apiUrl + '/getById?id=' + id);
  }

  delete(tercihSecilenBirim: TercihSecilenBirim): Observable<Response<TercihSecilenBirim>> {
    return this.http.post<Response<TercihSecilenBirim>>(this.apiUrl + '/delete', tercihSecilenBirim)
  }



  addUpdate(tercihSecilenBirim: TercihSecilenBirim): Observable<Response<TercihSecilenBirim>> {
    if (tercihSecilenBirim.id > 0) {
      return this.http.post<Response<TercihSecilenBirim>>(this.apiUrl + "/update", tercihSecilenBirim)
    }
    else {
      return this.http.post<Response<TercihSecilenBirim>>(this.apiUrl + "/add", tercihSecilenBirim)
    }
  }
}