import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { finalize } from 'rxjs';
import { OgrKullanici } from 'src/app/models/ogrKullanici.model';
import { SinavBasvuruDetay, SinavBasvurularColumns } from 'src/app/models/sinavBasvuru.model';
import { RaporTanim, SinavTanim } from 'src/app/models/sinavTanim.model';
import { SinavYer } from 'src/app/models/sinavYer.model';
import { Ulke } from 'src/app/models/ulke.model';
import { GirisService } from 'src/app/services/giris.service';
import { OgrKullaniciService } from 'src/app/services/ogrKullanici.service';
import { SinavBasvuruService } from 'src/app/services/sinav-basvuru.service';
import { SinavTanimService } from 'src/app/services/sinav-tanim.service';
import { SinavYerService } from 'src/app/services/sinav-yer.service';
import { UlkeService } from 'src/app/services/ulke.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
declare var bootstrap: any;
@Component({
  selector: 'sinav-basvurular',
  templateUrl: './sinav-basvurular.component.html',
  styleUrls: ['./sinav-basvurular.component.css']
})

export class SinavBasvurularComponent implements OnInit {

  constructor(private sinavBasvuruService: SinavBasvuruService, public sinavTanimService: SinavTanimService, public yetkiService: YetkiService, private route: ActivatedRoute, private router: Router, private ulkeService: UlkeService, private sinavYerService: SinavYerService,  private sanitizer: DomSanitizer, private ogrKullaniciService: OgrKullaniciService, private formBuilder: FormBuilder, private validationService: ValidationService,private girisService:GirisService) { this.service = sinavBasvuruService }
  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  sinavid: number = 0;
  dtOptions: any = {};
  sinavTanim: SinavTanim = new SinavTanim();
  basvuruSecilen: any;

  manuelSifre: boolean = false;
  ulkeler: Ulke[] = [];
  sinavYerler: SinavYer[] = [];
  tumBasvurular: SinavBasvuruDetay[] = [];
  bavuruTamamlanmayanGoster = true;
  base64textString: string = '';
  yeni = false;
  columnclass: SinavBasvurularColumns = new SinavBasvurularColumns();
  ogrKullaniciForm = this.formBuilder.group(new OgrKullanici());
  kaydediliyor = false;
  kulTc:string='';
  previewUrl: any = this.sanitizer.bypassSecurityTrustResourceUrl("");
  aramaClass:any={ basvuruTamamlamaDurum: true };
  hatalar: any = {};
  ngOnInit(): void {
 this.kulTc=this.girisService.girisBilgi().tckimlikno;
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.sinavid = params['id'];
        this.basvuruList();
        this.ulkeList();
        this.getSinavTanimById();
        this.sinavYeriList();
        this.sinavBasvuruListAllExcel();
      }
    });
  }


  getSinavTanimById() {
    this.yukleniyor = true;
    this.sinavTanimService.getById(this.sinavid).subscribe(res => {
      this.sinavTanim = res.data;
    }, err => {
      this.router.navigateByUrl('/')
    },);
  }

  tamamlanmayanlar() {
    this.aramaClass.basvuruTamamlamaDurum=this.bavuruTamamlanmayanGoster;
    this.gridYenile();
  }

  ngAfterViewInit(): void {

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      const that = this;
      $('.search-input').on('keyup change', function (evt) {
        if (evt.keyCode == 13 || evt.currentTarget.id == '7' || evt.currentTarget.id == '5' || evt.currentTarget.id == '8' || evt.currentTarget.id == '6') {
          dtInstance
            .column(this['id'])
            .search($(this).val() + "")
            .column(5).search($('#5').find(":selected").val() + '').column(7).search($('#7').find(":selected").val() + '').column(8).search($('#8').find(":selected").val() + '').column(6).search($('#6').find(":selected").val() + '').draw();
        }
      });
    });
  }

  basvuruDetay: SinavBasvuruDetay[] = [];
  service: SinavBasvuruService;
  yukleniyor = false;
  pageNo:number=0;
  basvuruList() {
    this.yukleniyor = true;
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      orderMulti: true,
      dom: 'lrtip',

      ajax: (dataTablesParameters: any, callback: (arg0: { recordsTotal: any; recordsFiltered: any; data: never[]; }) => void) => {
        this.sinavBasvuruService.getGridList(dataTablesParameters, JSON.stringify(this.aramaClass), this.sinavid).pipe(finalize(() => {
          this.yukleniyor = false;
        })).subscribe({
          next: res => {
            that.basvuruDetay = res.data;
            this.pageNo= (Number(dataTablesParameters.start) / Number(dataTablesParameters.length))+1;
            callback({
              recordsTotal: res.recordCount,
              recordsFiltered: res.recordCount,
              data: res.data
            });
          },
          error: err => {
          }
        });
      },
      columns: this.columnclass.columnsdegerler,
      language: {
        url: '/assets/image/Turkish.json',
      },
      responsive: true,
    };
  };

  gridYenile(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }


  inceleniyor=false;
  ayrintiModal(basvuruSecilen: any) {
    this.inceleniyor=true;
    this.sinavBasvuruService.incelemeBaslat(basvuruSecilen.basvuruBilgi.id).pipe(finalize(() => {
    })).subscribe({
      next: res => {
        this.sinavBasvuruService.dosyaBilgi(basvuruSecilen.basvuruBilgi.id, 0).subscribe(res => {
          this.base64textString = res?.data?.fileBase64Data;
          basvuruSecilen.base64textString = this.base64textString;
          this.basvuruSecilen = basvuruSecilen;
          document.getElementById('openAyrintiModalButton')?.click();
          this.gridYenile();
        });
      },
      error: err => {            
        this.hatalar = this.validationService.hatalar(err.error.Errors);
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertWarning(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertWarning(err.error?.Message) };
        }
      }
    });
  }

  ulkeList() {
    this.ulkeService.getAll('', 1, 300).subscribe(res => {
      this.ulkeler = res.data;
    });
  }

  sinavYeriList() {
    this.sinavYerService.getAll('', 1, 300, this.sinavid).subscribe(res => {
      this.sinavYerler = res.data;
    });
  }

  
  sinavBasvuruListAllExcel() {
    this.sinavBasvuruService.getListAll(this.sinavid).subscribe(res => {
      this.tumBasvurular = res.data;
    });
  }


  exportexcel(): void {
    const currentYear = new Date('' + this.sinavTanim.sinavTarih);
    let fileName = currentYear.getFullYear() + " Form Kayıtları.xlsx"
    var element = document.getElementById('kl_tumbasvurular_table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '' + currentYear.getFullYear() + " Yös Başvuruları");
    XLSX.writeFile(wb, fileName);
  }


  incelemeBitir(basvuruSecilen: any) {
    this.sinavBasvuruService.incelemeBitir(basvuruSecilen.basvuruBilgi.id).pipe(finalize(() => {
    })).subscribe({
      next: res => {
        if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
          this.gridYenile();
      },
      error: err => {
        this.hatalar = this.validationService.hatalar(err.error.Errors);
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
        }
      }
    });


  }

  belgeTip='';
  aliniyor=false;
  rapor: RaporTanim = new RaporTanim();
  
  girisBelgesiAl(ogrId:number) {
    this.belgeTip='Sınav Giriş Belgesi / Exam Entry Document';
    this.aliniyor = true;
    this.sinavTanimService.sinavGirisBelgeAl(this.sinavTanim.id, ogrId).pipe(finalize(() => {
    })).subscribe({
      next: res => {
        // format 0=pdf, 1=html view
        this.rapor.format = 1;
        this.rapor.data = res.data.jsonData;
        this.rapor.id = "2"; //rapor id
        this.rapor.kod="RX4H1VI7";
        this.rapor.name = 'Giris';
        this.sinavTanimService.download("https://neurapor.erbakan.edu.tr/app/raporlama", this.rapor).pipe(finalize(() => {
          this.aliniyor = false;
        })).subscribe({
          next: res1 => {
            this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res1.data.url);
            if (res?.message && res?.message != "") { this.yetkiService.success(res.message, 1) };
            //  window.open(res1.url);
          },
          error: err => {
            if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
              if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
              if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
            }
          }
        });

      },
      error: err => {
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
        }
      }
    });

  }


}



