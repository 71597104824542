import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '../models/response.model';
import { Ulke } from '../models/ulke.model';



@Injectable({
  providedIn: 'root'
})
export class UlkeService {
  apiUrl = '/api/ulke';

  constructor(private http: HttpClient) { 

  }

  getAll(query: string = '', page: number = 1, rowCount: number = 10): Observable<Response<Ulke[]>> {
    return this.http.get<Response<Ulke[]>>(this.apiUrl + '/getList', {
      params: {
        'aramastr': query,
        'sayfasayisi': page,
        'sayfadakisatirsayisi': rowCount
      }
    });
  }

  getListAll(): Observable<Response<Ulke[]>> {
    return this.http.get<Response<Ulke[]>>(this.apiUrl + '/getListAll');
  }

  getById(id: number): Observable<Response<Ulke>> {
    return this.http.get<Response<Ulke>>(this.apiUrl + '/getById?id=' + id);
  }

  delete(ulke: Ulke): Observable<Response<Ulke>> {
    return this.http.post<Response<Ulke>>(this.apiUrl + '/delete', ulke)
  }


  addUpdate(ulke: Ulke): Observable<Response<Ulke>> {

    if (ulke.id > 0) {
      return this.http.post<Response<Ulke>>(this.apiUrl + "/update", ulke)
    }
    else {
      return this.http.post<Response<Ulke>>(this.apiUrl + "/add", ulke)
    }
  }
}