<!--begin::Footer-->
<div class="footer py-2 d-flex flex-lg-column" id="kt_footer" style="border-top: 1px solid #d4f2fa;position: fixed;width: 100%;bottom: 0;">
    <!--begin::Container-->
    <div
        class="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
        <!--begin::Copyright-->
        <div class="text-dark order-2 order-md-1">
            <span class="text-muted fw-bold me-1">2023 &copy;</span>
            <a href="https://www.alvinyazilim.com.tr" target="_blank"
                class="text-gray-800 text-hover-primary">Alvin Yazılım</a>
                 & 
                <a href="https://devayazilim.com.tr" target="_blank"
                class="text-gray-800 text-hover-primary">Deva Yazılım</a>
        </div>
        <!--end::Copyright-->
        <!--begin::Menu-->
      
        <!--end::Menu-->
    </div>
    <!--end::Container-->
</div>
<!--end::Footer-->