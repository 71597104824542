<div id="kt_content_container" class="container-xxl mb-0">
  <div class="card">
    <div class="card-body">
      <div class="stepper stepper-links d-flex flex-column pt-0">
        <div class="stepper-nav mb-0">
          <div [ngClass]="{'current': aktifsayfa === 1}" class="stepper-item cursor-pointer"
            data-kt-stepper-element="nav" (click)="aktifsayfa=1">
            <h3 class="stepper-title" translate>basvuruBaslik</h3>
          </div>
          <!-- <div [ngClass]="{'current': aktifsayfa === 2}" class="stepper-item cursor-pointer"
            data-kt-stepper-element="nav" (click)="aktifsayfa=2">
            <h3 class="stepper-title">Tercih Edilen Birimler</h3>
          </div> -->
        </div>
        <div [ngClass]="{'current': aktifsayfa === 1}" data-kt-stepper-element="content"
          style="flex-direction: column ;">
          <!-- BURAYI SİL -->
          <hr>
          <loading [loader]="data?.basvuruBilgi.id==0">
            <div class="row">
              <div class="col-md-9">
                <h3 class="font-size-lg text-dark font-weight-bold mb-6"> <i class="fas fa-user me-2 text-success"
                    style="font-size: large; padding-right:2px;"></i>Kişisel Bilgiler / Personal Information
                  <span style="float: right;padding: 0px 0;" *ngIf="yetkiService.yetkiVar(['TercihBasvuru.Update'])">
                    <a  target="_blank" target="_blank" class="btn btn-primary btn-sm me-1" (click)="modalKapat()"
                      [routerLink]="['/ogr-tercih-basvuru',data?.basvuruBilgi.tercihTanimId]"
                      [queryParams]="{basvuruid: data?.basvuruBilgi.id,step987:2}" title="DÜZENLE">
                      Düzenle
                    </a>
                  </span>
                </h3>

                <hr>
                <div class="row  mb-4">
                  <label class="col-lg-4 fs-5 fw-bold text-info" translate>tcNo</label>
                  <div class="col-lg-8">
                    <span class="fs-5 text-gray-800 me-2">{{data?.basvuruBilgi?.tC_YU_PasaportNo}}
                      <span class="badge text-black " style="border: 1px solid blue;"><i
                          class="fas fa-mobile-alt me-2 text-primary  fs-2"></i>{{data?.basvuruBilgi?.telefon1}} -
                        {{data?.basvuruBilgi?.telefon2}}</span>
                    </span>

                  </div>

                </div>
                <div class="row  mb-4">
                  <label class="col-lg-4 fs-5 fw-bold text-info" translate>adayNo<span>/</span><span
                      translate>adSoyad</span></label>
                  <div class="col-lg-8">
                    <span class="fs-5 text-gray-800 me-2">{{data.basvuruNo}} / {{data.adi}}&nbsp;{{data.soyadi}}
                      &nbsp; <span class="badge badge-warning fs-6">&nbsp;{{data?.basvuruBilgi?.cinsiyet}}
                      </span>
                    </span>
                  </div>
                </div>
                <div class="row  mb-4">
                  <label class="col-lg-4 fs-5 fw-bold text-info" translate>babaadi</label>
                  <div class="col-lg-4">
                    <span class="fs-5 text-gray-800">
                      {{data?.basvuruBilgi.babaAdi}}</span>
                  </div>
                  <label class="col-lg-2 fs-5 fw-bold text-info" translate>anneadi</label>
                  <div class="col-lg-2">
                    <span class="fs-5 text-gray-800">
                      {{data?.basvuruBilgi.anneAdi}}</span>
                  </div>
                </div>
                <div class="row  mb-4">
                  <label class="col-lg-4  fs-5 fw-bold text-info" translate>dogumTarih/Yer</label>
                  <div class="col-lg-8 fv-row">
                    <span class="text-gray-800 fs-5 me-4">{{data?.basvuruBilgi?.dogumTarihi | date:'dd.MM.yyyy'}} /
                      {{data.dogumYerAdi}} </span>
                  </div>
                </div>
                <div class="row  mb-4">
                  <label class="col-lg-4  fs-5 fw-bold text-info" translate>medenihal</label>
                  <div class="col-lg-8 fv-row">
                    <span class="text-gray-800 fs-5 me-4">{{data?.basvuruBilgi?.medeniHal}} </span>
                  </div>
                </div>
                <div class="row  mb-4">
                  <label class="col-lg-4 fs-5  fw-bold text-info"><span translate>ilkUyruk</span>/ <span
                      translate>nasilSahipOldu</span></label>
                  <div class="col-lg-8 fv-row">
                    <span class="text-gray-800 fs-5 me-4">{{data.ilkUyrukAdi }} /
                      {{data?.basvuruBilgi?.ilkVatandaslikNasilSahipOldu}} </span>
                  </div>
                </div>
                <div class="row  mb-4">
                  <label class="col-lg-4 fs-5 fw-bold text-info"><span translate>digerUyruk</span>/ <span
                      translate>nasilSahipOldu</span></label>
                  <div class="col-lg-8 fv-row">
                    <span class="text-gray-800 fs-5 me-4">{{data.digerUyrukAdi }} /
                      {{data?.basvuruBilgi?.ikinciVatandaslikNasilSahipOldu}} </span>
                  </div>
                </div>
                <div class="row  mb-4">
                  <label class="col-lg-4  fs-5 fw-bold text-info" translate>basvuruTarih</label>
                  <div class="col-lg-8">
                    <span class="fs-5 text-gray-800 me-2 fw-bolder">
                      {{data?.basvuruBilgi.tabloKayitTarihi | date:'dd.MM.yyy HH:mm'}}</span>
                  </div>
                </div>
                <div class="row  mb-4">
                  <label class="col-lg-4 fs-5 fw-bold text-info" translate>basvuruTip</label>
                  <div class="col-lg-8">
                    <span class="fs-5 text-gray-800 me-2">
                      {{data.basvuruTipAdi}}</span>
                  </div>
                </div>
                <div class="row  mb-4">
                  <label class="col-lg-4  fs-5 fw-bold text-info" translate>kimlikPasaport</label>
                  <div class="col-lg-8">

                    <span class="fs-5 text-gray-800 me-6">
                      <span class=" text-gray-800 fs-6 fw-bold">
                        <a class="menu-link cursor-pointer fs-5"
                          (click)="dosyaIndir(data?.basvuruBilgi?.id,'pasaport')">{{data?.basvuruBilgi?.pasaportDosyaAdi}}
                        </a>
                      </span>
                    </span>

                    <span class="fs-5 text-gray-800">
                      <span class=" text-gray-800 fs-6 fw-bold">
                        <a class="menu-link cursor-pointer fs-5 text-danger"
                          (click)="dosyaGoster(data?.basvuruBilgi?.id,'pasaport')">Göster / Show
                        </a>
                      </span>
                    </span>

                    <div class="row" *ngIf="data.dosyaTip=='pasaport'">
                      <iframe [src]="data.pathReportString" height="500" frameborder="0" marginwidth="0" marginheight="0"
                        style="border:1px solid #CCC; border-width:1px; margin-bottom:5px; max-width: 100%; min-width: 100%;"
                        allowfullscreen> </iframe>
                    </div>

                  </div>
                </div>
                <div class="row  mb-4">
                  <label class="col-lg-4  fs-5 fw-bold text-info" translate>maviKartBelge</label>
                  <div class="col-lg-8">
                    <span class="fs-5 text-gray-800 me-2">
                      <span class=" text-gray-800 fs-6 fw-bold">
                        <a class="menu-link cursor-pointer fs-5"
                          (click)="dosyaIndir(data?.basvuruBilgi?.id,'mavi-kart')">{{data?.basvuruBilgi?.maviKartDosyaAdi}}
                        </a>
                      </span>
                    </span>

                    <span class="fs-5 text-gray-800 me-2" *ngIf="data?.basvuruBilgi?.maviKartDosyaAdi">
                      <span class=" text-gray-800 fs-6 fw-bold">
                        <a class="menu-link cursor-pointer fs-5 text-danger"
                          (click)="dosyaGoster(data?.basvuruBilgi?.id,'mavi-kart')">Göster / Show
                        </a>
                      </span>
                    </span>

                    <div class="row" *ngIf="data.dosyaTip=='mavi-kart'">
                      <iframe [src]="data.pathReportString" width="100%" height="500" frameborder="0" marginwidth="0"
                        marginheight="0"
                        style="border:1px solid #CCC; border-width:1px; margin-bottom:5px; max-width: 100%; min-width: 100%;"
                        allowfullscreen> </iframe>
                    </div>

                  </div>
                </div>
                <div class="row  mb-4">
                  <label class="col-lg-4  fs-5 fw-bold text-info" translate>onayDurumu</label>
                  <div class="col-lg-8 fv-row">

                    <span class="fw-bolder text-black" style=" word-wrap:break-word;   display:block;
                              width:100%;">
                      <i class="{{data?.sonHareketDurum?.iconClass}} fs-4 me-2"></i>
                      {{
                      data?.sonHareketDurum?.adi
                      }}&nbsp;({{data?.sonHareketDurum?.tarih | date:'dd.MM.yyyy'}}) -
                      {{data?.sonHareketDurum?.islemAciklama}}
                    </span>
                  </div>
                </div>

              </div>
              <div class="col-md-3">
                <div class="card-body">
                  <div class="col-lg-12 fv-row symbol symbol-400px symbol-lg-400px symbol-fixed position-relative">
                    <span class="  fs-6"><img style="width: 100%;" src="data:image/png;base64,{{data.base64textString}}"
                        alt="Secilen" /></span>
                  </div>
                </div>
              </div>
              <hr>
              <h3 class="font-size-lg text-dark font-weight-bold mb-6"><i
                  class="fas fa-graduation-cap text-success me-2" style="font-size: large;"></i>Lise Bilgileri / High
                School Information
                <span style="float: right;padding: 0px 0;" *ngIf="yetkiService.yetkiVar(['TercihBasvuru.Update'])">
                  <a target="_blank" class="btn btn-primary btn-sm me-1" (click)="modalKapat()"
                    [routerLink]="['/ogr-tercih-basvuru',data?.basvuruBilgi.tercihTanimId]"
                    [queryParams]="{basvuruid: data?.basvuruBilgi.id,step987:3}" title="DÜZENLE">
                    Düzenle
                  </a>
                </span>
              </h3>
              <div class="row  mb-4">
                <label class="col-lg-6 fs-5 fw-bold text-info" translate>noterKonsolosBelge</label>
                <div class="col-lg-6">
                  <span class="fs-5 text-gray-800 ">
                    <span class=" text-gray-800 fs-6 fw-bold me-6">
                      <a class="menu-link cursor-pointer fs-5"
                        (click)="dosyaIndir(data?.basvuruBilgi?.id,'lise-mez-asama-diploma')">{{data?.basvuruBilgi?.liseMezAsamaDiplomaDosyaAdi}}
                      </a>
                    </span>
                  </span>

                  <span class="fs-5 text-gray-800">
                    <span class=" text-gray-800 fs-6 fw-bold">
                      <a class="menu-link cursor-pointer fs-5 text-danger"
                        (click)="dosyaGoster(data?.basvuruBilgi?.id,'lise-mez-asama-diploma')">Göster / Show
                      </a>
                    </span>
                  </span>
                </div>
                <div class="col-lg-12">
                  <div class="row" *ngIf="data.dosyaTip=='lise-mez-asama-diploma'">
                    <iframe [src]="data.pathReportString" width="100%" height="400px" frameborder="0" marginwidth="0"
                      marginheight="0"
                      style="border:1px solid #CCC; border-width:1px; margin-bottom:5px; max-width: 100%;"
                      allowfullscreen> </iframe>
                  </div>
                </div>
              </div>

              <div class="row  mb-4">
                <label class="col-lg-6 fs-5 fw-bold text-info" translate>lisenotdokum</label>
                <div class="col-lg-6">
                  <span class="fs-5 text-gray-800 me-6">
                    <span class=" text-gray-800 fs-6 fw-bold">
                      <a class="menu-link cursor-pointer fs-5"
                        (click)="dosyaIndir(data?.basvuruBilgi?.id,'lise-not-dokumu')">{{data?.basvuruBilgi?.liseNotDokumuDosyaAdi}}
                      </a>
                    </span>
                  </span>

                  <span class="fs-5 text-gray-800">
                    <span class=" text-gray-800 fs-6 fw-bold">
                      <a class="menu-link cursor-pointer fs-5 text-danger"
                        (click)="dosyaGoster(data?.basvuruBilgi?.id,'lise-not-dokumu')">Göster / Show
                      </a>
                    </span>
                  </span>
                </div>

                <div class="col-lg-12">
                  <div class="row" *ngIf="data.dosyaTip=='lise-not-dokumu'">
                    <iframe [src]="data.pathReportString" width="100%" height="400px" frameborder="0" marginwidth="0"
                      marginheight="0"
                      style="border:1px solid #CCC; border-width:1px; margin-bottom:5px; max-width: 100%;"
                      allowfullscreen> </iframe>
                  </div>
                </div>
              </div>



              <div class="row  mb-4">
                <label class="col-lg-4 fs-5 fw-bold text-info"><span translate>liseulke</span></label>
                <div class="col-lg-8">
                  <span class="fs-5 text-gray-800 me-2">
                    {{data.mezunUlkeAdi}}</span>
                </div>
              </div>

              <div class="row  mb-4">
                <label class="col-lg-4 fs-5 fw-bold text-info" translate>liseAdi</label>
                <div class="col-lg-8">
                  <span class="fs-5 text-gray-800 me-2">
                    {{data?.basvuruBilgi?.liseAdi}}</span>
                </div>
              </div>


              <div class="row  mb-4">
                <label class="col-lg-4 fs-5 fw-bold text-info"><span translate>liseMezuniyetYil</span></label>
                <div class="col-lg-8">
                  <span class="fs-5 text-gray-800 me-2">
                    <b>{{data?.basvuruBilgi?.liseMezuniyetYil}}</b>
                  </span>
                </div>
              </div>

              <div class="row  mb-4">
                <label class="col-lg-4 fs-5 fw-bold text-info" translate><span translate>notSistemi</span> / <span
                    translate>notOrtalama</span></label>
                <div class="col-lg-8">
                  <span class="fs-5 text-gray-800 me-2">
                    {{data.notSistemAdi}} &nbsp;
                    {{data?.basvuruBilgi?.liseNotSistemAciklama}}&nbsp;/&nbsp;<b>{{data?.basvuruBilgi?.liseNotOrtalamasi}}
                    </b></span>
                </div>
              </div>

              <div class="row  mb-4">
                <label class="col-lg-6  fs-5 fw-bold text-info" translate>liseOgrenimGosterir</label>
                <div class="col-lg-6">
                  <span class=" fs-5 text-gray-800 me-6">
                    <span class=" text-gray-800 fs-6 fw-bold">
                      <a class="menu-link cursor-pointer fs-5"
                        (click)="dosyaIndir(data?.basvuruBilgi?.id,'lise-ogrenim-gordugu')">{{data?.basvuruBilgi?.liseOgrenimGorduguDosyaAdi}}
                      </a>
                    </span>
                  </span>

                  <span class="fs-5 text-gray-800" *ngIf="isFalsyFnc( data?.basvuruBilgi?.liseOgrenimGorduguDosyaAdi)">
                    <span class=" text-gray-800 fs-6 fw-bold">
                      <a class="menu-link cursor-pointer fs-5 text-danger"
                        (click)="dosyaGoster(data?.basvuruBilgi?.id,'lise-ogrenim-gordugu')">Göster / Show
                      </a>
                    </span>
                  </span>
                </div>

                <div class="col-lg-12">
                  <div class="row" *ngIf="data.dosyaTip=='lise-ogrenim-gordugu'">
                    <iframe [src]="data.pathReportString" width="100%" height="400px" frameborder="0" marginwidth="0"
                      marginheight="0"
                      style="border:1px solid #CCC; border-width:1px; margin-bottom:5px; max-width: 100%;"
                      allowfullscreen> </iframe>
                  </div>
                </div>
              </div>

              <hr>
              <h3 class="font-size-lg text-dark font-weight-bold mb-6"><i class="fas fa-scroll text-success me-2"
                  style="font-size: large;"></i>Sınavlar ve Belgeler / Exam Results Information
                  <span style="float: right;padding: 0px 0;" *ngIf="yetkiService.yetkiVar(['TercihBasvuru.Update'])">
                    <a target="_blank" class="btn btn-primary btn-sm me-1" (click)="modalKapat()"
                      [routerLink]="['/ogr-tercih-basvuru',data?.basvuruBilgi.tercihTanimId]"
                      [queryParams]="{basvuruid: data?.basvuruBilgi.id,step987:4}" title="DÜZENLE">
                      Düzenle
                    </a>
                  </span>
                </h3>
              <div class="row  mb-4">
                <label class="col-lg-4  fs-5 fw-bold text-info" translate>sinavTur</label>
                <div class="col-lg-8">
                  <span class="fs-5 text-gray-800 me-2">
                    {{data?.sinavTurAdi}}</span>
                </div>
              </div>

              <div class="row  mb-4">
                <label class="col-lg-4  fs-5 fw-bold text-info"><span translate>notSistemi</span> / <span
                    translate>sinavPuan</span></label>
                <div class="col-lg-8">
                  <span class=" fs-5 text-gray-800 me-2">
                    <b>{{data.sinavNotSistemAdi}}</b>&nbsp;
                    {{data?.basvuruBilgi?.sinavNotSistemAciklama}}&nbsp;/&nbsp;<b>{{data?.basvuruBilgi?.sinavPuan}}
                    </b></span>
                </div>
              </div>
              <div class="row  mb-4">
                <label class="col-lg-4  fs-5 fw-bold text-info" translate>Sınav puanı(Yüzlük)</label>
                <div class="col-lg-8">
                  <span class=" fs-5 text-gray-800 me-2">
                    <b>{{data?.basvuruBilgi?.sinavPuanYuzluk}}</b>
                  </span>
                </div>
              </div>

              <div class="row  mb-4">
                <label class="col-lg-4 fs-5 fw-bold text-info" translate>sinacSonucYukle</label>
                <div class="col-lg-8">
                  <span class=" fs-6 text-gray-800 me-6">
                    <span class=" text-gray-800 fs-6 fw-bold">
                      <a class="menu-link cursor-pointer fs-5"
                        (click)="dosyaIndir(data?.basvuruBilgi?.id,'sinav-sonuc-belge')">{{data?.basvuruBilgi?.liseOgrenimGorduguDosyaAdi}}
                      </a>
                    </span>
                  </span>

                  <span class="fs-5 text-gray-800">
                    <span class=" text-gray-800 fs-6 fw-bold">
                      <a class="menu-link cursor-pointer fs-5 text-danger"
                        (click)="dosyaGoster(data?.basvuruBilgi?.id,'sinav-sonuc-belge')">Göster / Show
                      </a>
                    </span>
                  </span>
                </div>

                <div class="col-lg-12">
                  <div class="row" *ngIf="data.dosyaTip=='sinav-sonuc-belge'">
                    <iframe [src]="data.pathReportString" width="100%" height="400px" frameborder="0" marginwidth="0"
                      marginheight="0"
                      style="border:1px solid #CCC; border-width:1px; margin-bottom:5px; max-width: 100%;"
                      allowfullscreen> </iframe>
                  </div>
                </div>
              </div>



              <div class="row  mb-4">
                <label class="col-lg-4  fs-5 fw-bold text-info" translate>sinavDegerlendirmePuan</label>
                <div class="col-lg-8">
                  <span class=" fs-5 text-gray-800 me-2">
                    <b>{{data?.basvuruBilgi?.degerlendirmePuan}}</b>
                  </span>
                </div>
              </div>
              <hr>

              <h3 class="font-size-lg text-dark font-weight-bold mb-6"><i class="fas fa-university text-success me-2"
                  style="font-size: larger;"></i>Tercih edilen birimler / Selected Programs
                  <span style="float: right;padding: 0px 0;" *ngIf="yetkiService.yetkiVar(['TercihBasvuru.Update'])">
                    <a target="_blank" class="btn btn-primary btn-sm me-1" (click)="modalKapat()"
                      [routerLink]="['/ogr-tercih-basvuru',data?.basvuruBilgi.tercihTanimId]"
                      [queryParams]="{basvuruid: data?.basvuruBilgi.id,step987:5}" title="DÜZENLE">
                      Düzenle
                    </a>
                  </span></h3>
              <div class="row  mb-4">
                <table class="table table-hover table-responsive gn-middle table-row-bordered fs-7 gy-1">
                  <tr>
                    <th style="width: 5%; text-align: center;" class="fw-bolder">S. No</th>
                    <th style="width: 30%;" class="fw-bolder" translate>
                      birim
                    </th>
                    <th style="width: 32%;" class="fw-bolder" translate>bolum
                    </th>
                    <th style="width: 33%;" class="fw-bolder" translate>
                      program
                    </th>
                  </tr>

                  <tr *ngFor="let item of data.tercihSecilenBirimList;let i=index;">
                    <td style=" text-align: center;"><b> {{
                        item.siraNo
                        }}</b>
                    </td>
                    <td>{{ item.fakAdi }}</td>
                    <td>{{ item.bolAdi }}</td>
                    <td>{{ item.progAdi }}</td>
                  </tr>
                </table>
              </div>


              <ng-container *ngIf="data.yerlestirmeYayindami">
                <div class="row  mb-4">
                  <label class="col-lg-4  fs-5 fw-bold text-info" translate>yerlesmeDurum</label>
                  <div class="col-lg-2">
                    <span class=" fs-5 text-gray-800 me-2">
                      <span *ngIf="data?.basvuruBilgi?.yerlestiMi;else yerlesmedi">
                        <i class="fas fa-check fs-3 text-success"></i>&nbsp;&nbsp;
                        {{data?.yerlestirmeNo}} . Yerleştirme
                      </span>
                      <ng-template #yerlesmedi>
                        <i class="fas fa-times fs-3 text-danger"></i>
                      </ng-template>
                    </span>
                  </div>

                  <label class="col-lg-4 fs-5 fw-bold text-info" translate>kayitYaptirmaDurum</label>
                  <div class="col-lg-2">
                    <span class=" fs-5 text-gray-800 me-2">
                      <span *ngIf="data?.basvuruBilgi?.kayitOlduMu;else kayitOlmadi">
                        <i class="fas fa-check fs-3 text-success"></i>
                      </span>
                      <ng-template #kayitOlmadi>
                        <i class="fas fa-times fs-3 text-danger"></i>
                      </ng-template>
                    </span>
                  </div>
                </div>

                <div class="row  mb-3">       
                  <label class="col-lg-4  fs-5 fw-bold text-info" translate>ybirim</label>
                  <div class="col-lg-8">
                    {{data?.basvuruBilgi?.yerlestigiFakAd}}
                  </div>

                  <label class="col-lg-4 fs-5 fw-bold text-info" translate>ybolum</label>
                  <div class="col-lg-8">
                    {{data?.basvuruBilgi?.yerlestigiBolAd}}
                  </div>
                  <label class="col-lg-4  fs-5 fw-bold text-info" translate>yprogram</label>
                  <div class="col-lg-8">
                    {{data?.basvuruBilgi?.yerlestigiProgAd}}
                  </div>
                  <label class="col-lg-4  fs-5 fw-bold text-info " translate>tercihSiraNo</label>
                  <div class="col-lg-8">
                    {{data?.basvuruBilgi?.yerlestigiTercihSiraNo}}
                  </div>
                </div>
                
              </ng-container>
            </div>


            <div class="col-md-12" *ngIf="yetkiService.yetkiVar(['TercihBasvuru.Degerlendirme'])">
              <div class="card-body">
                <div class="row  mb-4">
                  <label class="col-lg-2 text-info fw-bold">İşlem Yap :
                    <br>
                    <br>
                    <span class="fw-bolder text-black">
                      <i class="{{data?.sonHareketDurum?.iconClass}} fs-4 me-2"></i>
                      {{
                      data?.sonHareketDurum?.adi
                      }}
                    </span>
                  </label>


                  <div class="col-lg-10" style="border: 1px solid rgb(124, 219, 236);">
                    <table class="table">
                      <tr>
                        <td>
                          <div class="d-flex justify-content-center flex-shrink-0">
                            <label style="text-align: center;"
                              class="form-check form-check-custom form-check-solid form-switch mb-5 me-3">
                              <input type="radio"
                                [ngStyle]="{'background-color':data?.sonHareketDurum?.kisaltma=='basvuru-onaylandi'  ? '#50CD89':''}"
                                id="onyRadio" name="onyRadio" class="form-check-input" value="basvuru-onaylandi"
                                [(ngModel)]="data?.sonHareketDurum.kisaltma" style="border:2px  green solid">
                              <span class="form-check-label fw-bold fs-5 text-success">Onay
                              </span>
                            </label>
                          </div>
                        </td>

                        <td>

                          <div class="d-flex justify-content-center flex-shrink-0">
                            <label style="text-align: center;"
                              class="form-check form-check-custom form-check-solid form-switch mb-5 me-3">
                              <input type="radio" value="basvuru-reddedildi"
                                [ngStyle]="{'background-color': data.sonHareketDurum?.kisaltma=='basvuru-reddedildi'  ? 'red':''}"
                                name="onyRadio" id="onyRadio" [(ngModel)]="data?.sonHareketDurum.kisaltma"
                                class="form-check-input" style="border:2px  red solid">
                              <span class="form-check-label fs-5  fw-bold text-danger">Red
                              </span>
                            </label>
                          </div>


                        </td>
                        <td>
                          <div class="d-flex justify-content-center flex-shrink-0">
                            <label style="text-align: center;"
                              class="form-check form-check-custom form-check-solid form-switch mb-5 me-3">
                              <input type="radio" value="basvuru-hatali"
                                [ngStyle]="{'background-color': data.sonHareketDurum?.kisaltma=='basvuru-hatali'  ? 'yellow':''}"
                                name="onyRadio" id="onyRadio" [(ngModel)]="data?.sonHareketDurum.kisaltma"
                                class="form-check-input" style="border:2px  rgb(227, 227, 25) solid">
                              <span class="form-check-label fs-5  fw-bold text-danger">Hatalı başvuru
                              </span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <ng-container
                            *ngIf="data?.sonHareketDurum?.kisaltma=='basvuru-reddedildi' || data?.sonHareketDurum?.kisaltma=='basvuru-hatali'">
                            <div class="row mb-4">
                              <label class="col-lg-2 col-form-label fs-5 fw-bold text-info" translate>Cevap</label>
                              <div class="col-lg-10">
                                <select class="form-select"
                                  (change)="cevapYaz(cevapSelect.options[cevapSelect.selectedIndex].text)" #cevapSelect
                                  id="cevapid" name="cevapname" style="border-color: #8fb9d5;">
                                  <option [ngValue]=null>Lütfen seçiniz / Please choose...
                                  </option>

                                  <option *ngFor="let item of hazirCevaplar" [ngValue]="item.id">
                                    {{item.aciklama}}
                                  </option>
                                  <option [ngValue]=0>
                                    Diğer
                                  </option>
                                </select>
                              </div>
                            </div>

                            <div class="row  mb-4">
                              <label class="col-lg-2  fs-5 fw-bold text-info" translate></label>
                              <div class="col-lg-10">
                                <textarea style="border-color: #18dfbe;width: 100%; background-color: white;"
                                  name="icerik" id="icerik" [disabled]="cevapPasiflik"
                                  [(ngModel)]="data?.sonHareketDurum.islemAciklama" class="form-control"
                                  maxlength="1000" cols="10" rows="8"
                                  placeholder="Lütfen red nedenini belirtiniz."></textarea>
                              </div>
                            </div>
                          </ng-container>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <div class="d-flex justify-content-center flex-shrink-0">
                            <button type="button" id="kt_sign_in_submit"
                              *ngIf="data?.sonHareketDurum?.kisaltma!='beklemede'" style="border: 2px solid green;"
                              class="btn btn-block btn-active-color-primary btn-sm  text-center" title="Kaydet"
                              [disabled]="kaydediliyor" [attr.data-kt-indicator]="kaydediliyor ? 'on' : 'off'"
                              (click)="islemKaydet(data)">
                              <i class="fas fa-check text-primary"></i>
                              Kaydet
                              <span class="indicator-progress">
                                Kaydediliyor...
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </loading>
        </div>
      </div>
    </div>
  </div>
</div>