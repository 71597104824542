
import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { SinavTur } from 'src/app/models/sinavTur.model';
import { SinavTurService } from 'src/app/services/sinav-tur.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';

@Component({
  selector: 'sinav-tur-islem',
  templateUrl: './sinav-tur-islem.component.html',
  styleUrls: ['./sinav-tur-islem.component.css']
})
export class SinavTurIslemComponent implements OnInit {
  kaydediliyor = false;

  constructor(private formBuilder: FormBuilder, public yetkiService: YetkiService, private sinavTurService: SinavTurService, private route: ActivatedRoute, private router: Router, private validationService: ValidationService, private datePipe: DatePipe) { }
  yukleniyor: boolean = false;
  hatalar: any = {};
  detayForm = this.formBuilder.group(new SinavTur());
  yeni = true;
  tercihid: number = 0;

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['id'] != 'yeni') {
        this.getById(params['id']);
      }
      else {
        this.detayForm = this.formBuilder.group(new SinavTur());
      }
    });

    this.route.queryParams.subscribe(params => {
      if (params['tercihid']) {
        this.tercihid = Number(params['tercihid']);
      }
    });

  }

  getById(id: number) {
    this.yukleniyor = true;
    this.sinavTurService.getById(id).subscribe(res => {
      this.yeni = false;
      this.detayForm.patchValue(res.data);
      this.yukleniyor = false;
    }, err => {
      this.yukleniyor = false;
      this.router.navigateByUrl('/')
    });
  }

  kaydetGuncelle() {
    this.kaydediliyor = true;
    this.hatalar = {};
    var form = this.detayForm.value;
    form.tercihDonemTanimId = Number(this.tercihid);

    this.sinavTurService.addUpdate(form).pipe(finalize(() => {
      this.kaydediliyor = false;
    })).subscribe({
      next: res => {
        if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
        this.router.navigateByUrl('/sinav-tur/' + this.tercihid)
      },
      error: err => {
        this.hatalar = this.validationService.hatalar(err.error.Errors);
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
        }
      }
    });
  }
}



