<!-- <div class="content d-flex flex-column flex-column-fluid"> -->
    <div class="d-md-none d-lg-none mb-15">
    </div>
    <div class="toolbar mb-5" style="height: 8%">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <span class="d-flex text-dark fw-bolder  flex-column mb-0  fs-md-4 fs-7">
                    <span translate> 
                        <i class="far fa-envelope me-2" style="font-size: large; color: #d4af37;"></i>iletisimUyari</span>
                    <!-- <span class="text-muted fs-7 fw-bold mt-2">Alt Başlık</span> -->
                    </span>
            </div>

            <div class="d-flex align-items-center gap-2 gap-lg-3">
                <button translate type="button" routerLink="/" id="kt_modal_new_target_cancel"
                class="btn  btn-sm btn-danger btn-hover-rise me-3"><i class="fas fa-reply"></i> geri
            </button>
                <button class="btn btn-sm  btn-shadow-hover btn-secondary fs-6  border border-primary"
                    (click)="exportexcel()" style="color: green;">
                    <i class="fas fa-file-excel " style="color: green;"></i>
                    Excel
                </button>
                <a class="btn btn-sm  btn-shadow-hover fs-6" [routerLink]="['yeni']"
                    style="background-color: #77c2e9; color: white;">
                    <i class="fas fa-comment-dots" style="color: white;"></i>
                    <!--end::Svg Icon--><span translate>iletisimYeni</span>
                </a>
            </div>
        </div>
    </div>

    <div class="post d-flex flex-column-fluid mt-10" id="kt_post">
        <div id="kt_content_container" class="container-fluid mb-10">
            <grid #grid [service]="iletisimDetayService" [(data)]="iletisimDetay" [rowCount]="10">
                <div style="overflow:auto;width: 100%;">
                    <table class="table table-responsive gn-middle table-row-bordered fs-7 gy-2"
                        id="kl_subscriptions_table">
                        <thead>
                            <tr class="text-start fw-bolder fs-7 gs-0 " style="color:#cd7f32">
                                <th class="text-center" style="width: 5%;">#</th>
                                <th class="text-center">Mesaj</th>
                                <th class="text-center">Gönderim Tarihi</th>
                            </tr>
                        </thead>
                        <tbody class="text-gray-600 ">
                            <!-- dblclick -->
                            <tr *ngFor="let item of iletisimDetay;let i=index;" style="color: black;">
                                <td class="text-info text-left text-center fs-5" style="vertical-align: middle;">
                                    <b>{{(i+1) + (grid.page-1)*10}}</b>
                                </td>
                                <td class="text-center fs-5" style="vertical-align: middle;">
                                    {{ item.mesaj }}
                                </td>
                                <td class="text-center fs-5" style="vertical-align: middle;">
                                    {{ item.kayitTarihi | date:'dd.MM.yyyy'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </grid>
        </div>
    </div>

