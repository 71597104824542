<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span>
                        <span>
                            <i class="fas fa-calendar-alt text-success fs-3 me-2"></i>Form İşlemleri</span>

                        <span>
                            &nbsp;&nbsp;
                            <i class="fas fa-exclamation-triangle text-danger fs-4"></i>
                            Yapılan işlemler devam eden Form girişlerini etkileyebilir.</span>
                    </span>
                </h1>
              
            </div>
            <div class="d-flex align-items-center gap-2 gap-lg-3">
                <!-- <a [routerLink]="['yeni']" class="btn btn-sm btn-primary">Yeni Uygulama</a> -->
            </div>
        </div>
    </div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" class="container-fluid">
            <loading [loader]="yukleniyor">
                <form [formGroup]="detayForm" (submit)="kaydetGuncelle()">
                    <div class="card col-12 col-md-8 offset-md-2" style="border-top: 3px #77c2e9 solid;">
                        <div class="card-header">
                            <h3 class="card-title">
                                Yeni Ekle
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="form-floating mb-3">
                                <input formControlName="tanim" type="text" class="form-control" id="tanim"
                                oninput="this.value = this.value.toLocaleUpperCase('TR')"
                                    style="border-color: #8fb9d5;text-transform: uppercase;" placeholder="tanim"
                                    name="tanim" />
                                <label style="color: #009ef7;" class="fs-4" for="tanim">Form Tanımı</label>
                                <div class="text-danger" *ngFor="let hata of hatalar['Tanim']">
                                    {{hata}}
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-5 form-floating">
                                    <input formControlName="basvuruBaslangicTarihi" type="datetime-local"
                                        class="form-control datepicker" id="basvuruBaslangicTarihi"
                                        placeholder="basvuruBaslangicTarihi" name="basvuruBaslangicTarihi"
                                        style="border-color: #8fb9d5;" [value]="detayForm.value.toplantiTarih |
                                    date:'yyyy-MM-dd HH:mm'" />
                                    <label style="color: #009ef7;" class="fs-4 fw-bolder" for="basvuruBaslangicTarihi">
                                        Form Yayın Başlangıç Tarih / Saat</label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['BasvuruBaslangicTarihi']">
                                        {{hata}}
                                    </div>
                                </div>
                                <div class="col-5 form-floating ">
                                    <input formControlName="basvuruBitisTarihi" type="datetime-local"
                                        class="form-control datepicker" id="basvuruBitisTarihi"
                                        placeholder="basvuruBitisTarihi" name="basvuruBitisTarihi"
                                        style="border-color: #8fb9d5;" [value]="detayForm.value.toplantiTarih |
                                    date:'yyyy-MM-dd HH:mm'" />
                                    <label style="color: #009ef7;" class="fs-4 fw-bolder" for="basvuruBitisTarihi">
                                        Form Yayın Bitiş Tarih / Saat</label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['BasvuruBitisTarihi']">
                                        {{hata}}
                                    </div>
                                </div>


                            </div>

                            <div class="row  mb-3">
                                <div class="col-lg-2">
                                    <label for="class_type">
                                        <h5 style=" margin-top: 100px; text-align: center;"><span
                                                class=" text-info label label-primary">Form Açıklama<span class="text-danger me-2">*
                                                </span></span></h5>
                                    </label>
                                </div>
                                <!-- <label class="col-lg-2 control-label fw-bold text-info" style="vertical-align: middle;">Tercih tanım bilgisini gir </label> -->
                                <div class="col-lg-10">
                                    <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="model.editorData"
                                        (change)="onChange($event)" [ngModelOptions]="{standalone: true}"></ckeditor>
                                </div> 
                            </div>



                            <div class="form-floating mb-3">
                                
                                <div class="mb-2 mt-5">
                                    <input type="checkbox" class="form-check-input me-2"
                                        formControlName="anasayfaGorunurluk" id="anasayfaGorunurluk"
                                        name="anasayfaGorunurluk">
                                    <label class="checkbox-lg text-info text-justify" style="font-size:medium;"
                                        for="exampleCheck1">Anasayfada görünürlük
                                        ?</label>
                                </div>


                                <!-- <div class="mb-2 mt-5">
                                    <input type="checkbox" class="form-check-input me-2"
                                        formControlName="ayarlarAktarilsinMi" id="ayarlarAktarilsinMi"
                                        name="ayarlarAktarilsinMi">
                                    <label class="checkbox-lg text-info text-justify" style="font-size:medium;"
                                        for="exampleCheck1">Önceki sınavın sınav yerleri ve ek alanları aktarılsın mı
                                        ?</label>
                                </div> -->

                            </div>



                        </div>
                        <div class="row card-footer">
                            <div class="col-md-6 col-6 text-left">
                                <button type="button" routerLink="/sinav-bilgi" id="kt_modal_new_target_cancel"
                                    class="btn btn-danger btn-hover-rise me-3"><i class="fas fa-reply"></i> Geri
                                </button>
                            </div>
                            <div  *ngIf="yetkiService.yetkiVar(['FormTanim.Ekle','FormTanim.Düzenle'])" class="col-md-6 col-6 text-end">
                                <submit-button [loader]="kaydediliyor" [label]="yeni?'Kaydet':'Güncelle'"
                                    [loading]="yeni?'Kaydediliyor...':'Güncelleniyor...'">
                                </submit-button>
                            </div>
                        </div>


                    </div>

                </form>
            </loading>
        </div>
    </div>
</div>
