<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
             
            <h1 class="d-flex text-dark fw-bolder fs-2 flex-column mb-0">
                <span style="font-size: medium;">
                    <i class="far fa-map me-2 fs-3" style="color: #d4af37;"></i>Tercih kayıt
                    işlemleri &nbsp;(
                    {{this.tercihTanim.adi }}
                    <span>
                        &nbsp;
                        <i class="fas fa-calendar-alt text-success fs-3"></i>
                        {{this.tercihTanim.baslangicTarihi | date:'dd.MM.yyyy HH:mm' }}</span>
                    &nbsp;-&nbsp;
                    &nbsp;&nbsp;
                    <i class="fas fa-calendar-alt text-danger fs-3"></i>
                    {{this.tercihTanim.bitisTarihi | date:'dd.MM.yyyy HH:mm' }})</span>

                <!-- <span class="text-muted fs-7 fw-bold mt-2">Alt Başlık</span> -->
            </h1>
            </div>
            <div class="d-flex align-items-center gap-2 gap-lg-3">
                <button type="button" routerLink="/tercih-donem-bilgi" id="kt_modal_new_target_cancel"
                    class="btn btn-danger btn-hover-rise me-3"><i class="fas fa-reply"></i> Geri
                </button>
            </div>
        </div>
    </div>

    <div class="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" class="container-fluid">
            <div class="card col-10 offset-1" style="border-top: 3px rgb(146, 199, 224) solid;">
                <div class="card-body" style="width: 100%;">

                    <div class="form-group row mb-2">
                        <label class="col-lg-3 col-form-label text-info fs-6">Kayıt excel dosyasını seç :
                            <span class="text-danger me-2"> *
                            </span></label>
                        <div class="col-lg-9">
                            <input ui-jq="filestyle" class="form-control border-warning" type="file" id="file"
                                (change)="selectKayit($event)" name="file" nv-file-select=""
                                accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                uploader="uploader" data-icon="false" style="color: #7239EA;"
                                data-classButton="btn btn-default"
                                data-classInput="form-control inline v-middle input-s">
                            <a class="menu-link cursor-pointer fs-5" target="_blank"
                                href="assets/dosyalar/Sınav sonuc format.xlsx">
                                <span class="text-danger"><i class="fas fa-hand-point-right text-info me-2"></i>Kayıt
                                    dosya formatını indir ( Lütfen kayıt bilgilerini bu dosya formatına göre yükleyiniz.
                                    Aksi takdirde hatalı bir işlem olacaktır.).</span>
                            </a>
                        </div>
                    </div>


                    <div class="form-group row mb-5">
                        <label class="col-lg-3 col-form-label text-info fs-6">
                        </label>
                        <div class="col-lg-9 text-center">
                            <button  *ngIf="yetkiService.yetkiVar(['SinavBasvuru.SonucKaydet'])"
                            type="button" class="btn btn-success" [disabled]="kaydediliyor"
                                (click)="kayitListeYukle()" [attr.data-kt-indicator]="kaydediliyor ? 'on' : 'off'">
                                <span class="indicator-label"><i class="fas fa-check"></i>Kaydet
                                </span>
                                <span class="indicator-progress">Kaydediliyor...
                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>


                    <div class="col-8 offset-2 text-center mt-6">          
                        <div class="d-flex justify-content-center flex-shrink-0">
                            <button  *ngIf="yetkiService.yetkiVar(['SinavBasvuru.SonucSil'])"
                            type="button" class="btn btn-danger mt-3" [disabled]="siliniyor"
                                (click)="kayitSil()" [attr.data-kt-indicator]="siliniyor ? 'on' : 'off'">
                                <span class="indicator-label"><i class="fas fa-poll fs-3"></i>Kayıtları sil
                                </span>
                                <span class="indicator-progress">Tüm kayıtlar siliniyor...
                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
