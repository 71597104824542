import { SinavBasvuruEkAlanCevap } from "./sinavBasvuruEkAlanCevap.model";
import { SinavOdemeBilgi } from "./sinavOdemeBilgi.model";
import { SinavTanim } from "./sinavTanim.model";

type Nullable<T> = T | null;
export class SinavBasvuru {
    id: number = 0;
    ogrKullaniciId: number = 0;
    sinavTanimId: number = 0;
    fotoUrl: string = '';
    secilenFoto: string = '';
    adi: string = '';
    soyadi: string = '';
    aciklama: string = '';
    basvuruTamamlanmaDurumu: boolean = false;
    kayitTarihi: Date = new Date();
    sonGuncellemeTarihi: Date = new Date();
    bilgiDogrulukOnayi: boolean = false;
    arsiv: boolean = false;
}

export class SinavBasvuruDetay {
    basvuruBilgi: SinavBasvuru = new SinavBasvuru();
    sinavBasvuruEkAlanCevaplar: SinavBasvuruEkAlanCevap[] = [];

    dogumYeri: string = '';
    hareketBilgileri: SinavBasvuruHareket[] = [];
    sonHareketDurum: SonHareketDurumBilgi = new SonHareketDurumBilgi();

    // Grid ek sütünlar
    adi: string = '';
    soyadi: string = '';
    durumAdi: string = '';
    basvuruTarih: Date = new Date();

}

export class SinavBasvurularColumns {
    columnsdegerler = [
        {
            title: '#',
            data: 'dataIndex',
            render: function () {
                return null;
            },
        },
        {
            name: "adi",
            title: 'Adı',
            data: 'adi',
        },
        {
            name: "soyadi",
            title: 'Soyadı',
            data: 'soyadi',
        },
        {
            name: "durumAdi",
            title: 'Başvuru Durumu',
            data: 'durumAdi',
        },
        {
            name: "basvuruTarih",
            title: 'Başvuru Tarihi',
            data: 'basvuruTarih',
        },
        {
            title: '#',
            data: '',
            render: function () {
                return null;
            },
        },
    ];
}

export class SinavBasvuruHareket {
    id: number = 0;
    basvuruId: number = 0;
    hareketDurumId: number = 0;
    kayitTarihi: Date = new Date();
    islemYapanTc: string = '';
    islemYapanAdSoyad: string = '';
    ip: string = '';
    aciklama: string = '';
    arsiv: boolean = false;
    haraketDurumBilgi: SinavBasvuruHareketDurum = new SinavBasvuruHareketDurum();
}

export class SinavBasvuruHareketDurum {
    id: number = 0;
    siraNo: number = 0;
    adi: string = '';
    kisaltma: string = '';
    iconClass: string = '';
    arsiv: boolean = false;
}

export class SonHareketDurumBilgi {
    basvuruId: number = 0;
    siraNo: number = 0;
    adi: string = '';
    kisaltma: string = '';
    islemAciklama: string = ''; // red ise açıklama girilecek
    iconClass: string = '';
    tarih?: Nullable<Date> = null;
}


export class GenelDataTableDto {
    draw: number = 1;
    length: number = 10;
    start: number = 0;
    search: any;
    columns: any[] = [];
    order: any[] = [];
}

