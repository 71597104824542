<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    Üye İşlemleri
                    <!-- <span class="text-muted fs-7 fw-bold mt-2">Alt Başlık</span> -->
                </h1>
            </div>
            <div class="d-flex align-items-center gap-2 gap-lg-3">
                <!-- <a [routerLink]="['yeni']" class="btn btn-sm btn-primary">Yeni Uygulama</a> -->
            </div>
        </div>
    </div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" class="container-fluid">
            <loading [loader]="yukleniyor">
                <form [formGroup]="detayForm" (submit)="kaydetGuncelle()">
                    <div class="card col-12 col-md-10 offset-md-1" style="border-top: 4px #77c2e9 solid;">

                        
                        <div class="card-header">
                            <h3 class="card-title">
                                Yeni Ekle
                            </h3>
                        </div>
                        <div class="card-body ">

                            <div class="row  mb-3">
                                <div class="col-4 form-floating">
                                    <input formControlName="adi" type="text" class="form-control" id="adi"
                                        style="border-color: #8fb9d5; text-transform: uppercase;" placeholder="adi"
                                        name="adi" oninput="this.value = this.value.toLocaleUpperCase('TR')" />
                                    <label style="color: #009ef7;" class="fs-4 fw-bold" for="adi">Adı</label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['aciklama']">
                                        {{hata}}
                                    </div>
                                </div>
                                <div class="col-4 form-floating">
                                    <input formControlName="soyadi" type="text" class="form-control" id="soyadi"
                                        style="border-color: #8fb9d5; text-transform: uppercase;" placeholder="soyadi"
                                        name="soyadi" oninput="this.value = this.value.toLocaleUpperCase('TR')" />
                                    <label style="color: #009ef7;" class="fs-4 fw-bold" for="soyadi">Soyadı</label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['soyadi']">
                                        {{hata}}
                                    </div>
                                </div>
                                <div class="col-4 form-floating">
                                    <input formControlName="email" type="text" class="form-control" id="email"
                                        style="border-color: #8fb9d5;" placeholder="email" name="email" />
                                    <label style="color: #009ef7;" class="fs-4 fw-bold" for="email">E Posta</label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['email']">
                                        {{hata}}
                                    </div>
                                </div>
                            </div>


                            <!-- <div class="form-floating mb-3">
                                <select formControlName="teskilat" class="form-select" id="teskilat" name="teskilat"
                                    style="border-color: #8fb9d5;">
                                    <option value="">Lütfen seçiniz </option>
                                    <option value="TEŞKİLAT">TEŞKİLAT</option>
                                    <option value="ÜNVAN">ÜNVAN</option>
                                </select>
                                <label style="color: #009ef7;" class="fs-4 fw-bold" for="tip">Teşkilat</label>
                                <div class="text-danger" *ngFor="let hata of hatalar['teskilat']">
                                    {{hata}}
                                </div>

                            </div> -->

                            <div class="row  mb-3">

                                <div class="col-4 form-floating">
                                    <select formControlName="teskilat" class="form-select" id="teskilat" #dilSelect
                                        name="teskilat" style="border-color: #8fb9d5;">
                                        <option [ngValue]="0">Lütfen seçiniz </option>
                                        <option *ngFor="let item of tanimlarService.tanimlarVer('TEŞKİLAT') " [ngValue]="item.id">
                                            {{item.aciklama}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fs-4 fw-bold" for="teskilat">Teşkilat
                                        Tip</label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['teskilat']">
                                        {{hata}}
                                    </div>
                                </div>

                                <div class="col-4 form-floating">
                                    <select formControlName="unvan" class="form-select" id="unvan" #dilSelect
                                        name="unvan" style="border-color: #8fb9d5;">
                                        <option [ngValue]="0">Lütfen seçiniz </option>
                                        <option *ngFor="let item of tanimlarService.tanimlarVer('ÜNVAN') " [ngValue]="item.id">
                                            {{item.aciklama}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fs-4 fw-bold" for="unvan">Ünvan</label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['unvan']">
                                        {{hata}}
                                    </div>
                                </div>

                                <div class="col-4 form-floating">
                                    <select formControlName="komisyon" class="form-select" id="komisyon" #dilSelect
                                        name="komisyon" style="border-color: #8fb9d5;">
                                        <option [ngValue]="0">Lütfen seçiniz </option>
                                        <option *ngFor="let item of tanimlarService.tanimlarVer('KOMİSYON') " [ngValue]="item.id">
                                            {{item.aciklama}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fs-4 fw-bold" for="komisyon">Komisyon</label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['komisyon']">
                                        {{hata}}
                                    </div>
                                </div>

                            </div>


                            <div class="row  mb-3">

                                <div class="col-4 form-floating">
                                    <select formControlName="birim" class="form-select" id="birim" #dilSelect
                                        name="teskilat" style="border-color: #8fb9d5;">
                                        <option [ngValue]="0">Lütfen seçiniz </option>
                                        <option *ngFor="let item of tanimlarService.tanimlarVer('BİRİM') " [ngValue]="item.id">
                                            {{item.aciklama}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fs-4 fw-bold" for="birim">Birim</label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['birim']">
                                        {{hata}}
                                    </div>
                                </div>

                                <div class="col-4 form-floating">
                                    <select formControlName="altbirim" class="form-select" id="altbirim" #dilSelect
                                        name="altbirim" style="border-color: #8fb9d5;">
                                        <option [ngValue]="0">Lütfen seçiniz </option>
                                        <option *ngFor="let item of tanimlarService.tanimlarVer('ALT BİRİM') " [ngValue]="item.id">
                                            {{item.aciklama}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fs-4 fw-bold" for="altbirim">Al Birim</label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['altbirim']">
                                        {{hata}}
                                    </div>
                                </div>

                                <div class="col-4 form-floating">
                                    <input formControlName="altbirimaciklama" type="text" class="form-control" id="altbirimaciklama"
                                        style="border-color: #8fb9d5; text-transform: uppercase;" placeholder="altbirimaciklama"
                                        name="altbirimaciklama" oninput="this.value = this.value.toLocaleUpperCase('TR')" />
                                    <label style="color: #009ef7;" class="fs-4 fw-bold" for="altbirimaciklama">Alt Birim Açıklama</label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['altbirimaciklama']">
                                        {{hata}}
                                    </div>
                                </div>

                            </div>


                            <div class="row  mb-3">

                               
                                <div class="col-4 form-floating">
                                    <input formControlName="cepno" type="number" class="form-control" id="cepno"
                                        style="border-color: #8fb9d5; text-transform: uppercase;" placeholder="cepno"
                                        name="cepno" oninput="this.value = this.value.toLocaleUpperCase('TR')" />
                                    <label style="color: #009ef7;" class="fs-4 fw-bold" for="cepno">Cep Telefonu</label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['cepno']">
                                        {{hata}}
                                    </div>
                                </div>

                                <div class="col-8 form-floating">
                                    <input formControlName="adres" type="text" class="form-control" id="adres"
                                        style="border-color: #8fb9d5; text-transform: uppercase;" placeholder="adres"
                                        name="adres" oninput="this.value = this.value.toLocaleUpperCase('TR')" />
                                    <label style="color: #009ef7;" class="fs-4 fw-bold" for="adres">Adres</label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['adres']">
                                        {{hata}}
                                    </div>
                                </div>

                            </div>


                            <div class="row  mb-3">

                              
                                <div class="col-4 form-floating">
                                    <select formControlName="il" class="form-select" id="il" #dilSelect
                                        name="il" style="border-color: #8fb9d5;">
                                        <option [ngValue]="0">Lütfen seçiniz </option>
                                        <option *ngFor="let item of adresService.iller " [ngValue]="item.id">
                                            {{item.sehir_ilce_mahalle_adi}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fs-4 fw-bold" for="il">İl</label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['il']">
                                        {{hata}}
                                    </div>
                                </div>

                                <div class="col-4 form-floating">
                                    <select formControlName="ilce" class="form-select" id="ilce" #dilSelect
                                        name="ilce" style="border-color: #8fb9d5;">
                                        <option [ngValue]="-1">Lütfen seçiniz </option>
                                        <option *ngFor="let item of adresService.ilcelerVer(detayForm.value.il) " [ngValue]="item.id">
                                            {{item.sehir_ilce_mahalle_adi}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fs-4 fw-bold" for="ilce">İlçe</label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['ilce']">
                                        {{hata}}
                                    </div>
                                </div>


                            </div>




                            <div class="form-floating mb-3">
                                <input formControlName="aciklama" type="text" class="form-control" id="aciklama"
                                    style="border-color: #8fb9d5; text-transform: uppercase;" placeholder="aciklama"
                                    name="aciklama" oninput="this.value = this.value.toLocaleUpperCase('TR')" />
                                <label style="color: #009ef7;" class="fs-4 fw-bold" for="aciklama">Açıklama</label>
                                <div class="text-danger" *ngFor="let hata of hatalar['aciklama']">
                                    {{hata}}
                                </div>
                            </div>

                            <div class="row  mb-3">

                              
                                <div class="col-4 form-floating">
                                    <select formControlName="rol" class="form-select" id="rol" #dilSelect
                                        name="il" style="border-color: #8fb9d5;">
                                        <option [ngValue]="0">Lütfen seçiniz </option>
                                        <option [ngValue]="1">Merkez </option>
                                        <option [ngValue]="2">Şube(İl) </option>
                                        <option [ngValue]="3">İlçe </option>
                                        <option [ngValue]="4">Üye </option>
                                        <option [ngValue]="5">Admin </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fs-4 fw-bold" for="rol">Rol</label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['rol']">
                                        {{hata}}
                                    </div>
                                </div>

                                <div class="mb-2 mt-5">
                                    <input type="checkbox" class="form-check-input me-2"
                                    formControlName="onay" id="onay"
                                    name="onay">
                                <label class="checkbox-lg text-info text-justify" style="font-size:medium;"
                                    for="exampleCheck1">Üye onaylandı</label>
                                </div>


                            </div>


                        </div>
                        <div class="row card-footer">
                            <div class="col-md-6 col-6 text-left">
                                <button type="button" routerLink="/uyeler" id="kt_modal_new_target_cancel"
                                    class="btn btn-danger btn-hover-rise me-3"><i class="fas fa-reply"></i> Geri
                                </button>
                            </div>
                            <div class="col-md-6 col-6 text-end">
                                <submit-button [loader]="kaydediliyor" [label]="yeni?'Kaydet':'Güncelle'"
                                    [loading]="yeni?'Kaydediliyor...':'Güncelleniyor...'">
                                </submit-button>
                            </div>
                        </div>

                    </div>

                </form>
            </loading>
        </div>
    </div>
</div>