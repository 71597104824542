import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '../models/response.model';
import { SinavEkAlan } from '../models/sinavEkAlan.model';
import * as XLSX from 'xlsx';
import { TercihBasvuru, TercihBasvuruDetay, TercihSecilenBirim } from '../models/tercihBasvuru.model';

@Injectable({
  providedIn: 'root'
})
export class TercihBasvuruService {
  apiUrl = '/api/tercihbasvuru';

  constructor(private http: HttpClient) { 

  }

  // getAll(query: string = '', page: number = 1, rowCount: number = 10,sinavid:number): Observable<Response<TercihBasvuruDetay[]>> {
  //   return this.http.get<Response<TercihBasvuruDetay[]>>(this.apiUrl + '/getList', {
  //     params: {
  //       'aramastr': query,
  //       'sayfasayisi': page,
  //       'sayfadakisatirsayisi': rowCount,
  //       'sinavid': sinavid
  //     }
  //   });
  // }

  // getListAll(sinavid:number): Observable<Response<TercihBasvuruDetay[]>> {
  //   return this.http.post<Response<TercihBasvuruDetay[]>>(this.apiUrl + '/getTercihBasvuruTopluDetayList?sinavid='+sinavid,{});
  // }

  getGridList(dataTablesParameters: any,jsondata:any,tercihTanimId:number,ogrId:number=0,yerlestirmeTanimId:number=0, basvuruid:number=0): Observable<Response<any>> {
   
    return this.http.post<Response<any>>(this.apiUrl + '/getGridList', dataTablesParameters, {
      params: {
        'jsondata':jsondata,
        'tercihTanimId': tercihTanimId,
        'ogrId':ogrId,
        'yerlestirmeTanimId':yerlestirmeTanimId,
        'basvuruid':basvuruid
      }
    });
  }


  getGridListRapor(dataTablesParameters: any,jsondata:any,tercihTanimId:number,ogrId:number=0,yerlestirmeTanimId:number=0, basvuruid:number=0): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/getGridListRapor', dataTablesParameters, {
      params: {
        'jsondata':jsondata,
        'tercihTanimId': tercihTanimId,
        'ogrId':ogrId,
        'yerlestirmeTanimId':yerlestirmeTanimId,
        'basvuruid':basvuruid
      }
    });
  }


  getById(id: number): Observable<Response<TercihBasvuru>> {
    return this.http.get<Response<TercihBasvuru>>(this.apiUrl + '/getById?id=' + id);
  }

  delete(sinavbasvuru: TercihBasvuru): Observable<Response<TercihBasvuru>> {
    return this.http.post<Response<TercihBasvuru>>(this.apiUrl + '/delete', sinavbasvuru)
  }

  dosyaBilgi(basvuruid: number, dosyaTip:string):Observable<any> {
    return this.http.get<any>(this.apiUrl + "/fileInfo/?basvuruid="+basvuruid+"&dosyaTip="+dosyaTip)
  }

  ogrBasvuruKontrol(tercihid: number, basvuruid:number):Observable<any> {
    return this.http.get<any>(this.apiUrl + "/ogrBasvuruKontrol/?tercihid="+tercihid+"&basvuruid="+basvuruid)
  }

  basvuruTamamla(basvuruid:number):Observable<any> {
    return this.http.post<any>(this.apiUrl + "/basvuruTamamla/?basvuruid="+basvuruid,{})
  }

  basvuruTekrarGonder(basvuruid:number):Observable<any> {
    return this.http.post<any>(this.apiUrl + "/basvuruTekrarGonder/?basvuruid="+basvuruid,{})
  }

  birimGetir(birimId: number,birimTur:string):Observable<Response<any[]>> {
    return this.http.get<Response<any[]>>(this.apiUrl + "/birimGetir/?birimId="+birimId+"&birimTur="+birimTur)
  }

  birimGetirOgrTercih(birimId: number,tercihTanimId:number,fakId:number=0):Observable<Response<any[]>> {
    return this.http.get<Response<any[]>>(this.apiUrl + "/birimGetirOgrTercih/?birimId="+birimId+'&tercihTanimId='+tercihTanimId+'&fakId='+fakId)
  }

  
  basvuruSonucKaydet(sonHareketDurumBilgi:TercihBasvuruDetay): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/basvuruSonucKaydet', sonHareketDurumBilgi)
  }

  incelemeBaslat(basvuruid: number):Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + "/incelemeBaslat/?basvuruid="+basvuruid,{})
  }

  incelemeBitir(basvuruid: number):Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + "/incelemeBitir/?basvuruid="+basvuruid,{})
  }


  kabulBelgesiAl(basvuruid:number): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/kabulBelgesiAl?basvuruid=' + basvuruid,{});
  }

  dataURItoBlob(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array]);
    return blob;
  }
  

  getListAll(tercihId:number): Observable<Response<TercihBasvuruDetay[]>> {
    return this.http.post<Response<TercihBasvuruDetay[]>>(this.apiUrl + '/getTercihBasvuruTopluDetayList?tercihTanimId='+tercihId,{});
  }


  addUpdate(tercihBasvuruFdata: FormData): Observable<Response<TercihBasvuru>> {
    if (tercihBasvuruFdata.get('basvuruid')!= "0") {
      return this.http.post<Response<TercihBasvuru>>(this.apiUrl + "/update", tercihBasvuruFdata)
    }
    else {
      return this.http.post<Response<TercihBasvuru>>(this.apiUrl + "/add", tercihBasvuruFdata)
    }
  }

  exelIndir() :Observable<Response<any>>{
    let fileName = "Yös Başvuruları.xlsx"
    var element = document.getElementById('kl_tumbasvurular_table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
    return this.http.get<Response<any>>("")
  }


}