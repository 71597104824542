import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { finalize } from 'rxjs';
import { HazirCevap } from 'src/app/models/hazirCevap.model';
import { HazirCevapService } from 'src/app/services/hazircevap.service';
import { TercihBasvuruService } from 'src/app/services/tercih-basvuru.service';
import { YetkiService } from 'src/app/services/yetki.service';
import { isFalsy } from 'utility-types'

declare var bootstrap: any;

@Component({
  selector: 'ogr-tercih-basvuru-ayrinti',
  templateUrl: './ogr-tercih-basvuru-ayrinti.component.html',
  styleUrls: ['./ogr-tercih-basvuru-ayrinti.component.css']
})
export class OgrTercihBasvuruAyrintiComponent implements OnInit {
  @Input() data: any;
  aktifsayfa: number = 1;
  kaydediliyor = false;
  @Output() myevent: EventEmitter<any> = new EventEmitter();
  constructor(private tercihBasvuruService: TercihBasvuruService, public yetkiService: YetkiService, private sanitizer: DomSanitizer, private hazirCevapService: HazirCevapService) { }
  hazirCevaplar: HazirCevap[] = [];
  
  ngOnInit(): void {
    this.hazirCevapList();
    this.cevapPasiflik=true;
    this.data.pathReportString=this.sanitizer.bypassSecurityTrustResourceUrl("");
  }

  hazirCevapList() {
    this.hazirCevapService.getAll('', 1, 1000).subscribe(res => {
      this.hazirCevaplar = res.data;
    });
  }

  modalKapat(){
    bootstrap.Modal.getInstance(document.getElementById('basvuru_ayrinti_modal')).hide();
  }
  
  cevapPasiflik = true;
  cevapYaz(gelen: string) {
    if (gelen == 'Diğer') {
      this.cevapPasiflik = false;
      this.data.sonHareketDurum.islemAciklama='';
    }
    else {
      this.cevapPasiflik = true;
      this.data.sonHareketDurum.islemAciklama = gelen;
    }
  }


  dosyaGoster(id: number = 0, dosyaTip: string) {
    this.tercihBasvuruService.dosyaBilgi(id, dosyaTip).subscribe(res => {
      this.data.dosyaTip = dosyaTip;
      this.data.pathReportString = this.sanitizer.bypassSecurityTrustResourceUrl('data:' + res.data?.contentType + ';base64,' + res.data?.fileBase64Data);
    });
  }


  dosyaIndir(basvuruid: number, dosyaTip: string) {
    this.tercihBasvuruService.dosyaBilgi(basvuruid, dosyaTip).subscribe(res => {
      var blob = this.tercihBasvuruService.dataURItoBlob(res.data?.fileBase64Data);
      const imageFile = new File([blob], res.data?.fileName, { type: res.data?.contentType });
      var objectUrl = URL.createObjectURL(imageFile);
      var fileLink = document.createElement('a');
      fileLink.href = objectUrl;
      fileLink.download = res.data.fileName;
      fileLink.click();
    });

  }

  isFalsyFnc(gelen: string) {
    if (!isFalsy(gelen)) {
      return true;
    }
    return false;
  }


  isBlank(str: string) {
    return (!str || /^\s*$/.test(str));
  }


  @Output() puanDegistir = new EventEmitter<any>();
  islemKaydet(data: any) {
    this.kaydediliyor = true;

    var kontrol = ((data.sonHareketDurum.kisaltma == 'basvuru-hatali' || data.sonHareketDurum.kisaltma == 'basvuru-reddedildi') && this.isBlank(data.sonHareketDurum.islemAciklama) == true
    ) ? true : false;

    if (kontrol == false) {
      this.tercihBasvuruService.basvuruSonucKaydet(data).pipe(finalize(() => {
        this.kaydediliyor = false;
      })).subscribe({
        next: res => {
          if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };

          bootstrap.Modal.getInstance(document.getElementById('basvuru_ayrinti_modal')).hide();
          this.myevent.emit();

          // this.getOylamaMaddeDetayBySenatoId(this.senatoid);
        },
        error: err => {
          if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
            if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
            if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
          }
        }
      });
    }
    else {
      this.kaydediliyor = false;
      this.yetkiService.sweetAlertError("Lütfen red nedenini belirtiniz.")
    }
  }



}
