import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { finalize } from 'rxjs';
import { RaporTanim } from 'src/app/models/sinavTanim.model';
import { SinavTanimService } from 'src/app/services/sinav-tanim.service';
import { YetkiService } from 'src/app/services/yetki.service';

@Component({
  selector: 'belge-dogrula',
  templateUrl: './belge-dogrula.component.html',
  styleUrls: ['./belge-dogrula.component.css']
})
export class BelgeDogrulaComponent implements OnInit {

  constructor(private sinavTanimService: SinavTanimService, private yetkiService: YetkiService,private sanitizer: DomSanitizer) { }

  rapor: RaporTanim = new RaporTanim();
  aliniyorS:boolean=false;
  previewUrl: any = this.sanitizer.bypassSecurityTrustResourceUrl("");
  ngOnInit(): void {

    var searchstring = window.location.search.substring(0, 500);
    const urlParams = new URLSearchParams(searchstring);

    const tip = urlParams.get('tip') ?? "";
    const no = urlParams.get('no') ?? "";
    const gS = urlParams.get('gS') ?? 0;

    if (urlParams.has('tip') && urlParams.has('no') && urlParams.has('gS')) {
      this.aliniyorS=true;
      this.sinavTanimService.belgeDogrula(tip, no,Number(gS)).pipe(finalize(() => {
      })).subscribe({
        next: res => {
          this.rapor.format = 1;
          this.rapor.data = res.data.jsonData;
          this.rapor.id = "3"; //rapor id
          this.rapor.name = "NEÜYÖS Sonuç Belgesi";
          this.sinavTanimService.download("https://neurapor.erbakan.edu.tr/app/raporlama", this.rapor).pipe(finalize(() => {
            this.aliniyorS = false;
          })).subscribe({
            next: res1 => {
            
              this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res1.data.url);
              if (res?.message && res?.message != "") { this.yetkiService.success(res.message, 1) };
            },
            error: err => {
              if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
                if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
                if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
              }
            }
          });
        },
        error: err => {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };

        }
      });
    }

  }

}
