import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { TercihDonemTanim } from 'src/app/models/tercihDonemTanim.model';
import { TercihDonemTanimService } from 'src/app/services/tercih-donem-tanim.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

@Component({
  selector: 'tercih-donem-bilgi',
  templateUrl: './tercih-donem-bilgi.component.html',
  styleUrls: ['./tercih-donem-bilgi.component.css']
})
export class TercihDonemBilgiComponent implements OnInit {

  constructor(private tercihDonemTanimService: TercihDonemTanimService, public yetkiService: YetkiService, private route: ActivatedRoute, private router: Router, private validationService: ValidationService) {
    this.tercihDonemTanimDetayService = tercihDonemTanimService;

  }

  tercihDonemTanimDetay: TercihDonemTanim[] = [];
  tercihDonemTanimDetayService: TercihDonemTanimService;
  siliniyor = false;
  @ViewChild('grid') grid?: GridComponent;

  ngOnInit(): void {

  }
  silModal(tercihDonemTanim: TercihDonemTanim) {
    Swal.fire({
      title: 'Tercih dönem bilgilerini pasife almak istediğinize eminmisiniz ? <span class=text-danger>( İşlem geri alınamaz. )</span>',
      text: 'TERCİH DÖNEM SİL',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sil',
      cancelButtonText: 'İptal',
    }).then((result) => {
      if (result.value) {
        this.siliniyor = true;
        this.tercihDonemTanimService.delete(tercihDonemTanim).subscribe(response => {
          this.grid?.load();
          this.yetkiService.success("Tercih dönem bilgileri başarıyla pasife alındı.", 1)
          this.siliniyor = false;
        }, error => {
          this.yetkiService.error("Hata oluştu.", 1)
          this.grid?.load();
          this.siliniyor = false;
        });
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("İptal edildi.", "Silme işlemi iptal edildi.", 'error')
      }
    })

  }

  exportexcel(): void {
    let fileName = "Tercih Dönem Bilgi.xlsx"
    var element = document.getElementById('kl_subscriptions_table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
  }

}

















