import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '../models/response.model';
import { TercihKontenjan } from '../models/tercihKontenjan.model';

@Injectable({
  providedIn: 'root'
})
export class TercihKontenjanService {
  apiUrl = '/api/tercihKontenjan';

  constructor(private http: HttpClient) { 

  }

  getAll(query: string = '', page: number = 1, rowCount: number = 10,tercihTanimid: number = 0): Observable<Response<TercihKontenjan[]>> {
    return this.http.get<Response<TercihKontenjan[]>>(this.apiUrl + '/getList', {
      params: {
        'aramastr': query,
        'sayfasayisi': page,
        'sayfadakisatirsayisi': rowCount,
        'tercihTanimid': tercihTanimid,
      }
    });
  }

  getListAll(): Observable<Response<TercihKontenjan[]>> {
    return this.http.get<Response<TercihKontenjan[]>>(this.apiUrl + '/getListAll');
  }

  getById(id: number): Observable<Response<TercihKontenjan>> {
    return this.http.get<Response<TercihKontenjan>>(this.apiUrl + '/getById?id=' + id);
  }

  delete(tercihKontenjan: TercihKontenjan): Observable<Response<TercihKontenjan>> {
    return this.http.post<Response<TercihKontenjan>>(this.apiUrl + '/delete', tercihKontenjan)
  }


  addUpdate(tercihKontenjan: TercihKontenjan): Observable<Response<TercihKontenjan>> {    
    if (tercihKontenjan.id > 0) {
      return this.http.post<Response<TercihKontenjan>>(this.apiUrl + "/update", tercihKontenjan)
    }
    else {
      return this.http.post<Response<TercihKontenjan>>(this.apiUrl + "/add", tercihKontenjan)
    }
  }
}