<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    Tercih Kontenjan İşlemleri
                    <!-- <span class="text-muted fs-7 fw-bold mt-2">Alt Başlık</span> -->
                </h1>
            </div>
            <div class="d-flex align-items-center gap-2 gap-lg-3">
                <!-- <a [routerLink]="['yeni']" class="btn btn-sm btn-primary">Yeni Uygulama</a> -->
            </div>
        </div>
    </div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" class="container-fluid">
            <loading [loader]="yukleniyor">
                <form [formGroup]="detayForm" (submit)="kaydetGuncelle()">
                    <div class="card col-8 offset-2" style="border-top: 4px #77c2e9 solid;">
                        <div class="card-header">
                            <h3 class="card-title">
                                Yeni Ekle
                            </h3>
                        </div>
                        <div class="card-body ">
                            <div class="form-group row mb-2">
                                <label class="col-lg-3 col-form-label fw-bold text-info  fs-6" translate>Birim
                                    seçiniz
                                    <span class="text-danger me-2"> : *
                                    </span></label>
                                <div class="col-lg-9">
                                    <select formControlName="fakId" class="form-select" #fakSelect id="fakId"
                                        name="fakId" (change)="bolumList()" style="border-color: #8fb9d5;">
                                        <option [ngValue]=null>Lütfen seçiniz / Please choose...
                                        </option>
                                        <option *ngFor="let item of tercihBirim" [ngValue]="item.birimid">
                                            {{item.birimadi}}
                                        </option>
                                    </select>
                                    <div class="text-danger" *ngFor="let hata of hatalar['FakId']">
                                        {{hata}}
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row mb-2" *ngIf="detayForm.value.fakId>0">
                                <label class="col-lg-3 col-form-label fw-bold text-info  fs-6" translate>Bölüm
                                    seçiniz
                                    <span class="text-danger me-2"> : *
                                    </span></label>
                                <div class="col-lg-9">
                                    <select (change)="programList()" formControlName="bolId" class="form-select"
                                        #fakSelect id="bolId" name="bolId" style="border-color: #8fb9d5;">
                                        <option [ngValue]=null>Lütfen seçiniz / Please choose...
                                        </option>
                                        <option *ngFor="let item of tercihBolum" [ngValue]="item.birimid">
                                            {{item.birimadi}}
                                        </option>
                                    </select>
                                    <div class="text-danger" *ngFor="let hata of hatalar['BolId']">
                                        {{hata}}
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row mb-2" *ngIf="detayForm.value.bolId>0">
                                <label class="col-lg-3 col-form-label fw-bold text-info  fs-6" translate>Program
                                    seçiniz
                                    <span class="text-danger me-2"> : *
                                    </span></label>
                                <div class="col-lg-9">
                                    <select formControlName="programId" class="form-select" #fakSelect id="programId"
                                        name="programId" style="border-color: #8fb9d5;">
                                        <option [ngValue]=null>Lütfen seçiniz / Please choose...
                                        </option>
                                        <option *ngFor="let item of tercihAnabilimDali" [ngValue]="item.birimid">
                                            {{item.birimadi}}
                                        </option>
                                    </select>
                                    <div class="text-danger" *ngFor="let hata of hatalar['ProgramId']">
                                        {{hata}}
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row mb-2">
                                <label class="col-lg-3 col-form-label fw-bold text-info  fs-6" translate>Kayıt Adresi
                                    <span class="text-danger me-2"> : *
                                    </span></label>
                                <div class="col-lg-9">
                                    <textarea style="border-color: #188fdf;width: 100%;" name="kayitAdres"
                                        id="kayitAdres" formControlName="kayitAdres" class="form-control"
                                        maxlength="1000" rows="3">
                                </textarea>
                                    <div class="text-danger" *ngFor="let hata of hatalar['KayitAdres']">
                                        {{hata}}
                                    </div>
                                </div>
                            </div>


                            <div class="form-group row mb-2">
                                <label class="col-lg-3 col-form-label fw-bold text-info  fs-6" translate>Toplam
                                    kontenjan
                                    <span class="text-danger me-2"> : *
                                    </span></label>
                                <div class="col-lg-9">
                                    <input formControlName="toplamKontenjan" type="number" min="0" class="form-control"
                                        id="toplamKontenjan" style="border-color: #8fb9d5;"
                                        placeholder="toplamKontenjan" name="toplamKontenjan" />
                                    <div class="text-danger" *ngFor="let hata of hatalar['ToplamKontenjan']">
                                        {{hata}}
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row mb-2" style="text-align: center;">
                                <label class="col-lg-3  fw-bold text-info  fs-6">
                                </label>

                                <div class="col-md-3 form-check">
                                    <input type="checkbox" class="form-check-input" id="normKadroPlanlama"
                                        name="maviKartVarMi" formControlName="maviKartVarMi" value="true">
                                    <label class="checkbox-lg text-info text-justify" style="font-size:medium;"
                                        for="exampleCheck1">M. K. Kontenjanı var mi ?</label>
                                </div>

                                <ng-container *ngIf="detayForm.value.maviKartVarMi">
                                    <div class="col-md-3 form-floating">
                                        <input (keyup)="onKeypressEvent($event)"    formControlName="maviKartYuzde" type="number" class="form-control"
                                            id="maviKartYuzde" style="border-color: #8fb9d5;"
                                            placeholder="maviKartYuzde" name="maviKartYuzde" min="0" />
                                        <label style="color: #009ef7;" class="fs-4 fw-bolder" for="maviKartYuzde">Mavi
                                            Kart Yuzdesi
                                        </label>
                                        <div class="text-danger" *ngFor="let hata of hatalar['MaviKartYuzde']">
                                            {{hata}}
                                        </div>
                                    </div>

                                    <div class="col-md-3 form-floating">
                                        <input formControlName="maviKartKontenjan" type="number" class="form-control"
                                            id="maviKartKontenjan" style="border-color: #8fb9d5;"
                                            placeholder="maviKartKontenjan" name="maviKartKontenjan" min="0" />
                                        <label style="color: #009ef7;" class="fs-4 fw-bolder"
                                            for="maviKartKontenjan">Mavi Kart Kontenjanı
                                        </label>
                                        <div class="text-danger" *ngFor="let hata of hatalar['MaviKartKontenjan']">
                                            {{hata}}
                                        </div>
                                    </div>
                                </ng-container>

                            </div>

                            
                            <div class="form-group row mb-2" style="text-align: center;">
                                <label class="col-lg-3  fw-bold text-info  fs-6">
                                </label>
                                <div class="col-md-3 form-check">
                                    <input type="checkbox" class="form-check-input" id="uyrukSiniriVarMi"
                                        name="uyrukSiniriVarMi" formControlName="uyrukSiniriVarMi" value="true">
                                    <label class="checkbox-lg text-info text-justify" style="font-size:medium;"
                                        for="exampleCheck1">Uyruk sınırı var mi ?</label>
                                </div>

                                <ng-container *ngIf="detayForm.value.uyrukSiniriVarMi">
                                <div   (keyup)="onKeypressEvent2($event)"  class="col-md-3 form-floating">
                                    <input formControlName="uyrukYuzde" type="number" class="form-control"
                                        id="uyrukYuzde" style="border-color: #8fb9d5;" placeholder="uyrukYuzde"
                                        name="uyrukYuzde" min="0" />
                                    <label style="color: #009ef7;" class="fs-4 fw-bolder" for="uyrukYuzde">Uyruk Yüzdesi
                                    </label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['UyrukYuzde']">
                                        {{hata}}
                                    </div>
                                </div>

                                <div class="col-md-3 form-floating">
                                    <input formControlName="uyrukKontenjan" type="number" class="form-control"
                                        id="uyrukKontenjan" style="border-color: #8fb9d5;" placeholder="uyrukKontenjan"
                                        name="uyrukKontenjan" min="0" />
                                    <label style="color: #009ef7;" class="fs-4 fw-bolder" for="uyrukKontenjan">Uyruk
                                        Kontenjanı
                                    </label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['UyrukKontenjan']">
                                        {{hata}}
                                    </div>
                                </div>

                            </ng-container>
                            </div>

                        </div>

                        <div class="row card-footer">
                            <div class="col-md-6 text-left">
                                <button type="button"    [routerLink]="['/tercih-kontenjan-bilgi',this.tercihid]"   id="kt_modal_new_target_cancel"
                                    class="btn btn-danger btn-hover-rise me-3"><i class="fas fa-reply"></i> Geri
                                </button>
                            </div>
                            <div class="col-md-6 text-end">
                                <submit-button [loader]="kaydediliyor" [label]="yeni?'Kaydet':'Güncelle'"
                                    [loading]="yeni?'Kaydediliyor...':'Güncelleniyor...'">
                                </submit-button>
                            </div>
                        </div>
                    </div>
                </form>
            </loading>
        </div>
    </div>
</div>