export class Adres {
    id:number=0;
    ust_id:number=0;
    sehir_ilce_mahalle_adi:string='';
    minlongitude:string='';
    minlatitude:string='';
    maxlongitude:string='';
    maxlatitude:string='';
    mahalle_id:string='';
}


