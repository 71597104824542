import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '../models/response.model';
import { SinavSonucSablonDto } from '../models/sinavSonucSablonDto.model';
import { RaporTanim } from '../models/sinavTanim.model';
import { TercihDonemTanim } from '../models/tercihDonemTanim.model';
import { YerlestirmeTanim } from '../models/yerlestirmeTanim.model';

@Injectable({
  providedIn: 'root'
})
export class TercihDonemTanimService {
  apiUrl = '/api/tercihDonemTanim';

  constructor(private http: HttpClient) {

  }

  getAll(query: string = '', page: number = 1, rowCount: number = 10): Observable<Response<TercihDonemTanim[]>> {
    return this.http.get<Response<TercihDonemTanim[]>>(this.apiUrl + '/getList', {
      params: {
        'aramastr': query,
        'sayfasayisi': page,
        'sayfadakisatirsayisi': rowCount
      }
    });
  }

  getListAll(): Observable<Response<TercihDonemTanim[]>> {
    return this.http.get<Response<TercihDonemTanim[]>>(this.apiUrl + '/getListAll');
  }

  getById(id: number): Observable<Response<TercihDonemTanim>> {
    return this.http.get<Response<TercihDonemTanim>>(this.apiUrl + '/getById?id=' + id);
  }

  delete(tercihDonemTanim: TercihDonemTanim): Observable<Response<TercihDonemTanim>> {
    return this.http.post<Response<TercihDonemTanim>>(this.apiUrl + '/delete', tercihDonemTanim)
  }

  sonTercihDonemSayisi(): Observable<Response<any>> {
    return this.http.get<Response<any>>(this.apiUrl + '/sonTercihDonemSayisi')
  }

  addUpdate(tercihDonemTanim: TercihDonemTanim): Observable<Response<TercihDonemTanim>> {
    if (tercihDonemTanim.id > 0) {
      return this.http.post<Response<TercihDonemTanim>>(this.apiUrl + "/update", tercihDonemTanim)
    }
    else {
      return this.http.post<Response<TercihDonemTanim>>(this.apiUrl + "/add", tercihDonemTanim)
    }
  }

  kayitListesiYukle(kayitListeSablonDtoJson: string): Observable<Response<SinavSonucSablonDto[]>> {
    return this.http.post<Response<any>>(this.apiUrl + '/kayitListeYukle?kayitListeSablonDtoJson=' + kayitListeSablonDtoJson, {});
  }


  getGridSonucList(dataTablesParameters: any, jsondata: any, sinavid: number): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/sinavSonucGridList', dataTablesParameters, {
      params: {
        'jsonekdata': jsondata,
        'sinavid': sinavid
      }
    });
  }
  
  dosyaBilgi(tercihTanimId: number):Observable<any> {
    return this.http.get<any>(this.apiUrl + "/fileInfo/?tercihTanimId="+tercihTanimId)
  }

  dataURItoBlob(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array]);
    return blob;
  }
  
  tercihImzaDosyaEkle(tercihimzaFdata: FormData): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + "/tercihImzaDosyaEkle", tercihimzaFdata)
  }

  kayitlariSil(tercihId: number): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/kayitSil?tercihTanimId=' + tercihId, {});
  }


}







