import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { TercihBasvuruTip } from 'src/app/models/tercihBasvuruTip.model';
import { SinavTanim } from 'src/app/models/sinavTanim.model';
import { SinavTanimService } from 'src/app/services/sinav-tanim.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { TercihBasvuruTipService } from 'src/app/services/tercih-basvuru-tip.service';

@Component({
  selector: 'tercih-basvuru-tip-bilgi',
  templateUrl: './tercih-basvuru-tip-bilgi.component.html',
  styleUrls: ['./tercih-basvuru-tip-bilgi.component.css']
})
export class TercihBasvuruTipBilgiComponent implements OnInit {

  constructor(private tercihBasvuruTipService: TercihBasvuruTipService, public yetkiService: YetkiService, private route: ActivatedRoute, private router: Router, private validationService: ValidationService) { 
    this.tercihBasvuruTipDetayService=tercihBasvuruTipService;
  
  }

  tercihBasvuruTipDetay: TercihBasvuruTip[] = [];
  tercihBasvuruTipDetayService: TercihBasvuruTipService;
  siliniyor=false;
  @ViewChild('grid') grid?: GridComponent;
  
  ngOnInit(): void {

  }
  silModal(tercihBasvuruTipTanim: TercihBasvuruTip) {
    Swal.fire({
      title: 'TercihBasvuruTip bilgilerini pasife almak istediğinize eminmisiniz ? <span class=text-danger>( İşlem geri alınamaz. )</span>',
      text: 'TİP SİL',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sil',
      cancelButtonText: 'İptal',
    }).then((result) => {
      if (result.value) {
        this.siliniyor = true;
        this.tercihBasvuruTipService.delete(tercihBasvuruTipTanim).subscribe(response => {
          this.grid?.load();
          this.yetkiService.success("TercihBasvuruTip bilgileri başarıyla pasife alındı.", 1)
          this.siliniyor = false;
        }, error => {
          this.yetkiService.error("Hata oluştu.", 1)
          this.grid?.load();
          this.siliniyor = false;
        });
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("İptal edildi.", "Silme işlemi iptal edildi.", 'error')
      }
    })

  }

  
  exportexcel(): void {
    let fileName = "TercihBasvuruTipBilgi.xlsx"
    var element = document.getElementById('kl_subscriptions_table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
  }

}