import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { finalize } from 'rxjs';
import { TercihDonemTanim } from 'src/app/models/tercihDonemTanim.model';
import { TercihDonemTanimService } from 'src/app/services/tercih-donem-tanim.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';
import * as Editor from 'ckeditor5/build/ckeditor';

@Component({
  selector: 'tercih-donem-islem',
  templateUrl: './tercih-donem-islem.component.html',
  styleUrls: ['./tercih-donem-islem.component.css']
})
export class TercihDonemIslemComponent implements OnInit {
  kaydediliyor = false;

  constructor(private formBuilder: FormBuilder, private yetkiService: YetkiService, private tercihDonemTanimervice: TercihDonemTanimService, private route: ActivatedRoute, private router: Router, private validationService: ValidationService, private datePipe: DatePipe) { }
  yukleniyor: boolean = false;
  hatalar: any = {};
  detayForm = this.formBuilder.group(new TercihDonemTanim());
  yeni = true;
  public Editor = Editor;
  editorConfig = {
    
    toolbar: {
      
      items: [
        'bold',
        'italic',
        'underline',
        'link',
        '|',
        'alignment',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'undo',
        'redo'
      ],
      
    },
    language: 'tr',
    licenseKey: '',
  };
  paramid:number=0;


  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['id'] != 'yeni') {
        this.getById(params['id']);
        this.paramid=Number(params['id']);
      }
      else {
        this.detayForm = this.formBuilder.group(new TercihDonemTanim());
        this.sonTercihDonemSay();
      }
    });
  }

  public model = {
    editorData: ''
  };
  yenidata: string = "";

  getById(id: number) {
    this.yukleniyor = true;
    this.tercihDonemTanimervice.getById(id).subscribe(res => {
      this.yeni = false;
      this.model.editorData=res.data.aciklama;
      this.detayForm.patchValue(res.data);
      this.yukleniyor = false;
    }, err => {
      this.yukleniyor = false;
      this.router.navigateByUrl('/')
    });
  }

  public onChange({ editor }: ChangeEvent) {
    const data = editor.getData();
  }

  sonTercihDonemSay() {
    this.tercihDonemTanimervice.sonTercihDonemSayisi().subscribe(res => {
      this.detayForm.controls['donem']?.setValue(res.data.yeniSayi);
    });
  }



  ImzaBelge:any;
  selectAndSave(evt: any) {
    var files = evt.target.files;
    var file = files[0];
    var type = file.type;
    let dekont: string[] = ['image/jpeg', 'image/jpg', 'image/jpeg', 'image/png'];
    var uygunMu = dekont.indexOf(type);
    if (files && file && evt.target.files[0]?.size < 10485760 && uygunMu > -1) {
        this.ImzaBelge = file;
        const formData = new FormData();
        formData.append("fileImza", this.ImzaBelge);
        formData.append("tercihTanimid", ''+this.paramid);

        this.tercihDonemTanimervice.tercihImzaDosyaEkle(formData).pipe(finalize(() => {
        })).subscribe({
          next: res => {
            this.getById(this.paramid);
          },
          error: err => {
            this.hatalar = this.validationService.hatalar(err.error.Errors);
            if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
              if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
              if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
            }
          }
        });
    }
    else {
      this.yetkiService.sweetAlertError("Seçilen dosya formatı veya boyutu hatalı. / The selected file format or size is incorrect.");
        this.ImzaBelge = null;
    }
  }


  dosyaIndir() {
    this.tercihDonemTanimervice.dosyaBilgi(this.paramid).subscribe(res => {
      var blob = this.tercihDonemTanimervice.dataURItoBlob(res.data?.fileBase64Data);
      const imageFile = new File([blob], res.data?.fileName, { type: res.data?.contentType });
      var objectUrl = URL.createObjectURL(imageFile);
      var fileLink = document.createElement('a');
      fileLink.href = objectUrl;
      fileLink.download = res.data.fileName;
      fileLink.click();
    });
  }



  kaydetGuncelle() {
    this.kaydediliyor = true;
    this.hatalar = {};
    var form = this.detayForm.value;


    const datepipe: DatePipe = new DatePipe('en-US');

    let baslangicTarihi = datepipe.transform(form.baslangicTarihi, 'yyyy-MM-ddTHH:mm');
    form.basvuruBaslangicTarihi = baslangicTarihi;

    let bitisTarihi = datepipe.transform(form.bitisTarihi, 'yyyy-MM-ddTHH:mm');
    form.basvuruBitisTarihi = bitisTarihi;

    let kayitBitisTarih = datepipe.transform(form.kayitBitisTarih, 'yyyy-MM-ddTHH:mm');
    form.kayitBitisTarih = kayitBitisTarih;

    let kayitBaslangicTarih = datepipe.transform(form.kayitBaslangicTarih, 'yyyy-MM-ddTHH:mm');
    form.kayitBaslangicTarih = kayitBaslangicTarih;

    if (baslangicTarihi != null && bitisTarihi != null) {
      var dateBas = formatDate(form.baslangicTarihi, 'yyyy-MM-ddTHH:mm', 'en_US');
      var dateBit = formatDate(form.bitisTarihi, 'yyyy-MM-ddTHH:mm', 'en_US');

      if (dateBas < dateBit) {
        form.aciklama = this.model.editorData;
        this.tercihDonemTanimervice.addUpdate(form).pipe(finalize(() => {
          this.kaydediliyor = false;
        })).subscribe({
          next: res => {
            if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
            this.router.navigateByUrl('/tercih-donem-bilgi')
          },
          error: err => {
            this.hatalar = this.validationService.hatalar(err.error.Errors);
            if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
              if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
              if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
            }

          }
        });
      }
      else {
        this.kaydediliyor = false;
        this.yetkiService.sweetAlertError('Başvuru başlangıç tarihi başvuru bitiş tarihinden küçük olmalıdır.')
      }
    }
    else {
      this.kaydediliyor = false;
      this.yetkiService.sweetAlertError('Başvuru başlangıç tarihi ve başvuru bitiş tarihi boş olmamalıdır.')
    }
  }
}

