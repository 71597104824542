import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { Token } from '../models/token.model';
import { Kullanici } from '../models/kullanici.model';
import { GirisService } from '../services/giris.service';
import jwtDecode from 'jwt-decode';
import { YetkiService } from '../services/yetki.service';
import i from 'src/assets/plugins/formvalidation/dist/es6/plugins/Aria';

@Injectable({
  providedIn: 'root'
})
export class GirisGuard implements CanActivate {

  constructor(private router: Router, private girisService: GirisService, private yetkiService: YetkiService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const auth = localStorage.getItem('auth');
    if (auth) {
      const token = JSON.parse(auth) as Token;

      if (route.data['roller']) {
        var deger = false;
        for (const rol of route.data['roller'] as string[]) {
          if (this.yetkiService.yetkiVar(rol))
            deger = true;
        }
        if (!deger) {
          this.router.navigate(['yetkisizgiris']);
          return false;
        }
      }
      if (new Date(token.expiration).getTime() - (5 * 60 * 1000) > new Date().getTime()) {
        return true;
      } else {
        return this.girisService.refreshToken(token.userUid).pipe(
          map(res => {
            localStorage.setItem('auth', JSON.stringify(res.data));
            return true;
          }),
          catchError(err => {
            this.router.navigate(['giris']);
            return of(false);
          })
        );
      }
    }
    this.router.navigate(['giris']);
    return false;
  }

}
