    <div class="toolbar mb-5" style="height: 8%">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">

                <h1 class="d-flex text-dark fw-bolder fs-2 flex-column mb-0">
                    <span>
                        <i class="fas fa-highlighter me-2" style="font-size: large; color: #d4af37;"></i>Tercih Dönem
                        Listesi</span>
                    <!-- <span class="text-muted fs-7 fw-bold mt-2">Alt Başlık</span> -->
                </h1>
            </div>

            <div class="d-flex align-items-center gap-2 gap-lg-3">
                <button type="button" routerLink="/" id="kt_modal_new_target_cancel"
                class="btn btn-danger btn-hover-rise me-3"><i class="fas fa-reply"></i> Geri
            </button>
                <button class="btn btn-sm  btn-shadow-hover btn-secondary fs-6  border border-primary"
                    (click)="exportexcel()" style="color: green;">
                    <i class="fas fa-file-excel " style="color: green;"></i>
                    Excel
                </button>
                <a *ngIf="yetkiService.yetkiVar(['TercihTanim.Add'])"
                class="btn btn-sm  btn-shadow-hover fs-6" [routerLink]="['yeni']"
                    style="background-color: #77c2e9; color: white;">
                    <i class="fas fa-plus" style="color: white;"></i>
                    <!--end::Svg Icon-->Yeni Tercih Dönemi Ekle
                </a>

            </div>
        </div>
    </div>

    <div class="post d-flex flex-column-fluid mt-10 mb-10" id="kt_post">
        <div id="kt_content_container" class="container-fluid">
            <grid #grid [service]="tercihDonemTanimDetayService" [(data)]="tercihDonemTanimDetay" [rowCount]="10">
                <div style="width: 100%;overflow-x: auto;height: 500px;">
                    <table class="table   table-responsive gn-middle table-row-bordered fs-7 gy-2"
                        id="kl_subscriptions_table">
                        <thead>
                            <tr class="text-start fw-bolder fs-7 gs-0 " style="color:#cd7f32">
                                <th class="text-center" style="width: 5%;">#</th>
                                <th class="text-center">Tanım</th>
                                <th class="text-center">Yıl / Sayı</th>
                                <th class="text-center">Y. Tercih Sayısı</th>
                                <th class="text-center"> Başvuru Başlangiç / Bitiş Tarihi</th>
                                <th class="text-center">Başvuru Sayısı</th>
                                <th class="text-center">Anasayfada Görünürlük</th>
                                <th class="text-center">#</th>
                            </tr>
                        </thead>
                        <tbody class="text-gray-600 ">
                            <!-- dblclick -->
                            <tr *ngFor="let item of tercihDonemTanimDetay;let i=index;" style="color: black;">
                                <td class="text-info text-left text-center fs-5" style="vertical-align: middle;">
                                    <b>{{(i+1) + (grid.page-1)*10}}</b>
                                </td>
                                <td class="text-center fw-bold fs-5" style="vertical-align: middle;">
                                    {{ item.adi }}
                                </td>
                                <td class="text-center fw-bold fs-5" style="vertical-align: middle;">
                                    {{ item.baslangicTarihi | date:'yyyy' }} / {{ item.donem }}
                                </td>
                                <td class="text-center fw-bold fs-5" style="vertical-align: middle;">
                                    {{ item.yapilacakTercihSayisi }}
                                </td>
                                <td class="text-center fw-bold fs-5" style="vertical-align: middle;">
                                    {{ item.bitisTarihi | date:'dd.MM.yyyy HH:mm' }} / {{
                                    item.bitisTarihi
                                    | date:'dd.MM.yyyy HH:mm' }}
                                </td>

                                <td class="text-center fw-bold fs-5 text-primary" style="vertical-align: middle;">
                                    {{item.basvuruSayi}}
                                </td>
                                <td class="text-center" style="vertical-align: middle;">
                                    <span class="fs-5 text-gray-800 me-2">
                                        <span *ngIf="item?.anasayfaGorunurluk;else yayindaDegilx">
                                            <i class="fas fa-check fs-2 text-success"></i>
                                        </span>
                                        <ng-template #yayindaDegilx>
                                            <i class="fas fa-times fs-2 text-danger"></i>
                                        </ng-template>
                                    </span>
                                </td>
                                <!-- [queryParams]="{basvuruid: 15}" -->
                                <td class="text-center" style="vertical-align: middle;">
                                    <div class="d-flex justify-content-center flex-shrink-0" style="width:350px">

                                        <a *ngIf="yetkiService.yetkiVar(['TercihTanim.Update'])" [routerLink]="[item.id]" style="background-color:#009ef8"
                                            class="btn btn-icon btn-hover-rise  btn-active-color-primary btn-sm me-1"
                                            title="Tercih tanımı düzenle">
                                            <i class="far fa-edit text-white"></i>
                                        </a>

                                        <a *ngIf="yetkiService.yetkiVar(['TercihSinavTur.MenuGorme'])"  data-toggle="tooltip" [routerLink]="['/sinav-tur', item.id]"
                                            data-theme="dark" title="Sınav türlerini girmek için tıklayınız."
                                            class="btn  btn-hover-rise btn-sm me-1"
                                            style="color: primary; float: right; border: 2px solid green;">
                                            <i class="fas fa-scroll btn-hover-rise text-success fs-2"></i>
                                            Sınav Tür Tanım
                                        </a>

                                        <div class="dropdown" style=" border:2px solid rgb(57, 73, 216);">
                                            <button
                                                class="btn btn-icon-muted btn-sm  btn-active-default text-danger dropdown-toggle"
                                                type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                İŞLEM SEÇİNİZ
                                            </button>

                                            <ul class="dropdown-menu " aria-labelledby="dropdownMenuButton">

                                                <a *ngIf="yetkiService.yetkiVar(['TercihKontenjan.MenuGorme'])" class="dropdown-item fs-3 cursor-pointer"
                                                [routerLink]="['/tercih-kontenjan-bilgi', item.id]">
                                                <i class="fas fa-sort-numeric-down-alt text-primary me-3"></i>Tercih Kontenjan Tanımları
                                            </a>
                                            
                                                <a  *ngIf="yetkiService.yetkiVar(['TercihBasvuru.MenuGorme'])" class="dropdown-item fs-3 cursor-pointer"
                                                    [routerLink]="['/tercih-basvurular', item.id]">
                                                    <i class="fas fa-users fs-3 text-success me-3"></i>Başvuruları Gör
                                                </a>

                                                <a  *ngIf="yetkiService.yetkiVar(['TercihYerlestirme.MenuGorme'])" class="dropdown-item fs-3 cursor-pointer"
                                                    [routerLink]="['/tercih-yerlestirme-islem', item.id]">
                                                    <i class="fas fa-edit fs-3 text-success me-3"></i>Yerleştirme İşlemleri
                                                </a>
                                                
                                                <a  *ngIf="yetkiService.yetkiVar(['TercihYerlestirme.MenuGorme'])" class="dropdown-item fs-3 cursor-pointer"
                                                    [routerLink]="['/tercih-kayit-islem', item.id]">
                                                    <i class="far fa-save fs-3 text-success me-3"></i>Kayıt bilgilerini gir
                                                </a>


                                               
                                                <!-- <a class="dropdown-item fs-3 cursor-pointer"
                                                    [routerLink]="['/giris-belge-islem', item.id]">
                                                    <i class="far fa-file-pdf fs-3 text-info me-3"></i>Giriş Belgesi
                                                    İşlemleri</a>

                                                <a class="dropdown-item fs-3 cursor-pointer"
                                                    [routerLink]="['/sonuc-belge-islem', item.id]">
                                                    <i class="fas fa-poll fs-3 text-primary me-3"></i>Sınav Sonuç
                                                    İşlemleri</a> -->
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </grid>
        </div>
    </div>
