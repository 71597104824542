import { DatePipe, formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { TercihKontenjan } from 'src/app/models/tercihKontenjan.model';
import { TercihBasvuruService } from 'src/app/services/tercih-basvuru.service';
import { TercihKontenjanService } from 'src/app/services/tercih-kontenjan.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';

@Component({
  selector: 'tercih-kontenjan-islem',
  templateUrl: './tercih-kontenjan-islem.component.html',
  styleUrls: ['./tercih-kontenjan-islem.component.css']
})
export class TercihKontenjanIslemComponent implements OnInit {
  kaydetercihKontenjaniyor = false;

  constructor(private formBuilder: FormBuilder, private yetkiService: YetkiService, private tercihKontenjanService: TercihKontenjanService, private tercihBasvuruService: TercihBasvuruService, private route: ActivatedRoute, private router: Router, private validationService: ValidationService, private datePipe: DatePipe) { }
  yukleniyor: boolean = false;
  kaydediliyor: boolean = false;
  tercihid: number = 0;
  hatalar: any = {};
  detayForm = this.formBuilder.group(new TercihKontenjan());
  yeni = true;

  tercihBirim: any[] = [];
  tercihBolum: any[] = [];
  tercihAnabilimDali: any[] = [];


  ngOnInit(): void {
    this.birimList();
    this.route.params.subscribe(params => {
      if (params['id'] != 'yeni') {
        this.getById(params['id']);
      }
      else {
        this.detayForm = this.formBuilder.group(new TercihKontenjan());
      }
    });

    this.route.queryParams.subscribe(params => {
      if (params['tercihid']) {
        this.tercihid = Number(params['tercihid']);
      }
    });
  }

  getById(id: number) {
    this.yukleniyor = true;
    this.tercihKontenjanService.getById(id).subscribe(res => {
      this.yeni = false;
      this.detayForm.patchValue(res.data);
      this.bolumList(res.data.fakId ??0);
      this.programList(res.data.bolId??0);
      this.yukleniyor = false;
    }, err => {
      this.yukleniyor = false;
      this.router.navigateByUrl('/')
    });
  }

  kaydetGuncelle() {
    this.kaydediliyor = true;
    this.hatalar = {};
    var form = this.detayForm.value;
    form.tercihTanimId = this.tercihid;
    if(!form.maviKartVarMi){
      form.maviKartYuzde=0;
      form.maviKartKontenjan=0;
    }
    if(!form.uyrukSiniriVarMi){
      form.uyrukYuzde=0;
      form.uyrukKontenjan=0;
    }

    this.tercihKontenjanService.addUpdate(form).pipe(finalize(() => {
      this.kaydediliyor = false;
    })).subscribe({
      next: res => {
        if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
        this.router.navigateByUrl('/tercih-kontenjan-bilgi/' + this.tercihid)
      },
      error: err => {
        this.hatalar = this.validationService.hatalar(err.error.Errors);
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
        }
      }
    });
  }


  birimList() {
    this.tercihBirim = [];
    this.detayForm.get('fakId')?.setValue(null);
    this.tercihBirim.push({ id: null, text: "Lütfen Seçiniz" })
    this.tercihBasvuruService.birimGetir(0,"fakulte").subscribe(res => {
      this.tercihBirim = res.data;
    });
  }

  bolumList(test: number = 0) {
    this.tercihBolum = [];
    this.tercihBolum.push({ id: null, text: "Lütfen Seçiniz" })

    var fakid = this.detayForm.value.fakId;
    if (test != 0) {
      fakid = test;
    }
    else{
      this.detayForm.get('bolId')?.setValue(null);
      this.detayForm.get('programId')?.setValue(null);
    }
    if (fakid != null) {
      this.tercihBasvuruService.birimGetir(fakid,"bolum").subscribe(res => {
        this.tercihBolum = res.data;
        if (this.tercihBolum.length === 0) {
          this.tercihBolum = [];
          this.tercihAnabilimDali = [];
        }
      });
    }
  }

  programList(test:number=0) {
    this.tercihAnabilimDali = [];
    this.tercihAnabilimDali.push({ id: null, text: "Lütfen Seçiniz" })
    var bolid = this.detayForm.value.bolId;
    if (test != 0) {
      bolid = test;
    }
    else{
      this.detayForm.get('programId')?.setValue(null);
    }
    if (bolid != null) {
      this.tercihBasvuruService.birimGetir(bolid,"program").subscribe(res => {
        if(res.data?.length>0){
          this.tercihAnabilimDali = res.data;
        }
        else{
          this.tercihAnabilimDali=this.tercihBolum;
        }
      });
    }
    else {
      this.tercihAnabilimDali = [];
    }
  }

  onKeypressEvent(event: any){
    var kontenjan=this.detayForm.value.toplamKontenjan;
    const yuzde=(this.detayForm.value.toplamKontenjan*this.detayForm.value.maviKartYuzde)/100;  
    this.detayForm.get('maviKartKontenjan')?.setValue(Math.ceil(yuzde));
  }

  onKeypressEvent2(event: any){
    var kontenjan=this.detayForm.value.toplamKontenjan;
    const yuzde=(this.detayForm.value.toplamKontenjan*this.detayForm.value.uyrukYuzde)/100;  
    this.detayForm.get('uyrukKontenjan')?.setValue(Math.ceil(yuzde));
  }
}

