import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  hatalar(errors: []): any {
    let obj: any = {};
    if (errors) {
      for (let i = 0; i < errors.length; i++) {
        const err = errors[i] as any;
        if (obj[err.PropertyName]) {
          obj[err.PropertyName].push(err.ErrorMessage);
        } else {
          obj[err.PropertyName] = [err.ErrorMessage];
        }
      }
    }
    return obj;
  }
}
