<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h3 class="d-flex text-dark fw-bolder fs-2 flex-column mb-0">
                    <span><i class="fas fa-file-signature me-2" style="font-size: large; color: #d4af37;"></i>
                        Geçmiş tercih başvurularım / My past preferences
                    </span>
                </h3>

            </div>
            <div class="d-flex align-items-center gap-2 gap-lg-3">
                <button type="button" routerLink="/" id="kt_modal_new_target_cancel"
                    class="btn btn-danger btn-hover-rise me-3" translate><i class="fas fa-reply"></i>geri
                </button>
            </div>
        </div>
    </div>

    <div class="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" class="container-fluid mb-10">
            <div class="card " style="border-top: 3px rgb(146, 199, 224) solid;">
                <div class="card-body table-responsive" style="width: 100%;">
                    <table datatable [dtOptions]="dtOptions" class="row-border hover">
                        <thead>
                            <tr>
                                <th class="min-w-150px">#</th>
                                <th class="min-w-75px">Aday No</th>
                                <th class="min-w-75px" style="display: none;">Uyruk</th>
                                <th class="min-w-75px">Adı</th>
                                <th class="min-w-75px">Soyadı</th>
                                <th class="min-w-25px text-center">Sınav Puan (Yüzlük )</th>
                                <th class="min-w-25px text-center">Değerlendirme Puanı</th>
                                <th class="min-w-25px text-center">Yerleşme Durumu</th>
                                <th class="min-w-200px text-center">Durum</th>
                                <th class="text-center" style="display: none;">Başvuru Tarihi</th>
                                <th style="width:250px;"></th>
                            </tr>

                        </thead>
                        <tbody *ngIf="basvuruDetay?.length!= 0">
                            <tr *ngFor="let basvuru of basvuruDetay;let i=index;">
                                <td class="text-info text-left fs-5" style="vertical-align: middle;">
                                    <b>{{basvuru.tercihTanim?.adi}} - {{basvuru.tercihTanim?.baslangicTarihi |
                                        date:'dd.MM.yyyy'}}</b>
                                </td>
                                <td>
                                    {{ basvuru?.basvuruNo }}
                                </td>
                                <td style="display: none;">
                                    {{ basvuru?.ilkUyrukAdi }}
                                </td>
                                <td>
                                    {{ basvuru?.adi }}
                                </td>
                                <td>
                                    {{ basvuru?.soyadi }}
                                </td>
                                <td class="text-center">
                                    <b>{{ basvuru?.sinavPuanYuzluk }}</b>
                                </td>
                                <td class="text-center">
                                    <b> {{ basvuru?.degerlendirmePuan }}</b>
                                </td>

                                <td class="text-center">
                                    <span *ngIf="basvuru.yerlesmeDurum;else yerlesmedi">
                                        <i class="fas fa-check fs-3 text-success"></i>
                                    </span>
                                    <ng-template #yerlesmedi>

                                        <i class="fas fa-times fs-3 text-danger"></i>
                                    </ng-template>
                                </td>
                                <td class="text-center">
                                    <span class="fw-bolder text-info"> <i
                                            class="{{basvuru.sonHareketDurum?.iconClass}} fs-3 me-2"></i> {{
                                        basvuru.durumAdi
                                        }}</span>
                                </td>
                                <td class="text-center" style="display: none;">
                                    <span class="fw-bolder text-info"> {{
                                        basvuru?.basvuruTarih | date:'dd.MM.yyyy HH:mm'
                                        }}</span>
                                </td>

                                <td>
                                    <div class="d-flex justify-content-center flex-shrink-0">
                                        <a (click)="ayrintiModal(basvuru)" style="background-color:#2aa31f"
                                            data-toggle="tooltip" data-theme="dark" title="BAŞVURU BİLGİLERİNİ GÖR"
                                            class="btn btn-icon   btn-sm me-1" data-bs-toggle="modal"
                                            data-bs-target="#basvuru_ayrinti_modal_tercih">
                                            <i class="fas fa-info-circle text-white" style="font-size:x-large;"></i></a>

                                        <div class="dropdown" style=" border:2px solid rgb(57, 73, 216);">
                                            <button
                                                class="btn btn-icon-muted btn-sm  btn-active-default text-danger dropdown-toggle"
                                                type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                Belge Al / Get Document
                                            </button>

                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">

                                                <a class="dropdown-item fs-3 cursor-pointer" *ngIf="basvuru.basvuruBilgi?.yerlestiMi && basvuru.yerlestirmeYayindami"  data-bs-toggle="modal" data-bs-target="#belge_modal"
                                                data-toggle="modal"
                                                     (click)="kabulBelgesiAl(basvuru.basvuruBilgi.id)">
                                                    <i class="fas fa-file fs-3 text-primary me-3"></i>Kabul belgesi al / Get a certificate of acceptance
                                                </a>
                                            </ul>
                                        </div>

                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="basvuruDetay?.length == 0">
                            <tr>
                                <td colspan="9" class="no-data-available text-center">Herhangi bir veri bulunamadı.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>



<div id="basvuru_ayrinti_modal_tercih" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="" style="border-top: #36d35d 1px solid">

                <loading [loader]="basvuruSecilen==null">
                    <ogr-tercih-basvuru-ayrinti [data]="basvuruSecilen" *ngIf="basvuruSecilen">

                    </ogr-tercih-basvuru-ayrinti>
                </loading>
            </div>
        </div>
    </div>
</div>

<div id="belge_modal" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl" style="width: 870px;">

        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" style="border-top: #36d35d 1px solid">
                <loading [loader]="aliniyorKbelge">
                    <iframe id="iframe" name="iframe" frameBorder="10" [src]="previewUrl" marginHeight="0"
                        marginWidth="0" width="100%" height="600px" scrolling="auto">
                    </iframe>
                </loading>
            </div>
        </div>
    </div>
</div>