type Nullable<T> = T | null;
export class OgrKullanici {
    id:number=0;
    adi:string='';
    soyadi:string='';
    email:string='';
    sifreSalt:any;
    sifreHash:any;
    eskiSifre:string='';
    yeniSifre:string='';
    yeniSifreTekrar:string='';
    dogum_tarihi?:  Nullable<Date> = null;
    email_dogrulama?:boolean=false;
    kayit_tarihi?:Nullable<Date> = null;
    sonGuncellemeTarihi?:Nullable<Date> = null;
    aciklama:string='';
    userUid:string='';
    arsiv:boolean=false;
    duzenleme_durum:boolean=false;
    sifreDegistir:boolean=false;
    cepno:number=0;
    teskilat:number=0;
    unvan:number=0;
    komisyon:number=0;
    birim:number=0;
    altbirim:number=0;
    altbirimaciklama:string='';
    adres:string='';
    il:number=0;
    ilce:number=0;
    onay:boolean=false;
    rol:number=0;
}

export class OgrKullaniciColumns {
    columnsdegerler = [
        {
            title: '#',
            data: 'dataIndex',
            render: function () {
                return null;
            },
        },
        {
            name: "email",
            title: 'Email',
            data: 'email',
        },
        {
            name: "adi",
            title: 'Adı',
            data: 'adi',
        },
        {
            name: "soyadi",
            title: 'Soyadı',
            data: 'soyadi',
        },
        {
            name: "ePostaDogrulama",
            title: 'E-posta Doğrulama',
            data: 'ePostaDogrulama',
        },
        {
            title: '#',
            data: '',
            render: function () {
                return null;
            },
        },
    ];
}