import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.css']
})
export class SubmitButtonComponent implements OnInit {

  constructor() { }

  @Input() loader = false;
  @Input() label = 'Kaydet';
  @Input() loading = 'Kaydediliyor...';
  @Input() disabled = false;
  ngOnInit(): void {
  }

}
