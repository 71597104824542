<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h3 class="d-flex text-dark fw-bolder fs-2 flex-column mb-0">
                    <span><i class="fas fa-user me-2" style="font-size: large; color: #d4af37;"></i>
                        Öğrenci Kullanıcı Listesi
                    </span>
                </h3>
               
            </div>
            <button type="button" routerLink="/" id="kt_modal_new_target_cancel"
            class="btn btn-danger btn-hover-rise me-3"><i class="fas fa-reply"></i> Geri
        </button>
        </div>
    </div>

    <div class="post d-flex flex-column-fluid mb-10" id="kt_post">
        <div id="kt_content_container" class="container-fluid">
            <!-- <form (submit)="filterById()">
            <label>
              Min
              <input type="text" name="adi" id="adi" [(ngModel)]="basvuruTanimlar.adi" />
            </label>
            
            <button class="btn btn-primary " type="submit">Filter by ID</button>
          </form>   -->

            <div class="card " style="border-top: 3px rgb(146, 199, 224) solid;">
                <div class="card-body table-responsive" style="width: 100%;">
                    <table datatable [dtOptions]="dtOptions" class="row-border hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th><input class="form-control search-input" style="border-color: #8fb9d5;" id="1"
                                        type="text" placeholder="E-posta" />
                                </th>
                                <th><input class="form-control search-input" style="border-color: #8fb9d5;" id="2"
                                        type="text" placeholder="Ad" />
                                </th>
                                <th><input class="form-control search-input" style="border-color: #8fb9d5;" id="3"
                                        type="text" placeholder="Soyad" />
                                </th>
                                <th>

                                </th>
                                <th>

                                </th>
                            </tr>

                            <tr>
                                <th class="min-w-20px">#</th>
                                <th class="min-w-75px">E-posta</th>
                                <th class="min-w-75px">Adı</th>
                                <th class="min-w-75px">Soyadı</th>
                                <th class="text-center">E-posta doğrulama</th>
                                <th class="text-center">#</th>
                            </tr>

                        </thead>
                        <tbody *ngIf="ogrKullanicilar?.length!= 0">
                            <tr *ngFor="let ogrKullanici of ogrKullanicilar;let i=index;">
                                <td class="text-info text-left fs-5" style="vertical-align: middle;">
                                    <b>{{(i+1) + (this.pageNo-1)*10}}</b>
                                </td>
                                <td>
                                    {{ ogrKullanici?.email }}
                                </td>
                                <td>
                                    {{ ogrKullanici?.adi }}
                                </td>
                                <td>
                                    {{ ogrKullanici?.soyadi }}
                                </td>
                                <td style="vertical-align: middle;text-align: center;">
                                    <span class="fs-5 text-gray-800">
                                        <span *ngIf="ogrKullanici?.email_dogrulama;else dogrulamaYok">
                                            <i class="fas fa-check fs-2 text-success"></i>
                                        </span>
                                        <ng-template #dogrulamaYok>
                                            <i class="fas fa-times fs-2 text-danger"></i>
                                        </ng-template>
                                    </span>  
                                </td>
                                <td>
                                    <a (click)="getByIdOgr(ogrKullanici.id)"
                                        style="background-color:#009ef8" data-bs-toggle="modal" data-theme="dark"
                                        class="btn btn-icon  btn-active-color-primary btn-sm me-1"
                                        title="Kullanıcı işlemleri" data-bs-target="#kullanici_islem_modal">
                                        <i class="fas fa-unlock-alt text-white"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="ogrKullanicilar?.length == 0">
                            <tr>
                                <td colspan="6" class="no-data-available text-center">Herhangi bir veri bulunamadı.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>


<div id="kullanici_islem_modal" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <i class="fas fa-user me-2 text-primary" style="font-size: large; padding-right:2px;"></i>
                <h2 class="modal-title">Kullanıcı işlemleri</h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" style="border-top: #36d35d 1px solid">
                <form [formGroup]="ogrKullaniciForm" (submit)="sifreKaydetGuncelle()">
                    <div class="card-body ">
                        <div class="form-floating mb-3">
                            <input formControlName="email" type="text" class="form-control" id="email"
                                style="border-color: #8fb9d5;" placeholder="email" name="email" />
                            <label style="color: #009ef7;" class="fs-4 fw-bold" for="adi" translate>eposta</label>
                            <div class="text-danger" *ngFor="let hata of hatalar['Email']">
                                {{hata}}
                            </div>
                        </div>
                        <div class="mb-2 mt-2">
                            <input type="checkbox" class="form-check-input me-2" formControlName="sifreDegistir"
                                id="sifreDegistir" name="sifreDegistir">
                            <label class="checkbox-lg text-info text-justify" style="font-size:medium;"
                                for="exampleCheck1">Şifre Değiştir</label>
                        </div>

                        <div *ngIf="ogrKullaniciForm.value.sifreDegistir">
                            <div class="form-floating mb-3">
                                <input formControlName="yeniSifre" type="password" class="form-control" id="yeniSifre"
                                    style="border-color: #8fb9d5;" placeholder="yeniSifre" name="yeniSifre" />
                                <label style="color: #009ef7;" class="fs-4 fw-bold" for="adi" translate>yeniPass</label>
                                <div class="text-danger" *ngFor="let hata of hatalar['yeniSifre']">
                                    {{hata}}
                                </div>
                            </div>
                            <div class="form-floating mb-3">
                                <input formControlName="yeniSifreTekrar" type="password" class="form-control"
                                    id="yeniSifreTekrar" style="border-color: #8fb9d5;" placeholder="yeniSifreTekrar"
                                    name="yeniSifreTekrar" />
                                <label style="color: #009ef7;" class="fs-4 fw-bold" for="yeniSifreTekrar"
                                    translate>yeniPassRepeat</label>
                                <div class="text-danger" *ngFor="let hata of hatalar['yeniSifreTekrar']">
                                    {{hata}}
                                </div>
                            </div>
                        </div>

                        <div class="mb-2 mt-2">
                            <input type="checkbox" class="form-check-input me-2" formControlName="duzenlemeDurum"
                                id="duzenlemeDurum" name="duzenlemeDurum">
                            <label class="checkbox-lg text-info text-justify" style="font-size:medium;"
                                for="exampleCheck1">Profil bilgilerini düzenleyebilsin</label>
                        </div>

                    </div>
                    <div class="row card-footer">
                        <div class="col-md-12 text-center">
                            <submit-button [loader]="kaydediliyor" [label]="'Güncelle'"
                                [loading]="yeni?'Kaydediliyor...':'Güncelleniyor...'">
                            </submit-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>