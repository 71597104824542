import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { SinavOdemeBilgiService } from 'src/app/services/sinavOdemeBilgi.service';
import { YetkiService } from 'src/app/services/yetki.service';

@Component({
  selector: 'kart-odeme-kontrol',
  templateUrl: './kart-odeme-kontrol.component.html',
  styleUrls: ['./kart-odeme-kontrol.component.css']
})
export class KartOdemeKontrolComponent implements OnInit {

  constructor(private yetkiService: YetkiService, private route: ActivatedRoute, private router: Router, private odemeBilgiService: SinavOdemeBilgiService) { }
  basTar: any;
  bitTar: any;
  yukleniyor = false;
  odemeListesi: any[] = [];
  odemeListesiYeni: any[] = [];

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }
  ngOnInit(): void {
    const datepipe: DatePipe = new DatePipe('en-US');
    this.bitTar = datepipe.transform(this.addDays(new Date(),1), 'yyyy-MM-dd');
    this.basTar = datepipe.transform(this.addDays(new Date(),-6), 'yyyy-MM-dd');

  }

  kartOdemeListesiniGetir() {
    const datepipe: DatePipe = new DatePipe('en-US');
    let firstDate = datepipe.transform(this.basTar, 'yyyy-MM-ddTHH:mm:ss');
    let lastDate = datepipe.transform(this.bitTar, 'yyyy-MM-ddTHH:mm:ss');

    if (firstDate != null && lastDate != null) {
      this.yukleniyor = true;
      this.odemeBilgiService.kartOdemeListesiGetir(firstDate, lastDate).pipe(finalize(() => {
        this.yukleniyor = false;
      })).subscribe({
        next: res => {
          this.odemeListesi = res?.data;
          this.odemeListesiYeni = res?.data;
        },
        error: err => {
          this.yetkiService.sweetAlertError('Hata oluştu. Lütfen başlangıç ve bitiş tarihini kontrol ediniz.')

        }
      });
    }
    else {
      this.yetkiService.sweetAlertError("Lütfen başlangıç ve bitiş tarihini seçiniz.");
    }

  }

  isBlank(str: string) {
    return (!str || /^\s*$/.test(str));
  }

  searchChange(event: Event) {
    const value = (event.target as HTMLInputElement).value?.toLocaleLowerCase();
    if (this.odemeListesi.length > 0) {
      this.odemeListesiYeni = this.odemeListesi.filter(p => p.TCYUPNumber.includes(value) || p.AdiSoyadi?.toLocaleLowerCase().includes(value) ||  p.TransactionId.includes(value)  || this.isBlank(value));
    }
  }

}
