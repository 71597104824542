<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span><i class="fas fa-envelope me-2 text-success fs-3"></i> <span translate>iletisimYeni</span>
                    </span>
                    <!-- <span class="text-muted fs-7 fw-bold mt-2">Alt Başlık</span> -->
                </h1>
            </div>
            <div class="d-flex align-items-center gap-2 gap-lg-3">
                <!-- <a [routerLink]="['yeni']" class="btn btn-sm btn-primary">Yeni Uygulama</a> -->
            </div>
        </div>
    </div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" class="container-fluid">
            <loading [loader]="yukleniyor">
                <form [formGroup]="detayForm" (submit)="kaydetGuncelle()">
                    <div class="card col-12 col-md-6 offset-md-3" style="border-top: 4px #77c2e9 solid;">
                        <div class="card-header">
                            <h3 class="card-title">
                                <i class="fas fa-exclamation-triangle me-2 text-danger fs-2 me-2"></i>
                                <span translate>iletisimUyari2</span>
                            </h3>
                        </div>
                        <div class="card-body ">
                            <div class="form-floating mb-3">
                                <textarea style="border-color: #18dfbe;width: 100%; background-color: white;"
                                    name="mesaj" id="mesaj" formControlName="mesaj" class="form-control"
                                    maxlength="1000" cols="10" rows="8"
                                    placeholder="Lütfen red nedenini belirtiniz."></textarea>
                                <label style="color: #009ef7;" class="fs-4 fw-bold" for="mesaj">Mesajınızı
                                    giriniz.</label>
                                <div class="text-danger" *ngFor="let hata of hatalar['Mesaj']">
                                    {{hata}}
                                </div>
                            </div>
                        </div>
                        <div class="row card-footer">
                            <div class="col-md-6 text-left col-6">
                                <button type="button" routerLink="/iletisim-bilgi" id="kt_modal_new_target_cancel"
                                    class="btn btn-danger btn-hover-rise me-3"><i class="fas fa-reply"></i><span translate>geri</span>
                                </button>
                            </div>
                            <div class="col-md-6 text-end col-6">
                                <submit-button [loader]="kaydediliyor" [label]="yeni?'Gönder':'Güncelle'"
                                    [loading]="yeni?'Kaydediliyor...':'Güncelleniyor...'">
                                </submit-button>
                            </div>
                        </div>

                    </div>

                </form>
            </loading>
        </div>
    </div>
</div>