<div class="toolbar mb-5" style="height: 8%">
    <div class="container-fluid d-flex flex-stack">
        <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">

            <h1 class="d-flex text-dark fw-bolder fs-2 flex-column mb-0">
                <span style="font-size: medium;">
                    <i class="far fa-map me-2 fs-3" style="color: #d4af37;"></i>Tercih yerleştirme
                    işlemleri &nbsp;(
                    {{this.tercihTanim.adi }}
                    <span>
                        &nbsp;
                        <i class="fas fa-calendar-alt text-success fs-3"></i>
                        {{this.tercihTanim.baslangicTarihi | date:'dd.MM.yyyy HH:mm' }}</span>
                    &nbsp;-&nbsp;
                    &nbsp;&nbsp;
                    <i class="fas fa-calendar-alt text-danger fs-3"></i>
                    {{this.tercihTanim.bitisTarihi | date:'dd.MM.yyyy HH:mm' }})</span>

                <!-- <span class="text-muted fs-7 fw-bold mt-2">Alt Başlık</span> -->
            </h1>
        </div>

        <div class="d-flex align-items-center gap-2 gap-lg-3">
            <button type="button" routerLink="/tercih-donem-bilgi" id="kt_modal_new_target_cancel"
            class="btn btn-danger btn-hover-rise me-3"><i class="fas fa-reply"></i> Geri
        </button>
            <button *ngIf="yetkiService.yetkiVar(['TercihYerlestirme.Add'])"
            type="button" class="btn btn-success" [attr.data-kt-indicator]="yapiliyor ? 'on' : 'off'"
                [disabled]="yapiliyor" (click)="yerlestirmeModal()">
                <span class="indicator-label"><i class="fas fa-plus"></i>Yeni yerleştirme yap
                </span>
                <span class="indicator-progress">Yerleştirme yapılıyor...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            </button>
        </div>
    </div>
</div>

<div class="post d-flex flex-column-fluid mt-10" id="kt_post">
    <div id="kt_content_container" class="container-fluid">

        <grid #grid [service]="yerlestirmeTanimDetayService" [id]="tercihTanimId" [(data)]="yerlestirmeTanimDetay"
            [rowCount]="20">
            <div style="width: 100%;overflow-x: auto;height: 500px;">
                <table class="table   table-responsive gn-middle table-row-bordered fs-7 gy-2"
                    id="kl_subscriptions_table">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 " style="color:#cd7f32">
                            <th class="text-center">Yerleştirme No</th>
                            <th class="text-center">Yerleşen Sayısı</th>
                            <th class="text-center">Yerleşen Listesi</th>
                            <th class="text-center">Tarih</th>
                            <th class="text-center">Yayında Mı</th>
                            <th class="text-center">İşlemler</th>
                        </tr>
                    </thead>
                    <tbody class="text-gray-600 ">
                        <!-- dblclick -->
                        <tr *ngFor="let item of yerlestirmeTanimDetay;let i=index;" style="color: black;">
                            <td class="text-black text-left text-center fs-5" style="vertical-align: middle;">
                                <b>{{item.yerlestirmeNo}} .Yerleştirme</b>
                            </td>

                            <td class="text-black text-left text-center fs-5" style="vertical-align: middle;">
                                <b>{{item.yerlesenSayisi}}</b>
                            </td>

                            <td class="text-center fs-5" style="vertical-align: middle;">
                                <button type="button" class="btn btn-primary btn-sm"
                                    [routerLink]="['/tercih-yerlesen-bilgi', item.id]"
                                    [queryParams]="{tercihTanimId: item.tercihTanimId}">
                                    Yerleşen Listesi
                                </button>
                            </td>

                            <td class="text-center fs-5" style="vertical-align: middle;">
                                {{item.yerlestirmeTarihi | date:'dd.MM.yyyy HH:mm'}}
                            </td>

                            <td class="text-center fw-bold fs-5" style="vertical-align: middle;">
                                <span class=" fs-5 text-gray-800 me-2">
                                    <span *ngIf="item?.yayindaMi;else yayindaDegil">
                                        <i class="fas fa-check fs-3 text-success"></i>
                                    </span>
                                    <ng-template #yayindaDegil>
                                        <i class="fas fa-times fs-3 text-danger"></i>
                                    </ng-template>
                                </span>
                            </td>

                            <td style="vertical-align: middle;">
                                <div class="d-flex justify-content-center flex-shrink-0 gap-1 gap-lg-2">

                                    <a *ngIf="yetkiService.yetkiVar(['TercihYerlestirme.Update'])" (click)="yerlestirmeById(item.id)" style="background-color:#009ef8"
                                        class="btn btn-icon btn-hover-rise  btn-active-color-primary btn-sm me-1"
                                        title="Yerleştirme düzenle">
                                        <i class="far fa-edit text-white"></i>
                                    </a>

                                    <button *ngIf="item.yerlestirmeNo==yerlestirmeTanimDetay.length&& yetkiService.yetkiVar(['TercihYerlestirme.Delete'])" type="button"
                                        style="background-color:#f1416c;" (click)="silModal(item)"
                                        class="btn btn-icon btn-hover-rise btn-active-color-danger btn-sm"
                                        title="Yerleştirmeyi Sil">
                                        <i class="fas fa-trash-alt text-white"></i>
                                    </button>

                                    <button  type="button" class="btn btn-success btn-sm" *ngIf="item.yayindaMi==false && yetkiService.yetkiVar(['TercihYerlestirme.Yayinlama'])"
                                        (click)="yayinIslem(item.id,1)">
                                        <i class="fas fa-toilet-paper text-white me-1"></i> Listeyi yayınla
                                    </button>

                                    <button type="button" class="btn btn-danger btn-sm" (click)="yayinIslem(item.id,0)"
                                        *ngIf="item.yayindaMi==true && yetkiService.yetkiVar(['TercihYerlestirme.Yayinlama'])">
                                        <i class="fas fa-toilet-paper-slash text-white me-1"></i> Yayından kaldır
                                    </button>

                                </div>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </grid>
    </div>
</div>

<button id="openModalYerlestirmeButton"   [hidden]="true"   data-bs-toggle="modal" data-bs-target="#yerlestirme_modal"
data-toggle="modal">Open Modal</button>
<div id="yerlestirme_modal" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered  modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <i class="fas fa-bookmark text-danger me-2" style="font-size: large; padding-right:2px;"></i>
                <h3 class="modal-title text-center">{{yerlestirmeTanimDetay.length + 1}}. yerleştirme bilgileri</h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form [formGroup]="detayYerlestirmeForm" (submit)="yerlestirmeYap()">
                <div class="modal-body">
                    <div class="row mb-3">
                        <div class="col-6 form-floating">
                            <input formControlName="kayitBaslangicTarih" type="datetime-local"
                                class="form-control datepicker" id="kayitBaslangicTarih"
                                placeholder="KayitBaslangicTarih" name="kayitBaslangicTarih"
                                style="border-color: #8fb9d5;" [value]="detayYerlestirmeForm.value.kayitBaslangicTarih |
                            date:'yyyy-MM-dd HH:mm'" />
                            <label style="color:#009ef7;" class="fs-4 fw-bolder" for="BaslangicTarihi">
                                Kayıt Başlangıç Tarih / Saat</label>
                            <div class="text-danger" *ngFor="let hata of hatalar['KayitBaslangicTarih']">
                                {{hata}}
                            </div>
                        </div>
                        <div class="col-6 form-floating ">
                            <input formControlName="kayitBitisTarih" type="datetime-local"
                                class="form-control datepicker" id="kayitBitisTarih" placeholder="Kayıt bitiş tarihi"
                                name="kayitBitisTarih" style="border-color: #8fb9d5;" [value]="detayYerlestirmeForm.value.kayitBitisTarih |
                            date:'yyyy-MM-dd HH:mm'" />
                            <label style="color:#009ef7;" class="fs-4 fw-bolder" for="kayitBitisTarih">
                                Kayıt Bitiş Tarih / Saat</label>
                            <div class="text-danger" *ngFor="let hata of hatalar['KayitBitisTarih']">
                                {{hata}}
                            </div>
                        </div>
                    </div>

                    <div class="card-footer">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <submit-button [loader]="yapiliyor"
                                    [label]="yeniYerlestirme?'Yerleştirmeyi Yap':'Güncelle'"
                                    [loading]="yeniYerlestirme?'Yerleştirme yapılıyor...':'Güncelleniyor...'">
                                </submit-button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>