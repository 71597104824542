<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-2 flex-column mb-0">
                    <span style="font-size: medium;">
                        <i class="far fa-map me-2 fs-3" style="color: #d4af37;"></i>Tercih Yerleşen
                        Listesi &nbsp;(
                        {{this.yerlestirmeTanim.aciklama }} )
                        <!-- <span>
                            &nbsp;
                            <i class="fas fa-calendar-alt text-success fs-3"></i>
                            {{this.yerlestirmeTanim.yerlestirmeNo}} </span> -->
                    </span>
                </h1>

            </div>
            <div class="d-flex align-items-center gap-2 gap-lg-3">
                <button class="btn btn-sm  btn-shadow-hover btn-secondary fs-6  border border-primary"
                    (click)="exportexcel()" style="color: green;">
                    <i class="fas fa-file-excel " style="color: green;"></i>
                    Tüm listeyi excel olarak al
                </button>
                <button type="button" [routerLink]="['/tercih-yerlestirme-islem', tercihTanimId]"
                    id="kt_modal_new_target_cancel" class="btn btn-danger btn-hover-rise me-3"><i
                        class="fas fa-reply"></i> Geri
                </button>
            </div>
        </div>
    </div>

    <div class="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" class="container-fluid">
            <!-- <form (submit)="filterById()">
            <label>
              Min
              <input type="text" name="adi" id="adi" [(ngModel)]="basvuruTanimlar.adi" />
            </label>
            
            <button class="btn btn-primary " type="submit">Filter by ID</button>
          </form>   -->

            <div class="card " style="border-top: 3px rgb(146, 199, 224) solid;">
                <div class="card-body table-responsive" style="width: 100%;">
                    <table datatable [dtOptions]="dtOptions" class="row-border hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th><input class="form-control search-input" style="border-color: #8fb9d5;" id="1"
                                        type="text" placeholder="Aday No" />
                                </th>
                                <th><input class="form-control search-input" style="border-color: #8fb9d5;" id="2"
                                        type="text" placeholder="Adı" />
                                </th>
                                <th><input class="form-control search-input" style="border-color: #8fb9d5;" id="3"
                                        type="text" placeholder="Soyadı" />
                                </th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>
                                    <input class="form-control search-input" style="border-color: #8fb9d5;" id="9"
                                        type="text" placeholder="Fakülte" />
                                </th>
                                <th><input class="form-control search-input" style="border-color: #8fb9d5;" id="10"
                                        type="text" placeholder="Bölüm" /></th>
                                <th><input class="form-control search-input" style="border-color: #8fb9d5;" id="11"
                                        type="text" placeholder="Program" /></th>
                            </tr>
                            <tr>
                                <th class="max-w-10px">#</th>
                                <th class="min-w-75px">Aday No</th>
                                <th class="max-w-50px">Adı</th>
                                <th class="max-w-50px">Soyadı</th>
                                <th class="min-w-75px">Doğum Tarihi</th>
                                <th class="min-w-75px">Uyruk</th>
                                <th class="min-w-125px">SınavAdı</th>
                                <th class="min-w-75px">Sınav Puan Yüzlük</th>
                                <th class="min-w-75px text-center">Değerlendirme Puanı</th>
                                <th class="min-w-100px">Fakülte</th>
                                <th class="min-w-100px">Bölüm</th>
                                <th class="min-w-100px">Program</th>
                                <th class="max-w-10px">Tercih Sıra No</th>
                            </tr>
                        </thead>

                        <tbody *ngIf="basvuruDetay?.length!= 0" style="font-size: small;">
                            <tr *ngFor="let basvuru of basvuruDetay;let i=index;">
                                <td class="text-info text-left fs-5">
                                    <b>{{i+1}}</b>

                                </td>
                                <td>
                                    {{ basvuru?.basvuruNo }}
                                </td>
                                <td>
                                    {{ basvuru?.adi }}
                                </td>
                                <td>
                                    {{ basvuru?.soyadi }}
                                </td>
                                <td>
                                    {{ basvuru?.dogumTarih | date:'dd.MM.yyyy' }}
                                </td>
                                <td>
                                    {{ basvuru?.ilkUyrukAdi }}
                                </td>
                                <td>
                                    {{ basvuru?.sinavTurAdi }}
                                </td>
                                <td class="fw-bolder text-center">
                                    {{ basvuru?.sinavPuanYuzluk }}
                                </td>
                                <td class="fw-bolder text-center">
                                    {{ basvuru?.degerlendirmePuan }}
                                </td>
                                <td>
                                    {{ basvuru?.yerlestigiFakAd }}
                                </td>
                                <td>
                                    {{ basvuru?.yerlestigiBolAd }}
                                </td>
                                <td>
                                    {{ basvuru?.yerlestigiProgAd }}
                                </td>
                                <td>
                                    {{ basvuru?.tercihSiraNo }}
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="basvuruDetay?.length == 0">
                            <tr>
                                <td colspan="13" class="no-data-available text-center">Herhangi bir veri bulunamadı.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>


<div style="display: none;">
    <table id="kl_subscriptions_table_excel" class="row-border hover" >
        <thead>
            <tr>
                <th class="max-w-10px">#</th>
                <th class="min-w-75px">Aday No</th>
                <th class="max-w-50px">Adı</th>
                <th class="max-w-50px">Soyadı</th>
                <th class="min-w-75px">Doğum Tarihi</th>
                <th class="min-w-75px">Uyruk</th>
                <th class="min-w-125px">SınavAdı</th>
                <th class="min-w-75px">Sınav Puan Yüzlük</th>
                <th class="min-w-75px text-center">Değerlendirme Puanı</th>
                <th class="min-w-100px">Fakülte</th>
                <th class="min-w-100px">Bölüm</th>
                <th class="min-w-100px">Program</th>
                <th class="max-w-10px">Tercih Sıra No</th>
            </tr>
        </thead>
    
        <tbody *ngIf="tumBasvurular?.length!= 0" style="font-size: small;">
            <tr *ngFor="let basvuru of tumBasvurular;let i=index;">
                <td class="text-info text-left fs-5">
                    <b>{{i+1}}</b>
    
                </td>
                <td>
                    {{ basvuru?.basvuruNo }}
                </td>
                <td>
                    {{ basvuru?.basvuruBilgi?.adi }}
                </td>
                <td>
                    {{ basvuru?.basvuruBilgi?.soyadi }}
                </td>
                <td>
                    {{ basvuru?.basvuruBilgi?.dogumTarihi | date:'dd.MM.yyyy' }}
                </td>
                <td>
                    {{ basvuru?.ilkUyrukAdi }}
                </td>
                <td>
                    {{ basvuru?.sinavTurAdi }}
                </td>
                <td class="fw-bolder text-center">
                    {{ basvuru?.sinavPuanYuzluk }}
                </td>
                <td class="fw-bolder text-center">
                    {{ basvuru?.degerlendirmePuan }}
                </td>
                <td>
                    {{ basvuru?.basvuruBilgi?.yerlestigiFakAd }}
                </td>
                <td>
                    {{ basvuru?.basvuruBilgi?.yerlestigiBolAd }}
                </td>
                <td>
                    {{ basvuru?.basvuruBilgi?.yerlestigiProgAd }}
                </td>
                <td>
                    {{ basvuru?.basvuruBilgi?.yerlestigiTercihSiraNo }}
                </td>
            </tr>
        </tbody>
    </table></div>
