import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '../models/response.model';
import { TercihBasvuruTip } from '../models/tercihBasvuruTip.model';

@Injectable({
  providedIn: 'root'
})

export class TercihBasvuruTipService {
  apiUrl = '/api/tercihBasvuruTip';
  constructor(private http: HttpClient) { 
  }

  getAll(query: string = '', page: number = 1, rowCount: number = 10): Observable<Response<TercihBasvuruTip[]>> {
    return this.http.get<Response<TercihBasvuruTip[]>>(this.apiUrl + '/getList', {
      params: {
        'aramastr': query,
        'sayfasayisi': page,
        'sayfadakisatirsayisi': rowCount
      }
    });
  }

  getListAll(): Observable<Response<TercihBasvuruTip[]>> {
    return this.http.get<Response<TercihBasvuruTip[]>>(this.apiUrl + '/getListAll');
  }

  getById(id: number): Observable<Response<TercihBasvuruTip>> {
    return this.http.get<Response<TercihBasvuruTip>>(this.apiUrl + '/getById?id=' + id);
  }

  delete(tercihBasvuruTip: TercihBasvuruTip): Observable<Response<TercihBasvuruTip>> {
    return this.http.post<Response<TercihBasvuruTip>>(this.apiUrl + '/delete', tercihBasvuruTip)
  }


  addUpdate(tercihBasvuruTip: TercihBasvuruTip): Observable<Response<TercihBasvuruTip>> {
    if (tercihBasvuruTip.id > 0) {
      return this.http.post<Response<TercihBasvuruTip>>(this.apiUrl + "/update", tercihBasvuruTip)
    }
    else {
      return this.http.post<Response<TercihBasvuruTip>>(this.apiUrl + "/add", tercihBasvuruTip)
    }
  }
}